import React, { useEffect, useState, useRef }   from "react";
import { Button, Row, Col, Carousel, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';


function Gallery({ site, edit, onChange, ...props })
{
  /**
   * IMPORTANT!!!!
   * Make sure to update the counter part in the laravel blade template
   */
  const [popup, setPopup] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const galleryContainer = useRef(null);

  const moveItem = ({oldIndex, newIndex}) => {
    const newItems = props.items?.map && [].concat(props.items) || [];

    const items = newItems.splice(oldIndex, 1);

    newItems.splice(newIndex, 0, items[0]);

    onChange({ ...props, items: newItems });
  };

  const removeItem = index => {
    const newItems = props.items?.map && [].concat(props.items) || [];

    newItems.splice(index, 1);

    onChange({ ...props, items: newItems });
  };


  return (
    <section className="block block-gallery">
      <div className="container">

        {edit?.selectImage && (
          <div className="gallery-items"  ref={galleryContainer}>
            <SortableContainer useDragHandle axis="xy" onSortEnd={moveItem} helperContainer={_ => galleryContainer.current}>
              {props.items && props.items.map((item, index) => (
                <SortableItem {...{ key: `block-${index}`, index, remove: _ => removeItem(index), item }} />
              ))}
            </SortableContainer>

            <Button variant="success" onClick={_ => edit.selectImage({ multiple: true }).then(images => {
              onChange({
                ...props,
                items: [].concat(props.items || []).concat(images),
              })
            })}>
                <span className="bi bi-plus-lg" />
            </Button>

          </div>
        ) || (

        <>
        <Row className="gallery-items" ref={galleryContainer}>
          {props.items && props.items.map((item, index) => (
            <Col xl={3} key={index}>
              <GalleryItem {...{ item, onClick: _ => { setPopup(true); setIndex(index) } }} />
            </Col>
          ))}
        </Row>

        {popup && (
          <Modal show={true} onHide={_ => setPopup(false)} fullscreen={true}>
            <Modal.Header closeButton>
            </Modal.Header>

            <Modal.Body className="d-flex align-items-center justify-content-center">
              <Carousel activeIndex={index} onSelect={handleSelect}>
                {props.items && props.items.map((item, index) => (
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={item.url}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>

            </Modal.Body>

          </Modal>

        ) || ''}

        </>
        )}
      </div>
    </section>
  );
}

export default Gallery;

const GalleryItem = ({ site, item, onClick }) => (
  <a className="gallery-item" href="javascript:void(0);" onClick={onClick}>
    <img src={item.url} />
  </a>
);

const SortableContainer = sortableContainer(({ children }) => (
  <Row>
    {children}
  </Row>
));

const SortableHandle = sortableHandle(({children, items, ...props}) => (
  <span className="sort-handle btn btn-sm btn-primary btn-outline-light block-option">
    <span className="bi bi-arrows-move" />
  </span>
))


const SortableItem = sortableElement(({ item, remove, ...props }) => {
  const { t } = useTranslation();

  return (
    <Col xl={3}>
      <GalleryItem {...{ item }} />

      <span className="gallery-item-nav">
        <SortableHandle />

        <Button variant="danger" size="sm" className="btn-outline-light" onClick={remove}>
          <span className="bi bi-trash" />
        </Button>
      </span>
    </Col>
  );
});
