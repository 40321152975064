function PromotionsIcon({classDefined}) {
    return (
        <div className={`custom-logo ${classDefined}`}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.223 6.03003C14.639 2.34403 12.091 -0.459975 10.802 0.0630251C8.61296 0.954025 12.106 5.22702 1.35496 9.59602C0.425957 9.97502 0.190957 11.484 0.579957 12.388C0.967957 13.29 2.23796 14.189 3.16696 13.812C3.32796 13.746 3.91796 13.556 3.91796 13.556C4.58096 14.447 5.27496 13.919 5.52196 14.484L6.67996 17.144C6.89896 17.646 7.39496 18.111 7.75496 17.974L9.80496 17.195C10.273 17.017 10.384 16.599 10.241 16.271C10.087 15.916 9.45496 15.812 9.27396 15.398C9.09396 14.986 8.50496 13.66 8.33596 13.242C8.10596 12.674 8.59496 12.211 9.30596 12.138C14.2 11.626 15.115 14.65 16.781 13.972C18.068 13.447 17.806 9.71303 16.223 6.03003ZM15.672 12.006C15.385 12.121 13.459 10.604 12.229 7.73903C10.998 4.87603 11.153 2.25903 11.439 2.14202C11.725 2.02702 13.604 3.85903 14.834 6.72203C16.065 9.58503 15.958 11.889 15.672 12.006Z" fill="white"/>
            </svg>

        </div>
    );
}

export default PromotionsIcon;
