import {Button, Table, Row, Col, Form}                   from "react-bootstrap";
import LoadingPage from "@/components/LoadingPage";
import { Countries, ServiceCategories, useAuth } from "@/services";
import { useFieldArray } from 'react-hook-form';
import Select from "react-select";
import AsyncSelect from "react-select/async";
import React, { useRef, useState, useEffect } from "react";
import {useTranslation} from "react-i18next";

function SpendingCaps({
        spending_caps,
    }) {
    spending_caps = spending_caps || [];


    const auth = useAuth();

    const {t} = useTranslation();

    const addon_spending_caps  = spending_caps.filter(_ => typeof _.category_id !== 'undefined');
    const site_spending_caps   = spending_caps.filter(_ => typeof _.site_id !== 'undefined');
    const region_spending_caps = spending_caps.filter(_ => typeof _.country_id !== 'undefined');

    const SpendingCap = ({ item, index, name }) => {
        return (
            <tr>
                <td>
                    {name}
                </td>
                <td>
                    <div className="row" style={{minWidth: 400}}>
                        <div className="col-4">
                            {item.type_of_limit}
                        </div>

                        <div className="col-4">
                            {item.cap_period}
                        </div>

                        <div className="col-4">
                            {item.cost_cap}
                        </div>
                    </div>
                </td>
            </tr>
        )
    };


    return (
        <div>
        <div className="form-group my-4">
            <h5>{t('addon_specific')}</h5>
            <p>{t('addon_spending_cap_instructions')}</p>

            <Table striped responsive bordered hover className="mt-4">
                <thead>
                <tr>
                    <th style={{minWidth: 150}}>
                        <h5>{t('addon')}</h5>
                    </th>
                    <th>{t('covered_costs')}</th>
                </tr>
                </thead>
                <tbody>
                {addon_spending_caps.map((item) => {
                    return <SpendingCap item={item} key={item.id} index={spending_caps.indexOf(item)} name={item?.category?.name} />;
                })}

                </tbody>
            </Table>
        </div>

        <div className="form-group my-4">
            <h5>{t('site_specific')}</h5>
            <p>{t('site_spending_cap_instructions')}</p>

            <Table striped responsive bordered hover className="mt-4">
                <thead>
                <tr>
                    <th style={{minWidth: 150}}>
                        <h5>{t('site')}</h5>
                    </th>
                    <th>{t('covered_costs')}</th>
                </tr>
                </thead>
                <tbody>
               {site_spending_caps.map((item) => {
                    return <SpendingCap item={item} key={item.id} index={spending_caps.indexOf(item)} name={item?.site?.name} />;
                })}


                </tbody>
            </Table>
        </div>


        <div className="form-group my-4">
            <h5>{t('region_specific')}</h5>
            <p>{t('region_spending_cap_instructions')}</p>

            <Table striped responsive bordered hover className="mt-4">
                <thead>
                <tr>
                    <th style={{minWidth: 150}}>
                        <h5>{t('region')}</h5>
                    </th>
                    <th>{t('covered_costs')}</th>
                </tr>
                </thead>
                <tbody>
                {region_spending_caps.map((item) => {
                    return <SpendingCap item={item} key={item.id} index={spending_caps.indexOf(item)} name={item?.country?.name} />;
                })}

                </tbody>
            </Table>
        </div>

        </div>
    );
}

export default SpendingCaps;
