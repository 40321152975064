import React, {useEffect, useState} from "react";
import { Card } from "react-bootstrap";
import LoadingPage from "@/components/LoadingPage";
import {useTranslation} from "react-i18next";
import {Link, useHistory, useLocation} from "react-router-dom";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DatePicker from "react-datepicker";
import Select from "react-select";
import * as moment from "moment";
import {useAuth} from "@/services/Auth";
import { Helmet } from "react-helmet";
import Table, { useColumns }                      from "@/components/Tables/Promotions";
import * as csv from 'csv-stringify/lib/sync';
import FileSaver from 'file-saver';
import Filters from './components/Filters';

function Promotions() {
    const history = useHistory();
    const route = useLocation();

    let auth = useAuth();

    const {t} = useTranslation();
    const columns = useColumns();

    const [query, setQuery] = useState(route?.state?.query || {});

    const [loading, setLoading] = useState(true);
    const [exporting, setExporting] = useState(false);

    useEffect(() => {
        history.replace(
            route.pathname,
            {
                ...route.state,

                query,
            }
        )
    }, [query]);

    return (
        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{t('drawer_link_titles.promotions')} - {t('app')}</title>
            </Helmet>

            <Card.Subtitle className="d-flex justify-content-between flex-md-row flex-column">
                <h4 className="text-primary">{t('drawer_link_titles.promotions')}</h4>

                <div className="d-flex align-items-center">
                    {
                        auth?.roles?.create_promotion &&
                        <>
                        <Link to={`/promotions/import`} className="btn btn-primary mx-1 my-1 my-md-0">{t('import_promotions')}</Link>
                        <Link to="/promotions/create" className="btn btn-secondary mx-1 my-1 my-md-0">{t('new_promotion')}</Link>
                        </>
                    }
                </div>

            </Card.Subtitle>

            <Filters searching={loading || exporting} values={query} onSubmit={setQuery} />

            <Table query={query} showPagination={true} onData={_ => setLoading(!_)} />
        </Card>
    )
}

export default Promotions;
