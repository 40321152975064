import {useTranslation}                                   from "react-i18next";
import {useAuth}                                          from "@/services/Auth";
import {useParams}                                        from "react-router-dom";
import React, {useEffect, useState}                       from "react";
import LoadingPage                                        from "@/components/LoadingPage";
import {Button, Card, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import api                                                from "@/services/Api";
import Select                                             from "react-select";
import FleetForm                                          from "./FleetForm";
import SuccessDialog                                      from "../../../components/Modals/SuccessDialog";
import { Helmet } from "react-helmet";

function FleetCreate(props) {

    const {t} = useTranslation();
    let auth = useAuth();

    let {id} = useParams();

    const [loading, setLoading] = useState(true);
    const [creatingFleet, setCreatingFleet] = useState(false);
    const [clients, setClients] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [serverErrors, setServerErrors] = useState([]);
    const [createSuccess, setCreateSuccess] = useState(false);
    const [createdFleet, setCreatedFleet] = useState('');

    const handleSubmit = (payload) => {
        setCreatingFleet(true);
        auth.postRequest('/fleets', payload)
            .then(response => {
                setCreatingFleet(false);
                if (response.data.message && response.data.message === "OK") {
                    setCreateSuccess(true);
                    setCreatedFleet(response.data.fleet_id);
                }
            })
            .catch(error => {
                setCreatingFleet(false);
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }
                    setServerErrors(serverErrors)
                }
            })
    };

    const getVehicles = () => {
        if (id == null) {
            auth.getRequest('/vehicles')
                .then(response => {
                    setVehicles(response.data.vehicles);
                    setLoading(false)
                })
                .catch(error => {
                    setLoading(false)
                })
        } else {
            auth.postRequest('/vehicles/client-vehicles', {
                'company_id': id,
            })
                .then(response => {
                    if (response.data.message === 'OK') {
                        setVehicles(response.data.vehicles);
                    }
                })
                .catch(error => {

                })
        }
    }

    const getClients = () => {
        auth.getRequest('/clients')
            .then(response => {
                setClients(response.data.clients);
            })
            .catch(error => {
            })
    }

    const handleClose = () => {
        setCreateSuccess(false)
        if (createdFleet !== '' && createdFleet != null) {
            props.history.push(`/fleets/${createdFleet}`)
        }
    }

    useEffect(() => {
        if (auth.user.is_platform_admin && id == null) {
            getClients();
        } else {
            setLoading(false)
        }
        getVehicles();
    }, []);

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('create')} / {t('drawer_link_titles.fleets')} - {t('app')}</title>
                </Helmet>



                <SuccessDialog
                    show={createSuccess}
                    message="success_dialog.fleet_created"
                    handleClose={handleClose}
                />

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h4 className="text-primary">{t('new_fleet')}</h4>
                </Card.Subtitle>
                <FleetForm
                  fleet={{
                        company_id   : id != null ? id : auth.user.company_id != null ? auth.user.company_id : '',
                        name         : '',
                        contact_name : '',
                        contact_email: '',
                        vehicles     : [],
                        compliance_licences: 0,
                        compliance_auto_price: true,
                    }}
                    loading={creatingFleet}
                    buttonText="save_new_fleet"
                    fleetCompany={''}
                    initialVehicles={[]}
                    availableVehicles={vehicles}
                    fleetCompanies={clients}
                    handleSubmit={handleSubmit}
                    serverErrors={serverErrors}
                />
            </Card>
        )
    )
}

export default FleetCreate;
