import {Spinner} from "react-bootstrap";

import '../assets/scss/components/loadingPage.scss'

function LoadingPage() {
    return (
        <div className="loading-holder d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" variant="primary" size="lg">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default LoadingPage;