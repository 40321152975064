import {Badge, Button, Card, Col, Row, Spinner, Table, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation, Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {useAuth} from "@/services/Auth";
import LoadingPage from "@/components/LoadingPage";
import PaymentMethodForm from "@/components/Forms/PaymentMethodForm";
import * as Yup from "yup";
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Helmet} from "react-helmet";

function CheckOut({site, booking, updateBooking, setBookingErrors, wait}) {
    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const [loading, setLoading] = useState(false);
    const [payment_methods, updatePaymentMethods] = useState(false);

    const makeBooking = () => {
        setLoading(true);
        auth.postRequest('bookings', booking)
            .then(response => {
                if (response.data.message === 'OK') {
                    updateBooking(response.data.booking);
                }
            })
            .catch(error => {
                if (error.response.status == 422) {
                    setBookingErrors(error.response.data.errors);
                }
            })
            .finally(error => {
                setLoading(false);
            })
    }

    if (loading) {
        return <LoadingPage/>;
    }

    const allowSubmit = (booking.total_on_card <= 0 || payment_methods.length > 0) && booking.payment_method;

    return (
        <div className="booking booking-checkout container-fluid py-4">
            <Helmet>
                <title>{t('payment')} / {t('drawer_link_titles.bookings')} - {t('app')}</title>
            </Helmet>

            <h1 className="h2">{t('create_a_booking')} {t('steps', {current: 4, steps: 4})}</h1>
            <h4 className="mb-2">{t('payment')}</h4>

            <PaymentDetails {...{
                site,
                booking,
                payment_methods,
                updatePaymentMethods,
            }} />

            {!!site.approval_status && <div className="text-danger mt-2 d-inline-flex extra-small gap-2">
                <img src="/images/error-icon.png" alt="error-icon" className="img-fluid error-icon"/>
                {t('instant_booking.warning')}
            </div>}

            <div className="d-grid gap-2 mt-4">
                <Button
                    disabled={!allowSubmit || wait}
                    onClick={() => allowSubmit && makeBooking()}
                    variant="success">
                    {wait ? t('please_wait') : t('place_order')}
                </Button>
            </div>

        </div>
    )
}

export default CheckOut;

const PaymentDetails = ({site, booking, payment_methods, updatePaymentMethods}) => {
    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const [loading, setLoading] = useState(true);
    const [view, setView] = useState(false);

    const getUserPaymentData = () => {
        auth.getRequest(`/payment-methods`, {active: 1})
            .then(response => {
                updatePaymentMethods(response.data.payment_methods);
                setView(false);
            })
            .finally(error => {
                setLoading(false);
            })
    };

    const changeDefaultPaymentMethods = (methodId) => {
        setLoading(true);
        auth.putRequest(`/payment-methods/${methodId}`, {
            default_method: 1
        })
            .then(response => {
                if (response.data.message === 'OK') {
                    const newRecords = [].concat(payment_methods);

                    for (var index in newRecords) {
                        newRecords[index].default_method = newRecords[index].id === methodId;
                    }

                    updatePaymentMethods(newRecords);
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
            })

    }

    const createPaymentMethod = (values, {setError}) => {
        setLoading(true);

        let payload = values;
        payload.user_id = auth.user.id;

        auth.postRequest(`/payment-methods`, payload)
            .then(response => {
                if (response.data.message === 'OK') {
                    getUserPaymentData();
                }
            })
            .catch(error => {
                if (error?.response?.status === 422) {
                    for (var attribute in error?.response?.data.errors) {
                        const errors = error?.response?.data?.errors[attribute] || [];

                        errors[0] && setError(attribute, {type: 'manual', message: t(errors[0])});
                    }
                }

                setLoading(false);
            })
    };


    useEffect(() => {
        getUserPaymentData();
    }, []);


    if (loading) {
        return <LoadingPage/>;
    }

    if (view == 'new' || (payment_methods.length <= 0 && booking.total_on_card > 0)) {
        return <PaymentMethodForm handleSubmit={createPaymentMethod} loadingButton={loading}/>;
    }

    const display_payment_methods = payment_methods && [].concat(payment_methods).sort((a, b) => b.default_method - a.default_method) || [];
    const default_method = display_payment_methods.shift();

    return (
        <div>
            {
                (booking.payment_method && booking.total_on_account > 0) &&
                <Card className="border-1 payment-card p-3 my-2">
                    <Card.Title className="mt-4">
                        {t('on_account')}
                    </Card.Title>

                    <Card.Title className="mt-4">
                        {t('billing_address')}
                    </Card.Title>


                    <Card.Text>
                        <p>{booking.payment_method.holder_name}</p>
                        <p>{booking.payment_method.email}</p>
                        <p>{booking.payment_method.address}</p>
                        <p>{booking.payment_method.city}, {booking.payment_method.country?.name}</p>
                    </Card.Text>

                    <div className="d-md-flex justify-content-between my-2 py-2">
                        <div className="d-md-flex align-items-center">
                            {booking.payment_method.brand == 'credit' && t('mpark_credit_account') || (<>
                                <img alt={booking.payment_method.brand} className="mx-1 card-image"
                                     src={`/images/${booking.payment_method.brand}.png`}/>

                                <div className="mx-2">
                                    {t(booking.payment_method.number)}
                                </div>

                                <div className="mx-2">
                                    {t(booking.payment_method.expire)}
                                </div>
                            </>)}

                        </div>

                        <div className="d-flex">
                            <div className="mx-2 font-weight-bold">
                                {booking.total_on_account.toLocaleString(undefined, {minimumFractionDigits: 2})}&nbsp;{booking.billing_currency}
                            </div>
                        </div>
                    </div>
                </Card>
            }

            {booking.total_on_card && (
                <Card className="border-1 payment-card p-3 my-2">
                    <Card.Title className="mt-4">
                        {t('on_card')}
                    </Card.Title>


                    {default_method && (
                        <>
                            <Card.Title className="mt-4">
                                {t('billing_address')}
                            </Card.Title>


                            <Card.Text>
                                <p>{default_method.holder_name}</p>
                                <p>{default_method.email}</p>
                                <p>{default_method.address}</p>
                                <p>{default_method.city}, {default_method.country?.name}</p>
                            </Card.Text>

                            <div className="d-md-flex justify-content-between">
                                <div className="d-md-flex align-items-center">
                                    {default_method.brand == 'credit' && t('mpark_credit_account') || (<>
                                        <img alt={default_method.brand} className="mx-1 card-image"
                                             src={`/images/${default_method.brand}.png`}/>

                                        <div className="mx-2">
                                            {t(default_method.number)}
                                        </div>

                                        <div className="mx-2">
                                            {t(default_method.expire)}
                                        </div>
                                    </>)}

                                </div>

                                <div className="d-md-flex align-items-center">
                                    <div className="mx-2 font-weight-bold order-sm-2">
                                        {booking.total_on_card.toLocaleString(undefined, {minimumFractionDigits: 2})}&nbsp;{site.currency}
                                    </div>

                                    <Button variant="link" onClick={() => setView(view == 'edit' ? false : 'edit')}>
                                        {t('change')}
                                    </Button>
                                </div>
                            </div>
                        </>
                    ) || (
                        <div className="d-grid gap-2">
                            <Button onClick={() => setView('new')} variant="success">
                                {t('add_payment_method')}
                            </Button>
                        </div>
                    ) || ''}
                </Card>
            ) || ''}

            {view == 'edit' && (
                <Row className="mt-4">
                    {display_payment_methods.map((payment_method, index) => (
                        <Col className="col-6 py-3" md={3} key={payment_method.id || index}>
                            <Card role="button"
                                  onClick={() => changeDefaultPaymentMethods(payment_method.id)}
                                  className="border-1 payment-card credit-card d-flex justify-content-center align-items-center"
                                  style={{height: '150px', width: '100%'}}>

                                <div className="d-flex flex-column align-items-center">
                                    <h6>{payment_method.number}</h6>
                                    <p className="m-0">{payment_method.expire}</p>

                                    <img alt={payment_method.brand} className="mx-1 card-image"
                                         src={`/images/${payment_method.brand}.png`}/>
                                </div>
                            </Card>
                        </Col>
                    ))}

                    <Col className="col-6 py-3" md={3}>
                        <Card role="button"
                              onClick={() => setView('new')}
                              className="border-1 payment-card credit-card d-flex justify-content-center align-items-center"
                              style={{height: '150px', width: '100%'}}>

                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <i className="bi bi-plus" style={{fontSize: "5rem"}}/>
                            </div>
                        </Card>
                    </Col>
                </Row>
            )}
        </div>
    );
}


function InvalidPaymentMethod() {
    const {t, i18n} = useTranslation();

    return (
        <div className="d-flex justify-content-center align-items-center" style={{height: "100%"}}>
            <Card border="light">
                <Card.Body className="text-center">
                    <i className="bi bi-credit-card" style={{fontSize: "10rem"}}/>
                    <Card.Title>{t('no_payment_method_found')}</Card.Title>
                    <Card.Text>
                        <p>{t('please_set_up_a_valid_payment_method_for_your_fleet')}</p>
                    </Card.Text>
                    <div className="d-grid gap-2">
                        <Link variant="primary" className="btn btn-primary" to="/">{t('go_to_dashboard')}</Link>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

