import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useAuth } from "@/services";
import { useLayout } from "@/layouts/Layout";
import { Form, Table, Button, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import LoadingPage from "@/components/LoadingPage";
import Paginate from "@/components/Paginate";

function SiteDirectory() {
  const { t } = useTranslation();

  const auth = useAuth();
  const layout = useLayout();

  const route = useLocation();
  const history = useHistory();

  const perPage = 15;
  const [sites, setSites] = useState(false);
  const [totalRecords, setTotalRecords] = useState(null);
  const [query, setQuery] = useState({ });


  const {
    handleSubmit,
    register,
    reset,
  } = useForm();
  const onSubmit = (query) => {
    delete query.page;

    updateQuery(query);
  };

  const updateQuery = (query) => {
    const params = new URLSearchParams(query);

    history.push(route.pathname + '?' + params);
  };

  const statePop = (event) => {
    if(window.location.pathname == route.pathname)
    {
      const _query = {};

      const params = new URLSearchParams(window.location.search);

      params.forEach((value, key) => { _query[key] = value; });

      setQuery(_query);
    }
  };

  useEffect(() => {
      window.addEventListener('pushState', statePop);
      window.addEventListener('popstate', statePop);

      return () => {
          window.removeEventListener('pushState', statePop);
          window.removeEventListener('popstate', statePop);
      };
  }, []);



  useEffect(() => {
    setSites(false);
    reset(query);

    const params = {...query, hasPage: true};

    params.take = perPage;
    params.skip = ((query.page || 1) - 1) * perPage;

    delete params.page;

    auth.getRequest('/sites', params)
      .then(response => {
        setTotalRecords(response.data.total);
        setSites(response.data.sites)
      });
  }, [query]);

  useEffect(() => {
    layout.setCollapsed(true);

    return _ => layout.setCollapsed(false);
  }, []);

  const page = query.page || 1;

  if(!sites)
  {
    return <LoadingPage />;
  }

  return (
    <Container>
      <Helmet>
        <title>{t('marketing.directory')} - {t('app')}</title>
      </Helmet>

      <h1>{t('marketing.directory')}</h1>

      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="me-2">
          <Form.Control
            {...register('search')}
            type="search"
            placeholder={t('search') + '...'}
            />
        </Form.Group>


        <Form.Group>
          <Button variant="primary" type="submit">
            {t('search')}
          </Button>
        </Form.Group>
      </Form>


      <div className="small">
        <div className="table-responsive">
          <Table hover bordered size="lg">
            <thead>
              <tr>
                <th>
                  {t('Name')}
                </th>
              </tr>
            </thead>

            <tbody>
             {sites.map((site, index) => (
                <tr>
                  <td>
                    <Link to={`/pages/${site.slug}/`}>
                      {site.name}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Paginate {...{ changePage: ({ selected: page }) => updateQuery({ ...query, page: page + 1 }), totalRecords, page: page - 1, perPage }} />
        </div>
      </div>
    </Container>
  );
}


export default SiteDirectory;
