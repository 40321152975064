import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import Paginate from "../Paginate";
import {Button} from "react-bootstrap";

function useColumns(filter, revokeAccess) {
    let auth = useAuth();
    const {t} = useTranslation();

    let columns = [
        {
            dataField: 'id',
            text: t('id'),
        },

        {
            dataField: 'name',
            text: t('name'),
            formatter: (cell, row) => (row?.id && <Link to={`/users/${row?.id}`}>{cell}</Link>) || cell,
        },

        {
            dataField: 'user_type',
            text: t('type_of_user'),
        },

        {
            dataField: 'email',
            text: t('email_address'),
        },

        {
            name: 'vehicles',
            dataField: 'user_vehicles',
            text: t('drawer_link_titles.vehicles'),
            formatter: (cell, user) => cell?.map && (
                <>
                    {cell.slice(0, 4).map((vehicle, index, self) => (
                        <Link key={index} to={`/vehicles/${vehicle.id}`}>
                            {vehicle.registration_number}
                            {index !== self.length - 1 ? ',' : ''}&nbsp;
                        </Link>
                    ))}

                    {(cell.length > 4 && ('+ ' + (cell.length - 4)))|| ''}
                </>
            ),
            exportFormatter: (cell, user) => cell?.map && cell.map(_ => _.registration_number).filter(_ => !!_).join(', '),
        },

        {
            dataField: 'account_status',
            text: t('account_status'),
        },

        {
            dataField: 'account_number',
            text: t('account_number'),
        },
        (auth.roles.view_fleets && {
            name: 'fleet',
            dataField: 'user_vehicles',
            text: t('fleet'),
            formatter: (cell, row) => {
                if (cell && cell[0] && cell[0].fleet) {
                    return <Link to={`/fleets/${cell[0].fleet.id}`}>{cell[0].fleet.name}</Link>;
                }
            },
            exportFormatter: (cell, row) => {
                if (cell && cell[0] && cell[0].fleet) {
                    return cell[0].fleet.name;
                }
            },
        }) || '',
        (auth.roles.view_clients && {
            name: 'supplier',
            dataField: 'client',
            text: t('client_supplier'),
            formatter: (cell, row) =>
                (row?.company && (<Link to={`/clients/${row.company.id}`}>{row.company.name}</Link>)) ||
                (row?.supplier && (<Link to={`/suppliers/${row.supplier.id}`}>{row.supplier.company_name}</Link>)) || '',
            exportFormatter: (cell, row) => row?.company?.name || row?.supplier?.company_name,
        }) || '',
    ];


    if (auth.user.is_platform_admin || auth.user.is_platform_operator) {
        columns.push({
            dataField: 'access',
            text: t('access'),
            formatter: (cell, row) => <Button variant="danger" onClick={() => revokeAccess(row.id)}>{t('revoke')}</Button>,
            exportFormatter: null
        });

        columns.push({
            dataField: 'manager',
            text: t('manager'),
            formatter: (cell, row) => (cell?.id && <Link to={`/users/${cell?.id}`}>{cell?.name}</Link>) || null,
            exportFormatter: (cell, row) => cell?.name,
        });
    }

    return (filter && columns.filter(filter)) || columns;
}

function Users({data: recordsProp, paginate, url, query, ...props}) {
    let auth = useAuth();
    const {t} = useTranslation();

    const perPage = props.perPage || 20;
    const exclude = props.exclude || ['access'];
    const revokeAccess = props.revokeAccess || function (userId) {
        console.log(userId);
    }
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);

    const getRecords = () => {
        request?.current?.abort && request.current.abort();

        const critera = {...(query || {})};

        critera.take = perPage;
        critera.skip = page * perPage;

        request.current = auth.getRequest(url || '/users', critera);

        setRecords(false);

        request.current
            .then(response => {
                setRecords(response.data.users);
                setTotal(response.data.total);
            })
    }

    const columns = useColumns(col => {
        return !exclude.includes(col.dataField) && !exclude.includes(col.name)
    }, revokeAccess);

    const changePage = (event) => {
        setPage(event.selected)
    }

    const records = recordsProp || recordsDownloaded;

    useEffect(() => {
        if (!recordsProp) {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <>
            <BootstrapTable
                keyField="id"
                striped
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={records instanceof Array ? t('no_data') : t('loading')}
                {...props}
            />
            {
                showPagination && records && totalRecords && <Paginate {...{changePage, totalRecords, page, perPage}} />
            }
        </>
    );
}

export default Users;

export {
    useColumns
};
