import {Button, Spinner} from "react-bootstrap";
import React             from "react";
import {useTranslation}  from "react-i18next";

function LoadingButton({loading, onSubmit, titleTranslationKey, type, variant, disabled, children}) {
    const {t, i18n} = useTranslation();
    return (
        <div className="d-grid gap-2">
            {!loading ?
             <Button onClick={onSubmit} type={type} disabled={disabled} variant={variant == null ? 'primary' : variant}>{titleTranslationKey && t(titleTranslationKey) || children}</Button>
                      :
             <Button variant={variant == null ? 'primary' : variant} disabled>
                 <Spinner
                     as="span"
                     animation="border"
                     size="sm"
                     role="status"
                     ariaHidden="true"
                 />
                 <span className="mx-2">{t('please_wait')}</span>
             </Button>
            }
        </div>
    )
}

export default LoadingButton;
