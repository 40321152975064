import FavouriteSites from "../../views/pages/FavouriteSites";
import FavouriteIcon  from "../../components/icons/FavouriteIcon";

const favouriteRoutes = [
    {
        path         : "/favourite_sites",
        component    : FavouriteSites,
        exact        : true,
        gate         : 'view_favourite_sites',
        title        : 'Favourite Sites',
        translate_key: 'favourite_sites',
        // icon         : 'bi-heart',
        icon         : <FavouriteIcon/>,
        main         : true,
    },
];
export default favouriteRoutes;
