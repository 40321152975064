import React, { useEffect, useState, useRef }   from "react";
import { Modal } from "react-bootstrap";
import { Countries }   from "@/services";

function DisplayLocation({ location })
{
  const [countries, setCountries] = useState(null);

  useEffect(_ => {
    if(!countries)
    {
      Countries.get().then(setCountries);
    }
  }, [countries]);

  const country = countries && location?.country_id && countries.find(_ => _.id == location.country_id) || null;

  if(!location)
  {
    return <></>;
  }

  return (
    <>
      {typeof location.latitude != 'undefined' && typeof location.longitude != 'undefined' &&
       location.latitude != null && location.longitude != null && (
        <a href={`//maps.google.com?q=${location.latitude},${location.longitude}`} rel="nofollow" target="_blank">
          {location.latitude}{', '}{location.longitude}
        </a>
      ) || ''}

      {(
        ( location.address_1 || '') + '\n' +
        ( location.address_2 || '') + '\n' +
        ( location.address_3 || '') + '\n' +
        ( location.county || '') + '\n' +
        ( location.city || '') + '\n' +
        ( location.postcode || '') + '\n' +
        (country?.name || '')  ).replace(/\n(\n)/g, '$1').split('\n').map((text, index) => (
          <div key={index}>{text}</div>
      ))}
    </>
  );
}


export default DisplayLocation;
