import Notifications from "../../views/pages/Notifications";

const helpRoutes = [

    {
        path     : "/notifications",
        component: Notifications,
        exact    : true,
        gate     : 'view_notifications',
        main     : false,
    },
]
export default helpRoutes;