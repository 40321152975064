function SettingsIcon({classDefined}) {
    return (
        <div className={`custom-logo ${classDefined}`}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.343998 12.9984L2.344 16.1701C2.47678 16.3805 2.69527 16.5339 2.95143 16.5966C3.20758 16.6594 3.48044 16.6263 3.71 16.5047L5.106 15.7658C5.686 16.1847 6.327 16.5285 7.001 16.7852V18.25C7.001 18.4931 7.10635 18.7263 7.29389 18.8982C7.48143 19.0701 7.73578 19.1667 8.001 19.1667H12.001C12.2662 19.1667 12.5206 19.0701 12.7081 18.8982C12.8956 18.7263 13.001 18.4931 13.001 18.25V16.7852C13.6803 16.5259 14.3178 16.183 14.896 15.7658L16.292 16.5047C16.769 16.7567 17.383 16.6055 17.658 16.1701L19.658 12.9984C19.7896 12.7878 19.8251 12.538 19.7567 12.3034C19.6884 12.0689 19.5217 11.8686 19.293 11.7462L17.921 11.0193C18.0282 10.3433 18.0275 9.65653 17.919 8.98066L19.291 8.25375C19.767 8.00166 19.932 7.43791 19.656 7.00158L17.656 3.82991C17.5232 3.61953 17.3047 3.46611 17.0486 3.40336C16.7924 3.34062 16.5196 3.3737 16.29 3.49533L14.894 4.23416C14.3165 3.81651 13.6793 3.47353 13 3.21483V1.74999C13 1.50688 12.8946 1.27372 12.7071 1.10181C12.5196 0.929905 12.2652 0.833328 12 0.833328H8C7.73478 0.833328 7.48043 0.929905 7.29289 1.10181C7.10535 1.27372 7 1.50688 7 1.74999V3.21483C6.32072 3.47407 5.68318 3.81701 5.105 4.23416L3.71 3.49533C3.59637 3.43498 3.47087 3.39577 3.34069 3.37992C3.2105 3.36408 3.07819 3.37192 2.95132 3.403C2.82445 3.43407 2.7055 3.48777 2.60129 3.56103C2.49708 3.63428 2.40965 3.72565 2.344 3.82991L0.343998 7.00158C0.212386 7.21223 0.176897 7.46202 0.245261 7.69655C0.313624 7.93107 0.480302 8.13134 0.708998 8.25375L2.081 8.98066C1.97313 9.65662 1.97313 10.3434 2.081 11.0193L0.708998 11.7462C0.232998 11.9983 0.0679979 12.5621 0.343998 12.9984ZM10 6.33333C12.206 6.33333 14 7.97783 14 10C14 12.0222 12.206 13.6667 10 13.6667C7.794 13.6667 6 12.0222 6 10C6 7.97783 7.794 6.33333 10 6.33333Z" fill="white"/>
            </svg>

        </div>
    );
}

export default SettingsIcon;
