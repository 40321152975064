import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import {useEffect} from "react";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";

function Error0() {
    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const updateErrorPage = auth.updateErrorPage;
    useEffect(() => {
        auth?.errorPage !== false || (auth?.updateErrorPage && auth.updateErrorPage(true));

        return () => {
            updateErrorPage(false);
        }
    }, []);
    return (
        <div className="d-flex justify-content-center align-items-center" style={{height: "100%"}}>
            <Card border="light">
                <Card.Body className="text-center">
                    <i className="bi bi-wifi-off" style={{fontSize: "10rem"}}/>
                    <Card.Title className="text-uppercase">{t('errors.error_0_message')}</Card.Title>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Error0;
