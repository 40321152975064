import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function ConfirmDialog(props) {

    const {t, i18n} = useTranslation();
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('conform_dialog.are_you_sure')}</Modal.Title>
            </Modal.Header>
            {props.message && <Modal.Body>{t(props.message)}</Modal.Body> || ''}
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    {t(props.cancel)}
                </Button>
                <Button variant="primary" onClick={props.acceptAction}>
                    {t(props.accept)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmDialog;
