import { getRequest } from "./Api";

let service_category_request;
let service_categories;

export default {
  get: function () {
    return new Promise((resolve, reject) => {

      if(service_categories)
      {
        resolve(service_categories);
      }
      else
      {
        if(!service_category_request)
        {
          service_category_request = getRequest('/service-categories');
        }

        service_category_request
          .then((response) => {
            service_categories = response.data.service_categories;

            resolve(service_categories);
          })
          .catch(error => reject(error));
      }
    });
  }

};
