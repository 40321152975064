import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useState} from "react";

function UserSelect(props) {

    const [userEmail, setUserEmail] = useState(null);
    const [roleId, setRoleId] = useState(null);

    const {t} = useTranslation();
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('select_user')}</Modal.Title>
            </Modal.Header>
            <Form.Group controlId="userSelect" className="m-4">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    placeholder="Please enter user email"
                    onChange={e => {
                        setUserEmail(e.target.value);
                    }}
                    isInvalid={!!props.errors?.email}
                >
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    {props.errors?.email}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="roleSelect" className="m-4">
                <Form.Label>Select Role</Form.Label>
                <Form.Control
                    as="select"
                    onChange={e => {
                        setRoleId(e.target.value);
                    }}
                    isInvalid={!!props.errors?.role}
                >
                    <option value="">Select Role</option>
                    {props.roles.length > 0 && props.roles.map((role, key) => (
                        <option key={key} value={role.id}>{role.name}</option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    {props.errors?.role}
                </Form.Control.Feedback>
            </Form.Group>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    {t(props.cancel)}
                </Button>
                <Button variant="primary" onClick={() => props.acceptAction(userEmail, roleId)}>
                    {t(props.accept)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UserSelect;
