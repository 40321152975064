import { useHistory, useLocation, Redirect, Link } from "react-router-dom";
import React, { useEffect, useState, useCallback, useRef, useMemo }   from "react";
import { useTranslation }               from "react-i18next";
import { Helmet } from "react-helmet";
import { VehicleDailyReportTemplates, useAuth, Storage }   from "@/services";
import { Card, Form, Button, ProgressBar, Row, Col } from "react-bootstrap";
import moment from 'moment-timezone';
import LoadingPage                    from "@/components/LoadingPage";
import Wysiwyg                    from "@/components/Inputs/Wysiwyg";
import { CheckIcon, AlertIcon } from "@/components/icons";
import { useLayout } from "@/layouts/Layout";

function DailyReport() {
  const auth = useAuth();
  const main = useLayout();
  const {t} = useTranslation();
  const route = useLocation();
  const history = useHistory();

  const [currentLocation, setCurrentLocation] = useState(null);

  const locationError = () => {
    setCurrentLocation(false);
  }

  const showPosition = (position) => {
    setCurrentLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
    });

  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, locationError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const vehicle = route?.state?.vehicle || undefined;
  const occurance_date = route?.state?.occurance_date || null;
  const storage_key = 'daily-report-' + vehicle?.id + '-' + moment(occurance_date).startOf('day').toISOString();

  const [report, setReport] = useState(undefined);

  const [storedReport, setStoredReport] = useState({});

  const [schemas, setSchemas] = useState(null);
  const [loading, setLoading] = useState(false);
  const [syncd, setSyncd] = useState(false);

  const updateSchemas = useCallback(value => {
    setSchemas(value);
  }, [schemas]);

  const [schema, setSchema] = useState(null);

  const remaining = [];

  const updateSchema = useRef(() => {});

  const current = route?.state?.current || null;

  const child_groups = [];
  const completed_groups = [];

  if(schema)
  {
    for(var _key in schema.groups)
    {
      const _group = schema.groups[_key];

      for(var _child_key in _group.groups)
      {
        const _child_group = _group.groups[_child_key];

        child_groups.push(_child_group);

        if(_child_group.complete)
        {
          completed_groups.push(_child_group);
        }
      }
    }
  }

  const group_key = current && current.length > 0 && current[0] || null;
  const group = schema?.groups && group_key && schema.groups[group_key] || null;
  const child_group_key = current && current.length > 1 && current[1] || null;
  const child_group = group?.groups && child_group_key && group.groups[child_group_key] || null;
  const check_key = current && current.length > 2 && current[2] || null;
  const checks_length = child_group?.checks && Object.keys(child_group.checks).length || null;
  const check_index = child_group?.checks && Object.keys(child_group.checks).findIndex(_ => _ == check_key) || null;
  const check = child_group?.checks && check_key && child_group.checks[check_key] || null;


  const updateCurrent = (value) => {
    history.push(route.pathname, {
      ...(route.state || {}),

      current: value,
    });
  };

  const back = useCallback((_report, _storedReport) => {
    if(child_group)
    {
      updateSchema.current(null, _report, _storedReport);

      const keys = Object.keys(child_group.checks).filter(_ => child_group.checks[_].required || report[_]);

      keys.push(null);

      var target = keys.findIndex(_ => _ == check_key);

      if(target < 0)
      {
        target = 1;
      }
      else
      if(target == 0)
      {
        updateCurrent(null);
      }
      else
      {
        target --;

        updateCurrent([group_key, child_group_key, keys[target]]);
      }
    }
  }, [child_group, report, group_key, child_group_key, check_key]);

  const next = useCallback((_report, _storedReport) => {
    if(child_group)
    {
      updateSchema.current(null, _report, _storedReport);

      const keys = Object.keys(child_group.checks).filter(_ => child_group.checks[_].required || typeof report[_] != 'undefined');

      keys.push(null);

      var target = keys.findIndex(_ => _ == check_key);

      if(target < 0 || target > keys.length - 2)
      {
        target = keys.length - 2;
      }

      target ++;

      updateCurrent([group_key, child_group_key, keys[target]]);
    }
  }, [child_group, report, group_key, child_group_key, check_key]);

  const save = useCallback(() => {
    updateSchema.current();

    const data = JSON.parse(JSON.stringify(schema));

    data.type = 'DailyReport';
    data.vehicle_id = vehicle?.id;

    if(currentLocation)
    {
      data.location = currentLocation;
    }

    data?.groups && Object.entries(data.groups).forEach(([_group_key, group]) => {
      group.key = _group_key;

      group?.groups && Object.entries(group.groups).forEach(([_child_group_key, child_group]) => {
        child_group.key = _child_group_key;

        child_group?.checks && Object.entries(child_group.checks).forEach(([check_key, check]) => {
          check.key = check_key;

          if(report[check_key])
          {
            Object.assign(check, report[check_key]);

            if(currentLocation)
            {
              Object.assign(check, currentLocation);
            }
          }
          else
          {
            delete child_group.checks[check_key];
          }
        });

        if(Object.keys(child_group.checks).length == 0)
        {
          delete group.groups[_child_group_key];
        }
        else
        if(_child_group_key !== child_group_key)
        {
          delete child_group.checks;
        }
      });

      if(Object.keys(group.groups).length == 0)
      {
        delete data.groups[_group_key];
      }
      else
      if(_group_key !== group_key)
      {
        delete group.groups;
      }
    });

    setLoading(true);

    auth.postRequest('reports', data)
        .then(response => {
          const _storedReport = {};

          response?.data?.data?.groups && Object.entries(response?.data?.data?.groups).forEach(([group_key, group]) => {
            group?.groups && Object.entries(group.groups).forEach(([child_group_key, child_group]) => {
              child_group?.checks && Object.entries(child_group.checks).forEach(([check_key, check]) => {
                _storedReport[check.key] = check.value !== null;
              });
            });
          });

          setStoredReport(_storedReport);

          next(null, _storedReport);
        })
        .finally(_ => setLoading(false));
  }, [schema, report]);

  useEffect(() => {
      getLocation();
  },[]);

  useEffect(() => {
    if(!schemas)
    {
      VehicleDailyReportTemplates.get().then(updateSchemas);
    }
  }, [schemas]);

  useEffect(() => {
    if(!schema && schemas && vehicle)
    {
      updateSchema.current(schemas[ vehicle.vehicle_type ]);
    }
  }, [schema, schemas, vehicle]);

  useMemo(() => {
    if(report)
    {
      updateSchema.current();
    }
  }, [report]);

  useEffect(() => {
    const onUnload = _ => {
      if(report)
      {
        Storage.Session.setItem(storage_key, report);
      }
    };

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [report]);

  useEffect(() => {
    if(report === undefined)
    {
      if(storage_key)
      {
        Storage.Session.getItem(storage_key).then(value => {
          setReport(value || {});
        });
      }
      else
      {
        setReport({});
      }
    }
  }, [report]);

  useEffect(() => {
    if(group && child_group && !check)
    {
      updateSchema.current();

      const keys = Object.keys(child_group.checks).filter(_ => !child_group.checks[_].complete);

      if(keys.length > 0)
      {
        history.replace(route.pathname, {
          ...(route.state || {}),

          current: [group_key, child_group_key, keys[0]],
        });
      }
    }
  }, [current, group, child_group, check, report]);

  useEffect(() => {
    if(route?.state?.report?.guid && !syncd && report)
    {
      setLoading(true);

      auth.getRequest('reports/' + route?.state?.report?.guid, { include: 'all' })
          .then((response) => {
              const _report = { ...report };
              const _storedReport = {};

              response?.data?.data?.groups && Object.entries(response?.data?.data?.groups).forEach(([group_key, group]) => {
                group?.groups && Object.entries(group.groups).forEach(([child_group_key, child_group]) => {
                  child_group?.checks && Object.entries(child_group.checks).forEach(([check_key, check]) => {

                    if(check.value !== null)
                    {
                      _report[check.key] = {
                        content: check.content,
                        severe: check.severity == 'Severe',
                        fault: check.severity != 'None',
                        value: check.value,
                      };

                      _storedReport[check.key] = true;

                    }
                  });
                });
              });

              setReport(_report);
              setStoredReport(_storedReport);
              updateSchema.current(null, _report, _storedReport);
              setLoading(false);
              setSyncd(true);
          })
          .catch(() => console.error(arguments))
    }
  }, [syncd, report]);


  useEffect(() => {
    main.scrollTop();
  }, [current])


  updateSchema.current = (_schema, _report, _storedReport) => {
    _schema = _schema || schema;
    _report = _report || report || {};
    _storedReport = _storedReport || storedReport;

    if(!_schema)
    {
      return;
    }

    _schema.complete = true;
    _schema.severe = false;
    _schema.fault = false;

    _schema?.groups && Object.entries(_schema.groups).forEach(([group_key, group]) => {
      group.complete = true;
      group.severe = false;
      group.fault = false;

      group?.groups && Object.entries(group.groups).forEach(([child_group_key, child_group]) => {
        child_group.complete = true;
        child_group.severe = false;
        child_group.fault = false;

        child_group?.checks && Object.entries(child_group.checks).forEach(([check_key, check]) => {
          check.required = true;
          check.complete = true;
          check.severe = false;
          check.fault = false;

          if(_report[check_key])
          {
            if(_report[check_key].severe)
            {
              _schema.severe = true;
              group.severe = true;
              child_group.severe = true;
              check.severe = true;
            }

            if(_report[check_key].fault)
            {
              _schema.fault = true;
              group.fault = true;
              child_group.fault = true;
              check.fault = true;
            }

            if(!_storedReport[check_key])
            {
              child_group.complete = false;
              group.complete = false;
              _schema.complete = false;
            }

            return;
          }
          else
          if(check.conditional_logic instanceof Array)
          {
            var result = false;

            for(var i in check.conditional_logic)
            {
              const conditions = check.conditional_logic[i];

              for(var j in conditions)
              {
                const condition = conditions[j];
                const check_value = _report[condition.check] && _report[condition.check].value || null;

                switch(condition.compare || '==')
                {
                  case '==':
                    result = check_value == condition.value;
                  break;

                  case '!=':
                    result = check_value != condition.value;
                  break;

                  case '<=':
                    result = check_value <= condition.value;
                  break;

                  case '>=':
                    result = check_value >= condition.value;
                  break;

                  case '<':
                    result = check_value < condition.value;
                  break;

                  case '>':
                    result = check_value > condition.value;
                  break;
                }

                if(!result)
                {
                  break;
                }
              }

              if(result)
              {
                break;
              }
            }

            if(!result)
            {
              check.required = false;
              return;
            }
          }


          remaining.push(check_key);
          check.complete = false;
          child_group.complete = false;
          group.complete = false;
          _schema.complete = false;
        });
      });
    });

    const __schema = { ..._schema };
    setSchema(__schema);

    return __schema;
  };


  if(!vehicle || !occurance_date || (schemas && !schema && !schemas[vehicle.vehicle_type]))
  {
    return <Redirect to="/compliance" />;
  }

  if(!schema || loading || !report)
  {
    return <LoadingPage />;
  }


  if(child_group)
  {
    return (
      <div className="container compliance">
        <Helmet>
          <title>{t(child_group_key)} - {t('compliance.DailyReport.title')} - {t('app')}</title>
        </Helmet>

        <h2 className={`h6 mb-3 ${schema?.complete && (schema.fault && (schema.severe && 'text-danger' || 'text-warning') || 'text-success') || 'text-primary'}`}>
          {t('compliance.DailyReport.title')}

          {schema.complete && (
            (schema.fault && <AlertIcon variant={schema.severe ? 'danger' : 'warning'} />)
            ||
            <CheckIcon variant="success" />
          ) || ''}

        </h2>

        <ProgressBar variant="success" now={completed_groups.length / child_groups.length * 100}  className="my-3" />

        <div className="bg-success mx-n3 px-4 py-3 my-3">
          <h3 className="h4 text-light my-3 text-center mt-0">
            {t(child_group_key)}
          </h3>

          <ProgressBar striped  now={check ? check_index / checks_length * 100 : 100} className="mt-3" />
        </div>


        {(_ => {
          if(check)
          {

            return  (
              <Check key={check_key} {...{ index: check_index, check, check_key, value: report[check_key], onSubmit: _ => { const values = { ... report}; values[ check_key ] = _; setReport(values); next(values); }, onBack: back }} />
            );

          }
          else
          if(!child_group.complete)
          {
            return  (
              <>
                <h3 className="h6 text-primary my-3">
                  {t('summary')}
                </h3>

                {child_group?.checks && Object.entries(child_group.checks)
                  .filter(([check_key, check]) => check.required || typeof report[check.key] != 'undefined')
                  .map(([check_key, check], index) => (
                  <Form.Group key={check_key}>
                    <Form.Label className="small"><strong>{index + 1}{'. '}{t(check_key)}</strong></Form.Label>
                    <p>{check.type == 'true_false' ? (
                        parseInt(report[check_key] && report[check_key].value || '') && t('yes') || t('no')
                      ) : (
                        t(report[check_key] && report[check_key].value || '') || <i>{t('n_a')}</i>
                      )}</p>
                  </Form.Group>

                )) || ''}

                <Form.Group className="mb-3 mx-n2 d-flex">
                  <Button className="mx-2 w-50" variant="primary" onClick={back}>
                    {t('back')}
                  </Button>

                  <Button className="mx-2 w-50" variant="success" onClick={save}>
                    {t('submit')}
                  </Button>
                </Form.Group >
              </>
            );
          }
          else
          {

            var nextSection = null;

            if(!schema.complete)
            {
              groups:
              for(var _key in schema.groups)
              {
                const _group = schema.groups[_key];

                if(!_group.complete)
                {
                  for(var _child_key in _group.groups)
                  {
                    const _child_group = _group.groups[_child_key];

                    if(!_child_group.complete)
                    {
                      nextSection = [_key, _child_key];
                      break groups;
                    }
                  }
                }
              }
            }

            return  (
              <>

              <div className="large-icon-message">
                <CheckIcon variant="success" />

                <h3 className="h6 text-success strong mt-3 text-center">
                  {t('n_have_now_been_completed', { n: t(child_group_key) })}
                </h3>
              </div>

              <Form.Group className="mb-3 mx-n2 d-flex">


                { nextSection && (
                  <>
                  <Button className="mx-2 w-100" variant="primary" onClick={_ => updateCurrent(null)}>
                    {t('back_to_n', { n: t('list') })}
                  </Button>

                  <Button className="mx-2 w-100" variant="success" onClick={_ => updateCurrent(nextSection)}>
                    {t('compliance.next_section')}
                  </Button>
                  </>
                ) || (
                  <Button className="mx-2 w-100" variant="success" onClick={_ => updateCurrent(true)}>
                    {t('compliance.DailyReport.complete')}
                  </Button>
                )}
              </Form.Group >
              </>
            );
          }


        })()}
      </div>
    );
  }

  if(current === true && schema?.complete)
  {
    return (
      <div className="container compliance">
        <Helmet>
          <title>{t('compliance.DailyReport.title')} - {t('app')}</title>
        </Helmet>

        <h2 className="h4 mb-3 text-primary">
          <span className="me-2">
            {t('compliance.DailyReport.title')}
          </span>

         </h2>

        <div className="large-icon-message">
           {
              (schema.fault && <AlertIcon variant={schema.severe ? 'danger' : 'warning'} />)
              ||
              <CheckIcon variant="success" />
            }


          <h3 className={`h6 strong mt-3 text-center  ${schema.fault && (schema.severe && 'text-danger' || 'text-warning') || 'text-success'}`}>
            {t('compliance.DailyReport.completed', { registration: vehicle.registration_number })}
          </h3>
          {schema.fault && (
          <h3 className={`h6 strong mt-3 text-center  ${schema.fault && (schema.severe && 'text-danger' || 'text-warning') || 'text-success'}`}>
            {schema.severe && t('compliance.DailyReport.severe_issues_long') || t('compliance.DailyReport.minor_issues_long')}
          </h3>
          ) || ''}
        </div>

        <Link className="w-100 btn btn-primary" to={{ pathname: "/compliance", state: { occurance_date, vehicle_id: vehicle.id }}}>
          {t('back_to_n', { n: t('compliance.title') })}
        </Link>

      </div>
    );
  }

  return (
    <div className="container compliance">
      <Helmet>
        <title>{t('compliance.DailyReport.title')} - {t('app')}</title>
      </Helmet>

      <h2 className={`h4 mb-3 ${schema?.complete && (schema.fault && (schema.severe && 'text-danger' || 'text-warning') || 'text-success') || 'text-primary'}`}>
        <span className="me-2">
          {t('compliance.DailyReport.title')}
        </span>

        {schema.complete && (
          (schema.fault && <AlertIcon variant={schema.severe ? 'danger' : 'warning'} />)
          ||
          <CheckIcon variant="success" />
        ) || ''}

      </h2>

      <p className="small">
        {t('date') + ' '}

        <strong>{moment(occurance_date).format(t('formats.date_long'))}</strong>
      </p>


      {Object.entries(schema.groups).map(([group_key, group]) => (
        <section key={group_key} className="my-3">
          <h3 className={`h5 mb-3 ${group.complete && (group.fault && (group.severe && 'text-danger' || 'text-warning') || 'text-success') || 'text-primary'}`}>
            <span className="me-2">
              {t(group_key)}
            </span>

            {group.complete && (
              (group.fault && <AlertIcon variant={group.severe ? 'danger' : 'warning'} />)
              ||
              <CheckIcon variant="success" />
            ) || ''}
          </h3>



          <Row className="gx-2 gx-lg-3">
          {Object.entries(group.groups).map(([child_group_key, child_group]) => (
            <Col key={child_group_key} xs={6} md={3}>
              <Card className="compliance-check-group" onClick={_ => updateCurrent([group_key, child_group_key])}>
                <div className={`position-relative group-${child_group.complete && (
                  child_group.fault && (child_group.severe ? 'danger' : 'warning') || 'success')}`}>

                {child_group.complete && (
                  (child_group.fault && <AlertIcon />)
                  ||
                  <CheckIcon />
                ) || ''}

                <Card.Img variant="top" src={child_group.diagram || "data:image/svg+xml,%3Csvg width='134' height='134' viewBox='0 0 134 134' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='134' height='134' rx='8' fill='%2300458C'/%3E%3C/svg%3E%0A"} style={{ maxWidth: '100%' }} />
                </div>
                <Card.Body className={`text-center text-${child_group.complete && (
                  child_group.fault && (child_group.severe ? 'danger' : 'warning') || 'success') || 'primary'} small p-1 p-lg-3`}>
                  <strong>{t(child_group_key)}</strong>
                </Card.Body>
              </Card>
            </Col>
          ))}
          </Row>
        </section>
      ))}
    </div>
  );
}

export default DailyReport;


function Check({ check_key, check, value: initalValue, onSubmit, onBack, index }) {
  const {t} = useTranslation();

  const [value, setValue] = useState(initalValue?.value || null);
  const [value_options, setValueOptions] = useState(initalValue || null);
  const [content, setContent] = useState(initalValue?.content || null);

  const can_continue = value !== null && typeof value !== 'undefined' && ( content && content != '<p></p>' || !value_options?.fault);

  return (
    <div>
      {check.diagram && <img src={check.diagram} className="mb-3" style={{ maxWidth: '300px' }} /> || ''}

      <h3 className="text-primary h6">
        {(index + 1) + '. '}
        {t(check_key)}
      </h3>


      <div>
        {(_ => {
          switch(check.type)
          {
            case 'radio':
              return (
                <Form.Group>
                  {Object.entries(check.options).map(([key, options]) => (
                  <label
                      key={key}
                      className="d-block mb-2">
                      <Form.Check.Input
                          type="radio"
                          className="d-none"
                          checked={value == key}
                          name={check_key}
                          onChange={_ => { setValue(_.target.value); setValueOptions(options); }}
                        value={key}
                      />

                      <span className="btn btn-outline-primary w-100">{t(key)}</span>
                  </label>

                  ))}
                </Form.Group>
              );
            break;

            case 'true_false':
              return (
                <Form.Group className="row gx-3 mb-3">
                  <label
                      key={'true'}
                      className="col">
                      <Form.Check.Input
                        type="radio"
                        className="d-none"
                        checked={value == 1}
                        value={1}
                        name={check_key}
                        onChange={_ => { setValue(_.target.value); setValueOptions(check.if_true); }}
                      />

                      <span className="btn btn-outline-primary w-100">{t('yes')}</span>
                  </label>

                  <label
                      key={'false'}
                      className="col">
                      <Form.Check.Input
                        type="radio"
                        className="d-none"
                        checked={value == 0}
                        value={0}
                        name={check_key}
                        onChange={_ => { setValue(_.target.value); setValueOptions(check.if_false); }}
                      />

                      <span className="btn btn-outline-primary w-100">{t('no')}</span>
                  </label>
                </Form.Group>
              );
            break;

            // case has 3 options
            case 'true_false_na':
              return (
                <Form.Group className="row gx-3 mb-3">
                  <label
                    key={'true'}
                    className="col">
                    <Form.Check.Input
                      type="radio"
                      className="d-none"
                      checked={value == 1}
                      value={1}
                      name={check_key}
                      onChange={_ => { setValue(_.target.value); setValueOptions(check.if_true); }}
                    />

                    <span className="btn btn-outline-primary w-100">{t('yes')}</span>
                  </label>

                  <label
                    key={'false'}
                    className="col">
                    <Form.Check.Input
                      type="radio"
                      className="d-none"
                      checked={value == 0}
                      value={0}
                      name={check_key}
                      onChange={_ => { setValue(_.target.value); setValueOptions(check.if_false); }}
                    />

                    <span className="btn btn-outline-primary w-100">{t('no')}</span>
                  </label>

                  <label
                    key={'na'}
                    className="col">
                    <Form.Check.Input
                      type="radio"
                      className="d-none"
                      checked={value == -1}
                      value={-1}
                      name={check_key}
                      onChange={_ => { setValue(_.target.value); setValueOptions(check.if_na); }}
                    />

                    <span className="btn btn-outline-primary w-100">{t('na')}</span>
                  </label>
                </Form.Group>
              );
              break;
          }

        })()}


        {value !== null && (
            <Wysiwyg value={content} placeholder={t('description')} onChange={e => setContent(e.target.value)} blockEmbeds={false} preUpload={true} imageCompression={{ maxWidth: 320 }} toolbar={false} />
        )}

        <Form.Group className="my-3 mx-n2 d-flex">
          <Button className="mx-2 w-50" variant="primary" onClick={onBack}>
            {t('back')}
          </Button>

          <Button className="mx-2 w-50"
            variant={!can_continue && 'link' || 'success' }
            disabled={!can_continue}
            onClick={_ => onSubmit({ ...value_options, value, content  })}>
            {t('continue')}
          </Button>
        </Form.Group >

      </div>
    </div>
  );
}
