function LogoutIcon({classDefined, size}) {
    return (
        <div className={`custom-logo ${classDefined} `}>
            <svg width={size ? size : 14} height={size ? size : 14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.84567 13.8564C9.27732 13.8628 11.4172 12.6047 12.6402 10.704C12.6944 10.6195 12.6338 10.5079 12.5334 10.5079L11.4124 10.5079C11.3359 10.5079 11.2641 10.5414 11.2163 10.6004C11.1047 10.7359 10.9851 10.8667 10.8591 10.9911C10.3393 11.5093 9.73495 11.9175 9.06206 12.2013C8.36685 12.4947 7.62699 12.6446 6.86321 12.6446C6.09943 12.6446 5.35957 12.4963 4.66435 12.2013C3.99146 11.9175 3.38713 11.5093 2.86732 10.9911C2.3475 10.4728 1.9409 9.8685 1.65548 9.19721C1.36049 8.50199 1.2122 7.76372 1.2122 6.99995C1.2122 6.23617 1.36208 5.4979 1.65548 4.80268C1.9393 4.13139 2.3475 3.52706 2.86732 3.00884C3.38714 2.49062 3.99146 2.08242 4.66435 1.79859C5.36012 1.50472 6.10793 1.35397 6.86321 1.35531C7.62699 1.35531 8.36685 1.5052 9.06206 1.79859C9.73363 2.08213 10.3439 2.49312 10.8591 3.00884C10.9851 3.13481 11.1031 3.26556 11.2163 3.3995C11.2641 3.4585 11.3375 3.49198 11.4124 3.49198L12.5334 3.49198C12.6338 3.49198 12.696 3.38037 12.6402 3.29586C11.4188 1.39996 9.28849 0.143472 6.86321 0.143472C3.09693 0.143472 0.0386247 3.17467 0.000355372 6.92979C-0.0379131 10.7455 3.02518 13.8469 6.84567 13.8564ZM8.86562 5.21407L8.86562 6.42592L13.8724 6.42592C13.9426 6.42592 14 6.48332 14 6.55348L14 7.44641C14 7.51657 13.9426 7.57398 13.8724 7.57398L8.86562 7.57398L8.86562 8.78582C8.86562 8.89265 8.74124 8.95324 8.65833 8.88627L6.39569 7.1004C6.38045 7.08847 6.36812 7.07322 6.35964 7.05582C6.35116 7.03841 6.34675 7.01931 6.34675 6.99995C6.34675 6.98059 6.35116 6.96148 6.35964 6.94407C6.36812 6.92667 6.38045 6.91142 6.39569 6.89949L8.65833 5.11362C8.74124 5.04824 8.86562 5.10724 8.86562 5.21407Z"
                    fill="#00458C"/>
            </svg>
        </div>
    );
}

export default LogoutIcon;
