import Settings     from "../../views/pages/settings/Settings";
import SettingsIcon from "../../components/icons/SettingsIcon";

const settingsRoutes = [

    {
        path         : "/settings",
        component    : Settings,
        exact        : true,
        gate         : 'view_settings',
        title        : 'Settings',
        translate_key: 'settings',
        // icon         : 'bi-gear',
        icon         :<SettingsIcon/>,
        main         : true,
    },
]

export default settingsRoutes;
