import React, {useEffect, useState}               from "react";
import Table, { useColumns }                      from "@/components/Tables/Fleets";
import {useTranslation}                           from "react-i18next";
import {Button, Card, Col, Form, Pagination, Row} from "react-bootstrap";
import LoadingPage                                from "@/components/LoadingPage";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useAuth}                                  from "@/services/Auth";
import * as csv from 'csv-stringify/lib/sync';
import FileSaver from 'file-saver';
import { Helmet } from "react-helmet";
import Filters from "@/components/Forms/Search";

function Fleets() {
    const history = useHistory();
    const route = useLocation();

    let auth = useAuth();

    const {t} = useTranslation();
    const columns = useColumns();
    const exportColumns = useColumns(undefined, true);

    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);

    const exportFleets = () => {
        let headers = exportColumns.map(_ => _.text);

        let exportRequest = { ...query };

        exportRequest.include = 'account_items';
        exportRequest.skip = 0;
        exportRequest.take = 0;

        setExporting(true);

        auth.getRequest('/fleets', exportRequest)
            .then(response => {

                const records = response.data.fleets.map((record) => {
                    return exportColumns.map(_ => {
                        var value = record,
                        paths = _.dataField.split('.'),
                        name;

                        while((name = paths.shift()) && value)
                        {
                            value = value[name];
                        }

                       return _.exportFormatter ? _.exportFormatter(value, record) : value;
                    });
                });

                records.unshift(headers);

                const data = "\uFEFF" + csv.stringify(records, {});
                const blob = new Blob([data], {type: 'text/plain;charset=utf-8', encoding: 'UTF-8'});

                FileSaver.saveAs(blob, 'fleets.csv', {});

                setExporting(false);
            })
            .catch(error => {
                console.error(error);

                setExporting(false);
            })
    }


    const [query, setQuery] = useState(route?.state?.query || {});

    useEffect(() => {
        history.replace(
            route.pathname,
            {
                ...route.state,

                query,
            }
        )
    }, [query]);

    return (

        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{t('drawer_link_titles.fleets')} - {t('app')}</title>
            </Helmet>

            <Card.Subtitle>
                <Row>
                    <Col cols="12" className="d-flex justify-content-between flex-md-row flex-column">
                        <h4 className="text-primary">{t('drawer_link_titles.fleets')}</h4>
                        <div className="d-flex flex-md-row flex-column">
                            {(auth?.roles?.update_options) && <>
                                <Link to="/compliance/options" className="btn btn-success mx-1 my-1 my-md-0">{t('compliance.options')}</Link>
                            </>}
                            <Button variant="success" className="mx-1 my-1 my-md-0" onClick={() => exportFleets()}>{t('export')} (.csv)</Button>
                            {(auth?.roles?.create_fleet) && <>
                                <Link to="/fleets/import" className="btn btn-primary mx-1 my-1 my-md-0">{t('import_fleets')}</Link>
                                <Link to="/fleets/create" className="btn btn-secondary mx-1 my-1 my-md-0">{t('new_fleet')}</Link>
                            </>}
                        </div>
                    </Col>
                </Row>

                <Filters searching={loading || exporting} values={query} onSubmit={setQuery} />
            </Card.Subtitle>

            <Table query={query} showPagination={true} onData={_ => setLoading(!_)} />
        </Card>
    )
}

export default Fleets;
