import React, {useEffect, useState, useRef}   from "react";
import {Link, useParams}              from "react-router-dom";
import BootstrapTable                 from "react-bootstrap-table-next";
import {useTranslation}               from "react-i18next";
import {useAuth}                      from "@/services/Auth";
import Paginate from "../Paginate";
import useMoment from "@/hooks/useMoment";

function useColumns(filter, isExport) {
    let auth = useAuth();
    const {t} = useTranslation();

    const {momentLocal} = useMoment()

    let columns = [
        {
            dataField: 'registration_number',
            text     : t('vehicle_registration_number'),
            formatter: (cell, row) => row?.id && <Link to={`/vehicles/${row?.id}`}>{cell}</Link> || cell
        },
        auth.roles.view_users && {
            dataField: 'drivers',
            text     : t('drivers'),
            formatter: (cell, user) => cell?.map && (
                <>
                    {cell.slice(0, 3).map((user, index, self) => (
                        <Link key={index} to={`/users/${user.id}`}>
                            {user.name}
                            {index !== self.length - 1 ? ',' : ''}&nbsp;
                        </Link>
                    ))}

                    {cell.length > 3 && (
                        '+ ' + (cell.length - 3)
                    ) || ''}
                </>
            ),
            exportFormatter: (drivers) =>  drivers && drivers.map(driver => driver?.name).filter(_ => !!_).join(', '),
        } || '',
        !isExport &&
        {
            dataField: 'created_at',
            text     : t('date_first_used'),
            formatter: (created_at) => momentLocal(created_at).format(t('formats.date'))
        } || '',
        {
            dataField: 'vehicle_type',
            text     : t('vehicle_type'),
            formatter: cell => t(`vehicle_types.${cell}`),
        },
        auth.roles.view_fleets && {
            dataField: 'fleet',
            text     : t('fleet_name'),
            formatter: (cell, row) => cell?.id && <Link to={`/fleets/${cell?.id}`}>{cell?.name}</Link> || cell,
            exportFormatter:(fleet) => fleet?.name,
        } || '',
        auth.roles.view_clients && {
            name: 'client',
            dataField: 'fleet.client',
            text     : t('client'),
            formatter: (cell, row) => cell?.id && <Link to={`/clients/${cell?.id}`}>{cell?.name}</Link> || cell,
            exportFormatter:(cell) => cell?.name,
        } || '',
        isExport &&
        {
            dataField: 'account_number',
            text     : t('account_number'),
        } || '',

        auth.roles.view_compliance && {
            dataField: 'last_daily_report',
            text     : t('compliance.defects'),
            formatter: cell => cell?.severity && (
                <Link className={cell.status != 'Complete' && (cell.status == 'Resolved' && 'text-success' || cell.severity == 'Severe' && 'text-danger' || cell.severity == 'Fault' && 'text-warning' || 'text-info') || ''} to={`/compliance/${cell.guid}`}>
                    {t(`compliance.severity.${cell.severity}`)}
                </Link>
            ) || ''
        } || '',
    ].filter(_ => !!_);

    return filter && columns.filter(filter) || columns;
}

function Vehicles({ data: recordsProp, paginate, url, query, ...props }) {
    let auth = useAuth();
    const {t} = useTranslation();

    const perPage = props.perPage || 20;
    const exclude = props.exclude || [];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);

    const getRecords = () => {
        request?.current?.abort && request.current.abort();

        const critera = { ... (query || {})};

        critera.take = perPage;
        critera.skip = page * perPage;
        critera.include = [];

        if(auth.roles.view_users)
        {
            critera.include.push('drivers');
        }

        if(auth.roles.view_fleets)
        {
            critera.include.push('fleet');
        }

        if(auth.roles.view_clients)
        {
            critera.include.push('fleet.company');
        }

        if(auth.roles.view_compliance)
        {
            critera.include.push('last_daily_report');
        }

        request.current = auth.getRequest(url || '/vehicles', critera);

        setRecords(false);

        request.current
            .then(response => {
                setRecords(response.data.vehicles);
                setTotal(response.data.total);
            })
    }

    const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name));

    const changePage = ({ selected }) => {
        setPage(selected)
    }

    const records = recordsProp || recordsDownloaded;

    useEffect(() => {
        if(!recordsProp)
        {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <>
            <BootstrapTable
                keyField="id"
                striped
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={records instanceof Array ? t('no_data') : t('loading')}
                {...props}
            />
            {
                showPagination && records && totalRecords &&
                <Paginate {...{ changePage, totalRecords, page, perPage }} />
                || ''
            }
        </>
    );
}

export default Vehicles;

export {
    useColumns
};
