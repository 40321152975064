import React, {useEffect, useState, useRef}   from "react";
import {Link, useParams}              from "react-router-dom";
import BootstrapTable                 from "react-bootstrap-table-next";
import * as moment                    from "moment";
import {useTranslation}               from "react-i18next";
import {useAuth}                      from "@/services/Auth";
import Paginate from "../Paginate";

function useColumns(filter, isExport = false) {
    let auth = useAuth();
    const {t} = useTranslation();

    const is_platform_user = auth.user.is_platform_admin || auth.user.is_platform_operator;

    let columns = [
        {
            dataField: 'id',
            text     : t('id'),
        },
        {
            dataField: 'name',
            text     : t('site_name'),
            formatter: (cell, row) => row?.id && <Link to={`/sites/${row?.id}`}>{cell}</Link> || cell,
        },
        {
            dataField: 'supplier',
            text     : t('supplier'),
            formatter: (cell, row) => cell?.id && <Link to={`/suppliers/${cell?.id}`}>{cell?.company_name}</Link> || cell?.company_name,
            exportFormatter: cell => cell?.company_name
        },
        isExport && {
            dataField: 'supplier.account_number',
            text     : t('account_number'),
        },

        isExport && {
            dataField: 'phone_number',
            text     : t('phone_number'),
        },

        isExport && {
            dataField: 'description',
            text     : t('description'),
        },

        isExport && {
            dataField: 'security_rating',
            text     : t('security_rating'),
        },

        isExport && {
            dataField: 'vat_registration_number',
            text     : t('vat_registration_number')
        },

        isExport && {
            dataField: 'address',
            text     : t('first_line_address')
        },

        isExport && {
            dataField: 'address_line_2',
            text     : t('address_line_2')
        },

        isExport && {
            dataField: 'city',
            text     : 'City',
            exportFormatter: (city, row) => city
        },

        isExport && {
            dataField: 'county',
            text     : t('county')
        },

        isExport && {
            dataField: 'country',
            text     : t('country'),
            exportFormatter: (country, row) => country.label
        },

        isExport && {
            dataField: 'post_code',
            text     : t('post_code')
        },

        isExport && {
            dataField: 'latitude',
            text     : t('latitude')
        },

        isExport && {
            dataField: 'longitude',
            text     : t('longitude')
        },

        isExport && {
            dataField: 'parking_available',
            text     : t('parking_available'),
            exportFormatter: (parking_available, row) => parking_available ? 'Y' : 'N'
        },

        isExport && {
            dataField: 'total_spaces',
            text     : t('spaces_available'),
        },

        isExport && {
            dataField: 'pre_book_instant_book',
            text     : t('pre_book_instant_book'),
        },

        isExport && {
            dataField: 'currency',
            text     : t('currency'),
        },

        isExport && {
            dataField: 'supplier_payment_terms',
            text     : t('supplier_payment_terms'),
        },

        isExport && {
            dataField: 'facilities',
            text     : t('facilities'),
            exportFormatter: (cell, row) => cell && cell.map(value => value.name).join(',')

        },

        isExport && {
            dataField: 'security_facilities',
            text     : t('security_facilities'),
            exportFormatter: (cell, row) => cell && cell.map(value => value.name).join(',')
        },

        {
            dataField: 'email',
            text     : t('email_address')
        },
        {
            dataField: 'address',
            text     : t('address')
        },

        is_platform_user && {
            dataField: 'marketing_value',
            text     : t('marketing_price'),
            formatter: price => price && parseFloat(price).toFixed(2) || '',
            align    : 'right',
        },

    ].filter(Boolean);

    return filter && columns.filter(filter) || columns;
}

function Sites({ data: recordsProp, paginate, url, query, ...props }) {
    let auth = useAuth();
    const {t} = useTranslation();

    const perPage = props.perPage || 20;
    const exclude = props.exclude || [];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);

    const getRecords = () => {
        request?.current?.abort && request.current.abort();

        const critera = { ... (query || {})};

        critera.take = perPage;
        critera.skip = page * perPage;
        critera.include = ['supplier'].filter(_ => !exclude.includes(_));

        request.current = auth.getRequest(url || '/sites', critera);

        setRecords(false);

        request.current
            .then(response => {
                setRecords(response.data.sites);
                setTotal(response.data.total);
            })
    }

    const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name));

    const changePage = ({ selected }) => {
        setPage(selected)
    }

    const records = recordsProp || recordsDownloaded;

    useEffect(() => {
        if(!recordsProp)
        {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <>
            <BootstrapTable
                keyField="id"
                striped
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={records instanceof Array ? t('no_data') : t('loading')}
                {...props}
            />
            {
                showPagination && records && totalRecords &&
                <Paginate {...{ changePage, totalRecords, page, perPage }} />
                || ''
            }
        </>
    );
}

export default Sites;

export {
    useColumns
};
