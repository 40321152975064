import { getRequest } from "./Api";

let notification_channels_request;
let notification_channels;

export default {
  get: function () {
    return new Promise((resolve, reject) => {

      if(notification_channels)
      {
        resolve(notification_channels);
      }
      else
      {
        if(!notification_channels_request)
        {
          notification_channels_request = getRequest('/notification-delivery-channels');
        }

        notification_channels_request
          .then((response) => {
            notification_channels = response.data.delivery_channels;

            resolve(notification_channels);
          })
          .catch(error => reject(error));
      }
    });
  }

};
