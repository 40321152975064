import {useTranslation}                    from "react-i18next";
import {useAuth}                           from "@/services/Auth";
import React, {useEffect, useState, useRef, useCallback}        from "react";
import LoadingPage                         from "@/components/LoadingPage";
import {Button, Card, Col, Spinner, Form, Row, Badge}        from "react-bootstrap";
import NumberFormat                        from "react-number-format";
import {useForm}                                from "react-hook-form";
import {yupResolver}                            from "@hookform/resolvers/yup";
import * as Yup                            from "yup";
import { Cities, Countries }          from "@/services";
import AsyncSelect                         from "react-select/async";
import { loadStripe } from '@stripe/stripe-js';
import { useRollbar } from '@rollbar/react';
import {
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import Select                         from "react-select";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_TOKEN);

const isStripe = !!process.env.REACT_APP_STRIPE_CLIENT_TOKEN;

const StripeFields = React.forwardRef(({ errors }, ref) => {
    const {t, i18n} = useTranslation();

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if(ref && stripe)
        {
            ref.current = (values) => {
                return new Promise((resolve, reject) => {
                    stripe.createToken(elements.getElement('cardNumber'), { name: values.card_holder_name })
                        .then((request) => {
                            const payload = {...values};


                            if (request.token) {

                                var year = request.token.card.exp_year % 100;

                                if(year < 10)
                                {
                                    year = '0' + '' + year;
                                }

                                var month = request.token.card.exp_month;

                                if(month < 10)
                                {
                                    month = '0' + '' + month;
                                }


                                payload.card_number = '000000000000' + request.token.card.last4;
                                payload.expire = month + '/' + year;
                                payload.ccv = request.token.id;

                                resolve(payload);
                            }
                            else {
                                reject(request);
                            }
                        });
                });
            };
        }

        return () => {
            if(ref)
            {
                ref.current = null;
            }
        }
    }, [stripe]);


    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return <></>;
    }

    return (
        <>
        <Form.Group>
            <Form.Label>
                {t('card_number')}
            </Form.Label>

            <CardNumberElement className={"form-control w-100 " + (!!errors.card_number && 'is-invalid' || '')} />

            <Form.Control.Feedback type="invalid">
                {errors.card_number && errors.card_number.message}
            </Form.Control.Feedback>
        </Form.Group>

        <Row>
            <Col md={6}>
                <Form.Group>
                    <Form.Label>
                        {t('expire')}
                    </Form.Label>

                    <CardExpiryElement className={"form-control w-100 " + (!!errors.expire && 'is-invalid' || '')} />

                    <Form.Control.Feedback type="invalid">
                        {errors.expire && errors.expire.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col md={6}>
                <Form.Group>
                    <Form.Label>
                        {t('ccv')}
                    </Form.Label>

                    <CardCvcElement className={"form-control w-100 " + (!!errors.ccv && 'is-invalid' || '')} />

                    <Form.Control.Feedback type="invalid">
                        {errors.ccv && errors.ccv.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>

        </>

    )
});

function PaymentMethodForm({initialValues, loadingButton, handleSubmit, currentFleets, cardNumber, brand}) {
    const rollbar = useRollbar();

    const {t, i18n} = useTranslation();
    let auth = useAuth();
    let getRequest = auth.getRequest;

    const requestStore = useRef({});
    const { current: requests } = requestStore;

    const [paymentFleets, setPaymentFleet] = useState([]);
    const [countries, setCountries] = useState(null);
    const cardFields = useRef(null);

    const schema = Yup.object().shape({
        email           : Yup.string().email(t('form_validation.invalid_email_address'))
                            .required(t('form_validation.is_required', { attribute: t('email_address') })),
        phone_number    : Yup.string()
                            .required(t('form_validation.is_required', { attribute: t('phone_number') })),
        country_id      : Yup.string()
                            .required(t('form_validation.is_required', { attribute: t('country') })),
        city            : Yup.string()
                            .required(t('form_validation.is_required', { attribute: t('city') })),
        post_code       : Yup.string()
                            .required(t('form_validation.is_required', { attribute: t('post_code') })),
        card_holder_name: Yup.string()
                            .required(t('form_validation.is_required', { attribute: t('card_holder_name') })),
        card_number     : !initialValues?.id && !isStripe ? Yup.string()
                             .required(t('form_validation.is_required', { attribute: t('card_number') })) : null,
        expire          : !initialValues?.id && !isStripe ? Yup.string()
                             .required(t('form_validation.is_required', { attribute: t('expire') })) : null,
        ccv             : !initialValues?.id && !isStripe ? Yup.string()
                             .required(t('form_validation.is_required', { attribute: t('ccv') })) : null,
    });


    const {
              handleSubmit: processSubmit,
              register,
              formState: { errors },
              watch,
              setValue,
              setError
          } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const formatRecord = (record) => {
        const  object = {...record};

        object.value = record.id;
        object.label = record.name;

        return object;
    };

    const getFleets = (search) => {
        requests.fleets && requests?.fleets?.abort && requests.fleets.abort();

        return new Promise((resolve, reject) => {
            requests.fleets = auth.getRequest('fleets', { search });

            requests.fleets.then(response => {
                    resolve(response.data.fleets.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    }

    const limit = (val, max) => {
        if (val.length === 1 && val[0] > max[0]) {
            val = '0' + val;
        }

        if (val.length === 2) {
            if (Number(val) === 0) {
                val = '01';

                //this can happen when user paste number
            } else if (val > max) {
                val = max;
            }
        }

        return val;
    }


    const cardExpiry = (val) => {
        let month = limit(val.substring(0, 2), '12');
        let year = val.substring(2, 4);

        return month + (year.length ? '/' + year : '');
    }

    const onSubmit = useCallback((values) => {
        let payload = { ...values };
        payload.fleets = paymentFleets.map(fleet => fleet.value);

        console.log(cardFields);

        if(cardFields.current)
        cardFields.current(values)
            .then((payload) => handleSubmit(payload, { setError }))
            .catch((error) => {
                switch(error?.error?.code)
                {
                    case 'incomplete_number':
                        setError('card_number', { message: t('form_validation.is_required', { attribute: t('card_number') })});
                    break;

                    case 'invalid_number':
                        setError('card_number', { message: t('form_validation.invalid_card_number') });
                    break;

                    case 'incomplete_expiry':
                        setError('expire', { message: t('form_validation.is_required', { attribute: t('expire') })});
                    break;

                    case 'invalid_expiry_month_past':
                    case 'invalid_expiry_year_past':
                    case 'invalid_expiry':
                        setError('expire', { message: t('form_validation.invalid_expire') });
                    break;


                    case 'incomplete_cvc':
                        setError('ccv', { message: t('form_validation.is_required', { attribute: t('ccv') })});
                    break;

                    case 'invalid_cvc':
                        setError('ccv', { message: t('form_validation.invalid_ccv') });
                    break;

                    default:
                        console.error(error);
                        rollbar.error(error);
                }

            })
        else
        {
            handleSubmit(payload, { setError });
        }
    }, [cardFields.current, paymentFleets]);

    useEffect(() => {
        if (currentFleets != null) {
            setPaymentFleet(currentFleets.map(fleet => {
                return {value: fleet.id, label: fleet.name}
            }))
        }
    }, []);

    useEffect(() => {
        register('country_id');
        register('card_number');
        register('expire');
    }, []);


    useEffect(() => {
        if (countries === null) {
            Countries.get()
                .then(countries => setCountries(countries.map(formatRecord)))
                .catch(_ => setCountries([]));
        }
    }, [countries]);


    useEffect(() => {
        return () => {
            for(var key in requests)
            {
                requests[key] && requests[key].abort && requests[key].abort();
            }
        }
    }, []);

    const country_id = watch('country_id');

    const card_number = watch('card_number');
    const expire = watch('expire');

    if(!countries)
    {
        return <LoadingPage />;
    }

    return (
        <Form noValidate onSubmit={processSubmit(onSubmit)}>
            <h5 className="d-flex align-items-center mt-4">
                <Badge bg="primary" className="facility-name-site-card-badge">1</Badge>
                <span className="mx-2">{t('billing_information')}</span>
            </h5>

            <Form.Group>
                <Form.Label className="my-2">
                    {t('card_holder_name')}
                </Form.Label>

                <Form.Control
                    type="text"
                    {...register('card_holder_name')}
                    placeholder={t('enter_card_holder_name')}
                    isInvalid={!!errors.card_holder_name}
                />

                <Form.Control.Feedback type="invalid">
                    {errors.card_holder_name && errors.card_holder_name.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
                <Form.Label className="my-2">
                    {t('email_address')}
                </Form.Label>

                <Form.Control
                    type="email"
                    {...register('email')}
                    placeholder={t('enter_email_address')}
                    isInvalid={!!errors.email}
                />

                <Form.Control.Feedback type="invalid">
                    {errors.email && errors.email.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Row>
                {countries && (
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>
                                {t('country')}
                            </Form.Label>

                            <Select
                                className="react-select"
                                value={countries.find(_ => _.value == country_id)}
                                onChange={({value}) => setValue('country_id', value)}
                                placeholder={t('country')}
                                options={countries}/>

                            <Form.Control.Feedback type="invalid" className={errors.country_id && 'd-block' || ''}>
                                {errors.country_id && errors.country_id.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                )}

                <Col md={6}>
                    <Form.Group>
                        <Form.Label>
                            {t('city')}
                        </Form.Label>

                        <Form.Control
                            type="text"
                            {...register('city')}
                            placeholder={t('city')}
                            isInvalid={!!errors.city}
                        />

                        <Form.Control.Feedback type="invalid" className={errors.city && 'd-block' || ''}>
                            {errors.city && errors.city.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col md={6}>
                    <Form.Group>
                        <Form.Label>
                            {t('post_code')}
                        </Form.Label>

                        <Form.Control
                            type="text"
                            {...register('post_code')}
                            placeholder={t('enter_post_code')}
                            isInvalid={!!errors.post_code}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.post_code && errors.post_code.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col md={6}>
                    <Form.Group>
                        <Form.Label>
                            {t('phone_number')}
                        </Form.Label>

                        <Form.Control
                            type="text"
                            {...register('phone_number')}
                            placeholder={t('enter_phone_number')}
                            isInvalid={!!errors.phone_number}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.phone_number && errors.phone_number.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            {
                !initialValues?.id ? <>
                <h5 className="d-flex align-items-center mt-4">
                    <Badge bg="primary" className="facility-name-site-card-badge">2</Badge>
                    <span className="mx-2">{t('payment_details')}</span>
                </h5>


                {isStripe && (
                    <Elements stripe={stripePromise}>
                        <StripeFields ref={cardFields} errors={errors} />
                    </Elements>
                ) || (

                    <>

                    <Form.Group>
                        <Form.Label>
                            {t('card_number')}
                        </Form.Label>

                        <NumberFormat
                            className={`form-control ${!!errors.card_number ? 'is-invalid' : ''}`}
                            mask="_"
                            format="#### #### #### ####"
                            value={card_number}
                            onValueChange={({formattedValue}) => setValue('card_number', formattedValue)}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.card_number && errors.card_number.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Row>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>
                                    {t('expire')}
                                </Form.Label>

                                <NumberFormat
                                    className={`form-control ${errors.expire ? 'is-invalid' : ''}`}
                                    format={(val) => {
                                        let month = limit(val.substring(0, 2), '12');
                                        let year = val.substring(2, 4);

                                        return month + (year.length ? '/' + year : '');
                                    }}
                                    placeholder="MM/YY"
                                    mask={['M', 'M', 'Y', 'Y']}
                                    value={expire}
                                    onValueChange={({formattedValue}) => setValue('expire', formattedValue)}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.expire && errors.expire.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>
                                    {t('ccv')}
                                </Form.Label>


                                <Form.Control
                                    type="text"
                                    {...register('ccv')}
                                    placeholder={t('ccv')}
                                    isInvalid={!!errors.ccv}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.ccv && errors.ccv.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    </>
                )}

                </>
                                                     :
                <div className="form-group mt-2">
                    <div className="row">
                        <h4 className="text-primary">
                            <span className="text-uppercase me-2">{brand}</span>
                            {cardNumber}</h4>
                    </div>
                </div>
            }

            {auth?.roles?.view_fleets && auth?.user?.is_client_admin && (
            <div className="form-group mt-2">
                <div className="row">
                    <div className="col-12">

                        <AsyncSelect
                            isMulti
                            isClearable={true}
                            value={paymentFleets}
                            onChange={(e) => setPaymentFleet(e)}
                            placeholder={t('fleets')}
                            defaultOptions={true}
                            loadOptions={getFleets}/>
                    </div>
                </div>
            </div>
            ) || ''}


            <div className="form-group mt-4">
                <div className="d-grid gap-2">
                    {!loadingButton ?
                     <Button type="submit" variant="primary">{t('save_payment_method')}</Button>
                                    :
                     <Button variant="primary" disabled>
                         <Spinner
                             as="span"
                             animation="border"
                             size="sm"
                             role="status"
                             aria-hidden="true"
                         />
                         <span className="mx-2">{t('please_wait')}</span>
                     </Button>
                    }
                </div>
            </div>
        </Form>
    )
}

export default PaymentMethodForm;

