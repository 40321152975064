import React from 'react';
import { Alert } from 'react-bootstrap';

export default function ValidationErrors({ errors, setShowErrors }) {
    return (
        Object.keys(errors).length > 0 && (
            <>

                {errors && <Alert variant={'danger'} onClose={() => setShowErrors(false)} dismissible>
                    {errors.message && <Alert.Heading>{errors.message}</Alert.Heading>}
                    {Object.keys(errors.errors).map(function (key, index) {
                        return <li key={index}>{errors.errors[key]}</li>;
                    })}
                </Alert>}
            </>


        )
    );
}
