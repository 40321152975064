import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {useTranslation}               from "react-i18next";
import {useForm}                      from "react-hook-form";

function Filters({searching, onSubmit, ...props}) {

    const {t} = useTranslation();

    const {
        handleSubmit,
        register
    } = useForm({
        defaultValues: {
            ...props.values
        }
    });

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="my-4">
                <Col sm={4} className="col-12">
                    <Form.Control
                        type="text"
                        {...register('search')}
                        placeholder={t('search_for_anything')}
                        />
                </Col>

                <Col sm={3} className="col-12 mt-md-0 mt-3">
                    <div className="d-grid gap-2">
                    {
                        !searching ?
                            <Button type="submit" variant="success" className="w-100">
                                {t('search')}
                            </Button>
                            :
                            <Button variant="success" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="mx-2">{t('please_wait')}</span>
                            </Button>
                    }
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default Filters;
