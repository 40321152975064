import React, {useEffect, useState} from "react";
import {useAuth}                    from "@/services/Auth";
import LoadingPage                  from "@/components/LoadingPage";
import {Card, Container, Modal, Button}            from "react-bootstrap";
import {useTranslation}             from "react-i18next";
import UserForm                     from "./UserForm";
import * as Yup                     from "yup";
import { useParams }                from "react-router-dom";
import SuccessDialog                from "../../../components/Modals/SuccessDialog";
import LoadingButton from "@/components/LoadingButton";
import { Helmet } from "react-helmet";

function UserCreate(props) {

    let auth = useAuth();

    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(false);
    const [updatingUser, setUpdating] = useState(false);
    const [lastPayload, setLastPayload] = useState(false);
    const [serverErrors, setServerErrors] = useState([]);
    const [userFleet, setUserFleet] = useState([]);

    const [needMoreComplianceLicences, setNeedMoreComplianceLicences] = useState(false);

    const handleClose = () => {
        props.history.push(`/users/${userId}`);
    };


    let {id} = useParams();
    let {type} = useParams();

    const onSubmit = (payload) => {
        setLastPayload(payload);
        setUpdating(true);

        var url = '/users';

        switch(type)
        {
            case 'client':
            case 'clients':
                url = `/clients/${id}/users`;
            break;

            case 'fleet':
            case 'fleets':
                url = `/fleets/${id}/users`;
            break;

            case 'supplier':
            case 'suppliers':
                url = `/suppliers/${id}/users`;
            break;

            case 'site':
            case 'sites':
                url = `/sites/${id}/users`;
            break;
        }

        auth.postRequest(url, payload)
            .then(response => {
                setUserId(response.data.user.id);
            })
            .catch(error => {
                if (error.response.status === 422) {
                    let serverErrors = [];

                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }

                    if(serverErrors.length == 1 && serverErrors[0] == 'form_validation.not_enough_licences')
                    {
                        var fleets = payload.selected_fleets?.filter && payload.selected_fleets || payload.selected_fleets && [payload.selected_fleets] || ['fleet', 'fleets'].includes(type) && [{ id }] || [],
                        fleets_to_increase;

                        fleets_to_increase = fleets.filter(fleet => {
                            return fleet && fleet.compliance_status != 'Inactive' && fleet.compliance_licences < fleet.drivers_count + 1;
                        });

                        if(fleets.length <= 0)
                        {
                            setServerErrors(serverErrors);
                        }
                        else
                        if(fleets_to_increase.length <= 0)
                        {
                            auth.getRequest('/fleets', { ids:  fleets.map(_ => _.id) })
                                .then(response => {
                                    fleets_to_increase = response.data.fleets.filter(fleet => {
                                        return fleet.compliance_status != 'Inactive' && fleet.compliance_licences < fleet.drivers_count + 1;
                                    });

                                    if(fleets_to_increase.length <= 0)
                                    {
                                        setServerErrors(serverErrors);
                                    }
                                    else
                                    {
                                        setNeedMoreComplianceLicences(fleets_to_increase);
                                    }
                                })
                        }
                        else
                        {
                            setNeedMoreComplianceLicences(fleets);
                        }
                    }
                    else
                    {
                        setServerErrors(serverErrors)
                    }
                }
            })
            .finally(_ => setUpdating(false))
    };

    const increaseComplianceLicences = () => {
        const licencesErrors = [];

        setUpdating(true);

        const updates = Promise.all(needMoreComplianceLicences.map(fleet => {
            return auth.putRequest(`/fleets/${fleet.id}`, {
                compliance_licences: fleet.drivers_count + 1,
            }).catch(error => {
                if (error.response.status === 422) {
                    for (const key in error.response.data.errors) {
                        licencesErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }
                }
            });
        }))
        .then(() => {
            if(licencesErrors.length > 0)
            {
                setServerErrors(licencesErrors)
                setUpdating(false);
            }
            else
            {
                onSubmit(lastPayload);
            }

            setNeedMoreComplianceLicences(null);
        });
    };

    useEffect(() => {

        if (type === 'fleet' && id != null) {
            setUserFleet(id);
        }
        setLoading(false);
    }, []);

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('create')} / {t('drawer_link_titles.users')} - {t('app')}</title>
                </Helmet>

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h3 className="text-primary">{t('new_user')}</h3>
                </Card.Subtitle>

                <Modal show={needMoreComplianceLicences} onHide={_ => setNeedMoreComplianceLicences(null)}>
                    <Modal.Header>
                        <Modal.Title>{t('compliance.licences_hit_limit')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul>
                        {needMoreComplianceLicences?.map && needMoreComplianceLicences.map((fleet, index) => (
                            <li key={index}>{fleet.name}</li>
                        ))}
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <LoadingButton variant="success" loading={updatingUser} onSubmit={increaseComplianceLicences}>
                            {t('compliance.increase_licences')}
                        </LoadingButton>

                        <Button variant="secondary" onClick={_ => setNeedMoreComplianceLicences(null)}>
                            {t('cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <SuccessDialog
                    show={!!userId}
                    message="messages.user_created"
                    handleClose={handleClose}
                />
                <UserForm
                    onSubmit={onSubmit}
                    processing={updatingUser}
                    task="create"
                    type={type}
                    typeId={id}
                    buttonText="create_user"
                    serverErrors={serverErrors}
                />
            </Card>
        )
    )
}

export default UserCreate;
