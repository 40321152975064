import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import React, {useEffect, useState, useRef} from "react";
import LoadingPage from "@/components/LoadingPage";
import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";
import * as moment from "moment";
import { Helmet } from "react-helmet";
import { useForm } from 'react-hook-form';
import AsyncSelect from "react-select/async";

function Reports() {

    const requestStore = useRef({});
    const { current: requests } = requestStore;


    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const [loading, setLoading] = useState(true);
    const [fetchingReport, setFetchingReport] = useState(false);
    const [hasReport, setHasReport] = useState(false);

    const [reports, setReports] = useState([]);
    const [periods, setPeriods] = useState([]);

    const postRequest = auth.postRequest;
    const getRequest = auth.getRequest;

    const getReports = (values) => {
        requests.report && requests?.report?.abort && requests.report.abort();

        setFetchingReport(true);

        requests.report = getRequest('/report', {
            from_date  : values.from_date,
            to_date    : values.to_date,
            figures    : values.figures,
            user_id    : values.user_id,
            site_id    : values.site_id,
            supplier_id: values.supplier_id,
            fleet_id   : values.fleet_id,
            company_id  : values.company_id,
        });

        requests.report
            .then(response => {
                if (response.data.message === 'OK') {

                    setReports(response.data.report);
                    setPeriods(response.data.periods);
                    setHasReport(response.data.has_report)
                }
                setLoading(false);
                setFetchingReport(false);
            })
            .catch(error => {
                setLoading(false);
                setFetchingReport(false);
            })
    }

    const subHeads = () => {
        let subHeads = [];
        for (let i = 1; i <= periods.length; i++) {
            subHeads.push(t('amount'));
            subHeads.push(t('quantity'));
        }
        return subHeads;
    }


    const formatRecord = (record) => {
        const  object = {...record};

        object.value = record.id;
        object.label = record.name || record.company_name;

        return object;
    };

    const exportCurrentTable = () => {
        let html = document.querySelector("table").outerHTML
        htmlToCSV(html, 'reports.csv');
    }

    const {
              handleSubmit,
              register,
              watch,
              setValue,
              getValues
          } = useForm({
        defaultValues: {
            from_date: moment().startOf('month').format('YYYY-MM-DD'),
            figures: 'total_revenue',
        },
    });

    const getSites = (search) => {
        requests.sites && requests?.sites?.abort && requests.sites.abort();

        return new Promise((resolve, reject) => {
            requests.sites = auth.getRequest('sites', { search, managing: true });

            requests.sites.then(response => {
                    resolve(response.data.sites.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    const getUsers = (search) => {
        requests.users && requests?.users?.abort && requests.users.abort();

        return new Promise((resolve, reject) => {
            requests.users = auth.getRequest('users', { search });

            requests.users.then(response => {
                    resolve(response.data.users.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    const getFleets = (search) => {
        requests.fleets && requests?.fleets?.abort && requests.fleets.abort();

        return new Promise((resolve, reject) => {
            requests.fleets = auth.getRequest('fleets', { search });

            requests.fleets.then(response => {
                    resolve(response.data.fleets.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    const getCompanies = (search) => {
        requests.companies && requests?.companies?.abort && requests.companies.abort();

        return new Promise((resolve, reject) => {
            requests.companies = auth.getRequest('clients', { search });

            requests.companies.then(response => {
                    resolve(response.data.clients.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    const getSuppliers = (search) => {
        requests.suppliers && requests?.suppliers?.abort && requests.suppliers.abort();

        return new Promise((resolve, reject) => {
            requests.suppliers = auth.getRequest('suppliers', { search });

            requests.suppliers.then(response => {
                    resolve(response.data.suppliers.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    useEffect(() => {
        return () => {
            for(var key in requests)
            {
                requests[key] && requests[key].abort && requests[key].abort();
            }
        }
    }, []);

    useEffect(() => {
        register('supplier_id');
        register('selected_supplier');

        register('user_id');
        register('selected_user');

        register('fleet_id');
        register('selected_fleet');

        register('company_id');
        register('selected_company');

        register('site_id');
        register('selected_site');
    }, []);

    const supplier = watch('selected_supplier');
    const site = watch('selected_site');
    const user = watch('selected_user');
    const company = watch('selected_company');
    const fleet = watch('selected_fleet');
    const from_date = watch('from_date');
    const to_date = watch('to_date');
    const figures = watch('figures');
    const show_promotion = watch('show_promotion');


    const htmlToCSV = (html, filename) => {
        let data = [];
        let rows = document.querySelectorAll("table tr");

        for (let i = 0; i < rows.length; i++) {
            let row = [], cols = rows[i].querySelectorAll("td, th");

            for (let j = 0; j < cols.length; j++) {
                row.push(cols[j].innerText);
            }
            data.push(row.join(","));
        }
        downloadCSVFile(data.join("\n"), filename);
    }
    const downloadCSVFile = (csv, filename) => {
        let csv_file, download_link;
        csv_file = new Blob([csv], {type: "text/csv"});
        download_link = document.createElement("a");
        download_link.download = filename;
        download_link.href = window.URL.createObjectURL(csv_file);
        download_link.style.display = "none";
        document.body.appendChild(download_link);
        download_link.click();
    }

    useEffect(() => {
        getReports(getValues());
    }, [from_date, to_date, user, site, supplier, fleet, company, figures, show_promotion]);

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2 site-find-card relative">
                <Helmet>
                    <title>{t('drawer_link_titles.reports')} - {t('app')}</title>
                </Helmet>

                {
                    (fetchingReport && !loading) &&
                    <LoadingPage/>
                }
                <Card.Subtitle>
                    <Row>
                        <Col cols="12" className="d-flex justify-content-start justify-content-md-between flex-md-row flex-column">
                            <h4 className="text-primary">{t('drawer_link_titles.reports')}</h4>
                            <div className="d-flex flex-md-row flex-column">
                                {
                                    hasReport &&
                                    <Button variant="success" className="mx-md-1 my-1" onClick={() => exportCurrentTable()}>{t('export')} (.csv)</Button>
                                }
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <Row className="my-md-4 my-0">
                            {
                                (auth?.roles?.view_users) &&
                                <Col sm={6} md={3} className="my-2 my-md-1">
                                    <Row className="">
                                        <Col className="">
                                            <Form.Label className="mb-2">{t('filters_labels.filter_by_user')}</Form.Label>
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <Col className="">
                                            <AsyncSelect
                                                className="react-select"
                                                isClearable={true}
                                                value={user}
                                                onChange={(e) => { setValue('selected_user', e); setValue('user_id', e && e.value); }}
                                                placeholder={t('choose')}
                                                defaultOptions={true}
                                                loadOptions={getUsers}/>
                                        </Col>
                                    </Row>
                                </Col>
                            }

                            <Col sm={6} md={3} className=" my-2 my-md-1">
                                <Row className="">
                                    <Col className="">
                                        <Form.Label className="mb-2">{t('filters_labels.filter_by_site')}</Form.Label>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col className="">
                                        <AsyncSelect
                                            className="react-select"
                                            isClearable={true}
                                            value={site}
                                            onChange={(e) => { setValue('selected_site', e); setValue('site_id', e && e.value); }}
                                            placeholder={t('choose')}
                                            defaultOptions={true}
                                            loadOptions={getSites}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={6} className=" my-2 my-md-1">
                                <Row className="">
                                    <Col sm={12} className="">
                                        <Form.Label className="mb-2">{t('filters_labels.between_these_dates')}</Form.Label>
                                    </Col>
                                </Row>
                                <Row className="my-md-0">
                                    <Col className="col-12 d-flex align-items-center">

                                        <Form.Control
                                            placeholder={t('from')}
                                            type="date"
                                            max={to_date ? moment(to_date).format('YYYY-MM-DD') : null}
                                            {...register('from_date')}
                                        />
                                        <span className="mx-2">{t('to')}</span>

                                        <Form.Control
                                            placeholder={t('to')}
                                            type="date"
                                            min={from_date ? moment(from_date).format('YYYY-MM-DD') : null}
                                            {...register('to_date')}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="my-md-4">
                            {
                                (auth?.roles?.view_suppliers) &&

                                <Col sm={6} md={3} className=" my-md-1">
                                    <Row className="">
                                        <Col className="">
                                            <Form.Label className="mb-2">{t('filters_labels.filter_by_suppliers')}</Form.Label>
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <Col className="">
                                            <AsyncSelect
                                                className="react-select"
                                                isClearable={true}
                                                value={supplier}
                                                onChange={(e) => { setValue('selected_supplier', e); setValue('supplier_id', e && e.value); }}
                                                placeholder={t('choose')}
                                                defaultOptions={true}
                                                loadOptions={getSuppliers}/>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            {
                                (auth?.roles?.view_clients) &&
                                <Col sm={6} md={3} className="">
                                    <Row className="">
                                        <Col className="">
                                            <Form.Label className="mb-2">{t('filters_labels.filter_by_client')}</Form.Label>
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <Col className="">
                                            <AsyncSelect
                                                className="react-select"
                                                isClearable={true}
                                                value={company}
                                                onChange={(e) => { setValue('selected_company', e); setValue('company_id', e && e.value); }}
                                                placeholder={t('choose')}
                                                defaultOptions={true}
                                                loadOptions={getCompanies}/>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            {
                                (auth?.roles?.view_fleets)
                                &&
                                <Col sm={6} md={3} className=" my-2 my-md-1">
                                    <Row className="">
                                        <Col className="">
                                            <Form.Label className="mb-2">{t('filters_labels.filter_by_fleet')}</Form.Label>
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <Col className="">
                                            <AsyncSelect
                                                className="react-select"
                                                isClearable={true}
                                                value={fleet}
                                                onChange={(e) => { setValue('selected_fleet', e); setValue('fleet_id', e && e.value); }}
                                                placeholder={t('choose')}
                                                defaultOptions={true}
                                                loadOptions={getFleets}/>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            <Col sm={6} md={3} className=" my-2 my-md-1">
                                <Row className="">
                                    <Col className="">
                                        <Form.Label className="mb-2">{t('filters_labels.show_promotion')}</Form.Label>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col className="">
                                        <Form.Select className="react-select" aria-label={t('filters_labels.figures')}
                                            {...register('show_promotion')}
                                            >
                                            <option value="">{t('choose')}</option>
                                            <option value="1">{t('yes')}</option>
                                            <option value="-1">{t('no')}</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={6} md={3} className=" my-2 my-md-1">
                                <Row className="">
                                    <Col className="">
                                        <Form.Label className="mb-2">{t('filters_labels.figures')}</Form.Label>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col className="">
                                        <Col className="col-12">
                                            <Form.Select className="react-select" aria-label={t('filters_labels.figures')}
                                                {...register('figures')}>
                                                <option value="total_revenue">{t('total_revenue')}</option>
                                                { auth?.roles?.view_sites && <option value="site_fees">{t('site_fees')}</option> }
                                                { (auth?.user.is_platform_admin || auth?.user?.is_platform_finance ) && <option value="mpark_revenue">{t('mpark_revenue')}</option> }
                                            </Form.Select>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Card.Subtitle>
                {
                    hasReport ?
                    <Table striped bordered hover responsive className="mt-4">
                        <thead>
                        <tr>
                            <th rowSpan={2}>{t('service')}</th>
                            {
                                periods.map((period, index) => {
                                    return (<th colSpan={2} key={index} className="text-center">{t(period)}</th>)
                                })
                            }
                        </tr>
                        <tr>
                            {
                                subHeads().map((heading, index) => <th className="text-end" key={index}>{t(heading)}</th>)
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            reports.map((report, index) => {
                                return report.data ?
                                    <tr key={index}>
                                        {
                                            report.headers ?

                                            <th>{t(report.category)}</th> :
                                            <td>{t(report.category)}</td>
                                        }
                                        {
                                            report.data.map((data, dataIndex) => {
                                                return (
                                                    report.headers ?
                                                    <th className="text-end" key={dataIndex}>{data}</th> :
                                                    <td className="text-end" key={dataIndex}>{data}</td>
                                                )
                                            })
                                        }
                                    </tr> : null
                            })
                        }
                        </tbody>
                    </Table>
                    :
                    <Row clasName="text-center">
                        <p className="text-center text-danger">{t('no_report_data')}</p>
                    </Row>
                }
            </Card>
        )
    )
}

export default Reports;
