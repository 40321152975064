import {useHistory, useLocation}                   from "react-router-dom";
import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import React, {useState, useEffect}   from "react";
import {useTranslation}               from "react-i18next";
import DatePicker                     from "react-datepicker";
import { useForm } from 'react-hook-form';
import * as moment                    from 'moment';
import LoadingPage                    from "@/components/LoadingPage";
import {useAuth}                      from "@/services/Auth";
import LoadingButton                  from "@/components/LoadingButton";
import {Link}                         from "react-router-dom";
import { yupResolver }                from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select                         from "react-select";
import { Helmet } from "react-helmet";
import useMoment from "@/hooks/useMoment";


function Quote({ site, booking, onSubmit, instantOrder, setBookingErrors, wait}) {
    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const route = useLocation();
    const history = useHistory();

    const {momentLocal} = useMoment();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;



    const [pricing, setPricing] = useState(false);
    const [vehicle, setVehicle] = useState(route.state && route.state.vehicles && route.state.vehicles.find(_ => _.id == booking.vehicle_id) || false);

    const {
        handleSubmit,
    } = useForm();


    useEffect(() => {
        if(!pricing)
        {
            auth.postRequest('bookings/quote', booking)
                .then(response => {
                    setPricing(response.data);
                })
                .catch(error => {
                    if(error.response.status == 422)
                    {
                        setBookingErrors(error.response.data.errors);
                    }
                })
        }

        if(!vehicle)
        {
            auth.getRequest('vehicles/' + booking.vehicle_id)
                .then(response => {
                    setVehicle(response.data.vehicle);
                })
        }


    }, [pricing, vehicle]);


    const on_account_items = pricing?.services?.filter && pricing.services.filter(_ => !!_.on_account) || [];
    const on_card_items = pricing?.services?.filter && pricing.services.filter(_ => !_.on_account) || [];

    var
    on_account_subtotal = 0,
    on_account_vat = 0,
    on_account_total = 0,

    on_card_subtotal = 0,
    on_card_vat = 0,
    on_card_total = 0;

    on_account_items.forEach(item => {
        on_account_subtotal += item.price;
        on_account_vat += item.vat;
        on_account_total += item.price_with_vat;
    });

    on_card_items.forEach(item => {
        on_card_subtotal += item.price;
        on_card_vat += item.vat;
        on_card_total += item.price_with_vat;
    });

    if(!pricing || !vehicle)
    {
        return <LoadingPage />;
    }

    return (
        <Form noValidate onSubmit={handleSubmit(() => onSubmit(pricing))} className="booking booking-quote container-fluid py-4" style={{ "fontSize": '1.2rem' }}>
            <Helmet>
                <title>{t('booking_summary')} / {t('drawer_link_titles.bookings')} - {t('app')}</title>
            </Helmet>

            <Row>
                <Col xl={8} xxl={6}>

                    <h1 className="h2">{t('create_a_booking')} {t('steps', { current: 3, steps: 4 })}</h1>
                    <h2 className="h3 mb-4">{t('booking_summary')}</h2>

                    <Row className="mt-2">
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="fw-bold mb-0">
                                    {t('site')}
                                </Form.Label>

                                <span>{site.name}</span>
                            </Form.Group>
                        </Col>

                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="fw-bold mb-0">
                                    {t('vehicle')}
                                </Form.Label>

                                <span>{vehicle.registration_number}</span>
                            </Form.Group>
                        </Col>
                    </Row>

                    {booking.parking_booking && (
                        <Row className="mt-2">
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold mb-0">
                                        {t('from')}
                                    </Form.Label>

                                    <span>{booking.from_datetime && momentLocal(booking.from_datetime).tz(timezone).format(t('formats.datetime')) || t('now')}</span>
                                </Form.Group>
                            </Col>

                            {booking.to_datetime && (
                                <Col xs={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="fw-bold mb-0">
                                            {t('to')}
                                        </Form.Label>

                                        <span>{momentLocal(booking.to_datetime).tz(timezone).format(t('formats.datetime'))}</span>
                                    </Form.Group>
                                </Col>
                            ) || (
                                booking.duration &&
                                <Col xs={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="fw-bold mb-0">
                                            {t('duration')}
                                        </Form.Label>

                                        <span>{t('n_hours', {count: booking.duration})}</span>
                                    </Form.Group>
                                </Col>
                            )}

                        </Row>
                    )}

                    {booking.comments && (
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold mb-0">
                                {t('comments')}
                            </Form.Label>

                            <span>{booking.comments}</span>
                        </Form.Group>

                    )}

                    <Form.Group className="mb-3">
                        <Form.Label className="fw-bold mb-0">
                            {t('account_payment_method')}
                        </Form.Label>

                        <span>{pricing.paymentMethod ? pricing.paymentMethod.card_number : "Credit"}</span>
                    </Form.Group>

                    <Row className="mt-2">
                    {on_account_items.length > 0 && (
                            <Col xs={12} xxl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold mb-2">
                                        {t('on_account')} - {t('order_items')}
                                    </Form.Label>

                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>
                                                    {t('item')}
                                                </th>
                                                <th className="text-end">
                                                    {t('price')}
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                        {on_account_items.map((service, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {service.name}
                                                </td>

                                                <td className="text-end">
                                                    {
                                                        service.original_price != null &&
                                                        <strike className="me-2">{service.original_price.toLocaleString(undefined, {minimumFractionDigits: 2})}</strike>
                                                    }
                                                    <span>{service.price.toLocaleString(undefined, {minimumFractionDigits: 2})}&nbsp;{site.currency}</span>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <th>
                                                    {t('subtotal')}
                                                </th>

                                                <td className="text-end">
                                                    {on_account_subtotal.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                                    &nbsp;
                                                    {site.currency}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    {t('vat')}
                                                </th>

                                                <td className="text-end">
                                                    {on_account_vat.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                                    &nbsp;
                                                    {site.currency}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    {t('total_inc_vat')}
                                                </th>

                                                <td className="text-end">
                                                    {on_account_total.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                                    &nbsp;
                                                    {site.currency}
                                                </td>
                                            </tr>

                                        </tfoot>
                                    </Table>
                                </Form.Group>
                            </Col>
                        )}

                    {on_card_items.length > 0 && on_account_items.length > 0 && <hr />}

                    {on_card_items.length > 0 && (
                            <Col xs={12} xxl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold mb-2">
                                        {t('on_card')} - {t('order_items')}
                                    </Form.Label>

                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>
                                                    {t('item')}
                                                </th>
                                                <th className="text-end">
                                                    {t('price')}
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                        {on_card_items.map((service, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {service.name}
                                                </td>

                                                <td className="text-end">
                                                    {
                                                        service.original_price != null &&
                                                        <strike className="me-2">{service.original_price.toLocaleString(undefined, {minimumFractionDigits: 2})}</strike>
                                                    }
                                                    <span>{service.price.toLocaleString(undefined, {minimumFractionDigits: 2})}&nbsp;{site.currency}</span>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <th>
                                                    {t('subtotal')}
                                                </th>

                                                <td className="text-end">
                                                    {on_card_subtotal.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                                    &nbsp;
                                                    {site.currency}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    {t('vat')}
                                                </th>

                                                <td className="text-end">
                                                    {on_card_vat.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                                    &nbsp;
                                                    {site.currency}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    {t('total_inc_vat')}
                                                </th>

                                                <td className="text-end">
                                                    {on_card_total.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                                    &nbsp;
                                                    {site.currency}
                                                </td>
                                            </tr>

                                        </tfoot>
                                    </Table>
                                </Form.Group>
                            </Col>
                        )}
                        </Row>

                    <div className="mt-4">
                        <Button type="submit" className="w-100" disabled={wait}>
                            {wait ? t('please_wait') : t('confirm_booking')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

export default Quote;
