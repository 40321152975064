import { getRequest } from "./Api";

let cities_request;
let cities;

export default {
  get: function () {
    return new Promise((resolve, reject) => {

      if(cities)
      {
        resolve(cities);
      }
      else
      {
        if(!cities_request)
        {
          cities_request = getRequest('/cities')
        }

        cities_request
          .then((response) => {
            cities = response.data.cities;

            resolve(cities);
          })
          .catch(error => reject(error));
      }
    });
  }

};
