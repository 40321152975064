import {useTranslation}                             from "react-i18next";
import {useAuth}                                    from "@/services/Auth";
import {useParams}                                  from "react-router-dom";
import React, {useEffect, useState}                 from "react";
import LoadingPage                                  from "@/components/LoadingPage";
import {Button, Card, Col, Container, Row, Spinner} from "react-bootstrap";
import {ErrorMessage, Field, Formik, Form}          from "formik";
import * as Yup                                     from "yup";
import Select                                       from "react-select";
import SupplierForm                                 from "./components/SupplierForm";
import SuccessDialog                                from "../../../components/Modals/SuccessDialog";
import { Helmet } from "react-helmet";

function SupplierEdit(props) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const getRequest = auth.getRequest;
    const putRequest = auth.putRequest;
    let {id} = useParams();


    const [supplier, setSupplier] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updatingSupplier, setUpdatingSupplier] = useState(false);


    const [accountStatusOptions, setAccountStatusOptions] = useState([]);
    const [accountTypeOptions, setAccountTypeOptions] = useState([]);
    const [supportedCurrency, setSupportedCurrency] = useState([]);

    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);

    const [serverErrors, setServerErrors] = useState([]);
    const [paymentTerms, setPaymentTerms] = useState([]);

    const [updateSuccess, setUpdateSuccess] = useState(false);


    useEffect(() => {
        getRequest('/suppliers/' + id)
            .then(response => {
                setSupplier(response.data.supplier)
                //client data


                //options
                setAccountStatusOptions(response.data.account_status_options);
                setSupportedCurrency(response.data.supported_currency);
                setPaymentTerms(response.data.payment_terms);

                setCities(response.data.cities);
                setCountries(response.data.countries);

                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error)
            })

    }, []);

    const handleSubmit = (payload) => {
        setUpdatingSupplier(true);
        console.log(payload);
        putRequest(`/suppliers/${supplier.id}`, payload)
            .then(response => {
                setUpdatingSupplier(false);
                if (response.data.message && response.data.message === "OK") {
                    setUpdateSuccess(true);
                }
            })
            .catch(error => {
                setUpdatingSupplier(false);
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }
                    setServerErrors(serverErrors)
                }
            })
    };

    const handleClose = () => {
        setUpdateSuccess(false);
        if (id !== '' && id != null) {
            props.history.push(`/suppliers/${id}`)
        }
    }

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('edit')} / {t('drawer_link_titles.suppliers')} - {t('app')}</title>
                </Helmet>


                <SuccessDialog
                    show={updateSuccess}
                    message="success_dialog.supplier_updated"
                    handleClose={handleClose}
                />

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h4 className="text-primary">{supplier.company_name}</h4>
                </Card.Subtitle>

                <SupplierForm
                    initialValues={{
                        'company_name'                              : supplier.company_name,
                        'contact_name'                              : supplier.contact_name,
                        'email'                                     : supplier.email,
                        'phone_number'                              : supplier.phone_number,
                        'account_status'                            : supplier.account_status_main,
                        'account_number'                            : supplier.account_number,
                        'currency'                                  : supplier.currency,
                        'credit_limit'                              : supplier.credit_limit,
                        'vat_registration_number'                   : supplier.vat_registration_number,
                        'address'                                   : supplier.address,
                        'city'                                      : supplier.city,
                        'country_id'                                : supplier.country_id,
                        'post_code'                                 : supplier.post_code,
                        'supplier_payment_terms_days'               : supplier.supplier_payment_terms_days,
                        'supplier_payment_terms'                    : supplier.supplier_payment_terms,
                        'billing_address_same_as_registered_address': supplier.billing_address_same_as_registered_address,
                        'address_billing'                           : supplier.address_billing,
                        'billing_city'                              : supplier.billing_city,
                        'billing_country_id'                        : supplier.billing_country_id,
                        'post_code_billing'                         : supplier.post_code_billing,
                        'billing_address_line_2'                    : supplier.billing_address_line_2,
                        'billing_county'                            : supplier.billing_county,
                        'county'                                    : supplier.county,
                        'address_line_2'                            : supplier.address_line_2,
                    }}
                    handleSubmit={handleSubmit}
                    supplier={supplier}
                    loading={updatingSupplier}
                    accountStatusOptions={accountStatusOptions}
                    supportedCurrency={supportedCurrency}
                    cities={cities}
                    countries={countries}
                    buttonText="save_new_supplier"
                    serverErrors={serverErrors}
                    paymentTerms={paymentTerms}
                />
            </Card>
        )
    )
}

export default SupplierEdit;
