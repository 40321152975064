import { useHistory, useLocation, Redirect, Link } from "react-router-dom";
import React, { useEffect, useState, useCallback, useRef }   from "react";
import { useTranslation }               from "react-i18next";
import { Helmet } from "react-helmet";
import { useAuth, Countries, Storage }   from "@/services";
import { Card, Form, Button, ProgressBar } from "react-bootstrap";
import LoadingPage from "@/components/LoadingPage";
import Wysiwyg, { validateWysiwyg, validateImg } from "@/components/Inputs/Wysiwyg";
import YesNoToggle from "@/components/Inputs/YesNoToggle";
import { useForm, useFieldArray } from 'react-hook-form';
import { useJsApiLoader } from '@react-google-maps/api';
import * as yup from 'yup';
import Select from "react-select";
import { yupResolver } from '@hookform/resolvers/yup';
import DisplayWysiwyg from "@/components/Elements/DisplayWysiwyg";
import DisplayLocation from "@/components/Elements/DisplayLocation";
import LocationFields, { useLocationFieldsSchema } from "@/components/Forms/LocationFields";
import { CheckIcon } from "@/components/icons";
import { useLayout } from "@/layouts/Layout";
import useMoment from "@/hooks/useMoment";

function BorderReport() {
  const auth = useAuth();
  const main = useLayout();
  const {t} = useTranslation();
  const route = useLocation();
  const history = useHistory();

  const {momentLocal, moment} = useMoment()

  const vehicle = route?.state?.vehicle || undefined;
  const occurance_date = route?.state?.occurance_date || undefined;

  const storage_key = route?.state?.storage_key;

  const [report, setReport] = useState(undefined);

  const steps = ['location', 'general', 'other', 'summary'];
  const step = route?.state?.step || 'location';
  const step_index = steps.findIndex(_ => step == _);

  const updateStep = step => {
    history.push(route.pathname, {
      ...(route.state || {}),

      step,
    });
  };

  const onBack = () => {
    var target = steps.findIndex(_ => _ == step);

    if(target < 0 || target > steps.length - 1)
    {
      target = steps.length;
    }

    target --;

    updateStep(steps[target]);
  };

  const next = () => {
    var target = steps.findIndex(_ => _ == step);

    if(target < 0 || target > steps.length - 1)
    {
      target = -1;
    }

    target ++;

    updateStep(steps[target]);
  };

  const [loading, setLoading] = useState(false);

  const onSave = values => {
    const _report = { ...report, ...values };

    setReport(_report);
  };

  const onSubmit = values => {
    const _report = { ...report, ...values };

    setReport(_report);

    next();
  };

  const save = _ => {
    const data = {
      type: 'BorderReport',
      vehicle_id: vehicle.id,
      checks: [].concat(report.checks).concat(report.other_checks),
      location: report.location
    };


    // TODO convert times to UTC

    setLoading(true);

    auth.postRequest('reports', data)
        .then(response => {
          setReport(response.data.data);
        })
        .finally(_ => setLoading(false));
  };

  useEffect(() => {
    if(report?.guid)
    {
      Storage.Session.removeItem(storage_key);
    }

    const onUnload = _ => {
      if(report && !report.guid)
      {
        Storage.Session.setItem(storage_key, report);
      }
    };

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [report]);

  useEffect(() => {
    if(report === undefined)
    {
      if(storage_key)
      {
        Storage.Session.getItem(storage_key).then(value => {
          setReport(value || {});
        });
      }
      else
      {
        setReport({});
      }
    }
    else
    if(Object.keys(report).length <= 0)
    {
      if(step != steps[0])
      {
        updateStep(steps[0]);
      }
    }
  }, [report, step]);

  useEffect(() => {
    if(!storage_key)
    {
      history.replace(route.pathname, {
        ...(route.state || {}),

        storage_key: 'border-report-' + vehicle?.id + '-' + moment().toISOString(),
      });
    }

  }, [storage_key])

  useEffect(() => {
    main.scrollTop();
  }, [step])

  if(!vehicle)
  {
    return <Redirect to="/compliance" />;
  }

  if(loading || !report)
  {
    return <LoadingPage />;
  }

  if(report.guid)
  {
    return (
      <div className="container">
        <Helmet>
          <title>{t('compliance.BorderReport.title')} - {t('app')}</title>
        </Helmet>

        <h1 className="h4 mb-3 text-primary">
          {t('compliance.BorderReport.title')}
        </h1>

        <ProgressBar variant="success" now={100} />

        <div className="large-icon-message">
          <CheckIcon variant="success" />

          <h3 className="h6 text-success strong mt-3 text-center">
            {t('n_have_now_been_completed', { n: t('compliance.BorderReport.title') })}
          </h3>
        </div>

        <Link className="btn btn-primary w-100" to={{ pathname: '/compliance', state: { vehicle_id: vehicle.id, occurance_date } }}>
          {t('back')}
        </Link>
      </div>
    );
  }

  return (
    <div className="container">
      <Helmet>
        <title>{t('compliance.BorderReport.title')} - {t('app')}</title>
      </Helmet>

      <h1 className="h4 mb-3 text-primary">
        {t('compliance.BorderReport.title')}
      </h1>

      <ProgressBar variant="success" now={(step_index + 1) / steps.length * 100} />

      {(_ => {
        switch(step)
        {
          case 'location':
          default:
            return <Location {...{ vehicle, report, onBack, onSave, onSubmit }} />;

          case 'general':
            return <General {...{ report, onBack, onSave, onSubmit }} />;

          case 'other':
            return <Other {...{ report, onBack, onSave, onSubmit }} />;
          case 'summary':
            return <Summary {...{ vehicle, report, onBack, onSave, onSubmit: save }} />;
        }

      })()}
    </div>
  );
}

export default BorderReport;


function Location({ vehicle, report, onBack, onSave, onSubmit, ...props}) {
  const auth = useAuth();

  const {t} = useTranslation();

  const {momentLocal, moment}  = useMoment();


  const schema = yup.object().shape({
    location: useLocationFieldsSchema(),
  });


  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: (() => {
      const values = {... report};

      if(values.occurance_at)
      {
        values.occurance_at = momentLocal(values.occurance_at).format('YYYY-MM-DD');
      }

      return values;
    })(),
    resolver: yupResolver(schema),
  });


  const location = watch('location');


  useEffect(() => {
    onSave({
      location,
    });
  }, [
    location
  ]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>


      <div className="mt-3">
        {t('reported_by') + ' : '}

        <strong>{auth.user.name}</strong>
      </div>

      <div>
        {t('vehicle_registration_number') + ' : '}

        <strong>{vehicle.registration_number}</strong>
      </div>

      <div className="mb-3">
        {t('compliance.BorderReport.time') + ' : '}

        <strong>{momentLocal(moment()).format(t('formats.datetime'))}</strong>
      </div>


      <LocationFields {...{ register, errors, setValue, path: 'location.', useCurrentLocation: true }} />


      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" type="submit">
          {t('continue')}
        </Button>
      </Form.Group >
    </Form>
  );
}


function General({ report, onBack, onSave, onSubmit, ...props}) {
  const {t} = useTranslation();

  const wysiwyg = useRef({});


  const schema = yup.object().shape({
    checks: yup.array().of(
      yup.object().shape({

        key: yup.string()
          .required()
          .max(255),

        value: yup.string()
          .max(255)
          .when(['key'], (key, schema) =>
            key == 'compliance.BorderReport.is_damage' &&
              schema.required(t('form_validation.is_required', { attribute: t('field') }))
                    .typeError(t('form_validation.is_required', { attribute: t('field') }))
            ||
              schema.nullable()
          ),

        content: yup.string()
          .required(t('form_validation.is_required', { attribute: t('field') }))
          .test(
            'max-content',
            t('form_validation.max_length', { attribute: t('field'), max: 65000 }),
            validateWysiwyg
          )
          .test(
            'image',
            t('form_validation.image_required', { attribute: t('field') }),
            validateImg
          ),
      })
    )
  });


  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: report,
    resolver: yupResolver(schema),
  });

  const checks = watch('checks');

  useEffect(() => {
    onSave({
      checks
    });
  }, [checks]);

  useEffect(() => {
    register('checks.0.key', { value: 'compliance.BorderReport.inside' });
    register('checks.1.key', { value: 'compliance.BorderReport.outside' });
    register('checks.2.key', { value: 'compliance.BorderReport.is_damage' });
  }, [])

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <h3>{t('compliance.BorderReport.general_checks')}</h3>

      <Form.Group className="mb-3 wysiwyg-image">
        <label htmlFor="inside" className="my-2">{t('compliance.BorderReport.inside')}</label>

        <Wysiwyg
          key={'checks.0.content'}
          {...register('checks.0.content')}
          blockEmbeds={false}
          preUpload={true}
          imageCompression={{ maxWidth: 320 }}
          toolbar={false}
          isInvalid={!!(errors?.checks && errors.checks[0] && errors.checks[0].content)} />

        <Form.Control.Feedback type="invalid">
          {errors?.checks && errors.checks[0] && errors.checks[0].content?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3 wysiwyg-image">
        <label htmlFor="outside" className="my-2">{t('compliance.BorderReport.outside')}</label>

        <Wysiwyg
          key={'checks.1.content'}
          {...register('checks.1.content')}
          blockEmbeds={false}
          preUpload={true}
          imageCompression={{ maxWidth: 320 }}
          toolbar={false}
          isInvalid={!!(errors?.checks && errors.checks[1] && errors.checks[1].content)} />

        <Form.Control.Feedback type="invalid">
          {errors?.checks && errors.checks[1] && errors.checks[1].content?.message}
        </Form.Control.Feedback>

      </Form.Group>

      <Form.Group className="mb-3 wysiwyg-image">
        <label htmlFor="is_damage" className="my-2">{t('compliance.BorderReport.is_damage')}</label>

        <YesNoToggle
          {...register('checks.2.value')}
          isInvalid={!!(errors?.checks && errors.checks[2] && errors.checks[2].value)} />

        <Form.Control.Feedback type="invalid">
          {errors?.checks && errors.checks[2] && errors.checks[2].value?.message}
        </Form.Control.Feedback>

        <Wysiwyg
          key={'checks.2.content'}
          {...register('checks.2.content')}
          blockEmbeds={false}
          toolbar={false}
          preUpload={true}
          imageCompression={{ maxWidth: 320 }}
          isInvalid={!!(errors?.checks && errors.checks[2] && errors.checks[2].content)} />

        <Form.Control.Feedback type="invalid">
          {errors?.checks && errors.checks[2] && errors.checks[2].content?.message}
        </Form.Control.Feedback>

      </Form.Group>

      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" type="submit">
          {t('continue')}
        </Button>
      </Form.Group >
    </Form>
  );
}

function Other({ report, onBack, onSave, onSubmit, ...props}) {
  const {t} = useTranslation();

  const wysiwyg = useRef({});


  const schema = yup.object().shape({
    other_checks: yup.array().of(
      yup.object().shape({

        key: yup.string()
          .required()
          .max(255),

        value: yup.string()
          .max(255)
          .required(t('form_validation.is_required', { attribute: t('field') }))
          .typeError(t('form_validation.is_required', { attribute: t('field') })),

        content: yup.string()
          .required(t('form_validation.is_required', { attribute: t('field') }))
          .test(
            'max-content',
            t('form_validation.max_length', { attribute: t('field'), max: 65000 }),
            validateWysiwyg
          ),
      })
    )
  });


  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: report,
    resolver: yupResolver(schema),
  });

  const other_checks = watch('other_checks');

  useEffect(() => {
    onSave({
      other_checks
    });
  }, [other_checks]);

  useEffect(() => {
    register('other_checks.0.key', { value: 'compliance.BorderReport.external_compartments' });
    register('other_checks.1.key', { value: 'compliance.BorderReport.underside_accessible' });
    register('other_checks.2.key', { value: 'compliance.BorderReport.seals_intact' });
    register('other_checks.3.key', { value: 'compliance.BorderReport.locks_intact' });
    register('other_checks.4.key', { value: 'compliance.BorderReport.tir_intact' });
    register('other_checks.5.key', { value: 'compliance.BorderReport.life_signs_checked' });
  }, []);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <h3>{t('compliance.BorderReport.other_checks')}</h3>

      <Form.Group className="mb-3 wysiwyg-image">
        <label htmlFor="inside" className="my-2">{t('compliance.BorderReport.external_compartments')}</label>

        <YesNoToggle
          {...register('other_checks.0.value')}
          isInvalid={!!(errors?.other_checks && errors.other_checks[0] && errors.other_checks[0].value)} />

        <Form.Control.Feedback type="invalid">
          {errors?.other_checks && errors.other_checks[0] && errors.other_checks[0].value?.message}
        </Form.Control.Feedback>

        <Wysiwyg
          key={'other_checks.0.content'}
          {...register('other_checks.0.content')}
          blockEmbeds={false}
          preUpload={true}
          imageCompression={{ maxWidth: 320 }}
          toolbar={false}
          isInvalid={!!(errors?.other_checks && errors.other_checks[0] && errors.other_checks[0].content)} />

        <Form.Control.Feedback type="invalid">
          {errors?.other_checks && errors.other_checks[0] && errors.other_checks[0].content?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3 wysiwyg-image">
        <label htmlFor="outside" className="my-2">{t('compliance.BorderReport.underside_accessible')}</label>


        <YesNoToggle
          {...register('other_checks.1.value')}
          isInvalid={!!(errors?.other_checks && errors.other_checks[1] && errors.other_checks[1].value)} />

        <Form.Control.Feedback type="invalid">
          {errors?.other_checks && errors.other_checks[1] && errors.other_checks[1].value?.message}
        </Form.Control.Feedback>

        <Wysiwyg
          key={'other_checks.1.content'}
          {...register('other_checks.1.content')}
          blockEmbeds={false}
          preUpload={true}
          imageCompression={{ maxWidth: 320 }}
          toolbar={false}
          isInvalid={!!(errors?.other_checks && errors.other_checks[1] && errors.other_checks[1].content)} />

        <Form.Control.Feedback type="invalid">
          {errors?.other_checks && errors.other_checks[1] && errors.other_checks[1].content?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3 wysiwyg-image">
        <label htmlFor="seals_intact" className="my-2">{t('compliance.BorderReport.seals_intact')}</label>


        <YesNoToggle
          {...register('other_checks.2.value')}
          isInvalid={!!(errors?.other_checks && errors.other_checks[2] && errors.other_checks[2].value)} />

        <Form.Control.Feedback type="invalid">
          {errors?.other_checks && errors.other_checks[2] && errors.other_checks[2].value?.message}
        </Form.Control.Feedback>

        <Wysiwyg
          key={'other_checks.2.content'}
          {...register('other_checks.2.content')}
          blockEmbeds={false}
          preUpload={true}
          imageCompression={{ maxWidth: 320 }}
          toolbar={false}
          isInvalid={!!(errors?.other_checks && errors.other_checks[2] && errors.other_checks[2].content)} />

        <Form.Control.Feedback type="invalid">
          {errors?.other_checks && errors.other_checks[2] && errors.other_checks[2].content?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3 wysiwyg-image">
        <label htmlFor="locks_intact" className="my-2">{t('compliance.BorderReport.locks_intact')}</label>

        <YesNoToggle
          {...register('other_checks.3.value')}
          isInvalid={!!(errors?.other_checks && errors.other_checks[3] && errors.other_checks[3].value)} />

        <Form.Control.Feedback type="invalid">
          {errors?.other_checks && errors.other_checks[3] && errors.other_checks[3].value?.message}
        </Form.Control.Feedback>

        <Wysiwyg
          key={'other_checks.3.content'}
          {...register('other_checks.3.content')}
          blockEmbeds={false}
          preUpload={true}
          imageCompression={{ maxWidth: 320 }}
          toolbar={false}
          isInvalid={!!(errors?.other_checks && errors.other_checks[3] && errors.other_checks[3].content)} />

        <Form.Control.Feedback type="invalid">
          {errors?.other_checks && errors.other_checks[3] && errors.other_checks[3].content?.message}
        </Form.Control.Feedback>

      </Form.Group>

      <Form.Group className="mb-3 wysiwyg-image">
        <label htmlFor="tir_intact" className="my-2">{t('compliance.BorderReport.tir_intact')}</label>

        <YesNoToggle
          {...register('other_checks.4.value')}
          isInvalid={!!(errors?.other_checks && errors.other_checks[4] && errors.other_checks[4].value)} />

        <Form.Control.Feedback type="invalid">
          {errors?.other_checks && errors.other_checks[4] && errors.other_checks[4].value?.message}
        </Form.Control.Feedback>

        <Wysiwyg
          key={'other_checks.4.content'}
          {...register('other_checks.4.content')}
          preUpload={true}
          imageCompression={{ maxWidth: 320 }}
          blockEmbeds={false}
          toolbar={false}
          isInvalid={!!(errors?.other_checks && errors.other_checks[4] && errors.other_checks[4].content)} />

        <Form.Control.Feedback type="invalid">
          {errors?.other_checks && errors.other_checks[4] && errors.other_checks[4].content?.message}
        </Form.Control.Feedback>


      </Form.Group>

      <Form.Group className="mb-3 wysiwyg-image">
        <label htmlFor="life_signs_checked" className="my-2">{t('compliance.BorderReport.life_signs_checked')}</label>

        <YesNoToggle
          {...register('other_checks.5.value')}
          isInvalid={!!(errors?.other_checks && errors.other_checks[5] && errors.other_checks[5].value)} />

        <Form.Control.Feedback type="invalid">
          {errors?.other_checks && errors.other_checks[5] && errors.other_checks[5].value?.message}
        </Form.Control.Feedback>

        <Wysiwyg
          key={'other_checks.5.content'}
          {...register('other_checks.5.content')}
          preUpload={true}
          imageCompression={{ maxWidth: 320 }}
          blockEmbeds={false}
          toolbar={false}
          isInvalid={!!(errors?.other_checks && errors.other_checks[5] && errors.other_checks[5].content)} />

        <Form.Control.Feedback type="invalid">
          {errors?.other_checks && errors.other_checks[5] && errors.other_checks[5].content?.message}
        </Form.Control.Feedback>

      </Form.Group>

      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" type="submit">
          {t('continue')}
        </Button>
      </Form.Group >
    </Form>
  );
}


function Summary({ vehicle, report, onBack, onSubmit, ...props}) {
  const auth = useAuth();
  const {t} = useTranslation();

  const {moment, momentLocal} =useMoment()

  return (
    <>
      <h3>{t('summary')}</h3>

      <Form.Group className="mb-3">
        <label htmlFor="reported_by" className="my-2"><strong>{t('reported_by')}</strong></label>


        {auth.user.name}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="vehicle_registration" className="my-2"><strong>{t('vehicle_registration_number')}</strong></label>

        {vehicle.registration_number}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="occurance_at" className="my-2"><strong>{t('compliance.BorderReport.time')}</strong></label>

        {momentLocal(moment()).format(t('formats.datetime'))}
      </Form.Group>


      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('location')}</strong></label>

        <DisplayLocation location={report.location} />
      </Form.Group>

      {report?.checks?.map && report.checks.map((check, index) => (
        <Form.Group key={'check' + index} className="mb-3">
          <label htmlFor="type" className="my-2 "><strong>{t(check.key)}</strong></label>

          {check.value && <p>{t(check.value)}</p> || ''}

          <DisplayWysiwyg html={check.content} />
        </Form.Group>
      )) || ''}

      {report?.other_checks?.map && report.other_checks.map((check, index) => (
        <Form.Group key={'other_checks' + index} className="mb-3">
          <label htmlFor="type" className="my-2 "><strong>{t(check.key)}</strong></label>

          {check.value && <p>{t(check.value)}</p> || ''}

          <DisplayWysiwyg html={check.content} />
        </Form.Group>
      )) || ''}

      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" onClick={_ => onSubmit({})}>
          {t('continue')}
        </Button>
      </Form.Group >
    </>
  );
}

