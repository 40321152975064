import React, { useEffect, useState } from "react";

function Markdown({ content })
{
  const [html, setHtml] = useState('')

  useEffect(_ => {
    import('markdown-it').then(Markdown => {
      const md = Markdown.default();

      setHtml(md.render(content))
    })


  }, [content])


  return <div dangerouslySetInnerHTML={{ __html: html }} />
}


export default Markdown;
