import Clients from "../../views/pages/clients/Clients";
import ClientCreate from "../../views/pages/clients/ClientCreate";
import ClientSingle from "../../views/pages/clients/ClientSingle";
import ClientEdit from "../../views/pages/clients/ClientEdit";
import ClientsIcon from "../../components/icons/ClientsIcon";
import ImportClients from "../../views/pages/clients/ImportClients";
import ImportPayments from "../../views/pages/clients/ImportPayments";

const clientsRoutes = [
    {
        path         : "/clients",
        component    : Clients,
        exact        : true,
        gate         : 'view_clients',
        title        : 'Clients',
        translate_key: 'clients',
        // icon         : 'bi-building',
        icon         : <ClientsIcon/>,
        main         : true,
    },
    {
        path     : "/clients/import",
        component: ImportClients,
        exact    : true,
        gate     : 'view_clients',
        main     : false,
    },
    {
        path     : "/clients/import/payments",
        component: ImportPayments,
        exact    : true,
        gate     : 'view_clients',
        main     : false,
    },
    {
        path     : "/clients/create",
        component: ClientCreate,
        exact    : true,
        gate     : 'view_clients',
        main     : false,
    },
    {
        path     : "/clients/:id",
        component: ClientSingle,
        exact    : true,
        gate     : 'view_clients',
        main     : false,
    },
    {
        path     : "/clients/edit/:id",
        component: ClientEdit,
        exact    : true,
        gate     : 'view_clients',
        main     : false,
    },
];

export default clientsRoutes;
