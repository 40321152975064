import LoadingPage                   from "@/components/LoadingPage";
import {Card, Col, Row, Table, Form, Button, Spinner} from "react-bootstrap";
import React, {useEffect, useState}  from "react";
import {useTranslation}              from "react-i18next";
import {useAuth}                     from "@/services/Auth";
import {Link}                        from "react-router-dom";
import { Helmet } from "react-helmet";
import { useForm, useFieldArray } from 'react-hook-form';
import * as moment from 'moment-timezone';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

function UpdateExchangeRate() {

    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [loading, setLoading] = useState(true);



    const schema = yup.object().shape({
        rates: yup.array()
            .typeError(t('form_validation.is_required', { attribute: t('currencies') }))
    }).required().noUnknown(true);

    const {
        handleSubmit,
        control,
        register,
        formState: { errors, isSubmitting },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const { fields: currencyFields, replace } = useFieldArray({
        control,
        name: 'rates',
    });

    const onSave = (input) => {
        return auth.postRequest('/exchange-rates', input)
            .then(response => {
                replace(response.data);
            });
    }

    useEffect(() => {
        setLoading(true);

        auth.getRequest('exchange-rates')
            .then(response => {
                replace(response.data);
                setLoading(false);
            })
    }, []);

    if(loading)
    {
        return <LoadingPage/>;
    }

    return (
        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{t('update_exchange_rates')} - {t('app')}</title>
            </Helmet>


            <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                <h3>{t('update_exchange_rates')}</h3>
            </Card.Subtitle>
            <Form noValidate onSubmit={handleSubmit(onSave)}>
                <Row>
                    <Col>
                        <Table striped bordered hover className="mt-1">
                            <thead>
                            <tr>
                                <th>{t('currencies')}</th>
                                <th>{t('rate_base_currency', {baseCurrency: 'GBP'})}</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>GBP</td>
                                    <td className="font-weight-bold">1</td>
                                    <td className="d-flex align-items-center">
                                        <span>&nbsp;</span>
                                    </td>
                                </tr>
                            {
                                currencyFields?.map && currencyFields.map((exchangeRate, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{exchangeRate.currency}</td>
                                            <td>
                                                <input type="hidden" {...register(`rates.${index}.currency`)} value={exchangeRate.currency} />

                                                <Form.Control
                                                    type="number"
                                                    step="0.00000001"
                                                    required
                                                    {...register(`rates.${index}.rate`)}
                                                    defaultValue={exchangeRate.rate}
                                                    isInvalid={!!errors[`rates.${exchangeRate.id}`]}
                                                />
                                            </td>
                                            <td className="d-flex align-items-center">
                                                <small className="text-black-50 mt-2">{t('last_updated')}{': '}{moment.utc(exchangeRate.updated_at).tz(timezone).format(t('formats.datetime'))}</small>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <div className="form-group mt-4">
                    <div className="d-grid gap-2">
                        {!isSubmitting ?
                            <Button type="submit" variant="primary">{t('update')}</Button>
                            :
                            <Button variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="mx-2">{t('please_wait')}</span>
                            </Button>
                        }
                    </div>
                </div>
            </Form>
        </Card>

    )
}

export default UpdateExchangeRate;
