import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import { useForm } from "react-hook-form";
import moment from  'moment-timezone';

function Filters({options,searching, ...props}) {

  const {t, i18n} = useTranslation();
  const auth = useAuth();

  const onSubmit = (values) => {
    let updatedValues = values;

    if(updatedValues.occurance_from)
    {
      updatedValues.occurance_from = moment(updatedValues.occurance_from).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
    }

    if(updatedValues.occurance_to)
    {
      updatedValues.occurance_to = moment(updatedValues.occurance_to).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
    }

    props.onSubmit(updatedValues)
  };

  const {
        handleSubmit,
        register,
        watch,
        setValue
      } = useForm({
    defaultValues: {
      ...props.values
    }
  });


  const [advanceFilters, setAdvanceFilters] = useState(false);

  useEffect(() => {
    register('type')
    register('status')
  }, []);


  const report_types = [
    {
      value: 'DailyReport',
      label: t('compliance.DailyReport.title'),
    },
    {
      value: 'CrimeReport',
      label: t('compliance.CrimeReport.title'),
    },
    {
      value: 'LoadingReport',
      label: t('compliance.LoadingReport.title'),
    },
    {
      value: 'BorderReport',
      label: t('compliance.BorderReport.title'),
    },
    {
      value: 'TrafficIncidentReport',
      label: t('compliance.TrafficIncidentReport.title'),
    },
    {
      value: 'JourneyReport',
      label: t('compliance.JourneyReport.title'),
    },
  ];

  const statuses = [
    {
      value: 'Incomplete',
      label: t('compliance.report_status.Incomplete'),
    },
    {
      value: 'Pending',
      label: t('compliance.report_status.Pending'),
    },
    {
      value: 'Waiting',
      label: t('compliance.report_status.Waiting'),
    },
    {
      value: 'Resolved',
      label: t('compliance.report_status.Resolved'),
    },
    {
      value: 'Complete',
      label: t('compliance.report_status.Complete'),
    },
  ];

  const selectedTypes = watch('type');

  const typesValues = report_types.filter(_ => selectedTypes?.includes && selectedTypes.includes(_.value));

  const selectedStatuses = watch('status');

  const statuesValues = statuses.filter(_ => selectedStatuses?.includes && selectedStatuses.includes(_.value));

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="my-4">
        <Col sm={4} className="col-12">
          <Form.Control
            type="text"
            {...register('search')}
            placeholder={t('search_for_anything')}
            />
        </Col>
          <Col md={2}>
              <div className="form-check me-2 my-3">
                  <label className="form-check-label">
                      <input
                          {...register('outstanding')}
                          className="form-check-input horizontal-check-labels d-flex mb-2"
                          type="checkbox"
                      />
                      {t('compliance.outstanding')}
                  </label>
              </div>
          </Col>
        <Col sm={2} className="col-12 mt-md-0 mt-3">
          <div className="d-grid gap-2">
          {
            !searching ?
              <Button type="submit" variant="success" className="w-100">
                {advanceFilters ? t('filters_labels.apply') : t('search')}
              </Button>
              :
              <Button variant="success" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="mx-2">{t('please_wait')}</span>
              </Button>
          }
          </div>
        </Col>
        <Col sm={4} className="col-12">
          <Button variant="link" className="my-md-0 my-3" onClick={(e) => {
            e.preventDefault();
            setAdvanceFilters(!advanceFilters)
          }}>{!advanceFilters ? t('filters_labels.show_advance_filters') : t('filters_labels.hide_advance_filters')}</Button>
        </Col>
      </Row>
      {advanceFilters && <>
        <Row className="my-md-4 my-2">

          <Col md={3}>
            <Form.Label className="mb-2">
              {t('compliance.report_type')}
            </Form.Label>

            <Select
              className="react-select"
              isMulti
              isClearable={true}
              placeholder={t('all')}
              value={typesValues}
              onChange={e =>{
                e && setValue('type', e?.map(option => option.value))
              }}
              options={report_types}/>
          </Col>

          <Col sm={6}>
            <Form.Label className="mb-2">
              {t('compliance.datetime')}
            </Form.Label>

            <div className="d-flex align-items-center">
              <Form.Control
                placeholder={t('from')}
                type="date"
                {...register('occurance_from')}
                />

              <span className="mx-2">{t('to')}</span>

              <Form.Control
                placeholder={t('to')}
                type="date"
                {...register('occurance_to')}
                />
            </div>
          </Col>

          <Col md={3}>
            <Form.Label className="mb-2">
              {t('status')}
            </Form.Label>

            <Select
              className="react-select"
              isMulti
              isClearable={true}
              placeholder={t('all')}
              value={statuesValues}
              onChange={e =>{
                e && setValue('status', e?.map(option => option.value))
              }}
              options={statuses}/>
          </Col>

        </Row>
      </>}
    </Form>
  )
}

export default Filters;
