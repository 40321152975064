import Promotions       from "../../views/pages/promotions/Promotions";
import VehicleEdit      from "../../views/pages/vehicles/VehicleEdit";
import PromotionEdit    from "../../views/pages/promotions/PromotionEdit";
import PromotionCreate  from "../../views/pages/promotions/PromotionCreate";
import OrdersIcon       from "../../components/icons/OrdersIcon";
import PromotionsIcon   from "../../components/icons/PromotionsIcon";
import ImportPromotions from "../../views/pages/promotions/ImportPromotions";

const promotionRoutes = [
    {
        path         : "/promotions",
        component    : Promotions,
        exact        : true,
        gate         : 'view_promotions',
        title        : 'Promotions',
        translate_key: 'promotions',
        // icon         : 'bi-megaphone',
        icon         : <PromotionsIcon/>,
        main         : true,
    },
    {
        path     : "/promotions/create",
        component: PromotionCreate,
        exact    : true,
        gate     : 'view_promotions',
        main     : false,
    },
    {
        path     : "/promotions/import",
        component: ImportPromotions,
        exact    : true,
        gate     : 'view_promotions',
        main     : false,
    },
    {
        path     : "/promotions/create/:id",
        component: PromotionCreate,
        exact    : true,
        gate     : 'view_promotions',
        main     : false,
    },
    {
        path     : "/promotions/create/:id/:service",
        component: PromotionCreate,
        exact    : true,
        gate     : 'view_promotions',
        main     : false,
    },
    {
        path     : "/promotions/edit/:id",
        component: PromotionEdit,
        exact    : true,
        gate     : 'view_promotions',
        main     : false,
    },
];

export default promotionRoutes;
