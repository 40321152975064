import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {Card} from "react-bootstrap";
import {useAuth} from "@/services/Auth";
import {useEffect} from "react";

function FontEndError() {
    const {t, i18n} = useTranslation();
    let auth = useAuth();

    useEffect(() => {
        auth?.errorPage || (auth?.updateErrorPage && auth.updateErrorPage(true));

        return () => {
            auth?.updateErrorPage && auth.updateErrorPage(false);
        }
    }, []);
    return (
            <div className="d-flex justify-content-center align-items-center" style={{height: "100%"}}>
                <Card border="light">
                    <Card.Body className="text-center">
                        <i className="bi bi-emoji-frown" style={{fontSize: "10rem"}}/>
                        <Card.Title className="text-uppercase">{t('errors.page_error')}</Card.Title>
                        <Card.Text>
                            {t('errors.sorry_an_error_has_occurred_to_this_page')}
                        </Card.Text>
                        <div className="d-grid gap-2">
                            <a className="btn btn-primary" href="/">{t('go_to_dashboard')}</a>
                        </div>
                    </Card.Body>
                </Card>
            </div>
    )
}
export  default FontEndError;
