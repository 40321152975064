import React, { useRef, useEffect, useState }   from "react";
import { useTranslation }               from "react-i18next";
import { CameraIcon } from "@/components/icons";
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { useAuth } from "@/services";

const processImage = (file, imageCompression) => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const image = new Image();

    if (!url) {
      reject('unable to read file');
    }

    image.src = url;

    image.onload = function () {
      const dx = (imageCompression?.maxWidth || image.width) / image.width;
      const dy = (imageCompression?.maxHeight || image.height) / image.height;
      const d = Math.min(dx, dy);

      const canvas = document.createElement('canvas');
      const width = image.width * d;
      const height = image.height * d;

      canvas.width = width;
      canvas.height = height;

      canvas.getContext('2d').drawImage(image, 0, 0, width, height);

      canvas.toBlob(resolve, 'image/jpeg', 0.7);
    };

    image.onerror = function () {
      reject('unable to load image');
    };
  });
}


const Wysiwyg = React.forwardRef(({
  onChange,
  onBlur,
  defaultValue,
  value: propValue,
  name,
  onFileDrop,
  uploadText,
  modules,
  toolbar,
  className,
  isInvalid,
  preUpload,
  imageCompression,
  editorRef: interfaceRef,
  ...props
}, forwardedRef) => {
  const auth = useAuth();
  const {t} = useTranslation();

  const valueStore = useRef([]);
  const base_uri = typeof preUpload == 'string' ? preUpload : '';

  const ref = useRef(null);

  const editorRef = useRef(null);
  const dummyInterface = useRef({
        type: 'textarea',
        name: null,

        get value() {
          var content = editorRef?.current?.root?.innerHTML;

          if(content == '<p><br></p>')
          {
            content = '';
          }

          valueStore.current.push(content);

          return content;
        },

        set value(value) {
          const currentValue = valueStore.current.shift();

          // Do not trigger the setContents if the onChange above was the cause of the change to 'value'
          if(currentValue != value)
          {
            if(editorRef?.current?.root)
            {
              editorRef.current.root.innerHTML = value;
            }
            else
            if(ref.current)
            {
              ref.current.innerHTML = value;
            }
          }
        },

        insert: (html) => {
          const selection = editorRef.current.getSelection();

          editorRef.current && editorRef.current.pasteHTML(
            selection?.index || 0,
            html
          );
        },
      })
  const [generatedId, setGeneratedId] = useState('wysiwyg-' + parseInt(Math.random() * 100000));

  const id = props.id || generatedId;

  const [config, setConfig] = useState(null);


  useEffect(_ => {
    if(!ref.current || !config)
    {
      return;
    }

    valueStore.current = [];

    editorRef.current = new Quill(ref.current, config);

    editorRef.current.on('blur', originalEvent => {
      if(onBlur)
      {
        const event = { isTrusted: false, type: 'blur', originalEvent };

        event.target = dummyInterface.current;

        onBlur(event);
      }
    });

    editorRef.current.on('drop', event => {
      var files = Array.from(event.dataTransfer.files);

      if(!event.isDefaultPrevented() && files.filter(_ => !/image\//.test(_.type)).length)
      {
        event.preventDefault();
        event.stopPropagation();

        onFileDrop && onFileDrop(files);
      }
      else
      if(config.blockEmbeds)
      {
        event.preventDefault();
        event.stopPropagation();
      }
    });

    editorRef.current.on('text-change', originalEvent => {
      if(onChange)
      {
        const event = { isTrusted: false, type: 'change', originalEvent };

        event.target = dummyInterface.current;

        onChange(event);
      }
    });


    return _ => {
      if(editorRef?.current?.root && ref.current)
      {
        ref.current.innerHTML = editorRef.current.root.innerHTML;
      }


      var toolbar = editorRef.current?.getModule('toolbar');

      if(toolbar)
      {
        toolbar?.container?.remove && toolbar.container.remove();
      }

      if(editorRef?.current?.root && ref.current)
      {
        ref.current.innerHTML = editorRef.current.root.innerHTML;
      }

      editorRef.current = null;
    };
  }, [config]);

  useEffect(_ => {
    const _config = {
      theme: 'snow',
      modules: {
        toolbar: [[{'list' : 'bullet'}, {'list' : 'ordered'}, {'indent' : '-1'}, {'indent' : '+1'},], ['bold', 'italic', { 'color': [] }, { 'background': [] }], [{ header: [false, 6, 5, 4, 3, 2, 1]}, 'clean']],
      },

      block_unsupported_drop: false,
      blockEmbeds: true,
    };

    if(typeof toolbar != 'undefined')
    {
      _config.modules.toolbar = toolbar;
    }

    Object.assign(_config, props);

    if(JSON.stringify(_config) != JSON.stringify(config))
    {
      setConfig(_config);
    }
  }, [props, toolbar]);

  useEffect(_ => {
    dummyInterface.current.name = name;
  }, [name])

  useEffect(_ => {
    if(typeof propValue != 'undefined')
    {
      dummyInterface.current.value = propValue;
    }
  }, [propValue]);

  useEffect(_ => {
    if(typeof defaultValue != 'undefined')
    {
      dummyInterface.current.value = defaultValue;
    }
  }, []);

  useEffect(_ => {
    if(typeof interfaceRef == 'function')
    {
      interfaceRef(dummyInterface.current);
    }
    else
    if(interfaceRef)
    {
      interfaceRef.current = dummyInterface.current;
    }
  }, []);

  const onFileUpload = (event) => {
    const files = event.target.files;


    for(var i = 0; i < files.length; i ++) {
      var file = files[i];


      const attachment = {};

      const addAttachment = () => {
      };

      attachment.name = file.name;
      attachment.mime_type = file.type;

      const process = new Promise(async (resolve) => {
        file = await processImage(file, imageCompression).then(blob => new File([blob], file.name, { type: 'image/jpeg' }));

        const reader = new FileReader();

        reader.onload = () => {
          const dataUrl = reader.result;

          attachment.file_base64 = dataUrl.split('base64,')[1];

          if (preUpload) {
            auth.postRequest(base_uri + '/attachments', attachment)
              .then(response => {
                Object.assign(attachment, response.data.data);

                delete attachment.file_base64;

                resolve();
              });
          }
          else {
            attachment.url = dataUrl;
            resolve();
          }

        };

        reader.readAsDataURL(file);
      });

      process.then(() => {
        const img = document.createElement('img');

        img.src = attachment.url;
        img.alt = attachment.name;

        if(attachment.hash)
        {
          img.setAttribute('data-hash', attachment.hash);
        }

        img.width = 320;

        dummyInterface.current.insert(
          img.outerHTML
        );

      });
    }

    event.target.value = null;
  };

  const _props = {
    id,
    ref: (element) => {
      ref.current = element;

      if(typeof forwardedRef == 'function')
      {
        forwardedRef(dummyInterface.current);
      }
      else
      if(forwardedRef)
      {
        forwardedRef.current = dummyInterface.current;
      }
    },
  };



  return (
    <div className={`wysiwyg ${className || ''} ${config && !config.blockEmbeds && 'wysiwyg-upload'  || ''} ${isInvalid && 'is-invalid'  || ''}`}>
      <div {..._props} />

      {config && !config.blockEmbeds && (
        <label className="btn btn-success btn-sm">
          <input type="file" accept="image/*" multiple className="d-none" onChange={onFileUpload} />
          {uploadText || (
            <>
              <CameraIcon  classDefined="me-2" />
              {t('take_a_photo')}
            </>
          )}
        </label>

      ) || ''}
    </div>
  )
});


function validateWysiwyg(value) {
  // This is want happens when stored, the raw attachment data is stored in a file and replaced with a hash 107 characters long, that output can't be more than 65000 characters long
  const doc = new DOMParser().parseFromString("<div>" + value + "</div>", "text/html");

  const imgs = doc.querySelectorAll('img');

  for(var i = 0; i < imgs.length; i++)
  {
    if(/^data:/.test(imgs[i].src))
    {
      imgs[i].src = "0".repeat(107);
    }
  }

  const parsed = doc.documentElement.innerHTML;

  return parsed.length < 65000;
};


function validateImg(value) {
  // This is want happens when stored, the raw attachment data is stored in a file and replaced with a hash 107 characters long, that output can't be more than 65000 characters long
  const doc = new DOMParser().parseFromString("<div>" + value + "</div>", "text/html");

  return !value || doc.querySelectorAll('img').length > 0;
};


export default Wysiwyg;


// Allow img to have all attributes passed originally
const ImageBlot = Quill.import("formats/image");
class CustomImageBlot extends ImageBlot {
  static blotName = "customImage";
  static tagName = "img";

  /**
   * Converts the HTML tag to image blot
   * @param value
   */
  static create(value) {
    let node = super.create();
    Object.getOwnPropertyNames(value).forEach((attribute_name) => {
      node.setAttribute(attribute_name, value[attribute_name]);
    });

    return node;
  }

  /**
   * Converts the image blot to HTML tag
   * @param node
   */
  static value(node) {
    var blot = {};
    node.getAttributeNames().forEach((attribute_name) => {
      blot[attribute_name] = node.getAttribute(attribute_name);
    });

    return blot;
  }
}

Quill.register(CustomImageBlot);

export { validateWysiwyg, validateImg };
