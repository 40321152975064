import Wysiwyg from "@/components/Inputs/Wysiwyg";
import DisplayWysiwyg from "@/components/Elements/DisplayWysiwyg";
import Buttons from "../components/Buttons";
import { Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function MediaWithContent({ site, edit, onChange, ...props })
{
  /**
   * IMPORTANT!!!!
   * Make sure to update the counter part in the laravel blade template
   */

  const { t } = useTranslation();

  return (
    <section className={`block block-media-with-content py-3 py-xl-4 media-${props.media_left ? 'left' : 'right'}`}>
      <div className="container py-3 py-xl-5">
        <Row className="align-items-lg-center">
          <Col lg={6} xxl={5} className="media-container">
            {edit && (
              <a href="javascript:void(0);" onClick={_ => edit.selectImage && edit.selectImage(false).then((images) => {
                  onChange({ ...props, image: images[0] })
                })}>
                <img width="100%" src={props.image?.url || "data:image/svg+xml,%3Csvg width='134' height='94' viewBox='0 0 134 94' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='134' height='94' rx='8' fill='%2300458C'/%3E%3C/svg%3E%0A"} />
              </a>
            ) || (
              <img width="100%" src={props.image?.url || "data:image/svg+xml,%3Csvg width='134' height='94' viewBox='0 0 134 94' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='134' height='94' rx='8' fill='%2300458C'/%3E%3C/svg%3E%0A"} />
            )}
          </Col>

          <Col lg >
            {edit && <Wysiwyg value={props.content} onChange={event => onChange({ ...props, content: event.target.value })} /> || <DisplayWysiwyg html={props.content || ''} />}

            <Buttons buttons={props.buttons || []} edit={edit} updateButtons={buttons => onChange({ ...props, buttons })} />
          </Col>
        </Row>

        {edit && (
          <div className="py-3 text-center">
            <Button className="block-option" onClick={_ => onChange({ ...props, media_left: !props.media_left })}>
              {t('marketing.switch_sides')}
            </Button>
          </div>
        ) || ''}
      </div>
    </section>
  );
}



export default MediaWithContent;
