import {ErrorMessage, Field, Form, Formik} from "formik";
import Select from "react-select";
import {Button, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import PhoneInput from "react-phone-input-2";

function SupplierForm({
                          initialValues,
                          handleSubmit,
                          supplier,
                          loading,
                          accountStatusOptions,
                          supportedCurrency,
                          countries,
                          buttonText,
                          serverErrors,
                          paymentTerms,
                      }) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const [country, setCountry] = useState('');
    const [billingSameAsRegisteredAddress, setBillingSameAsRegisteredAddress] = useState(false)
    const [countryBilling, setCountryBilling] = useState('');


    const validation = Yup.object().shape({
        company_name               : Yup.string()
                                        .required(t('form_validation.name_is_required')),
        contact_name               : Yup.string()
                                        .required(t('form_validation.primary_contact_name_is_required')),
        email                      : Yup.string().email()
                                        .required(t('form_validation.email_address_is_required')),
        phone_number               : Yup.string()
                                        .required(t('form_validation.phone_number_is_required')),
        account_status             : Yup.string()
                                        .required(t('form_validation.account_status_is_required')),
        account_number             : Yup.string()
                                        .required(t('form_validation.account_number_is_required')),
        currency                   : Yup.string()
                                        .required(t('form_validation.currency_is_required')),
        vat_registration_number    : Yup.string()
                                        .required(t('form_validation.vat_registration_number_required')),
        supplier_payment_terms     : Yup.string()
                                        .required(t('form_validation.client_payment_terms_required')),
        supplier_payment_terms_days: Yup.string()
                                        .required(t('form_validation.client_payment_terms_days_required')),
        address                    : Yup.string()
                                        .required(t('form_validation.address_is_required')),
        county                     : Yup.string()
                                        .required(t('form_validation.county_is_required')),
        city                       : Yup.string()
                                        .required(t('form_validation.city_town_is_required')),
        country_id                 : Yup.number()
                                        .required(t('form_validation.country_is_required')),
        post_code                  : Yup.string()
                                        .required(t('form_validation.post_code_is_required')),
    });


    useEffect(() => {

        if (supplier) {
            setCountry(supplier.country)
            setBillingSameAsRegisteredAddress(supplier.billing_address_same_as_registered_address);

            setCountryBilling(supplier.country_billing)

            setCountry(supplier.country)

            setCountryBilling(supplier.billing_country_select)
        } else {
            setBillingSameAsRegisteredAddress(true)
        }
    }, [])

    const onSave = (values) => {
        values.phone_number = values.phone_number.startsWith('+') ? values.phone_number : '+'.concat(values.phone_number)
        handleSubmit(values)
    }
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSave}
        >
            {({values, errors, touched, handleChange, handleBlur}) => (
                <Form>
                    <div className="form-group mt-4">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="name">{t('company_name')}</label>
                                <Field
                                    type="text"
                                    name="company_name"
                                    placeholder={t('company_name')}
                                    className={`form-control ${errors.company_name && touched.company_name ? 'is-invalid' : ''}`}
                                />
                                <small>
                                    <ErrorMessage component="div" name="company_name" className="text-danger  py-1"/>
                                </small>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="name">{t('contact_name')}</label>
                                <Field
                                    type="text"
                                    name="contact_name"
                                    placeholder={t('contact_name')}
                                    className={`form-control ${errors.contact_name && touched.contact_name ? 'is-invalid' : ''}`}
                                />
                                <small>
                                    <ErrorMessage component="div" name="contact_name" className="text-danger  py-1"/>
                                </small>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="name">{t('email_address')}</label>
                                <Field
                                    type="email"
                                    name="email"
                                    placeholder={t('email_address')}
                                    className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                />
                                <small>
                                    <ErrorMessage component="div" name="email" className="text-danger  py-1"/>
                                </small>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="name">{t('phone_number')}</label>
                                <Field
                                    name={'phone_number'}
                                    render={({field, form, ...props}) => (<PhoneInput
                                            country={'gb'}
                                            inputProps={{
                                                name: field.name
                                            }}
                                            inputStyle={{
                                                padding: '0.55rem 14px 0.55rem 60px',
                                                width: 'inherit'
                                            }}
                                            {...field}
                                            placeholder={t('enter_phone_number')}
                                            onChange={(phoneNumber, country, e) => {
                                                handleChange(e)
                                            }}
                                            onBlur={handleBlur}
                                        />
                                    )
                                    }
                                />
                                <small>
                                    <ErrorMessage component="div" name="phone_number" className="text-danger  py-1"/>
                                </small>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="name">{t('account_status')}</label>
                                <Field
                                    as="select"
                                    type="number"
                                    name="account_status"
                                    placeholder={t('account_status')}
                                    className={`form-control ${errors.account_status && touched.account_status ? 'is-invalid' : ''}`}
                                >
                                    <option value="">--{t('choose')}--</option>
                                    {accountStatusOptions.map((accountStatusOption) => <option key={accountStatusOption.id} value={accountStatusOption.id}>{t(accountStatusOption.name)}</option>)}
                                </Field>
                                <small>
                                    <ErrorMessage component="div" name="account_status" className="text-danger  py-1"/>
                                </small>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="name">{t('account_number')}</label>
                                <Field
                                    type="text"
                                    name="account_number"
                                    placeholder={t('account_number')}
                                    className={`form-control ${errors.account_number && touched.account_number ? 'is-invalid' : ''}`}
                                />
                                <small>
                                    <ErrorMessage component="div" name="account_number" className="text-danger  py-1"/>
                                </small>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="name">{t('billing_currency')}</label>
                                <Field
                                    as="select"
                                    type="text"
                                    name="currency"
                                    placeholder={t('billing_currency')}
                                    className={`form-control ${errors.currency && touched.currency ? 'is-invalid' : ''}`}
                                >
                                    <option value="">--{t('choose')}--</option>
                                    {supportedCurrency.map((currency) => <option key={currency}>{t(currency)}</option>)}
                                </Field>
                                <small>
                                    <ErrorMessage component="div" name="currency" className="text-danger  py-1"/>
                                </small>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="name">{t('credit_limit')}</label>
                                <Field
                                    type="number"
                                    name="credit_limit"
                                    placeholder={t('credit_limit')}
                                    className={`form-control ${errors.credit_limit && touched.credit_limit ? 'is-invalid' : ''}`}
                                />
                                <small>
                                    <ErrorMessage component="div" name="credit_limit" className="text-danger  py-1"/>
                                </small>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="vat_registration_number">{t('vat_registration_number')}</label>
                                <Field
                                    type="text"
                                    name="vat_registration_number"
                                    placeholder={t('vat_registration_number')}
                                    className={`form-control ${errors.vat_registration_number && touched.vat_registration_number ? 'is-invalid' : ''}`}
                                />
                                <small>
                                    <ErrorMessage component="div" name="vat_registration_number" className="text-danger  py-1"/>
                                </small>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="name">{t('supplier_payment_terms')}</label>
                                <Field
                                    as="select"
                                    type="text"
                                    name="supplier_payment_terms"
                                    placeholder={t('supplier_payment_terms')}
                                    className={`form-control ${errors.supplier_payment_terms && touched.supplier_payment_terms ? 'is-invalid' : ''}`}
                                >
                                    <option value="">--{t('choose')}--</option>
                                    {paymentTerms.map((paymentTerm) => <option key={paymentTerm}>{t(paymentTerm)}</option>)}
                                </Field>
                                <small>
                                    <ErrorMessage component="div" name="supplier_payment_terms" className="text-danger  py-1"/>
                                </small>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="name">{t('supplier_payment_terms_days')}</label>

                                <Field
                                    as="select"
                                    type="number"
                                    name="supplier_payment_terms_days"
                                    placeholder={t('supplier_payment_terms_days')}
                                    className={`form-control ${errors.supplier_payment_terms_days && touched.supplier_payment_terms_days ? 'is-invalid' : ''}`}
                                >
                                    <option value="">--{t('choose')}--</option>
                                    <option value={7}>7</option>
                                    <option value={14}>14</option>
                                    <option value={30}>30</option>
                                    <option value={45}>45</option>
                                    <option value={60}>60</option>
                                </Field>
                                <small>
                                    <ErrorMessage component="div" name="supplier_payment_terms_days" className="text-danger  py-1"/>
                                </small>
                            </div>

                        </div>
                        <div className="row mt-4">
                            <h5>{t('registered_address')}</h5>
                        </div>
                        <div className="row">

                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="country_id">{t('country')}</label>

                                <Select
                                    className="react-select"
                                    isClearable={true}
                                    value={country}
                                    onChange={(e) => {
                                        values.country_id = e ? e.value : null;
                                        console.log(e);
                                        setCountry(e);
                                    }}
                                    placeholder={t('country')}
                                    options={countries.map(country => {
                                        return {value: country.id, label: country.name}
                                    })}/>

                                <small>
                                    <ErrorMessage component="div" name="country_id" className="text-danger  py-1"/>
                                </small>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="address">{t('first_line_address')}</label>
                                <Field
                                    type="text"
                                    name="address"
                                    placeholder={t('first_line_address')}
                                    className={`form-control ${errors.address && touched.address ? 'is-invalid' : ''}`}
                                />
                                <small>
                                    <ErrorMessage component="div" name="address" className="text-danger  py-1"/>
                                </small>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="address">{t('address_line_2')}</label>
                                <Field
                                    type="text"
                                    name="address_line_2"
                                    placeholder={t('address_line_2')}
                                    className={`form-control ${errors.address_line_2 && touched.address_line_2 ? 'is-invalid' : ''}`}
                                />
                                <small>
                                    <ErrorMessage component="div" name="address_line_2" className="text-danger  py-1"/>
                                </small>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="address">{t('county')}</label>
                                <Field
                                    type="text"
                                    name="county"
                                    placeholder={t('county')}
                                    className={`form-control ${errors.county && touched.county ? 'is-invalid' : ''}`}
                                />
                                <small>
                                    <ErrorMessage component="div" name="county" className="text-danger  py-1"/>
                                </small>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="city">{t('city')}</label>

                                <Field
                                    type="text"
                                    name="city"
                                    placeholder={t('city')}
                                    className={`form-control ${errors.city && touched.city ? 'is-invalid' : ''}`}
                                />

                                <small>
                                    <ErrorMessage component="div" name="city" className="text-danger  py-1"/>
                                </small>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                <label htmlFor="post_code">{t('post_code')}</label>
                                <Field
                                    type="text"
                                    name="post_code"
                                    placeholder={t('post_code')}
                                    className={`form-control ${errors.post_code && touched.post_code ? 'is-invalid' : ''}`}
                                />
                                <small>
                                    <ErrorMessage component="div" name="post_code" className="text-danger  py-1"/>
                                </small>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="form-check d-flex">
                                    <Field
                                        type="checkbox"
                                        name="billing_address_same_as_registered_address"
                                        placeholder={t('billing_address_same_as_registered_address')}
                                        className={`form-check-input ${errors.billing_address_same_as_registered_address && touched.billing_address_same_as_registered_address ? 'is-invalid' : ''}`}

                                        render={({field}) => (
                                            <input
                                                {...field}
                                                className="form-check-input"
                                                defaultChecked={supplier ? supplier.billing_address_same_as_registered_address : true}
                                                onChange={(e) => {
                                                    console.log(e);
                                                    values.billing_address_same_as_registered_address = e.target.checked;
                                                    setBillingSameAsRegisteredAddress(e.target.checked)
                                                }}
                                                type="checkbox"/>
                                        )}
                                    />
                                    <label className="form-check-label mx-2" htmlFor="parking_available">
                                        {t('billing_address_same_as_registered_address')}
                                    </label>
                                </div>
                            </div>
                        </div>
                        {
                            !billingSameAsRegisteredAddress &&
                            <div>
                                <div className="row mt-4">
                                    <h5>{t('billing_address')}</h5>
                                </div>
                                <div className="row">

                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                        <label htmlFor="address">{t('billing_county')}</label>
                                        <Field
                                            type="text"
                                            name="billing_county"
                                            placeholder={t('billing_county')}
                                            className={`form-control ${errors.billing_county && touched.billing_county ? 'is-invalid' : ''}`}
                                        />
                                        <small>
                                            <ErrorMessage component="div" name="billing_county" className="text-danger  py-1"/>
                                        </small>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                        <label htmlFor="address">{t('first_line_billing_address')}</label>
                                        <Field
                                            type="text"
                                            name="address_billing"
                                            placeholder={t('first_line_billing_address')}
                                            className={`form-control ${errors.address_billing && touched.address_billing ? 'is-invalid' : ''}`}
                                        />
                                        <small>
                                            <ErrorMessage component="div" name="address_billing" className="text-danger  py-1"/>
                                        </small>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                        <label htmlFor="address">{t('billing_address_line_2')}</label>
                                        <Field
                                            type="text"
                                            name="billing_address_line_2"
                                            placeholder={t('billing_address_line_2')}
                                            className={`form-control ${errors.billing_address_line_2 && touched.billing_address_line_2 ? 'is-invalid' : ''}`}
                                        />
                                        <small>
                                            <ErrorMessage component="div" name="billing_address_line_2" className="text-danger  py-1"/>
                                        </small>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                        <label htmlFor="country_id">{t('country')}</label>

                                        <Select
                                            className="react-select"
                                            isClearable={true}
                                            value={countryBilling}
                                            onChange={(e) => {
                                                values.billing_country_id = e ? e.value : null;
                                                setCountryBilling(e);
                                            }}
                                            placeholder={t('country')}
                                            options={countries.map(country => {
                                                return {value: country.id, label: country.name}
                                            })}/>

                                        <small>
                                            <ErrorMessage component="div" name="billing_country_id" className="text-danger  py-1"/>
                                        </small>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                        <label htmlFor="billing_city">{t('city')}</label>

                                        <Field
                                            type="text"
                                            name="billing_city"
                                            placeholder={t('city')}
                                            className={`form-control ${errors.billing_city && touched.billing_city ? 'is-invalid' : ''}`}
                                        />

                                        <small>
                                            <ErrorMessage component="div" name="billing_city" className="text-danger  py-1"/>
                                        </small>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                        <label htmlFor="post_code">{t('post_code')}</label>
                                        <Field
                                            type="text"
                                            name="post_code_billing"
                                            placeholder={t('post_code')}
                                            className={`form-control ${errors.post_code_billing && touched.post_code_billing ? 'is-invalid' : ''}`}
                                        />
                                        <small>
                                            <ErrorMessage component="div" name="post_code_billing" className="text-danger  py-1"/>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            (serverErrors.length !== 0) &&
                            <div className="form-group mt-4">
                                {
                                    serverErrors.map((error, index) => <p className="text-danger font-weight-bold" key={index}>{error}</p>)
                                }
                            </div>
                        }

                        <div className="d-grid gap-2 mt-4">
                            {!loading ?
                                <Button type="submit" variant="primary">{t(buttonText)}</Button>
                                :
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className="mx-2">{t('please_wait')}</span>
                                </Button>
                            }
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default SupplierForm;
