import {Button, Table, Row, Col, Form}                   from "react-bootstrap";
import LoadingPage from "@/components/LoadingPage";
import { Countries, ServiceCategories, useAuth } from "@/services";
import { useFieldArray } from 'react-hook-form';
import Select from "react-select";
import AsyncSelect from "react-select/async";
import React, { useRef, useState, useEffect } from "react";
import {useTranslation} from "react-i18next";
import * as yup from 'yup';


function useSpendingCapsRules()
{
    const {t} = useTranslation();

    return  yup.array().of(
        yup.object({
            site_id: yup.number().integer(),
            country_id: yup.number().integer(),
            category_id: yup.number().integer(),

            cost_cap: yup.number()
                    .transform(_ => isNaN(_) ? null : _)
                    .nullable()
                    .typeError(t('form_validation.is_required', { attribute: t('spending_cap') }))
                    .min(0.01, t('form_validation.min', { attribute: t('spending_cap'), value: 0.01 })),

            cap_period: yup.string()
                .typeError(t('form_validation.is_required', { attribute: t('period_value') }))
                .when(['cost_cap'], (cost_cap,schema) => (
                    cost_cap && schema.oneOf(['Daily','Weekly','Monthly','Yearly'], t('form_validation.is_required', { attribute: t('period_value') })) ||
                    schema.nullable()
                )),
            type_of_limit: yup.string()
                .oneOf(['Cost','Quantity'], t('form_validation.is_required', { attribute: t('type_of_limit') }))
                .typeError(t('form_validation.is_required', { attribute: t('type_of_limit') })),
        })
    );
}

function SpendingCapsFields({
        errors,
        control,
        register,
        disabled,
    }) {
    const auth = useAuth();

    const [countries, setCountries] = useState(null);
    const [categories, setCategories] = useState(null);
    const [parkingCategory, setParkingCategory] = useState([]);

    const getRequest = useRef(null);

    const {t} = useTranslation();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'spending_caps',
    });

    const maybeAppend = (attribute, value, attributes) => {
        attributes = typeof attributes == typeof {} && attributes || {};

        if(!fields.find(_ => _[attribute] == value))
        {
            attributes[attribute] = value;

            attributes = Object.assign({
                type_of_limit: "Quantity",
                cap_period   : "Daily",
                cost_cap     : null,
            }, attributes);

            append(attributes);
        }
    };

    const formatRecord = (record) => {
        record.value = record.id;
        record.label = record.name;

        return record;
    }

    const getSites = (search) => {
        getRequest.current && getRequest?.current?.abort && getRequest.current.abort();

        return new Promise((resolve, reject) => {
            getRequest.current = auth.getRequest('sites', { search });

            getRequest.current.then(response => {
                    resolve(response.data.sites.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    const addon_spending_caps  = fields.filter(_ => typeof _.category_id !== 'undefined');
    const site_spending_caps   = fields.filter(_ => typeof _.site_id !== 'undefined');
    const region_spending_caps = fields.filter(_ => typeof _.country_id !== 'undefined');

    const SpendingCap = ({ item, index, name }) => {
        return (
            <tr>
                <td>
                    {name}
                </td>
                <td>
                    <div className="row" style={{minWidth: 400}}>
                        <div className="col-4">
                            <Form.Select
                                {...register(`spending_caps.${index}.type_of_limit`)}
                                defaultValue={item.type_of_limit}
                                disabled={disabled}
                                isInvalid={errors.spending_caps && errors.spending_caps[ index ] && errors.spending_caps[ index ].type_of_limit}
                                >
                                <option value="Quantity">{t('quantity')}</option>
                                <option value="Cost">{t('cost')}</option>
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                                {errors.spending_caps && errors.spending_caps[ index ] && errors.spending_caps[ index ].type_of_limit?.message}
                            </Form.Control.Feedback>
                        </div>

                        <div className="col-4">
                            <Form.Select
                                {...register(`spending_caps.${index}.cap_period`)}
                                defaultValue={item.cap_period}
                                disabled={disabled}
                                isInvalid={errors.spending_caps && errors.spending_caps[ index ] && errors.spending_caps[ index ].cap_period}
                                >
                                <option value="Daily">{t('daily')}</option>
                                <option value="Weekly">{t('weekly')}</option>
                                <option value="Monthly">{t('monthly')}</option>
                                <option value="Yearly">{t('yearly')}</option>
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                                {errors.spending_caps && errors.spending_caps[ index ] && errors.spending_caps[ index ].cap_period?.message}
                            </Form.Control.Feedback>
                        </div>

                        <div className="col-4">
                            <Form.Control
                                {...register(`spending_caps.${index}.cost_cap`)}
                                type="number"
                                min={0}
                                defaultValue={item.cost_cap}
                                placeholder={t('unlimited')}
                                disabled={disabled}
                                isInvalid={errors.spending_caps && errors.spending_caps[ index ] && errors.spending_caps[ index ].cost_cap}
                                />

                            <Form.Control.Feedback type="invalid">
                                {errors.spending_caps && errors.spending_caps[ index ] && errors.spending_caps[ index ].cost_cap?.message}
                            </Form.Control.Feedback>
                        </div>
                    </div>
                </td>
                <td>
                    <Button variant="light" className="btn-icon btn-rounded" size="sm" onClick={() => disabled || remove(index)}>
                        <i className="bi bi-x text-danger"></i>
                    </Button>
                </td>
            </tr>
        )
    };

    useEffect(() => {
        if(countries == null)
        {
            setCountries(false);
            Countries.get().then((records) => setCountries(records.map(formatRecord)));
        }

        if(categories == null)
        {
            setCategories(false);
            ServiceCategories.get().then((records) => {
                setCategories(records.filter(record => record.addon).map(formatRecord))
                setParkingCategory(parkingCategory.concat(records.filter(record => record.name == 'Parking')));

            });
        }
    }, [countries, categories]);

    if(!countries || !categories)
    {
        return <LoadingPage />;
    }

    parkingCategory.forEach(record => {
        maybeAppend('category_id', record.id, {
            type_of_limit: 'Cost',
            cap_period   : '',
        });
    })

    return (
        <div>
        <div className="form-group my-4">
            <h5>{t('parking_specific')}</h5>

            {addon_spending_caps.filter(_ => parkingCategory.map(item=> item.id).includes(_.category_id)).map((item) => {
                let index = fields.indexOf(item);

                register(`spending_caps.${index}.category_id`);

                return <div key={'parking-' + index}>
                    <Row>
                        <Col md={3} sm={6}>
                            <Form.Label className="my-2">
                                {t('period_value')}
                            </Form.Label>

                            <Form.Select
                                {...register(`spending_caps.${index}.cap_period`)}
                                defaultValue={item.cap_period}
                                disabled={disabled}
                                isInvalid={errors.spending_caps && errors.spending_caps[ index ] && errors.spending_caps[ index ].cap_period}
                            >
                                <option value="">--{t('choose')}--</option>
                                <option value="Daily">{t('daily')}</option>
                                <option value="Weekly">{t('weekly')}</option>
                                <option value="Monthly">{t('monthly')}</option>
                                <option value="Yearly">{t('yearly')}</option>
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                                {errors.spending_caps && errors.spending_caps[ index ] && errors.spending_caps[ index ].cap_period?.message}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>

                    <Row className="my-2">
                        <Col md={3} sm={6}>
                            <Form.Label className="my-2">
                                {t('spending_cap')}
                            </Form.Label>

                            <Form.Control
                                {...register(`spending_caps.${index}.cost_cap`)}
                                type="number"
                                min={0}
                                defaultValue={item.cost_cap}
                                placeholder={t('unlimited')}
                                disabled={disabled}
                                isInvalid={errors.spending_caps && errors.spending_caps[ index ] && errors.spending_caps[ index ].cost_cap}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.spending_caps && errors.spending_caps[ index ] && errors.spending_caps[ index ].cost_cap?.message}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                </div>
            })}
            <h5>{t('addon_specific')}</h5>
            <p>{t('addon_spending_cap_instructions')}</p>

            <Table striped responsive bordered hover className="mt-4">
                <thead>
                <tr>
                    <th style={{minWidth: 150}}>
                        <h5>{t('addon')}</h5>
                    </th>
                    <th>{t('covered_costs')}</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {addon_spending_caps.filter(_ => !parkingCategory.map(item=> item.id).includes(_.category_id)).map((item) => {
                    let index = fields.indexOf(item);
                    register(`spending_caps.${index}.category_id`);

                    return <SpendingCap item={item} key={item.id} index={index} name={item?.category?.name} />;
                })}

                </tbody>
            </Table>

            {!disabled && (
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="d-grid gap-2">
                        <Select
                            className="react-select"
                            value={null}
                            onChange={(category) => maybeAppend('category_id', category.id, { category })}
                            placeholder={t('add_addon')}
                            options={categories}
                            />
                    </div>
                </div>
            </div>
            ) || ''}
        </div>

        <div className="form-group my-4">
            <h5>{t('site_specific')}</h5>
            <p>{t('site_spending_cap_instructions')}</p>

            <Table striped responsive bordered hover className="mt-4">
                <thead>
                <tr>
                    <th style={{minWidth: 150}}>
                        <h5>{t('site')}</h5>
                    </th>
                    <th>{t('covered_costs')}</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
               {site_spending_caps.map((item) => {
                    register(`spending_caps.${fields.indexOf(item)}.site_id`);

                    return <SpendingCap item={item} key={item.id} index={fields.indexOf(item)} name={item?.site?.name} />;
                })}


                </tbody>
            </Table>

            {!disabled && (
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="d-grid gap-2">
                        <AsyncSelect
                            className="react-select"
                            value={null}
                            onChange={(site) => maybeAppend('site_id', site.id, { site })}
                            placeholder={t('add_site')}
                            loadOptions={getSites}
                            />
                    </div>
                </div>
            </div>
            ) || ''}
        </div>


        <div className="form-group my-4">
            <h5>{t('region_specific')}</h5>
            <p>{t('region_spending_cap_instructions')}</p>

            <Table striped responsive bordered hover className="mt-4">
                <thead>
                <tr>
                    <th style={{minWidth: 150}}>
                        <h5>{t('region')}</h5>
                    </th>
                    <th>{t('covered_costs')}</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {region_spending_caps.map((item) => {
                    register(`spending_caps.${fields.indexOf(item)}.country_id`);

                    return <SpendingCap item={item} key={item.id} index={fields.indexOf(item)} name={item?.country?.name} />;
                })}

                </tbody>
            </Table>

            {!disabled && (
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="d-grid gap-2">
                        <Select
                            className="react-select"
                            value={null}
                            onChange={(country) => maybeAppend('country_id', country.id, { country })}
                            placeholder={t('add_region')}
                            options={countries}
                            />
                    </div>
                </div>
            </div>
            ) || ''}
        </div>

        </div>
    );
}

export default SpendingCapsFields;
export {
    useSpendingCapsRules
};
