import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services";
import { Card, Form, Button, ProgressBar } from "react-bootstrap";
import LoadingPage from "@/components/LoadingPage";
import { Attachments } from "@/components/Inputs";
import { useLayout } from "@/layouts/Layout";

function JourneyChecks({ values, setValues, onSave, onSubmit, ...props }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const layout = useLayout();

  const route = useLocation();
  const history = useHistory();

  const [checks, setChecks] = useState(undefined);
  const [numberofChecks, setNumberOfChecks] = useState(0);
  const check_keys = checks && Object.keys(checks);
  const check_index = route?.state?.check_index || 0;
  const check_key = check_keys && check_keys[check_index] || null;
  const check = checks && checks[check_key] || null;

  const updateCheck = check_index => {
    history.push(route.pathname, {
      ...(route.state || {}),

      check_index,
    });
  };

  const next = (values) => {
    var target = check_index;

    if(target < 0 || target > Object.keys(checks).length - 1)
    {
      target = -1;
    }

    target ++;

    if(target > Object.keys(checks).length - 1)
    {
      onSubmit(values);
    }
    else
    {
      updateCheck(target);
    }
  };

  useEffect(() => {
    const request = auth.getRequest('/reports/journey-questions');

    request
      .then(response => {
        setChecks(response.data)
        setNumberOfChecks(Object.keys(response.data).length)
      });

    return () => request.abort();
  }, []);

  useEffect(() => {
    layout.scrollTop();
  }, [check_index]);

  if(!checks || !check)
  {
    return <LoadingPage />;
  }

  return (
    <>

      <ProgressBar variant="success" now={(check_index + 1) / Object.keys(checks).length * 100} />

      <h3 className="h6 text-primary my-3">
        {t('question_n', { count: check_index + 1 })}
      </h3>

      <Check key={check_key} {...{
        check_key,
        check,
        value: values && values.find(_ => _.key == check_key),
        check_index,
        numberofChecks,
        onSubmit: (check) => {
          const _values = [].concat(values || []);
          const index = _values.findIndex(_ => _.key == check.key);

          if (index >= 0) {
            _values.splice(index, 1);
          }

          _values.push(check);

          setValues && setValues(_values);

          next(_values);
        }
      }} />
    </>
  );
}

function Check({ check_key, check, value: initalValue, check_index, numberofChecks, onSubmit, index }) {
  const { t } = useTranslation();

  const [value, setValue] = useState(initalValue?.value || null);
  const [value_options, setValueOptions] = useState(initalValue || null);
  const [attachments, setAttachments] = useState(initalValue?.attachments || []);
  const [content, setContent] = useState(initalValue?.content || '');

  const can_continue =
    value !== null
    && typeof value !== 'undefined'
    && (
      attachments.length > 0
      || (value_options?.value == 0 || value_options?.fault == 0)
      || (value_options?.value == -1 || value_options?.fault == 0)
    )
    && (
      value_options?.explanation == 0
      || (value_options?.explanation == 1 && content && content.length > 0)
    );


  return (
    <>
      <h3 className="h6 text-primary my-3">
        {t(check_key)}
      </h3>

      {(_ => {
        switch (check.type) {
          case 'radio':
            return (
              <Form.Group>
                {Object.entries(check.options).map(([key, options]) => (
                  <label
                    key={key}
                    className="d-block mb-2">
                    <Form.Check.Input
                      type="radio"
                      className="d-none"
                      checked={value == key}
                      name={check_key}
                      onChange={_ => { setValue(_.target.value); setValueOptions(options); }}
                      value={key}
                    />

                    <span className="btn btn-outline-primary w-100">{t(key)}</span>
                  </label>

                ))}
              </Form.Group>
            );
            break;

          case 'true_false':
            return (
              <Form.Group className="row gx-3 mb-3">
                <label
                  key={'true'}
                  className="col">
                  <Form.Check.Input
                    type="radio"
                    className="d-none"
                    checked={value == 1}
                    value={1}
                    name={check_key}
                    onChange={_ => { setValue(_.target.value); setValueOptions(check.if_true); }}
                  />

                  <span className="btn btn-outline-primary w-100">{ check.true_label ? t(check.true_label) : t('yes') }</span>
                </label>

                <label
                  key={'false'}
                  className="col">
                  <Form.Check.Input
                    type="radio"
                    className="d-none"
                    checked={value == 0}
                    value={0}
                    name={check_key}
                    onChange={_ => { setValue(_.target.value); setValueOptions(check.if_false); }}
                  />

                  <span className="btn btn-outline-primary w-100">{ check.false_label ? t(check.false_label) : t('no')}</span>
                </label>
              </Form.Group>
            );
            break;

          // case has 3 options
          case 'true_false_na':
            return (
              <Form.Group className="row gx-3 mb-3">
                <label
                  key={'true'}
                  className="col-lg-4 col-md-4 col-sm-12 mb-1">
                  <Form.Check.Input
                    type="radio"
                    className="d-none"
                    checked={value == 1}
                    value={1}
                    name={check_key}
                    onChange={_ => { setValue(_.target.value); setValueOptions(check.if_true); }}
                  />

                  <span className="btn btn-outline-primary w-100">{ check.true_label ? t(check.true_label) : t('yes') }</span>
                </label>

                <label
                  key={'false'}
                  className="col-lg-4 col-md-4 col-sm-12 mb-1">
                  <Form.Check.Input
                    type="radio"
                    className="d-none"
                    checked={value == 0}
                    value={0}
                    name={check_key}
                    onChange={_ => { setValue(_.target.value); setValueOptions(check.if_false); }}
                  />

                  <span className="btn btn-outline-primary w-100">{ check.false_label ? t(check.false_label) : t('no')}</span>
                </label>

                <label
                  key={'na'}
                  className="col-lg-4 col-md-4 col-sm-12 mb-1">
                  <Form.Check.Input
                    type="radio"
                    className="d-none"
                    checked={value == -1}
                    value={-1}
                    name={check_key}
                    onChange={_ => { setValue(_.target.value); setValueOptions(check.if_na); }}
                  />

                  <span className="btn btn-outline-primary w-100">{ check.na_label ? t(check.na_label) : t('na')}</span>
                </label>
              </Form.Group>
            );
            break;
        }

      })()}

      {(value_options !== null && value_options?.explanation == 1) && (
        <Form.Group className="row mx-1 mb-3">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Explanation..."
            value={content}
            onChange={_ => { setContent(_.target.value); }}

          />
        </Form.Group>
      )}

      {value !== null && (
          <Attachments value={attachments} preUpload={true} placeholder={t('description')} onChange={e => setAttachments(e.target.value)} imageCompression={{ maxWidth: 320 }}  />
      )}

      <Form.Group className="my-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50"
          variant={!can_continue && 'link' || 'success'}
          disabled={!can_continue}
          onClick={_ => onSubmit({ key: check_key, ...value_options, value, attachments, content })}>
          {(check_index === numberofChecks - 1) ? t('complete') : t('continue')}
        </Button>
      </Form.Group >
    </>
  );
}


export default JourneyChecks;
