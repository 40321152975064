export default {
    "Apr": "Kwiecie\u0144",
    "Aug": "Sierpie\u0144",
    "Credit": "Kredyt",
    "Dec": "Grudzie\u0144",
    "Feb": "Luty",
    "Jan": "Stycze\u0144",
    "Jul": "Lipiec",
    "Jun": "Czerwiec",
    "Mar": "Marzec",
    "May": "Maj",
    "Nov": "Listopad",
    "Oct": "Pa\u017adziernik",
    "Sep": "Wrzesie\u0144",
    "a_new_order_has_been_made": "Nowe zam\u00f3wienie #{{orderId}} zosta\u0142o wykonane na {{siteName}} dla {{bookingTime}}",
    "account_number": "Numer konta",
    "account_payment": "P\u0142atno\u015b\u0107 na konto",
    "account_payment_method": "Metoda p\u0142atno\u015bci konta",
    "account_status": "Status Konta",
    "account_type": "typ konta",
    "activate": "Aktywuj",
    "active": "Aktywny",
    "add": "Dodaj",
    "add_addon": "Dodaj dodatek",
    "add_client_pricing": "Dodaj ceny klienta",
    "add_driver": "Nowy kierowca",
    "add_drivers": "Dodaj kierowc\u00f3w",
    "add_favourite": "Dodaj jako ulubiony",
    "add_parking_price": "Dodaj cen\u0119 parkowania",
    "add_parking_prices": "Dodaj ceny za parking",
    "add_payment_method": "Dodaj metod\u0119 p\u0142atno\u015bci",
    "add_person": "Dodaj osob\u0119",
    "add_promotion": "Dodaj promocj\u0119",
    "add_region": "Dodaj region",
    "add_service": "Dodaj us\u0142ug\u0119",
    "add_single_payout": "Dodaj pojedyncz\u0105 wyp\u0142at\u0119",
    "add_site": "Dodaj stron\u0119",
    "add_site_pricing": "Dodaj ceny witryny",
    "add_to_order": "Doda\u0107 do zam\u00f3wienia",
    "addon": "Dodatek",
    "addon_purchase_using_fleet_account": "Kierowca kupuje dodatek za pomoc\u0105 konta floty",
    "addon_specific": "Specyficzne dla serwisu i dodatku",
    "addon_spending_cap_instructions": "Aby ograniczy\u0107 okre\u015blon\u0105 us\u0142ug\u0119\/dodatek, wymie\u0144 je poni\u017cej",
    "addons": "Dodatki\"",
    "addons_services": "Dodatki i us\u0142ugi",
    "address": "Adres",
    "address_1": "Adres wiersz 1",
    "address_2": "Adres wiersz 2",
    "address_3": "Adres wiersz 3",
    "address_line_2": "Adres wiersz 2",
    "admin_dashboard": "Pulpit nawigacyjny administratora",
    "all": "Wszystko",
    "all_clients_are_imported": "Wszyscy klienci zostali pomy\u015blnie zaimportowani",
    "all_drivers_are_imported": "Wszyscy kierowcy zostali pomy\u015blnie zaimportowani",
    "all_fleets_are_imported": "Wszystkie floty zosta\u0142y pomy\u015blnie zaimportowane",
    "all_payments_are_imported": "Wszystkie p\u0142atno\u015bci zosta\u0142y pomy\u015blnie zaimportowane",
    "all_payouts_are_imported": "Wszystkie wyp\u0142aty zosta\u0142y pomy\u015blnie zaimportowane",
    "all_promotions_are_imported": "Wszystkie promocje zosta\u0142y pomy\u015blnie zaimportowane",
    "all_sites_are_imported": "Wszystkie witryny zosta\u0142y pomy\u015blnie zaimportowane",
    "all_suppliers_are_imported": "Wszyscy dostawcy zostali pomy\u015blnie zaimportowani",
    "all_users_are_imported": "Wszyscy u\u017cytkownicy zostali pomy\u015blnie zaimportowani",
    "all_vehicles_are_imported": "Wszystkie pojazdy zosta\u0142y pomy\u015blnie zaimportowane",
    "alternative_payment_method": "Alternatywna metoda p\u0142atno\u015bci",
    "amount": "Kwota",
    "app": "Aplikacja M-Park",
    "approve": "Zatwierd\u017a",
    "archived": "Zarchiwizowany\"",
    "back": "Powr\u00f3t",
    "back_to_map_view": "Powr\u00f3t do widoku mapy",
    "back_to_n": "Powr\u00f3t do {{n}}",
    "balance": "Saldo",
    "begin": "Rozpocznij",
    "best": "Najlepsze",
    "billing_address": "Adres rozliczeniowy",
    "billing_address_line_2": "Adres rozliczeniowy wiersz 2",
    "billing_address_same_as_registered_address": "Adres rozliczeniowy taki sam jak adres siedziby firmy?",
    "billing_county": "Hrabstwo",
    "billing_currency": "Waluta rozliczeniowa",
    "billing_information": "Informacje o rozliczeniu",
    "book_now": "Rezerwuj teraz",
    "booking_cant_be_extend_to_before_initial_expire_date": "Rezerwacja nie mo\u017ce zosta\u0107 przed\u0142u\u017cona przed up\u0142ywem pocz\u0105tkowej daty wyga\u015bni\u0119cia i musi zosta\u0107 przed\u0142u\u017cona o co najmniej 1 godzin\u0119",
    "booking_cant_end_end_before_it_stated": "Rezerwacja nie mo\u017ce si\u0119 zako\u0144czy\u0107 przed rozpocz\u0119ciem",
    "booking_cant_start_on_the_past": "Rezerwacja nie mo\u017ce rozpocz\u0105\u0107 si\u0119 w przesz\u0142o\u015bci i musi trwa\u0107 co najmniej 45 minut od aktualnej godziny",
    "booking_checked_in": "odprawiony\"",
    "booking_checked_out": "Wyrejestrowany\"",
    "booking_duration": "Na jak d\u0142ugo?",
    "booking_duration_must_be_at_lest_one_hour": "Czas rezerwacji musi wynosi\u0107 co najmniej 1 godzin\u0119",
    "booking_extended": "Rezerwacja rozszerzona",
    "booking_from": "Wprowad\u017a dat\u0119\/godzin\u0119 przybycia ...",
    "booking_id": "Identyfikator rezerwacji",
    "booking_summary": "podsumowanie",
    "booking_to": "Wprowad\u017a dat\u0119\/godzin\u0119 wyjazdu",
    "call_to_action": "Wezwanie do dzia\u0142ania",
    "call_to_action_link_text": "Wezwanie do dzia\u0142ania\/tekst linku",
    "cancel": "Anuluj",
    "cancel_extend": "Anuluj rozszerzenie",
    "cancel_question": "Anulowa\u0107?",
    "card": "Karta",
    "card_expiry": "Wyga\u015bni\u0119cie karty",
    "card_holder_name": "Imi\u0119 i nazwisko w\u0142a\u015bciciela karty",
    "card_number": "Numer karty",
    "category": "Kategoria",
    "ccv": "CCV",
    "change": "Zmiana",
    "check_email": "Prosz\u0119 sprawdzi\u0107 sw\u00f3j e -mail",
    "check_in": "Odpraw si\u0119",
    "check_off_what_customer_has_received": "Prosz\u0119 sprawd\u017a, co otrzyma\u0142 klient.",
    "check_out": "Wymelduj",
    "checked_in_at": "Odprawiony w",
    "checked_out_at": "Wymeldowany o",
    "checkout_now": "Sprawd\u017a teraz",
    "choose": "Wybiera\u0107",
    "choose_client": "Wybierz klienta",
    "choose_csv_file_to_upload": "Wybierz plik CSV, aby przes\u0142a\u0107",
    "choose_driver": "Wybierz kierowc\u0119",
    "choose_facility": "Wybierz obiekt",
    "choose_fleet": "Wybierz flot\u0119",
    "choose_type": "Wybierz typ",
    "city": "Miasto\/miejscowo\u015b\u0107 ",
    "city_country": "Miasto (kraj)",
    "client": "Klient",
    "client_deleted": "Klient usuni\u0119ty!",
    "client_name": "Nazwa klienta",
    "client_payment_terms": "Warunki p\u0142atno\u015bci klienta",
    "client_payment_terms_days": "Dni p\u0142atno\u015bci klienta",
    "client_pricing": "Ceny dla klient\u00f3w",
    "client_pricing_reset": "Resetowanie cen klienta",
    "client_pricing_saved": "Zapisano cen\u0119 klienta",
    "client_supplier": "Klient \/ Dostawca",
    "clients_imported": "Klienci zaimportowani",
    "close": "Blisko",
    "column": "Kolumna",
    "comma_separated_available_options": "Oddzielone przecinkami, Dost\u0119pne opcje",
    "commission_amount": "Kwota prowizji",
    "commission_percentage": "Procent prowizji",
    "commission_rate_options": {
        "percentage": "Procent",
        "flat_rate": "Stawka rycza\u0142towa\""
    },
    "commission_rate_type": "Rodzaj stawki prowizji",
    "commission_rate_value": "Warto\u015b\u0107 stawki prowizji",
    "commission_rates": "Stawki prowizji",
    "company": "Firma",
    "company_details": "Szczeg\u00f3\u0142y klienta",
    "company_name": "Nazwa firmy",
    "complete": "Uzupe\u0142nij",
    "complete_purchase": "zako\u0144cz zakup i zap\u0142a\u0107 {{amount}}",
    "compliance": {
        "options": "Opcje zgodno\u015bci",
        "licence_price": "Cena licencji automatycznej za u\u017cytkownika (GBP)",
        "licences_hit_limit": "Osi\u0105gn\u0105\u0142e\u015b sw\u00f3j limit licencji na zgodno\u015b\u0107",
        "increase_licences": "Zwi\u0119ksz, aby doda\u0107 u\u017cytkownika",
        "licences_description": "Podczas gdy zgodno\u015b\u0107 jest aktywna, musisz mie\u0107 wystarczaj\u0105c\u0105 ilo\u015b\u0107 licencji dla wszystkich kierowc\u00f3w we flocie",
        "next_section": "Nast\u0119pny dzia\u0142",
        "title": "Zgodno\u015b\u0107 z istniej\u0105cymi przepisami prawnymi",
        "active": "Aktywuj",
        "defects": "Usterki",
        "dashboard_link": "Codzienna kontrola pojazd\u00f3w i przestrzeganie przepis\u00f3w",
        "dashboard_advert": "Chcesz \u0142atwiejszego sposobu na przeprowadzenie kontroli zgodno\u015bci pojazdu?",
        "dashboard_advert_button": "Dowiedz si\u0119 wi\u0119cej",
        "incident_information": "Informacja o zdarzeniu",
        "datetime": "Data\/Godzina raportu",
        "override_warning": "Nale\u017cy pami\u0119ta\u0107, \u017ce rachunek miesi\u0119czny zostanie nadpisany w przypadku zmiany liczby licencji.",
        "report_status": {
            "Incomplete": "Niekompletny",
            "Pending": "W trakcie realizacji",
            "Waiting": "Oczekiwanie",
            "Resolved": "Rozwi\u0105zany",
            "Complete": "Kompletny"
        },
        "severity": {
            "None": "Brak problem\u00f3w",
            "Fault": "Drobne problemy",
            "Severe": "Powa\u017cne problemy"
        },
        "severity_label": "Istotno\u015b\u0107",
        "reporting_to": "Podlegaj\u0105cy",
        "report_type": "Typ kontroli",
        "view_image": "Wy\u015bwietl obraz",
        "answer": "Odpowiedz",
        "check_key_name": "Sprawd\u017a",
        "details": "Szczeg\u00f3\u0142y",
        "DailyReport": {
            "title": "Codzienne kontrole pojazd\u00f3w",
            "dashboard_summary": "Karta codziennej kontroli pojazdu to lista konkretnych pozycji, kt\u00f3re nale\u017cy sprawdzi\u0107 ka\u017cdego dnia przed u\u017cyciem pojazdu. System powiadamia kierownika floty o wszelkich problemach i prowadzi rejestr wszystkich kontroli.",
            "complete": "Kompletny",
            "incomplete": "Niekompletny",
            "no_issues": "Wszystko w porz\u0105dku",
            "minor_issues": "Drobne problemy",
            "severe_issues": "Powa\u017cne problemy",
            "group_key_name": "Grupa kontrolna",
            "completed": "Zako\u0144czy\u0142e\u015b codzienne kontrole dla {{registration}}",
            "minor_issues_long": "Zarejestrowano drobne problemy, skontaktuj si\u0119 z kierownikiem floty i napraw je w odpowiednim czasie.",
            "severe_issues_long": "Zarejestrowano kilka powa\u017cnych problem\u00f3w Przed rozpocz\u0119ciem jazdy nale\u017cy skontaktowa\u0107 si\u0119 z kierownikiem floty.",
            "inside": {
                "title": "Wewn\u0105trz pojazdu",
                "mirrors_glass": {
                    "title": "Lusterka i szyby",
                    "is_window_cracked": "Czy przednia szyba jest p\u0119kni\u0119ta \/ porysowana \/ odbarwiona?",
                    "cracked": "Tak p\u0119kni\u0119ta",
                    "scratched": "Tak porysowana",
                    "discoloured": "Tak odbarwiona",
                    "is_vision_obsured": "Czy utrudnia to widoczno\u015b\u0107 kierowcy?",
                    "is_mirrors_acceptable": "Czy wszystkie wymagane lusterka s\u0105 zamontowane, sprawne i nieuszkodzone?",
                    "not_fitted": "Niezamontowane",
                    "is_camera_working": "Je\u015bli zamiast lustra u\u017cywany jest system kamer, czy nie dzia\u0142aj\u0105 one, czy widok jest nieprawid\u0142owy?"
                },
                "wipers": {
                    "title": "Wycieraczki i spryskiwacze przedniej szyby",
                    "is_working": "Czy wycieraczki przedniej szyby s\u0105 prawid\u0142owo zamontowane i dzia\u0142aj\u0105?",
                    "is_missing": "Czy brakuje wycieraczek przedniej szyby?",
                    "is_damaged": "Czy wycieraczki przedniej szyby s\u0105 uszkodzone lub zu\u017cyte?"
                },
                "front_view": {
                    "title": "Widok z przodu",
                    "object_obstruction": "Czy jakie\u015b przedmioty ograniczaj\u0105 Twoj\u0105 widoczno\u015b\u0107 z przodu?",
                    "wiper_obstruction": "Czy w obszarze dzia\u0142ania wycieraczek przedniej szyby znajduj\u0105 si\u0119 jakie\u015b przeszkody?"
                },
                "dashboard": {
                    "title": "Kontrolki i wska\u017aniki na desce rozdzielczej",
                    "is_reading_working": "Czy wszystkie przyrz\u0105dy i wska\u017aniki dzia\u0142aj\u0105 prawid\u0142owo?",
                    "is_warning_working": "Czy Twoje kontrolki dzia\u0142aj\u0105 prawid\u0142owo?"
                },
                "steering": {
                    "title": "Uk\u0142ad kierowniczy",
                    "is_moving": "Czy kierownica porusza si\u0119 prawid\u0142owo?",
                    "is_powered": "Czy wspomaganie uk\u0142adu kierowniczego dzia\u0142a prawid\u0142owo?",
                    "has_play": "Czy uk\u0142ad kierowniczy ma nadmierny luz?",
                    "does_jam": "Czy uk\u0142ad kierowniczy si\u0119 zacina?"
                },
                "horn": {
                    "title": "Klakson",
                    "is_working": "Czy klakson dzia\u0142a?",
                    "is_accessible": "Czy klakson jest dost\u0119pny z fotela kierowcy?"
                },
                "breaks_air": {
                    "title": "Hamulce i nagromadzenie powietrza",
                    "working": "Czy powietrze gromadzi si\u0119 prawid\u0142owo?",
                    "warning": "Czy system ostrzegania dzia\u0142a?",
                    "leaks": "Czy wyst\u0119puj\u0105 wycieki powietrza?",
                    "footwell": "Czy przestrze\u0144 na stopy jest pusta?",
                    "service": "Czy hamulec roboczy obs\u0142uguje oba hamulce ci\u0105gnika i naczepy?",
                    "parking": "Czy dzia\u0142a hamulec postojowy ci\u0105gnika siod\u0142owego?",
                    "loose": "Czy hamulec roboczy jest lu\u017any, czy ma nadmierny luz boczny lub niepe\u0142ny bie\u017cnik antypo\u015blizgowy?"
                },
                "height_marker": {
                    "title": "Znacznik wysoko\u015bci",
                    "is_correct": "Czy prawid\u0142owa wysoko\u015b\u0107 jest wy\u015bwietlana na znaczniku wysoko\u015bci pojazdu w kabinie?"
                },
                "seatbelts": {
                    "title": "Pasy bezpiecze\u0144stwa",
                    "is_damaged": "Czy pasy bezpiecze\u0144stwa maj\u0105 jakie\u015b naci\u0119cia, uszkodzenia lub postrz\u0119pienia, kt\u00f3re mog\u0105 uniemo\u017cliwi\u0107 ich dzia\u0142anie?",
                    "is_secure": "Czy pasy bezpiecze\u0144stwa pozostaj\u0105 zabezpieczone po pod\u0142\u0105czeniu?",
                    "does_retract": "Czy pasy bezpiecze\u0144stwa zwijaj\u0105 si\u0119, gdy s\u0105 zamontowane, i zwijaj\u0105 si\u0119 ca\u0142kowicie, gdy je zdejmujesz?"
                }
            },
            "outside": {
                "title": "Na zewn\u0105trz pojazdu",
                "lights": {
                    "title": "\u015awiat\u0142a i wska\u017aniki",
                    "is_working": "Czy wszystkie \u015bwiat\u0142a i wska\u017aniki dzia\u0142aj\u0105 prawid\u0142owo?",
                    "is_colour_correct": "Czy wszystkie soczewki s\u0105 dopasowane, czyste i maj\u0105 w\u0142a\u015bciwy kolor?",
                    "brakes": "Czy \u015bwiat\u0142a stopu zapalaj\u0105 si\u0119 po zaci\u0105gni\u0119ciu hamulca roboczego i gasn\u0105 po jego zwolnieniu?",
                    "side": "Czy \u015bwiat\u0142a obrysowe boczne s\u0105 zamontowane i czy dzia\u0142aj\u0105?"
                },
                "fuel_oil": {
                    "title": "Wycieki paliwa i oleju",
                    "cap": "Czy korek wlewu paliwa jest prawid\u0142owo zamontowany?",
                    "leaks": "Czy przy w\u0142\u0105czonym silniku pod pojazdem wyst\u0119puj\u0105 wycieki paliwa lub oleju?"
                },
                "battery": {
                    "title": "Bezpiecze\u0144stwo i warunki u\u017cytkowania akumulatora",
                    "is_secure": "Czy akumulator jest zabezpieczony?",
                    "is_good": "Czy akumulator jest w dobrym stanie?",
                    "does_leak": "Czy akumulator przecieka?"
                },
                "diesel_exhaust": {
                    "title": "P\u0142yn DEF (AdBlue)",
                    "has_adblue": "Czy w Twoim poje\u017adzie z silnikiem diesla znajduje si\u0119 wystarczaj\u0105ca ilo\u015b\u0107 p\u0142ynu AdBlue (je\u015bli jest wymagany)?"
                },
                "exhaust": {
                    "title": "Nadmierne dymienie z uk\u0142adu wydechowego",
                    "excessive": "Czy uk\u0142ad wydechowy wydziela nadmiern\u0105 ilo\u015b\u0107 dymu?"
                },
                "body_security": {
                    "title": "Bezpiecze\u0144stwo nadwozia",
                    "fasteners_working": "Czy wszystkie elementy mocuj\u0105ce dzia\u0142aj\u0105?",
                    "doors": "Czy drzwi kabiny i drzwi naczepy s\u0105 zabezpieczone po zamkni\u0119ciu?",
                    "panels": "Czy panele nadwozia na ci\u0105gniku lub naczepie s\u0105 zabezpieczone i nie gro\u017c\u0105 odpadni\u0119ciem?",
                    "legs": "Czy n\u00f3\u017cki do l\u0105dowania (je\u015bli s\u0105 zamontowane) s\u0105 bezpieczne i nie gro\u017c\u0105 odpadni\u0119ciem podczas jazdy?",
                    "guards": "Sprawd\u017a, czy os\u0142ony boczne i tylne os\u0142ony najazdowe s\u0105 zamontowane, je\u015bli s\u0105 wymagane, i czy nie s\u0105 niezabezpieczone lub uszkodzone"
                },
                "spray": {
                    "title": "Klapy przeciwrozbryzgowe",
                    "required": "Czy wymagane s\u0105 klapy przeciwrozbryzgowe?",
                    "secure": "Czy s\u0105 zabezpieczone?",
                    "damaged": "Czy s\u0105 uszkodzone?",
                    "clogged": "czy s\u0105 zatkane b\u0142otem lub gruzem?"
                },
                "wheels": {
                    "title": "Opony i mocowanie k\u00f3\u0142",
                    "secure": "Czy opony i ko\u0142a s\u0105 zabezpieczone?",
                    "tread": "Czy opony maj\u0105 g\u0142\u0119boko\u015b\u0107 bie\u017cnika co najmniej 1 mm?",
                    "inflated": "Czy opony s\u0105 prawid\u0142owo napompowane?",
                    "cuts": "Czy w \u015bcianie bocznej opony s\u0105 jakie\u015b g\u0142\u0119bokie naci\u0119cia?",
                    "cord": "Czy gdziekolwiek na oponie widoczny jest drut?",
                    "nuts": "Czy wszystkie nakr\u0119tki k\u00f3\u0142 s\u0105 wystarczaj\u0105co dokr\u0119cone? - W tym celu mo\u017cna sprawdzi\u0107, czy wska\u017aniki nakr\u0119tek k\u00f3\u0142 (je\u015bli s\u0105 zamontowane) przesun\u0119\u0142y si\u0119.",
                    "twin": "Czy pomi\u0119dzy ko\u0142ami bli\u017aniaczymi znajduj\u0105 si\u0119 jakie\u015b przedmioty lub zanieczyszczenia?"
                },
                "brakes": {
                    "title": "Przewody hamulcowe i hamulec postojowy naczepy",
                    "couplings": "Czy z\u0142\u0105cza s\u0105 wolne od zanieczyszcze\u0144 i znajduj\u0105 si\u0119 we w\u0142a\u015bciwym miejscu?",
                    "damage": "Czy przewody hamulcowe s\u0105 uszkodzone lub zu\u017cyte?",
                    "trailer_brake": "Czy hamulec postojowy naczepy dzia\u0142a prawid\u0142owo?"
                },
                "electrical": {
                    "title": "Przy\u0142\u0105cza elektryczne",
                    "insulated": "Czy widoczne przewody s\u0105 zaizolowane?",
                    "risk": "Czy jakie\u015b widoczne przewody mog\u0105 zosta\u0107 przytrza\u015bni\u0119te lub uszkodzone?",
                    "coupling": "Czy wszystkie elektryczne z\u0142\u0105cza naczepy s\u0105 bezpiecznie pod\u0142\u0105czone?",
                    "switches": "Czy wszystkie prze\u0142\u0105czniki elektryczne dzia\u0142aj\u0105 prawid\u0142owo?"
                },
                "coupling": {
                    "title": "Zabezpieczenie sprz\u0119gu",
                    "primary": "Czy przyczepa jest prawid\u0142owo umieszczona w siodle lub sprz\u0119gu?",
                    "secondary": "Czy wt\u00f3rne urz\u0105dzenia blokuj\u0105ce znajduj\u0105 si\u0119 we w\u0142a\u015bciwym po\u0142o\u017ceniu?"
                },
                "load": {
                    "title": "Bezpiecze\u0144stwo \u0142adunku",
                    "secure": "Czy Tw\u00f3j \u0142adunek jest zabezpieczony przed przemieszczaniem si\u0119?",
                    "guidance": "Czy potrzebujesz wskaz\u00f3wek w zwi\u0105zku z bezpiecze\u0144stwem swojego \u0142adunku?"
                },
                "numberplate": {
                    "title": "Tablica rejestracyjna",
                    "broken": "Czy tablica rejestracyjna jest uszkodzona?",
                    "incorrect": "Czy tablica rejestracyjna jest nieprawid\u0142owa lub ma niew\u0142a\u015bciwy odst\u0119p?",
                    "dirty": "Czy tablica rejestracyjna jest brudna?",
                    "faded": "Czy tablica rejestracyjna jest wyblak\u0142a?",
                    "covered": "Czy tablica rejestracyjna jest czym\u015b zas\u0142oni\u0119ta?"
                },
                "reflectors": {
                    "title": "\u015awiat\u0142a odblaskowe (w tym boczne)",
                    "missing": "Czy brakuje odblask\u00f3w?",
                    "broken": "Czy odblaski s\u0105 uszkodzone?",
                    "insecure": "Czy \u015bwiat\u0142a odblaskowe s\u0105 niezabezpieczone?",
                    "fitted": "Czy \u015bwiat\u0142a odblaskowe s\u0105 prawid\u0142owo zamontowane?",
                    "colour": "Czy odblaski maj\u0105 niew\u0142a\u015bciwy kolor?",
                    "covered": "Czy odblaski s\u0105 zas\u0142oni\u0119te przez brud lub inne przedmioty?"
                },
                "plates": {
                    "title": "Oznakowanie i tablice ostrzegawcze (w tym oznakowanie odblaskowe)",
                    "colour": "Czy oznaczenia i tablice ostrzegawcze maj\u0105 w\u0142a\u015bciwy kolor?",
                    "visible": "Czy oznaczenia i tablice ostrzegawcze s\u0105 widoczne?",
                    "secured": "Czy oznaczenia i tablice ostrzegawcze s\u0105 bezpiecznie zamocowane?",
                    "obscured": "Czy oznaczenia i tablice ostrzegawcze s\u0105 zas\u0142oni\u0119te przez brud lub inne przedmioty?",
                    "hazard": "Je\u015bli pojazd przewozi towary niebezpieczne, czy tablice informacyjne o zagro\u017ceniach pokazuj\u0105 prawid\u0142owe informacje dotycz\u0105ce \u0142adunku?",
                    "hazard_visible": "Je\u015bli pojazd przewozi towary niebezpieczne, czy tablice informuj\u0105ce o niebezpiecze\u0144stwie s\u0105 widoczne?",
                    "hazard_secured": "Je\u015bli pojazd przewozi towary niebezpieczne, czy tablice informuj\u0105ce o niebezpiecze\u0144stwie s\u0105 bezpiecznie zamocowane?",
                    "hazard_covered": "Je\u015bli pojazd przewozi towary niebezpieczne, czy tablice informacyjne o zagro\u017ceniach s\u0105 zas\u0142oni\u0119te przez brud lub inne przedmioty?"
                }
            },
            "before_use": {
                "title": "Przed u\u017cyciem",
                "general": {
                    "title": "Og\u00f3lne",
                    "previous": "Czy wszystkie zg\u0142oszone wcze\u015bniej usterki zosta\u0142y usuni\u0119te?"
                },
                "leaks": {
                    "title": "Oczywiste wycieki",
                    "any": "Czy wyst\u0119puj\u0105 oznaki nieszczelno\u015bci?"
                },
                "hydraulics": {
                    "title": "Poziom p\u0142ynu hydraulicznego",
                    "fluid_level": "Czy zbiornik jest na w\u0142a\u015bciwym poziomie?"
                },
                "carriage": {
                    "title": "Maszt i karetka",
                    "damage": "Czy na maszcie i karetce s\u0105 jakie\u015b uszkodzenia lub obce elementy?",
                    "lubrication": "Czy maszt i karetka s\u0105 odpowiednio nasmarowane?"
                },
                "chains_bolts": {
                    "title": "\u0141a\u0144cuchy i \u015bruby mocuj\u0105ce",
                    "damage": "Czy linki lub \u015bruby mocuj\u0105ce s\u0105 uszkodzone?",
                    "stretching": "Czy jest jakie\u015b oczywiste rozci\u0105gni\u0119cie?",
                    "lubrication": "Czy zapewniono odpowiednie smarowanie?"
                },
                "forks": {
                    "title": "Wid\u0142y",
                    "cracks": "Czy s\u0105 jakie\u015b \u015blady p\u0119kni\u0119\u0107 na wid\u0142ach, zw\u0142aszcza na 'pi\u0119tach', lub inne \u015blady uszkodze\u0144 lub nadmiernego zu\u017cycia",
                    "damage": "Czy na wid\u0142ach s\u0105 jakie\u015b inne oznaki uszkodzenia lub nadmiernego zu\u017cycia?",
                    "pins": "Czy na ko\u0142kach zabezpieczaj\u0105cych wyst\u0119puj\u0105 inne oznaki uszkodzenia lub nadmiernego zu\u017cycia?"
                },
                "backrest": {
                    "title": "Oparcie\/Wyd\u0142u\u017cenie",
                    "damage": "Czy na oparciach i przed\u0142u\u017ceniu wida\u0107 \u015blady uszkodze\u0144 lub gruzu?"
                },
                "attachments": {
                    "title": "Osprz\u0119t",
                    "secure": "Czy osprz\u0119t s\u0105 jest zabezpieczony w punktach mocowania i blokowania?",
                    "hydraulics": "Czy hydraulika i inne po\u0142\u0105czenia s\u0105 zabezpieczone?"
                },
                "wheels": {
                    "title": "Opony \/ ko\u0142a \/ nakr\u0119tki",
                    "tyres": "Czy na oponach s\u0105 widoczne \u015blady uszkodze\u0144, w tym przeci\u0119cia i zu\u017cycie bie\u017cnika?",
                    "pressure": "Czy opony pneumatyczne maj\u0105 w\u0142a\u015bciwe ci\u015bnienie?",
                    "damage": "Czy ko\u0142a, w tym felgi s\u0105 uszkodzone?",
                    "nuts": "Czy nakr\u0119tki k\u00f3\u0142 s\u0105 poluzowane?"
                },
                "seat": {
                    "title": "Fotel i pasy bezpiecze\u0144stwa",
                    "secure": "Czy fotel jest prawid\u0142owo zamocowany do poajzdu i nie jest lu\u017any lub uszkodzony?",
                    "belt": "Czy pas bezpiecze\u0144stwa lub inne urz\u0105dzenie przytrzymuj\u0105ce jest bezpieczne i dzia\u0142a prawid\u0142owo?"
                },
                "steering": {
                    "title": "Uk\u0142ad kierowniczy",
                    "acceptable": "Czy uk\u0142ad kierowniczy zachowuje si\u0119 normalnie, bez nadmiernego luzu lub nietypowych ruch\u00f3w?"
                },
                "service_brakes": {
                    "title": "Hamulce robocze",
                    "acceptable": "Czy hamulce dzia\u0142aj\u0105 sprawnie?"
                },
                "parking_brakes": {
                    "title": "Hamulce postojowe",
                    "acceptable": "Czy hamulec postojowy dzia\u0142a sprawnie i zwalnia si\u0119 prawid\u0142owo?"
                },
                "controls": {
                    "title": "Elementy steruj\u0105ce",
                    "acceptable": "Czy wszystkie elementy steruj\u0105ce no\u017cne i r\u0119czne dzia\u0142aj\u0105 prawid\u0142owo i nie s\u0105 w \u017caden spos\u00f3b zablokowane?"
                },
                "system": {
                    "title": "System operacyjny",
                    "acceptable": "Je\u015bli pojazd jest wyposa\u017cony w systemy komputerowe to czy dzia\u0142aj\u0105 one prawid\u0142owo?"
                },
                "warning_lights": {
                    "title": "\u015awiat\u0142a ostrzegawcze",
                    "operating": "Czy wszystkie \u015bwiat\u0142a ostrzegawcze dzia\u0142aj\u0105 prawid\u0142owo?",
                    "any_warning": "Czy zapalaj\u0105 si\u0119 jakie\u015b \u015bwiat\u0142a ostrzegawcze?"
                },
                "dashboard": {
                    "title": "Wska\u017aniki\/przyrz\u0105dy",
                    "acceptable": "Czy wszystkie wska\u017aniki i inne przyrz\u0105dy dzia\u0142aj\u0105 prawid\u0142owo?"
                },
                "lights": {
                    "title": "\u015awiat\u0142a\/\u015awiat\u0142a ostrzegawcze",
                    "acceptable": "Czy wszystkie \u015bwiat\u0142a ostrzegawcze dzia\u0142aj\u0105 prawid\u0142owo?"
                },
                "horn": {
                    "title": "Klakson",
                    "acceptable": "Czy klakson dzia\u0142a prawid\u0142owo?"
                },
                "alarms": {
                    "title": "Alarmy",
                    "acceptable": "Czy alarm dzia\u0142a prawid\u0142owo?"
                },
                "warnings": {
                    "title": "Inne urz\u0105dzenia ostrzegawcze",
                    "acceptable": "Je\u015bli zamontowano inne systemy ostrzegawcze, czy dzia\u0142aj\u0105 one prawid\u0142owo?"
                },
                "guards": {
                    "title": "Zabezpieczenia i os\u0142ony",
                    "acceptable": "Czy wszystkie os\u0142ony bezpiecze\u0144stwa i pokrywy s\u0105 prawid\u0142owo zamocowane i zabezpieczone?"
                },
                "body": {
                    "title": "Nadwozie",
                    "acceptable": "Czy widoczne s\u0105 uszkodzenia nadwozia, w tym stela\u017cy i innego wyposa\u017cenia?"
                }
            }
        },
        "LoadingReport": {
            "title": "Raport dotycz\u0105cy za\u0142adunku",
            "location_name": "Nazwa miejsca za\u0142adunku",
            "dashboard_summary": "Pojazdy powinny by\u0107 dostosowane do przewo\u017conych \u0142adunk\u00f3w, a szczeg\u00f3lnie wa\u017cne jest, aby pojazd mia\u0142 odpowiednie punkty zaczepienia, aby zapewni\u0107 bezpieczne przewo\u017cenie \u0142adunk\u00f3w. U\u017cyj tego raportu, aby zapisa\u0107 swoje obserwacje i udowodni\u0107, \u017ce wszystkie niezb\u0119dne \u015brodki ostro\u017cno\u015bci zosta\u0142y podj\u0119te.",
            "date": "Data sporz\u0105dzenia raportu",
            "cargo": "\u0141adunek"
        },
        "JourneyReport": {
            "title": "Raport dotycz\u0105cy bezpiecze\u0144stwa pojazdu",
            "new": "Rozpocznij nowy",
            "list": "Dost\u0119p zapisany",
            "report_id": "Identyfikator raportu",
            "start": "Rozpocznij",
            "stop": "Sprawd\u017a",
            "end": "Zako\u0144cz",
            "start_time": "Godzina rozpocz\u0119cia",
            "end_time": "Godzina zako\u0144czenia",
            "dashboard_summary": "Raport o bezpiecze\u0144stwie pojazdu pozwala w prosty i skuteczny spos\u00f3b rejestrowa\u0107 oznaczenia geograficzne i czasowe kontroli przeprowadzanych za ka\u017cdym razem, gdy pojazd pozostaje bez nadzoru lub gdy \u0142adunek jest poddawany interwencji, aby zminimalizowa\u0107 ryzyko obecno\u015bci na pok\u0142adzie nielegalnych imigrant\u00f3w.",
            "name": "Tytu\u0142 podr\u00f3\u017cy",
            "date_time": "Data i godzina",
            "trailer_number": "Numery naczepy",
            "security_device_type": "Typ urz\u0105dzenia zabezpieczaj\u0105cego",
            "security_device_type_other": "Prosz\u0119 poda\u0107",
            "security_device_number": "Numer seryjny urz\u0105dzenia zabezpieczaj\u0105cego",
            "security_device_photos": "Zdj\u0119cia urz\u0105dze\u0144 zabezpieczaj\u0105cych",
            "device_type": {
                "Seal": "Plomba",
                "Lock": "Zamek",
                "Other": "Inne"
            },
            "check_load_space": "Sprawd\u017a, czy przestrze\u0144 \u0142adunkowa jest skutecznie zabezpieczona przed dost\u0119pem os\u00f3b nieupowa\u017cnionych?",
            "check_cargo": "Czy \u0142adunek jest skutecznie zabezpieczony przed dost\u0119pem os\u00f3b nieupowa\u017cnionych? (je\u015bli dotyczy)",
            "check_devices": "Sprawd\u017a wszystkie zamki, plomby i inne urz\u0105dzenia zabezpieczaj\u0105ce pod k\u0105tem \u015blad\u00f3w manipulacji, uszkodze\u0144 i nieuprawnionych napraw lub wymiany?",
            "check_cables": "Czy kable i ta\u015bmy oraz zwi\u0105zane z nimi oczka zosta\u0142y sprawdzone pod k\u0105tem \u015blad\u00f3w manipulacji, uszkodze\u0144 lub \u015blad\u00f3w nieautoryzowanej naprawy lub wymiany? (je\u015bli dotyczy)",
            "check_shell": "Czy zewn\u0119trzna pow\u0142oka lub pokrycie z tkaniny zosta\u0142y sprawdzone pod k\u0105tem \u015blad\u00f3w nieautoryzowanego dost\u0119pu? (je\u015bli dotyczy)",
            "check_external_storage": "Sprawd\u017a wszystkie zewn\u0119trzne schowki pod k\u0105tem \u015blad\u00f3w nieautoryzowanego dost\u0119pu?",
            "check_wind_deflectors": "Sprawd\u017a deflektory wiatrowe pod k\u0105tem \u015blad\u00f3w nieautoryzowanego dost\u0119pu?",
            "check_beneath": "Sprawd\u017a pod pojazdem, czy nie ma \u015blad\u00f3w nieautoryzowanego dost\u0119pu?",
            "check_inside": "Sprawd\u017a, czy wewn\u0105trz pojazdu nie ma \u015blad\u00f3w nieautoryzowanego dost\u0119pu?",
            "check_roof_inside": "Sprawd\u017a dach z wn\u0119trza pojazdu pod k\u0105tem \u015blad\u00f3w nieautoryzowanego dost\u0119pu?",
            "check_person": "Sprawd\u017a, czy \u017cadna osoba nie uzyska\u0142a nieuprawnionego dost\u0119pu?",
            "check_documentaion": "Sprawd\u017a, czy wszystkie niezb\u0119dne dokumenty s\u0105 poprawne?",
            "any_signs": "Czy zauwa\u017cono jakie\u015b oznaki, \u017ce kto\u015b uzyska\u0142 lub pr\u00f3bowa\u0142 uzyska\u0107 nieuprawniony dost\u0119p do pojazdu?",
            "endorsement": "Potwierdzenie od osoby trzeciej, kt\u00f3ra przeprowadzi\u0142a powy\u017csze kontrole (je\u015bli dotyczy)",
            "instructions_complete_journey": "Aby wy\u015bwietli\u0107 zapisane kontrole przejazd\u00f3w, wystarczy wybra\u0107 odpowiedni\u0105 pozycj\u0119 poni\u017cej. Je\u015bli chcesz udost\u0119pni\u0107 je osobom trzecim, mo\u017cesz \u0142atwo wyeksportowa\u0107 swoje kontrole przejazdu do pliku PDF. Wystarczy klikn\u0105\u0107 na przycisk \"Raport PDF\", a nast\u0119pnie wybra\u0107 przycisk \"Udost\u0119pnij\" w przegl\u0105darce i wybra\u0107 spos\u00f3b udost\u0119pnienia z urz\u0105dzenia.",
            "add_stop": "Dodaj now\u0105 kontrol\u0119",
            "add_final": "Zako\u0144cz podr\u00f3\u017c"
        },
        "CrimeReport": {
            "title": "Raport dotycz\u0105cy przest\u0119pstwa",
            "dashboard_summary": "Rejestruj do cel\u00f3w kontrolnych i prawnych wszelkie incydenty kryminalne, kt\u00f3re maj\u0105 miejsce w trakcie wykonywania obowi\u0105zk\u00f3w. Prowad\u017a rejestr podj\u0119tych krok\u00f3w, zaanga\u017cowanych organ\u00f3w i istotnych szczeg\u00f3\u0142\u00f3w.",
            "date": "Data zdarzenia",
            "type": "Rodzaj zdarzenia",
            "types": {
                "Robbery": "Kradzie\u017c",
                "Mugging": "Napad",
                "Assault": "Atak",
                "VehicleTheft": "Kradzie\u017c pojazdu",
                "VehicleDamage": "Uszkodzenie pojazdu",
                "FuelTheft": "Kradzie\u017c paliwa",
                "Hijacking": "Uprowadzenie",
                "Terrorist": "Zdarzenie terrorystyczne",
                "Stowaways": "Pasa\u017cerowie na gap\u0119"
            },
            "description": "Opis zdarzenia",
            "parties_involved": "Strony bior\u0105ce udzia\u0142",
            "witnesses": "\u015awiadkowie",
            "police_report": "Raport policyjny",
            "filed_with_police": "Zg\u0142oszenie na policj\u0119?",
            "police_branch_name": "Komisariat Policji",
            "police_reference": "Numer zg\u0142oszenia o przest\u0119pstwie",
            "officer_name": "Funkcjonariusz raportuj\u0105cy",
            "police_contact_number": "Numer kontaktowy",
            "follow_up_actions": "Dalsze kroki"
        },
        "BorderReport": {
            "title": "Raport graniczny",
            "dashboard_summary": "Dow\u00f3d, \u017ce podj\u0105\u0142e\u015b wszelkie niezb\u0119dne kroki w celu zapewnienia integralno\u015bci \u0142adunku i pojazdu podczas przekraczania granic mi\u0119dzynarodowych swoim pojazdem.",
            "time": "Godzina sporz\u0105dzenia raportu",
            "general_checks": "Og\u00f3lne kontrole pojazd\u00f3w",
            "inside": "Wewn\u0105trz pojazdu",
            "outside": "Na zewn\u0105trz pojazdu",
            "is_damage": "Czy wyst\u0119puj\u0105 uszkodzenia dachu lub paneli\/poszycia nadwozia pojazdu?",
            "other_checks": "Pozosta\u0142e kontrole",
            "external_compartments": "Czy zewn\u0119trzne schowki s\u0105 puste i szczelnie zamkni\u0119te?",
            "underside_accessible": "Czy sp\u00f3d pojazdu i wszelkie inne dost\u0119pne miejsca ukrycia s\u0105 puste?",
            "seals_intact": "Czy wszystkie plomby s\u0105 nienaruszone?",
            "locks_intact": "Wszystkie k\u0142\u00f3dki i zamki w drzwiach zapi\u0119te?",
            "tir_intact": "Czy drut TIR jest zamontowany i uszczelniony?",
            "life_signs_checked": "Czy kontrole Co2, kamer i monitora t\u0119tna zosta\u0142y przeprowadzone przez agencj\u0119 zewn\u0119trzn\u0105?"
        },
        "TrafficIncidentReport": {
            "title": "Raport z wypadku",
            "dashboard_summary": "Je\u015bli bra\u0142e\u015b udzia\u0142 w wypadku z udzia\u0142em Twojego pojazdu, u\u017cyj tego raportu, aby zapisa\u0107 wszystkie istotne informacje i dowody, kt\u00f3re b\u0119d\u0105 wspiera\u0107 Ci\u0119 w celach audytowych i prawnych.",
            "date": "Data\/godzina wypadku",
            "description": "Opis wypadku",
            "description_instructions": "Prosz\u0119 doda\u0107 rysunek przedstawiaj\u0105cy wypadek",
            "injuries_damage": "Obra\u017cenia i szkody",
            "driver_injuries": "Czy kierowca dozna\u0142 jakich\u015b obra\u017ce\u0144?",
            "passengers_injuried": "Czy kt\u00f3ry\u015b z pasa\u017cer\u00f3w odni\u00f3s\u0142 jakie\u015b obra\u017cenia?",
            "no_injuries": "Brak obra\u017ce\u0144",
            "injured_passengers": "Nazwiska i adresy pasa\u017cer\u00f3w, kt\u00f3rzy odnie\u015bli obra\u017cenia",
            "no_damange": "Brak uszkodze\u0144",
            "vehicle_damaged": "Czy pojazd zosta\u0142 uszkodzony?",
            "vehicle_damage": "Opis uszkodze\u0144 pojazdu",
            "parties_involved": "Dane kierowcy strony trzeciej"
        },
        "inactive": {
            "title": "Kontrole zgodno\u015bci z przepisami",
            "message_to_drivers": "Je\u015bli jeste\u015b zainteresowany tymi funkcjami, porozmawiaj ze swoim mened\u017cerem floty.",
            "message_to_clients": "Je\u015bli jeste\u015b zainteresowany tymi funkcjami, mo\u017cesz je w\u0142\u0105czy\u0107 na jednej ze swoich flot ze strony edycji",
            "button_text": "Aktywuj zgodno\u015b\u0107 w ustawieniach"
        }
    },
    "confirm_booking": "Potwierd\u017a rezerwacj\u0119",
    "confirm_your_email": "Potwierd\u017a adres e-mail",
    "conform_dialog": {
        "are_you_sure": "Jeste\u015b pewny?",
        "order_confirm": {
            "message": "Zatwierdzi\u0107 zam\u00f3wienie?",
            "cancel": "Anuluj",
            "accept": "OK,Akceptuj"
        },
        "order_reject": {
            "message": "Odrzuci\u0107 zam\u00f3wienie?",
            "cancel": "Anuluj",
            "accept": "OK,Akceptuj"
        },
        "cancel": "Anuluj",
        "delete": "Usu\u0144",
        "accept": "OK,Akceptuj",
        "reset": "Zresetuj",
        "no": "Nie",
        "yes": "Tak",
        "cancel_order": "Anuluj zam\u00f3wienie",
        "extend": "Przed\u0142u\u017c rezerwacj\u0119",
        "new_parking_price_will_be_calculated_and_order_will_be_extended": "Nowa cena parkingowa zostanie obliczona, a rezerwacja zostanie przed\u0142u\u017cona na wybran\u0105 dat\u0119 i godzin\u0119",
        "clear_all_selected_vehicles": "Usun\u0105\u0107 wszystkie wybrane pojazdy?"
    },
    "contact_name": "Nazwa Kontaktu",
    "continue": "Kontynuowa\u0107",
    "continue_sign_in": "Prosimy o logowanie si\u0119 z nowym has\u0142em",
    "cost": "Koszt",
    "country": "Kraj",
    "county": "Hrabstwo",
    "covered_costs": "Pokryte koszty",
    "create": "Tworzy\u0107",
    "create_a_booking": "Utw\u00f3rz rezerwacj\u0119",
    "create_account": "Utw\u00f3rz konto",
    "create_password_header": "Utw\u00f3rz nowe has\u0142o poni\u017cej",
    "create_user": "Stw\u00f3rz u\u017cytkownika",
    "credit": "Kredyt",
    "credit_limit": "Limit kredytowy M-Park",
    "credit_zero_definition": "definicja\"",
    "csv_file_should_contain_the_following_columns": "Plik CSV powinien zawiera\u0107 nast\u0119puj\u0105ce kolumny:",
    "currencies": "Waluty",
    "currency": "Waluta",
    "custom": "Niestandardowe",
    "daily": "Codziennie",
    "dashboard": {
        "dashboard": "Panel",
        "total_m_park_fee": "Ca\u0142kowita op\u0142ata M-Park",
        "parking_occurrences": "Zdarzenia zwi\u0105zane z parkowaniem",
        "non_parking_occurrences": "Zdarzenia niezwi\u0105zane z parkowaniem",
        "active_sites": "Aktywne strony",
        "active_users": "Aktywni u\u017cytkownicy",
        "active_accounts": "Aktywne konta",
        "total_site_revenue": "Ca\u0142kowite przychody z witryny",
        "total_revenue": "\u0141\u0105czne przychody",
        "total_non_parking_revenue": "Przychody pozaparkingowe og\u00f3\u0142em",
        "average_order_value": "\u015arednia warto\u015b\u0107 zam\u00f3wienia",
        "platform_dashboard": "Pulpit platformy",
        "driver_dashboard": "Pulpit kierowcy",
        "company_dashboard": "Pulpit nawigacyjny klienta",
        "fleet_manager_dashboard": "Pulpit nawigacyjny mened\u017cera floty",
        "supplier_dashboard": "Pulpit dostawcy",
        "site_manager_dashboard": "Pulpit nawigacyjny mened\u017cera witryny",
        "operator_dashboard": "Pulpit nawigacyjny operatora",
        "supplier_operator_dashboard": "Pulpit operatora dostawcy",
        "client_operator_dashboard": "Pulpit nawigacyjny operatora klienta",
        "platform_operator_dashboard": "Pulpit operatora platformy",
        "platform_finance_dashboard": "Pulpit nawigacyjny platformy finansowej"
    },
    "date": "Data",
    "date_first_issued": "Data pierwszego u\u017cycia",
    "date_first_used": "Data pierwszego u\u017cycia",
    "date_time": "Data i godzina",
    "date_time_completed": "Data\/Czas wykonania",
    "day": "Dzie\u0144",
    "delete": "Usu\u0144",
    "delete_current_client": "Usun\u0105\u0107 klienta?",
    "delete_payment_method": "Usun\u0105\u0107 form\u0119 p\u0142atno\u015bci?",
    "delete_selected_image": "Usun\u0105\u0107 wybrany obraz?",
    "delete_selected_vat_record": "Usun\u0105\u0107 wybrany zapis VAT?",
    "description": "Opis",
    "did_not_receive_security_code": "Nie otrzyma\u0142em kliknij tutaj, aby ponownie wys\u0142a\u0107",
    "disapprove": "Odrzu\u0107",
    "discount": "Zni\u017cka",
    "discount_type": "Typ rabatu",
    "discount_value": "Warto\u015b\u0107 rabatu",
    "dismiss": "Odrzu\u0107",
    "dismiss_all": "Odrzuci\u0107 wszystkie",
    "dismiss_all_notification": "Odrzuci\u0107 wszystkie powiadomienia?",
    "dismiss_this_notification": "Odrzuci\u0107 to powiadomienie?",
    "distance": "Dystans",
    "distance_units": "Jednostki odleg\u0142o\u015bci",
    "downgrade": "Obni\u017cenie warto\u015bci",
    "download_reports": "Pobierz raport",
    "download_sample_file": "Pobierz przyk\u0142adowy plik",
    "drawer_link_titles": {
        "dashboard": "Panel",
        "users": "U\u017cytkownicy",
        "vehicles": "Pojazdy",
        "favourite_sites": "Ulubione strony",
        "settings": "Ustawienia",
        "need_help": "Potrzebujesz pomocy?",
        "clients": "Klienci",
        "fleets": "Floty",
        "suppliers": "Dostawcy",
        "sites": "Strony",
        "promotion": "Awans",
        "promotions": "Promocje",
        "bookings": "Rezerwacje",
        "orders": "Zam\u00f3wienia",
        "reports": "Raporty",
        "find_site": "Znajd\u017a stron\u0119",
        "scan_qr_code": "Zeskanuj kod QR",
        "my_history": "Moja historia",
        "compliance": "Zgodno\u015b\u0107"
    },
    "driver": "Kierowca",
    "driver_checked_out_at": "Kierowca wymeldowany o {{checkedOutTime}}",
    "driver_have_been_checked_in": "Kierowca zosta\u0142 odprawiony",
    "driver_have_been_checked_out": "Kierowca zosta\u0142 odprawiony",
    "driver_parking_period_expires_on": "Okres p\u0142atnego parkowania kierowcy wygasa {{expireDate}}",
    "driver_settings": "Ustawienia kierowcy",
    "drivers": "Kierowca(y)",
    "drivers_imported": "Kierowcy zaimportowani",
    "duration": "Czas trwania",
    "east": "Wsch\u00f3d",
    "east_short": "WSCH",
    "edit": "Edytowa\u0107",
    "edit_payment_method": "Edytuj metod\u0119 p\u0142atno\u015bci",
    "email_address": "Adres e -mail",
    "email_notifications": "Powiadomienia e-mailowe",
    "enter_card_holder_name": "Wprowad\u017a imi\u0119 i nazwisko w\u0142a\u015bciciela karty",
    "enter_card_number": "Wprowad\u017a numer karty",
    "enter_email_address": "Wprowad\u017a e-mail",
    "enter_expire": "Wprowad\u017a dat\u0119 wyga\u015bni\u0119cia",
    "enter_message": "Wprowad\u017a wiadomo\u015b\u0107",
    "enter_name": "Wprowad\u017a imi\u0119",
    "enter_order_id_manually": "Wprowad\u017a identyfikator zam\u00f3wienia r\u0119cznie",
    "enter_parking_site_name": "Wprowad\u017a nazw\u0119 parkingu",
    "enter_parking_site_website": "wprowad\u017a witryn\u0119 parkingu",
    "enter_phone_number": "Wpisz numer telefonu",
    "enter_post_code": "Wpisz kod pocztowy",
    "enter_postcode_town": "Wpisz kod pocztowy\/ miasto",
    "enter_town_city": "wpisz miasto\/miejscowo\u015b\u0107",
    "enter_username": "Wpisz nazw\u0119 u\u017cytkownika",
    "enter_your_security_code": "Wprowad\u017a sw\u00f3j kod bezpiecze\u0144stwa",
    "entity": "Podmiot",
    "errors": {
        "error_401_message": "401 | Nieautoryzowana pro\u015bba",
        "error_404_message": "404 | STRONA NIEZNALEZIONA",
        "error_0_message": "brak internetu",
        "page_error": "B\u0141\u0104D STRONY!",
        "sorry_an_error_has_occurred_to_this_page": "Przepraszamy, tej strony nie mo\u017cna za\u0142adowa\u0107",
        "you_are_not_authorized_to_access_the_page_you_requested": "Przepraszamy, nie jeste\u015b upowa\u017cniony do uzyskania dost\u0119pu do strony, o kt\u00f3rej prosi\u0142e\u015b",
        "page_not_available": "Przepraszamy, strona, kt\u00f3rej szukasz nie istnieje, zosta\u0142a usuni\u0119ta, zmieniono jej nazw\u0119 lub jest tymczasowo niedost\u0119pna"
    },
    "example": "Przyk\u0142ad",
    "expire": "Wygasa\u0107",
    "expires": "Wygasa",
    "expiry_date": "Data wa\u017cno\u015bci",
    "export": "Eksport",
    "export_code": "Eksportuj kod QR",
    "export_payouts": "Wyp\u0142aty eksportowe",
    "export_payouts_file_format": "Format plik\u00f3w wyp\u0142aty eksportowych",
    "export_pdf": "Eksportuj raport",
    "extend_booking": "Przed\u0142u\u017cy\u0107 rezerwacj\u0119",
    "facilities": "obiekty",
    "field": "Pole",
    "filter_by_site_name": "Filtruj wed\u0142ug nazwy witryny",
    "filters_labels": {
        "show_advance_filters": "Poka\u017c zaawansowane filtry",
        "hide_advance_filters": "Ukryj zaawansowane filtry",
        "filter_by_creation_date": "Filtr wed\u0142ug daty tworzenia konta",
        "filter_by_country": "Filtr wed\u0142ug nazwy kraju",
        "filter_by_region": "Filtr wed\u0142ug regionu",
        "filter_by_account_status": "Filtr wed\u0142ug statusu konta",
        "search_for_email_address": "Wyszukaj adres e -mail",
        "filter_by_company_name": "Filtr wed\u0142ug nazwy firmy",
        "filter_by_site": "Filtr wed\u0142ug strony",
        "filter_by_user": "Filtr wed\u0142ug u\u017cytkownika",
        "filter_by_client": "Filtr wed\u0142ug klienta",
        "filter_by_suppliers": "Filtr przez dostawc\u0119",
        "filter_by_fleet": "Filtr wed\u0142ug floty",
        "filter_by_product": "Filtr wed\u0142ug produktu",
        "start_date": "Data rozpocz\u0119cia",
        "end_date": "Data zakonczenia",
        "filter_by_primary_contact_name": "Filtr wed\u0142ug podstawowej nazwy kontaktu",
        "credit_status": "Status kredytowy",
        "between_these_dates": "Mi\u0119dzy tymi datami",
        "company_name": "Nazwa firmy",
        "show_promotion": "Poka\u017c promocj\u0119?",
        "active_date": "Data aktywna",
        "expire_date": "Data wa\u017cno\u015bci",
        "order_date": "Data zam\u00f3wienia",
        "due_date": "Termin p\u0142atno\u015bci",
        "balance": "Saldo",
        "figures": "Dane",
        "any": "Ka\u017cdy",
        "no_min": "Brak minimum",
        "no_max": "Brak Maximum",
        "show_filters": "Poka\u017c filtry",
        "apply": "Zastosuj filtry"
    },
    "find_site": "Znajd\u017a stron\u0119",
    "find_site_to_book": "Znajd\u017a stron\u0119 do zarezerwowania",
    "find_site_with_promotion_to_book": "Znajd\u017a strony z promocjami",
    "find_sites_with_promotions": "Znajd\u017a witryn\u0119 z promocjami",
    "find_user": "Znajd\u017a u\u017cytkownika",
    "first_line_address": "Adres wiersz 1",
    "first_line_billing_address": "Adres rozliczeniowy wiersz 1",
    "first_name": "Imi\u0119\"",
    "fleet": "Flota",
    "fleet_name": "Nazwa floty",
    "fleet_name_company": "Klient | Nazwa floty",
    "fleet_spending_caps": "Domy\u015blne limity wydatk\u00f3w floty",
    "fleets": "Flota(y)",
    "fleets_imported": "Zaimportowane floty",
    "forgot_password": "Zapomnia\u0142e\u015b has\u0142a?",
    "form_validation": {
        "is_required": "{{attribute}} jest wymagany",
        "min": "{{attribute}} musi by\u0107 wi\u0119ksze lub r\u00f3wne {{value}}",
        "max_length": "Nie mo\u017ce by\u0107 wi\u0119ksza ni\u017c {{max}}{{length}} znak\u00f3w.",
        "invalid_email_address": "Prosz\u0119 poda\u0107 poprawny adres e-mail",
        "password_invalid_min_length": "Has\u0142o nie mo\u017ce by\u0107 kr\u00f3tsze ni\u017c {{length}} znak\u00f3w",
        "code_invalid_min_length": "Kod nie mo\u017ce by\u0107 mniejszy ni\u017c {{length}} cyfr",
        "code_invalid_max_length": "Kod nie mo\u017ce zawiera\u0107 wi\u0119cej ni\u017c {{length}} cyfr",
        "password_is_required": "Has\u0142o jest wymagane",
        "code_is_required": "Kod jest wymagany",
        "password_is_must_match": "Has\u0142o musi pasowa\u0107",
        "password_confirm_required": "Wymagane potwierdzenie has\u0142a",
        "email_address_is_required": "Adres e-mail jest wymagany",
        "name_is_required": "Nazwa jest wymagana",
        "username_is_required": "Nazwa u\u017cytkownika jest wymagana",
        "user_name_is_required": "Nazwa u\u017cytkownika jest wymagana",
        "phone_number_is_required": "numer telefonu jest wymagany",
        "user_type_is_required": "Typ u\u017cytkownika jest wymagany",
        "site_is_required": "Witryna \/Strona jest wymagana",
        "message_is_required": "Wiadomo\u015b\u0107 jest wymagana",
        "product_is_required": "Produkt jest wymagany",
        "discount_is_required": "Rabat jest wymagany",
        "start_date_is_required": "Data rozpocz\u0119cia jest wymagana",
        "expiry_date_is_required": "Data wa\u017cno\u015bci jest wymagana",
        "expiry_date_min": "Data wa\u017cno\u015bci nie mo\u017ce by\u0107 p\u00f3\u017aniejsza ni\u017c data bie\u017c\u0105ca",
        "call_to_action_required": "Wezwanie do dzia\u0142ania jest wymagane",
        "link_to_action_required": "Link jest wymagany",
        "site_name_is_required": "Nazwa strony jest wymagana",
        "description_is_required": "Opis jest wymagany",
        "total_spaces_available_is_required": "Ca\u0142kowite dost\u0119pne przestrzenie s\u0105 wymagane",
        "site_currency_is_required": "Waluta strony jest wymagana",
        "supplier_is_required": "Dostawca jest wymagany",
        "longitude_is_required": "D\u0142ugo\u015b\u0107 geograficzna jest wymagana",
        "latitude_is_required": "Wymagana jest szeroko\u015b\u0107 geograficzna",
        "address_is_required": "1 wiersz Adres jest wymagany",
        "address_line_2_is_required": "2 linia Adres jest wymagany",
        "city_town_is_required": "Miasto\/Miasto jest wymagane",
        "country_is_required": "Kraj jest wymagany",
        "county_is_required": "Powiat jest wymagany",
        "post_code_is_required": "Wymagany jest kod pocztowy",
        "parking_price_is_required": "Wymagana jest cena za parking",
        "parking_price_period_value_is_required": "Warto\u015b\u0107 okresu jest wymagana",
        "parking_price_per_period_type_is_required": "Wymagana jest warto\u015b\u0107 za okres",
        "parking_price_up_to_value_is_required": "Do warto\u015bci jest wymagane",
        "parking_price_up_to_period_is_required": "Do okresu jest wymagane",
        "category_is_required": "Kategoria jest wymagana",
        "price_is_required": "Wymagana jest cena",
        "commission_rate_type_is_required": "Wymagany jest typ stawki prowizji",
        "commission_value_is_required": "Wymagana jest stawka prowizji",
        "vehicle_registration_number_is_required": "Wymagany jest numer rejestracji pojazdu",
        "primary_contact_name_is_required": "Nazwa kontaktu jest wymagana",
        "contact_name_is_required": "Nazwa kontaktu jest wymagana",
        "account_status_is_required": "Wymagany jest status konta",
        "account_number_is_required": "Numer konta jest wymagany",
        "currency_is_required": "Wymagana jest waluta",
        "account_type_is_required": "Typ konta jest wymagany",
        "vat_registration_number_required": "Wymagany jest numer rejestracyjny VAT",
        "client_credit_limit_required": "Limit kredytowy jest wymagany",
        "client_payment_terms_required": "Wymagany jest termin p\u0142atno\u015bci",
        "client_payment_terms_days_required": "Wymagane dni terminu p\u0142atno\u015bci",
        "expire_date_is_required": "Wymagana jest data wyga\u015bni\u0119cia",
        "security_code_is_required": "Kod bezpiecze\u0144stwa jest wymagany",
        "card_number_is_required": "Numer karty jest wymagany",
        "invalid_user_email": "Nie znaleziono u\u017cytkownika z tym e -mailem",
        "account_number_required": "Numer konta jest wymagany",
        "order_id_is_required": "Wymagany jest identyfikator zam\u00f3wienia",
        "payment_date_is_required": "Data p\u0142atno\u015bci jest wymagana",
        "amount_is_required": "Wymagana jest kwota",
        "ccv_is_required": "Wymagane jest CCV",
        "discount_type_is_required": "Wymagany jest typ rabatowy",
        "company_is_required": "Klient jest wymagany",
        "parking_limit_reached": "To przekroczy Tw\u00f3j limit parkingowy ",
        "credit_limit_reached": "Spowoduje to przekroczenie limitu kredytowego konta",
        "invalid_card_number": "Numer karty jest nieprawid\u0142owy",
        "invalid_expire": "Karta wygas\u0142a",
        "invalid_ccv": "Kod CCV jest nieprawid\u0142owy",
        "image_required": "Prosz\u0119 za\u0142adowa\u0107 zdj\u0119cie",
        "invalid_qr_code": "Zeskanuj prawid\u0142owy kod QR M-Park",
        "addons_required_without_parking": "Je\u015bli nie parkujesz, musisz wybra\u0107 przynajmniej jeden dodatek",
        "booking": {
            "invalid_instant_order_token": "Twoja sesja wygas\u0142a, prosimy o zeskanowanie kodu QR",
            "invalid_location": "Aby zeskanowa\u0107 kod QR, musisz by\u0107 na miejscu"
        },
        "not_enough_licences": "Potrzebujesz wystarczaj\u0105cej liczby licencji zgodno\u015bci dla wszystkich kierowc\u00f3w w tej flocie"
    },
    "formats": {
        "date": "Zr\u00f3b mmm yy",
        "datetime": "Zr\u00f3b mmm yy hh: mm",
        "short_datetime": "Dd\/mm\/rr hh: mm"
    },
    "free": "wolny ",
    "from": "Z",
    "full_period": "Pe\u0142ny okres",
    "get_directions": "Uzyskaj wskaz\u00f3wki",
    "global_commission": "Globalne prowizje",
    "go_to_booking": "Id\u017a do rezerwacji",
    "go_to_clients": "Id\u017a do klient\u00f3w",
    "go_to_dashboard": "Przejd\u017a do panelu",
    "go_to_fleet": "Id\u017a do floty",
    "go_to_fleets": "Id\u017a do flot",
    "go_to_promotions": "Id\u017a do promocji",
    "go_to_sites": "Id\u017a do stron",
    "go_to_suppliers": "Id\u017a do dostawc\u00f3w",
    "go_to_users": "Przejd\u017a do u\u017cytkownik\u00f3w",
    "go_to_vehicles": "Id\u017a do pojazd\u00f3w",
    "got_to_settings": "Przejd\u017a do ustawie\u0144",
    "gr_code_location_error": "Nie uda\u0142o nam si\u0119 uzyska\u0107 Twojej lokalizacji. W\u0142\u0105cz us\u0142ug\u0119 lokalizacyjn\u0105, aby m\u00f3c zeskanowa\u0107 kod QR",
    "grand_total": "\u0141\u0105czna suma",
    "help_message_receive": "Dzi\u0119kujemy za skontaktowanie si\u0119 z nami, Twoja wiadomo\u015b\u0107 zosta\u0142a przyj\u0119ta. ",
    "home": "Strona g\u0142\u00f3wna",
    "hour": "Godzina",
    "id": "ID",
    "images": "Obrazy",
    "import": "Import",
    "import_clients": "Importowa\u0107 klient\u00f3w",
    "import_drivers": "Importowa\u0107 kierowc\u00f3w",
    "import_fleets": "Importowa\u0107 floty",
    "import_payments": "importuje wyp\u0142aty",
    "import_payouts": "importowa\u0107 wyp\u0142aty",
    "import_promotions": "importowa\u0107 pormocje",
    "import_sites": "importowa\u0107 strony",
    "import_suppliers": "importowa\u0107 dostawc\u00f3w",
    "import_users": "Importowa\u0107 u\u017cytkownik\u00f3w",
    "import_vehicles": "Importowa\u0107 pojazdy",
    "inactive": "Nieaktywny",
    "incl_vat": "w tym faktura VAT",
    "instant_order": "Rezerwuj teraz",
    "journey_time": "Czas podr\u00f3\u017cy",
    "last_name": "Nazwisko\"",
    "last_ten_orders": "10 ostatnich zam\u00f3wie\u0144",
    "last_updated": "Ostatnia aktualizacja: {{lastUpdated}}",
    "latitude": "Szeroko\u015b\u0107 geograficzna",
    "leave_feedback": "Wystaw opini\u0119",
    "link": "Link",
    "list": "Lista",
    "list_of_site_names_separated_by_comma": "Lista nazw stron oddzielonych przecinkami.",
    "list_of_vehicles_registration_numbers_separated_by_comma": "Lista numer\u00f3w rejestracyjnych pojazd\u00f3w oddzielona przecinkiem. jest wymagane, je\u015bli typem u\u017cytkownika jest kierowca",
    "list_of_vehicles_registration_numbers_separated_by_comma_driver": "Lista numer\u00f3w rejestracyjnych pojazd\u00f3w oddzielonych przecinkiem.",
    "loading": "\u0141adowanie...",
    "location": "Lokalizacja",
    "login": "Zaloguj si\u0119",
    "logout": "Wyloguj",
    "longitude": "D\u0142ugo\u015b\u0107 geograficzna",
    "longitude_latitude": "Szeroko\u015b\u0107 i d\u0142ugo\u015b\u0107 geograficzna",
    "manager": "Mened\u017cer",
    "marketing": {
        "title": "Marketing",
        "directory": "Katalog",
        "page": "Poka\u017c stron\u0119",
        "active": "Aktywny",
        "description": "Mo\u017cliwo\u015b\u0107 utworzenia publicznej strony internetowej, aby zaprezentowa\u0107 swoj\u0105 witryn\u0119",
        "edit_mode": "Tryb edycji",
        "options": "Opcje marketingowe",
        "licence_price": "Cena licencji",
        "switch_sides": "Zamie\u0144 strony",
        "colours": {
            "change": "Zmie\u0144 kolory",
            "primary": "Podstawowy",
            "secondary": "Dodatkowy"
        },
        "button": {
            "click_to_edit": "Kliknij, aby edytowa\u0107",
            "edit": "Przycisk Edytuj",
            "title": "Nazwa",
            "href": "Link",
            "target": "Cel",
            "targets": {
                "_self": "Bie\u017c\u0105ca zak\u0142adka",
                "_blank": "Nowa zak\u0142adka"
            },
            "variant": "Styl"
        },
        "blocks": {
            "Addons": "Dodatki",
            "CallToAction": "Wezwanie do dzia\u0142ania",
            "Details": "Szczeg\u00f3\u0142y",
            "Gallery": "Galeria",
            "MediaWithContent": "Obraz z zawarto\u015bci\u0105",
            "Pricing": "Cennik",
            "Services": "Us\u0142ugi"
        }
    },
    "max_journey_duration": "Maksymalny czas podr\u00f3\u017cy",
    "media_selector": {
        "title": "Wybierz obraz",
        "save": "Zapisz",
        "upload": "Za\u0142aduj"
    },
    "message": "Wiadomo\u015b\u0107",
    "messages": {
        "user_updated": "Zaktualizowano u\u017cytkownika",
        "user_created": "Utworzony przez u\u017cytkownika"
    },
    "month": "Miesi\u0105c",
    "monthly": "Miesi\u0119cznie",
    "monthly_bill": "Rozliczenie miesi\u0119czne",
    "more_information_about_the_site": "Wi\u0119cej informacji o witrynie",
    "more_than_500_spaces": "Ponad 500 miejsc",
    "more_than_hours": "Wi\u0119cej ni\u017c {{hours}} godziny",
    "more_than_two_hours": "ponad 2 godziny",
    "mpark_credit": "Kredyt M-Park",
    "mpark_credit_account": "Kredyt M-Park",
    "mpark_revenue": "Przychody M-Park",
    "n_days": "1 dzie\u0144",
    "n_days_plural": "{{count}} dni",
    "n_have_now_been_completed": "{{n}} zosta\u0142 uko\u0144czony\"",
    "n_hours": "1 godzina",
    "n_hours_plural": "{{count}} godziny",
    "na": "nie dotyczy",
    "name": "Nazwa",
    "name_on_the_card": "Nazwisko na karcie",
    "net_amount": "Kwota netto",
    "new_client": "Dodaj nowego klienta",
    "new_fleet": "Dodaj now\u0105 flot\u0119",
    "new_promotion": "Dodaj promocj\u0119",
    "new_site": "Dodaj now\u0105 stron\u0119",
    "new_supplier": "Dodaj nowego dostawc\u0119",
    "new_user": "Nowy u\u017cytkownik",
    "new_vat_rate": "Nowa stawka VAT",
    "new_vehicle": "Dodaj nowy pojazd",
    "no": "Nie",
    "no_data": "Brak danych",
    "no_new_notification": "Brak nowego powiadomienia",
    "no_orders_available": "Brak dost\u0119pnych zam\u00f3wie\u0144",
    "no_parking_prices_defined": "Na tej stronie nie okre\u015blono cen parking\u00f3w",
    "no_payment_method_found": "Nie znaleziono metody p\u0142atno\u015bci",
    "no_report_data": "Brak danych dotycz\u0105cych raportu",
    "no_sites_found_with_selected_filters": "Brak witryn z wybranymi filtrami",
    "none": "Brak",
    "north": "P\u00f3\u0142noc",
    "north_east": "P\u00f3\u0142nocny Wsch\u00f3d",
    "north_east_short": "PN-WSCH",
    "north_short": "PN",
    "north_west": "p\u00f3\u0142nocny zach\u00f3d",
    "north_west_short": "PN-ZACH",
    "not_payouts_found_for_today": "Nie znaleziono wyp\u0142at na dzi\u015b",
    "notes": "Notatki",
    "notifications": "Powiadomienia",
    "notifications_types": {
        "BookingConfirmed": {
            "header": "Rezerwacja zatwierdzona ",
            "body": "Twoja rezerwacja #{{bookingNumber}} zosta\u0142a zatwierdzona  {{siteName}} dla {{bookingTime}}"
        },
        "BookingRejected": {
            "header": "Rezerwacja odrzucona",
            "body": "Twoja rezerwacja #{{bookingNumber}} zosta\u0142a odrzucona na  {{siteName}}. Zarezerwuj ponownie lub skontaktuj si\u0119 z nami"
        },
        "BookingCheckedIn": {
            "header": "Zakwaterowany\"",
            "body": "Zameldowa\u0142e\u015b sie {{siteName}} na swojej rezerwacji #{{bookingNumber}} na {{bookingTime}}"
        },
        "BookingCheckedOut": {
            "header": "Wyrejestrowany\"",
            "body": "Sprawdzi\u0142e\u015b {{siteName}} na swojej rezerwacji #{{bookingNumber}} na {{bookingTime}}"
        },
        "BookingRequested": {
            "header": "Nowa rezerwacja",
            "body": "Nowa rezerwacja #{{bookingNumber}} zosta\u0142a dokonana na {{siteName}} dla {{bookingTime}}"
        }
    },
    "notify": {
        "None": "Brak",
        "Inherit": "Domy\u015blny",
        "Instant": "Natychmiastowo",
        "Daily": "Raz dziennie",
        "Weekly": "Raz w tygodniu"
    },
    "notify_border_report": "Powiadomienia o zako\u0144czeniu raportu z przekroczenia granicy",
    "notify_crime_report": "Powiadomienia o zako\u0144czeniu raportu o przest\u0119pstwie",
    "notify_daily_report": "Powiadomienia o zako\u0144czeniu codziennej kontroli pojazd\u00f3w",
    "notify_default": "Domy\u015blne dla powiadomie\u0144",
    "notify_fault_daily_report": "Powiadomineia o drobnej usterce pojazdu",
    "notify_loading_report": "Powiadomienia o zako\u0144czeniu raportu o za\u0142adunku pojazdu",
    "notify_new_orders": "Nowe zam\u00f3wienia",
    "notify_orders_status": "Aktualizacje statusu zam\u00f3wienia",
    "notify_severe_daily_report": "Powiadomienie o powa\u017cnej usterce pojazdu",
    "notify_traffic_incident_report": "Powiadomienia o zako\u0144czeniu raportu z wypadku drogowego",
    "now": "Teraz",
    "number_of_bookings": "Liczba rezerwacji",
    "number_of_drivers": "Liczba kierowc\u00f3w",
    "number_of_licences": "Liczba licencji",
    "number_of_vehicles": "Liczba pojazd\u00f3w",
    "of_preposition": "{{item1}} z {{item2}}",
    "okay": "OK",
    "on_account": "Na koncie",
    "on_card": "Na karcie",
    "only_show_sales": "Pokazuj tylko witryny z promocjami?",
    "open_24_hours": "Otwarte 24 godziny",
    "options": "Opcje",
    "order_approved": "Zam\u00f3wienie zatwierdzone",
    "order_canceled": "Zam\u00f3wienie anulowane!",
    "order_declined": "Zam\u00f3wienie odrzucone",
    "order_has_been_updated": "Zam\u00f3wienie zosta\u0142o zaktualizowane",
    "order_id": "Identyfikator zam\u00f3wienia",
    "order_item_id": "Identyfikator pozycji",
    "order_items": "Zamawia\u0107 rzeczy",
    "order_received": "Zam\u00f3wienie przyj\u0119te",
    "order_status": {
        "pending": "W oczekiwaniu",
        "paid": "P\u0142atny",
        "approved": "Zatwierdzony",
        "completed": "Zako\u0144czony",
        "rejected": "Odrzucony",
        "started": "Rozpocz\u0119ty\u00a0",
        "canceled": "Anulowany",
        "cancelled": "Anulowany"
    },
    "order_success_waiting_approval": "Poczekaj, a\u017c {{siteName}} zatwierdzi Twoje zam\u00f3wienie. Mo\u017cesz opu\u015bci\u0107 t\u0119 stron\u0119, a my powiadomimy Ci\u0119, gdy b\u0119dziemy mieli aktualizacj\u0119.",
    "order_summary": "podsumowanie zamowienia",
    "order_summary_and_checkout": "Podsumowanie zam\u00f3wienia i kasa",
    "order_updated": "Zam\u00f3w zaktualizowane",
    "orders": {
        "recent_orders": "ostatnie zam\u00f3wienia",
        "view_all_orders": "Zobacz wszystkie zam\u00f3wienia",
        "order_reference": "Odniesienie do zam\u00f3wienia",
        "last_ten_orders": "Ostatnie 10 zam\u00f3wie\u0144"
    },
    "over_cap": "Ponad limit",
    "paid": "P\u0142atny",
    "parking_available": "Dost\u0119pny parking?",
    "parking_available_described": "Dost\u0119pny parking (T\/N)",
    "parking_booking_question": "Potrzebujesz parkingu?",
    "parking_hourly_price": "Cena godzinowa parkingu",
    "parking_price": "Cena parkingu",
    "parking_price_configuration_note": "Uwaga: u\u017cywana jest najni\u017csza maksymalna cena mieszcz\u0105ca si\u0119 w zakresie, a naliczany okres jest zaokr\u0105glany w g\u00f3r\u0119 do najbli\u017cszego \u201eza okres\u201d",
    "parking_prices": "Ceny parkingowe (bez VAT)",
    "parking_prices_vat": "Ceny parkingowe (z VAT)",
    "parking_site_name": "Nazwa parkingu",
    "parking_site_website": "Witryna parkingu",
    "parking_specific": "Szczeg\u00f3\u0142y parkingu",
    "parking_with_trailer": "Czy potrzebujesz miejsca na przyczep\u0119?",
    "password": "Has\u0142o",
    "password_is_reset": "Twoje has\u0142o jest resetowane",
    "payment": "p\u0142atno\u015b\u0107",
    "payment_date": "Termin p\u0142atno\u015bci",
    "payment_details": "Szczeg\u00f3\u0142y p\u0142atno\u015bci",
    "payment_method": "Metoda p\u0142atno\u015bci",
    "payment_methods": "Metody P\u0142atno\u015bci",
    "payment_terms_days": "Dni p\u0142atno\u015bci klienta",
    "payments_imported": "P\u0142atno\u015bci importowane",
    "payout": "Wyp\u0142ata",
    "payout_saved": "Wyp\u0142ata zapisana!",
    "payouts_imported": "Wyp\u0142aty importowane",
    "percentage": "odsetki",
    "period_per": "okres (przez)",
    "period_value": "Warto\u015b\u0107 okresu",
    "person_n": "Osoba\"",
    "phone_number": "Numer telefonu",
    "place_order": "Z\u0142o\u017cy\u0107 zam\u00f3wienie",
    "please_enter_a_valid_code": "Wprowad\u017a prawid\u0142owy kod",
    "please_set_up_a_valid_payment_method": "Ustaw aktualn\u0105 metod\u0119 p\u0142atno\u015bci",
    "please_set_up_a_valid_payment_method_for_your_fleet": "Skontaktuj si\u0119 z mened\u017cerem, aby doda\u0107 wa\u017cn\u0105 metod\u0119 p\u0142atno\u015bci do swojej floty",
    "please_specify": "Prosz\u0119 poda\u0107",
    "please_wait": "Prosz\u0119 czeka\u0107...",
    "post_code": "Kod pocztowy",
    "postcode_town": "Kod pocztowy \/ miasto",
    "prebookable": "Mo\u017cliwo\u015b\u0107 wcze\u015bniejszej rezerwacji?",
    "prebookable_facility": "Mo\u017cliwo\u015b\u0107 wcze\u015bniejszej rezerwacji",
    "present_qr_code_for_payment": "Prosz\u0119 przedstawi\u0107 kod QR w trakcie p\u0142atno\u015bci.",
    "price": "cena",
    "price_per_twelve_hours": "{{price}} {{currency}} za 12 godzin",
    "price_range": "Przedzia\u0142 cenowy",
    "price_range_between": "na 12 godzin - {{min}} i {{max}}",
    "primary_contact": "Kontakt podstawowy",
    "print": "Drukuj",
    "print_report": "Raport PDF",
    "product": "Produkt",
    "product_category": "Kategoria produktu",
    "profile": "Profil",
    "promotion_cant_expire_before_it_is_started": "Promocja nie mo\u017ce wygasn\u0105\u0107 przed rozpocz\u0119ciem",
    "promotion_message": "Wiadomo\u015b\u0107 promocyjna",
    "promotions_available": "Dost\u0119pne promocje",
    "promotions_imported": "Importowane promocje",
    "provide_email_address_to_sent_reset_request": "Podaj sw\u00f3j adres e-mail poni\u017cej, aby poprosi\u0107 o zresetowanie kodu",
    "quantity": "Ilo\u015b\u0107",
    "question_n": "Pytanie {{count}}",
    "rate_base_currency": "Szybko\u015b\u0107 na walut\u0119 podstawow\u0105 ({{baseCurrency}})",
    "rebook": "Zarezerwuj ponownie",
    "receive_marketing_emails": "Otrzymuj e-maile marketingowe?",
    "record_deleted": "Rekord zosta\u0142 zarchiwizowany",
    "reference": "Odno\u015bnik",
    "region": "Region",
    "region_country": "Region (kraj)",
    "region_specific": "Specyficzne dla regionu",
    "region_spending_cap_instructions": "je\u015bli okre\u015blono jakiekolwiek limity region\u00f3w, zam\u00f3wienia dla stron poza okre\u015blonym regionem nie b\u0119d\u0105 uwzgl\u0119dniane",
    "registered_address": "Adres siedziby firmy",
    "registration": "Rejestracja",
    "reject": "Odrzu\u0107",
    "remaining": "({{figure}} x pozosta\u0142y z {{{period}} przydzia\u0142u)",
    "remove_all_filters": "Usu\u0144 wszystkie filtry",
    "remove_favourite": "Usu\u0144 jako ulubiony",
    "remove_person": "Usu\u0144 osob\u0119",
    "renew_date": "Data odnowienia",
    "repeat_password": "Powt\u00f3rz has\u0142o",
    "reported_by": "Zg\u0142oszone przez",
    "request_order": "Pro\u015bba o rezerwacj\u0119",
    "reset": "Resetowanie",
    "reset_link_sent": "Link resetowania has\u0142a jest wysy\u0142any",
    "reset_parking_pricing_for_this_site_client": "Zresetuj ceny parkingowe dla tej witryny i klienta",
    "reset_password": "Zresetuj has\u0142o",
    "reset_to_default_pricing": "Przywr\u00f3\u0107 ceny domy\u015blne",
    "role": "Rola",
    "roles": {
        "platform_admin": "Platforma globalna",
        "platform_finance": "Finansowanie platformy",
        "supplier_admin": "Mened\u017cer dostawcy",
        "site_manager": "Mened\u017cer witryny",
        "fleet_manager": "Menad\u017cer floty",
        "operator": "Operator witryny",
        "client_admin": "Mened\u017cer Klienta",
        "driver": "Kierowca",
        "client_operator": "Operator klienta",
        "supplier_operator": "Operator dostawcy",
        "platform_operator": "Operator platformy"
    },
    "save": "Zapisz",
    "save_client_pricing": "Zapisz ceny klienta",
    "save_new_client": "Zapisz klienta",
    "save_new_fleet": "Zapisz flot\u0119",
    "save_new_promotion": "Zapisz promocj\u0119",
    "save_new_site": "Zapisz stron\u0119",
    "save_new_supplier": "Zapisz dostawc\u0119",
    "save_new_user": "zapisz nowego u\u017cytkownika",
    "save_new_vehicle": "Zapisz pojazd",
    "save_payment_method": "Zapisz metod\u0119 p\u0142atno\u015bci",
    "save_payout": "Zapisz wyp\u0142at\u0119",
    "search": "Szukaj",
    "search_for_anything": "Szukaj czegokolwiek",
    "search_placeholder": "Szukaj...",
    "second_line_address": "Adres wiersz 2",
    "security_facilities": "Obiekty bezpiecze\u0144stwa",
    "security_rating": "Ocena bezpiecze\u0144stwa",
    "security_rating_described": "Ocena bezpiecze\u0144stwa TAPA (1-5)",
    "select_vehicle": "Wybierz sw\u00f3j pojazd, aby rozpocz\u0105\u0107...",
    "send_email": "Wys\u0142a\u0107 email",
    "service": "Us\u0142uga",
    "share_app_on_social_media": "Udost\u0119pnij aplikacj\u0119 znajomym  w mediach spo\u0142eczno\u015bciowych",
    "share_location": "Udost\u0119pnij lokalizacj\u0119?",
    "show_all": "Poka\u017c wszystko",
    "show_only": "Poka\u017c tylko {{subject}}",
    "show_order_details": "Poka\u017c szczeg\u00f3\u0142y zam\u00f3wienia",
    "show_qr_code": "Poka\u017c kod QR",
    "sign_up": "Zarejestruj si\u0119\"",
    "site": "Strona",
    "site_created": "Witryna utworzona",
    "site_currency": "Waluta witryny",
    "site_description": "Opis strony",
    "site_feedback": "Opinia o stronie",
    "site_feedback_sent": "Opinia o witrynie zosta\u0142a wys\u0142ana!",
    "site_fees": "Op\u0142aty za miejsce",
    "site_id": "Identyfikator witryny",
    "site_name": "Nazwa strony",
    "site_pricing": "Cennik witryny",
    "site_size": "Rozmiar strony",
    "site_specific": "Specyficzne dla witryny",
    "site_spending_cap_instructions": "Na okre\u015blonych stronach b\u0119d\u0105 ograniczone",
    "site_updated": "Zaktualizowano witryn\u0119",
    "sites": "STRONY",
    "sites_found_with_current_filters": "{{sites}} Znalezowane za pomoc\u0105 bie\u017c\u0105cego filtra",
    "sites_imported": "Strony importowane",
    "south": "Po\u0142udnie",
    "south_east": "Po\u0142udniowo -wsch\u00f3d",
    "south_east_short": "PD-WSCH",
    "south_short": "PD",
    "south_west": "Po\u0142udniowy zach\u00f3d",
    "south_west_short": "PD-ZACH",
    "spaces_available": "\u0141\u0105czna ilo\u015b\u0107 dost\u0119pnych miejsc",
    "spaces_available_display": "{{spaces}} Dost\u0119pne miejsca",
    "spending_cap": "Limit wydatk\u00f3w",
    "spending_caps": "Wydatki\"",
    "spending_caps_addon_not_defined_warning": "*Dodatki nie zdefiniowane na tych zakresach wydatk\u00f3w nie b\u0119d\u0105 obj\u0119te przez firm\u0119",
    "start_date": "Data rozpocz\u0119cia",
    "state_code": "Kod stanu",
    "state_code_placeholder": "SO-3166-2 i.e. GB-ENG symbol kodu pa\u0144stwa",
    "status": "Status",
    "steps": "({{current}} of {{steps}})",
    "stopped": "Zatrzymany",
    "submit": "potwierd\u017a",
    "subtotal": "Suma cz\u0105stkowa",
    "success_dialog": {
        "success": "Powodzenie!",
        "error": "B\u0142\u0105d!",
        "fleet_setting_updated": "Ustawienia floty zosta\u0142y zaktualizowane",
        "driver_setting_updated": "Ustawienia zosta\u0142y zaktualizowane",
        "site_suggestion_sent": "Wys\u0142ana sugestia witryny",
        "client_created": "Klient utworzony!",
        "client_updated": "Klient zaktualizowany!",
        "fleet_created": "Flota stworzona!",
        "fleet_updated": "Flota zaktualizowana!",
        "vehicle_created": "Pojazd stworzony!",
        "vehicle_updated": "Zaktualizowany pojazd!",
        "supplier_created": "Utworzony dostawca!",
        "supplier_updated": "Dostawca zaktualizowany!",
        "promotion_created": "Promocja stworzona!",
        "promotion_updated": "Zaktualizowano promocj\u0119!"
    },
    "suggest_a_parking_site": "Zaproponuj miejsce parkingowe",
    "summary": "Podsumowanie",
    "supplier": "Dostawca",
    "supplier_payment_terms": "Warunki p\u0142atno\u015bci dostawcy",
    "supplier_payment_terms_days": "Warunki p\u0142atno\u015bci dostawcy w dniach",
    "supplier_site": "Dostawca | Strona",
    "suppliers_imported": "Dostawcy importowani",
    "switch_to_list_view": "Prze\u0142\u0105cz na widok listy",
    "switch_to_map_view": "Prze\u0142\u0105cz na widok mapy",
    "take_a_photo": "Zr\u00f3b zdj\u0119cie",
    "tapa_rating": "Ocena TAPA",
    "tapa_rating_display": "{{rating}} OCENA TAPA",
    "terms_condition": "Zasady i warunki",
    "this_order_has_been_completed_on": "To zam\u00f3wienie zosta\u0142o zako\u0144czone o {{time}}",
    "this_order_has_been_rejected": "To zam\u00f3wienie zosta\u0142o odrzucone",
    "this_order_is_not_approved_yet": "To zam\u00f3wienie nie jest jeszcze zatwierdzone",
    "time": "Godzina",
    "title": "Nazwa",
    "to": "Do",
    "total": "Ca\u0142kowity",
    "total_addons": "Ca\u0142kowite dodatki",
    "total_inc_vat": "\u0141\u0105cznie (z VAT)",
    "total_parking": "Ca\u0142kowity parking",
    "total_revenue": "\u0141\u0105czne przychody",
    "total_spaces": "Miejsca og\u00f3\u0142em",
    "total_to_pay_on_card": "Suma do zap\u0142aty na karcie",
    "town_city": "Miasto\/miasto",
    "trailer": "Przyczepa",
    "transactions": "Transakcje",
    "truck_park": "Parking dla ci\u0119\u017car\u00f3wek",
    "twelve_hours": "12 godzin",
    "type": "Typ",
    "type_of_user": "Rodzaj u\u017cytkownika",
    "uncertified": "Niecertyfikowany",
    "unit_price_gbp": "Cena jednostkowa (GBP)",
    "unlimited": "Nieograniczony",
    "unpaid": "Nieop\u0142acony",
    "up_to_500_spaces": "Do 500 miejsc",
    "up_to_hours": "Do {{hours}} godziny",
    "up_to_one_hour": "Do 1 godziny",
    "up_to_two_hours": "do dw\u00f3ch godzin",
    "update": "Aktualizacja",
    "update_client": "Zaktualizuj klienta",
    "update_exchange_rates": "Zaktualizuj kursy wymiany walut",
    "update_fleet": "Zaktualizuj flot\u0119",
    "update_fleet_settings": "Zaktualizuj ustawienia floty",
    "update_promotion": "Aktualizacja promocji",
    "update_settings": "Ustawienia aktualizacji",
    "update_site": "Zaktualizuj witryn\u0119",
    "update_supplier": "Zaktualizuj dostawc\u0119",
    "update_user": "Zaktualizuj u\u017cytkownika",
    "update_vehicle": "Zaktualizuj pojazd",
    "upgrade": "Aktualizacja",
    "upload": "Wgrywa\u0107",
    "upload_a_photo": "Za\u0142aduj zdj\u0119cie",
    "upload_payments": "wczytaj p\u0142atno\u015bci",
    "upto": "a\u017c do",
    "upto_period": "Do okresu",
    "upto_value": "Do wysoko\u015bci warto\u015bci",
    "use_current_location": "Zastosuj obecn\u0105 lokalizacj\u0119",
    "use_fleet_defaults": "U\u017cyj domy\u015blnych ustawie\u0144 floty",
    "use_standard_price": "Zastosuj cen\u0119 standardow\u0105",
    "used": "U\u017cyty?",
    "user": "U\u017cytkownik",
    "username": "Nazwa u\u017cytkownika",
    "users_imported": "U\u017cytkownicy zaimportowani",
    "valid_fleet_name_that_is_created_for_this_driver_supplier": "Prawid\u0142owa nazwa floty powi\u0105zana z kontem klienta kierowcy",
    "vat": "VAT",
    "vat_rate": "Stawka VAT",
    "vat_rates": "Stawki podatku VAT",
    "vat_registration_number": "Numer rejestracyjny VAT",
    "vehicle": "Pojazd",
    "vehicle_registration_nr": "Numer rejestracyjny",
    "vehicle_registration_number": "Numer rejestracyjny",
    "vehicle_type": "Typ pojazdu",
    "vehicle_types": {
        "Forklift": "W\u00f3zek wid\u0142owy",
        "Van": "Van",
        "Truck": "Sol\u00f3wka",
        "TractorUnit": "Ci\u0105gnik siod\u0142owy z naczep\u0105"
    },
    "vehicles": "Pojazdy",
    "vehicles_imported": "Pojazdy importowane",
    "view_all_bookings": "Zobacz wszystkie rezerwacje",
    "view_all_sites": "Zobacz wszystkie strony",
    "view_all_users": "Zobacz wszystkich u\u017cytkownik\u00f3w",
    "view_details": "Poka\u017c szczeg\u00f3\u0142y",
    "view_order": "Wy\u015bwietl zam\u00f3wienie",
    "waiting_approval_title": "Czekam na zatwierdzenie strony ....",
    "we_have_sent_a_security_code": "Wys\u0142ali\u015bmy kod bezpiecze\u0144stwa do {{email}}",
    "weekly": "Co tydzie\u0144",
    "west": "Zach\u00f3d",
    "west_short": "ZACH",
    "year": "Rok",
    "yearly": "Rocznie",
    "yes": "tak",
    "you_have_been_checked_in": "Zosta\u0142e\u015b odprawiony ",
    "you_have_been_checked_out": "Zosta\u0142e\u015b wyrejestrowany ",
    "you_have_checked_in": "Odprawi\u0142e\u015b si\u0119 {{siteName}} na swoim  zam\u00f3wieniu #{{orderId}} w {{bookingTime}}",
    "you_have_checked_out": "Sprawdzi\u0142e\u015b  {{siteName}} swoje zam\u00f3wienie #{{bookingNumber}} w {{bookingTime}}",
    "you_have_not_selected_any_site_as_favourite": "Nie wybra\u0142e\u015b \u017cadnej witryny jako ulubionej",
    "your_account_is_current_on_stop": "Twoje konto jest obecnie zablokowane. Skontaktuj si\u0119 z mened\u017cerem.",
    "your_booking_has_been_extended": "Rezerwacja zosta\u0142a rozszerzona na {{expiredate}}",
    "your_booking_is_ready": "Twoja rezerwacja jest gotowa",
    "your_order_has_been_approved": "Twoje zam\u00f3wienie #{{orderId}} zosta\u0142o zatwierdzone na {{siteName}} dla {{bookingTime}}",
    "your_order_has_been_declined": "Twoje zam\u00f3wienie #{{orderId}} zosta\u0142o odrzucone w {{siteName}}. Popro\u015b o ponown\u0105 rezerwacj\u0119 lub skontaktuj si\u0119 z nami",
    "your_order_is_successful_show_qr_code": "Twoja rezerwacja zosta\u0142a zatwierdzona, poka\u017c kod QR na {{siteName}} do przetworzenia",
    "your_parking_period_expires_on": "Tw\u00f3j p\u0142atny okres parkowania wygasa {{expireDate}}"
}


