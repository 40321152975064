// Hook (use-auth.js)
import React, {createContext, useContext, useEffect, useCallback, useState, useRef} from "react";
import { useRollbarPerson, useRollbar } from '@rollbar/react';
import api, { setToken as setApiToken, setHandler,
    postRequest,
    getRequest,
    putRequest,
    deleteRequest } from '../services/Api';
import {useTranslation}             from "react-i18next";
import Pusher from "pusher-js";
import Push from 'push.js';
import Storage from './Storage';
import useMoment from "../hooks/useMoment";

const pushKey = process.env.REACT_APP_PUSHER_KEY;
const pusherConfig = {};

if (process.env.REACT_APP_PUSHER_HOST) {
    pusherConfig.wsHost = process.env.REACT_APP_PUSHER_HOST;
}

if (process.env.REACT_APP_PUSHER_CLUSTER) {
    pusherConfig.cluster = process.env.REACT_APP_PUSHER_CLUSTER;
}

if (process.env.REACT_APP_PUSHER_PORT) {
    pusherConfig.wssPort = process.env.REACT_APP_PUSHER_PORT;
}

if (process.env.REACT_APP_PUSHER_AUTH_ENDPOINT) {
    pusherConfig.authEndpoint = process.env.REACT_APP_PUSHER_AUTH_ENDPOINT;
}


const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({children}) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const rollbar = useRollbar();
    const {t, i18n} = useTranslation();

    const {momentLocal} = useMoment()

    let baseUrl = process.env.REACT_APP_API;

    const [token, setToken]       = useState( undefined );
    const [user, setUser]         = useState( undefined );
    const [roles, setRoles]       = useState( undefined );
    const [language, setLanguage] = useState( undefined );

    const [pushedNotifications, setPushedNotifications] = useState([]);
    const [complianceFigures, setComplianceFigures] = useState({});
    const [notifications, setNotifications] = useState(null);
    const [tokenChecked, setTokenChecked] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [accountActive, setAccountActive] = useState(true);
    // Wrap any Firebase methods we want to use making sure ...
    // ... to save the user to state.
    const pushedNotificationRef = useRef();
    const dismissedNotificationRef = useRef();

    const [pushGranted, setPushGranted] = useState(null);
    const [socket, setSocket] = useState(null);
    const [userChannel, setUserChannel] = useState(null);

    const [compliance_licence_price, setComplianceLicencePrice] = useState(false);
    const [marketing_licence_price, setMarketingLicencePrice] = useState(false);

    const updateUser = (user) => {
        setUser(user);
    }

    const updateUserAccountStatus = (status) => {
        setAccountActive(status);
    }

    const updateErrorPage = (errorPage) => {
        setErrorPage(errorPage);
    }

    const signIn = (payload) => {
        return new Promise((resolve, reject) => {
            api.post('/login', payload)
               .then(response => {
                   setToken(response.data.token);

                   setUser(response.data.user);
                   setRoles(response.data.roles);
                   setComplianceFigures(response.data.compliance);
                   setNotifications(response.data.notifications);
                   resolve(response)
               })
               .catch(error => {
                   reject(error);
               });
        })
    };
    const signUp = (payload) => {
        return new Promise((resolve, reject) => {
            api.post('/sign-up', payload)
               .then(response => {
                   setToken(response.data.token);

                   setUser(response.data.user);
                   setRoles(response.data.roles);
                   setNotifications(response.data.notifications);
                   setComplianceFigures(response.data.compliance);
                   resolve(response)
               })
               .catch(error => {
                   reject(error);
               });
        })
    };
    const requestResetPassword = (payload) => {
        return new Promise((resolve, reject) => {
            api.post('/forgot-password', payload)
               .then(response => {
                   resolve(true)
               })
               .catch(error => {
                   reject(error);
               });
        })
    };
    const resetPassword = (payload) => {
        return new Promise((resolve, reject) => {
            api.post('/reset-password', payload)
               .then(response => {
                   resolve(true)
               })
               .catch(error => {
                   reject(error);
               });
        })
    };
    const signOut = () => {
        sessionStorage.clear();
        return api.post('/logout')
                  .then(response => {
                      setToken(null);
                      setUser(null);
                      return user;
                  })
                  .catch(error => {

                  });
    };

    const handleApiErrorResponse = useCallback((error) => {
        if(error?.response)
        switch (error.response.status || 0) {
            case 401:
                setUser(null);
                setRoles(null);
                setToken(null);
                setTokenChecked(true);
                return;
            break;

            case 403:
                if(error.response.data && error.response.data.message === 'stopped')
                {
                    setAccountActive(false);
                }
                else
                {
                    setErrorPage(403);
                }
                return;
            break;

            case 422:
                return;
            break;

            case 0:
            case 404:
                setErrorPage(error.response.status || 0);
                return;
            break;

            default:
                setErrorPage(500);
        }

        rollbar.error(error);
        console.error(error);
    });

    const checkToken = useCallback(() => {
        return new Promise((resolve, reject) => {
            api.get('/handshake')
               .then(response => {
                   let data = response.data;
                   let notes = data.notifications.filter(_ => !!_);

                   setUser(data.user);
                   setRoles(data.roles);

                   if(!(notifications instanceof Array))
                   {
                     setPushedNotifications(notes.map(_ => _.id).concat(pushedNotifications));
                   }

                   setNotifications(notes);
                   setComplianceFigures(response.data.compliance);
                   setComplianceLicencePrice(response.data.compliance_licence_price);
                   setMarketingLicencePrice(response.data.marketing_licence_price);

                   setTokenChecked(true);

                   setAccountActive(data.user.linked_status && data.user.account_status);
                   resolve(response)
               })
               .catch(error => {
                   handleApiErrorResponse(error);
                   reject(error);
               });
        })
    }, [notifications]);

    const pushedNotification = useCallback((notification) => {

        if(notification)
        {
            notifications.unshift(notification);

            const newNotifications = [].concat(notifications);

            setNotifications(newNotifications.filter(_ => !!_));
        }
    }, [notifications]);

    const dismissedNotification = useCallback((notification) => {
        if(notification)
        {
            const newNotifications = [].concat(notifications);

            setNotifications(newNotifications.filter(_ => !!_ && _.id != notification.id));
        }
    }, [notifications]);

    const pushNotification = useCallback((notification) => {
        var data = {};

        if(notification?.booking)
        {
            data.bookingId       = notification.booking?.id;
            data.bookingNumber   = notification.booking?.booking_number;
            data.siteName    = notification.booking?.site?.name;
            data.bookingTime = notification.booking?.from_datetime && momentLocal(notification.booking?.from_datetime).format(t('formats.datetime'));
        }

        Push.create(
            t(`notifications_types.${notification.type}.header`, data),
            {
                body: t(`notifications_types.${notification.type}.body`, data),
                tag: notification.id,
                requireInteraction: true,
                onClick           : function () {
                    window.focus();
                    this.close();
                }
            }
        );

        setPushedNotifications([notification.id].concat(pushedNotifications));
    }, [pushedNotifications]);

    const pushQueuedNotifications = useCallback(() => {
        const queue = notifications?.filter && notifications.filter(_ => !pushedNotifications.includes(_?.id)) || [];

        for(var index in queue)
        {
            pushNotification(queue[index]);
        }
    }, [notifications]);

    useEffect(() => {
        if(token !== undefined)
        {
            token ? Storage.Long.setItem('token', token) : Storage.Long.removeItem('token');

            checkToken();
        }
        else
        {
            Storage.Long.getItem('token')
                .then(value => setToken(value || null))
        }
    }, [token]);

    useEffect(() => {
        if(user !== undefined)
        {
            user ? Storage.Long.setItem('user', user) : Storage.Long.removeItem('user');
        }
        else
        {
            Storage.Long.getItem('user')
                .then(value => setUser(value || null))
        }
    }, [user]);

    useEffect(() => {
        if(roles !== undefined)
        {
            roles ? Storage.Long.setItem('roles', roles) : Storage.Long.removeItem('roles');
        }
        else
        {
            Storage.Long.getItem('roles')
                .then(value => setRoles(value || null))
        }
    }, [roles]);

    useEffect(() => {
        if(language !== undefined)
        {
            i18n.changeLanguage(language || 'en');

            language && language != 'en' ? Storage.Long.setItem('language', language) : Storage.Long.removeItem('language');
        }
        else
        {
            Storage.Long.getItem('language')
                .then(value => setLanguage(value || 'en'))
        }
    }, [language]);

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    useEffect(() => {
        setHandler(handleApiErrorResponse);

        pusherConfig.authorizer = ({ name: channel_name }) => ({
            authorize: (socket_id, callback) => {
                postRequest(pusherConfig.authEndpoint, { channel_name, socket_id })
                    .then((response) => callback(false, { auth: response.data?.auth }))
                    .catch(() => callback(true))
            }
        });

        const pusher = new Pusher(pushKey, pusherConfig);

        setSocket(pusher);

        pusher.connection.bind('connected', function () {
            api.defaults.headers.common['X-Socket-ID'] = pusher.connection.socket_id;
        })

        if (Push.Permission.DENIED === 'denied') {
            Push.Permission.request(() => {
                pushQueuedNotifications();
            }, () => {
                console.error('Cannot Push');
            });
        }
    }, []);


    useRollbarPerson(user);

    useEffect(() => {
        if(socket && user)
        {
            const channelName = `private-users.${user.id}`;

            const channel = socket.subscribe(channelName);

            setUserChannel(channel);

            return () => {setUserChannel(null); socket.unsubscribe(channelName); };
        }
    }, [socket, user])

    useEffect(() => {
        if(userChannel)
        {
            const event_name = 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated';
            const push = (notification) => pushedNotificationRef && pushedNotificationRef.current(notification);

            userChannel.bind(event_name, push)

            const channel = userChannel;

            return () => channel.unbind(event_name, push);
        }
    }, [userChannel])

    useEffect(() => {
        if(userChannel)
        {
            const event_name = 'notification-dismissed';
            const push = (notification) => dismissedNotificationRef && dismissedNotificationRef.current(notification);

            userChannel.bind(event_name, push)

            const channel = userChannel;

            return () => channel.unbind(event_name, push);
        }
    }, [userChannel])

    useEffect(() => {
        pushedNotificationRef.current = pushedNotification;
    }, [pushedNotification])

    useEffect(() => {
        dismissedNotificationRef.current = dismissedNotification;
    }, [dismissedNotification])

    useEffect( () => {
        pushQueuedNotifications();
    }, [notifications]);

    setApiToken(token);
    setHandler(handleApiErrorResponse);

    // Return the user object and auth methods
    return {
        user,
        roles,
        tokenChecked,
        complianceFigures,
        compliance_licence_price,
        marketing_licence_price,
        notifications,
        errorPage,
        baseUrl,
        accountActive,
        updateUser,
        language,
        setLanguage,
        signIn,
        signUp,
        signOut,
        checkToken,
        socket,
        userChannel,
        requestResetPassword,
        resetPassword,
        postRequest,
        getRequest,
        putRequest,
        deleteRequest,
        updateErrorPage,
    };
}
