import {useTranslation}                                         from "react-i18next";
import {useAuth}                                                from "@/services/Auth";
import {useParams}                                              from "react-router-dom";
import React, {useEffect, useState}                             from "react";
import LoadingPage                                              from "@/components/LoadingPage";
import {Button, Card, Col, Container, InputGroup, Row, Spinner} from "react-bootstrap";
import * as Yup                                                 from "yup";
import {Formik, Form, Field, ErrorMessage}                      from "formik";
import DatePicker                                               from "react-datepicker";
import PromotionForm                                            from "./components/PromotionForm";
import SuccessDialog                                            from "../../../components/Modals/SuccessDialog";
import { Helmet } from "react-helmet";

function PromotionCreate(props) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const getRequest = auth.getRequest;
    const postRequest = auth.postRequest;

    const [loading, setLoading] = useState(false);
    const [creatingPromotion, setCreatingPromotion] = useState(false);
    const [site, setSite] = useState('');

    const [sites, setSites] = useState([]);


    const [createSuccess, setCreateSuccess] = useState(false);
    const [createdPromotion, setCreatedPromotion] = useState('');

    let {id} = useParams();
    let {service} = useParams();

    const handleSubmit = (payload) => {
        setCreatingPromotion(true);
        console.log(payload);
        postRequest(`/promotions`, payload)
            .then(response => {
                setCreatingPromotion(false);
                if (response.data.message && response.data.message === "OK") {
                    setCreateSuccess(true)
                }
            })
            .catch(error => {
                setCreatingPromotion(false);
                console.log(error);
            })
    };


    const handleClose = () => {
        setCreateSuccess(false)
        props.history.push(`/promotions`)
    }

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('create')} / {t('drawer_link_titles.promotion')} - {t('app')}</title>
                </Helmet>

                <SuccessDialog
                    show={createSuccess}
                    message="success_dialog.promotion_created"
                    handleClose={handleClose}
                />

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h4 className="text-primary">{t('new_promotion')}</h4>
                </Card.Subtitle>
                <PromotionForm
                    createForSite={id}
                    initialValues={{
                        message        : '',
                        site_id        : id != null ? id : '',
                        site_service_id: service != null ? service : '',
                        discount       : '',
                        discount_type  : '',
                        start_at       : '',
                        expire_at      : '',
                    }}
                    loading={creatingPromotion}
                    buttonText="save_new_promotion"
                    serviceSent={service}
                    promotionSite={null}
                    handleSubmit={handleSubmit}
                />

            </Card>
        )
    )
}

export default PromotionCreate;
