import React, { useRef, useEffect, useState } from "react";
import { Row, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CameraIcon } from "@/components/icons";
import { useAuth } from "@/services";

const processImage = (file, imageCompression) => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const image = new Image();

    if (!url) {
      reject('unable to read file');
    }

    image.src = url;

    image.onload = function () {
      const dx = (imageCompression?.maxWidth || image.width) / image.width;
      const dy = (imageCompression?.maxHeight || image.height) / image.height;
      const d = Math.min(dx, dy);

      const canvas = document.createElement('canvas');
      const width = image.width * d;
      const height = image.height * d;

      canvas.width = width;
      canvas.height = height;

      canvas.getContext('2d').drawImage(image, 0, 0, width, height);

      canvas.toBlob(resolve, 'image/jpeg', 0.7);
    };

    image.onerror = function () {
      reject('unable to load image');
    };
  });
}

const Attachments = React.forwardRef(({
  onChange,
  onBlur,
  preUpload,
  defaultValue,
  value: propValue,
  name,
  onFileDrop,
  uploadText,
  className,
  isInvalid,
  imageCompression,
  editorRef: interfaceRef,
  ...props
}, forwardedRef) => {
  const auth = useAuth();

  const {t} = useTranslation();
  const base_uri = typeof preUpload == 'string' ? preUpload : '';

  const [attachments, setAttachments] = useState(undefined);

  const attachmentsRef = useRef(null);
  const attachmentsSetRef = useRef(null);

  const ref = useRef(null);

  const dummyInterface = useRef({
        type: 'textarea',
        name: null,

        get value() {
          return attachmentsRef.current;
        },

        set value(value) {
          attachmentsSetRef.current(value);
        },
      })

  useEffect(_ => {
    dummyInterface.current.name = name;
  }, [name])

  useEffect(_ => {
    if(typeof propValue != 'undefined')
    {
      dummyInterface.current.value = propValue;
    }
  }, [propValue]);

  useEffect(_ => {
    if(typeof defaultValue != 'undefined')
    {
      dummyInterface.current.value = defaultValue;
    }
  }, []);

  useEffect(_ => {
    if(typeof interfaceRef == 'function')
    {
      interfaceRef(dummyInterface.current);
    }
    else
    if(interfaceRef)
    {
      interfaceRef.current = dummyInterface.current;
    }
  }, []);


  useEffect(_ => {
    ref.current.addEventListener('drop', event => {
      var files = Array.from(event.dataTransfer.files);

      if(!event.isDefaultPrevented() && files.filter(_ => !/image\//.test(_.type)).length)
      {
        event.preventDefault();
        event.stopPropagation();

        onFileDrop && onFileDrop(files);
      }
    });
  }, [])

  const triggerChange = () => {
    const event = { isTrusted: false, type: 'change' };

    event.target = dummyInterface.current;

    onChange(event);
  };

  const refreshAttachments = () => {
    const _attachments = [].concat(attachmentsRef.current || []);

    attachmentsSetRef.current(_attachments);
    triggerChange();
  };

  const onFileUpload = async (event) => {
    const files = event.target.files;
    // get the image-compression property from the input field
    const _attachments = [].concat(attachmentsRef.current || []);

    for (var i = 0; i < files.length; i++) {
      var file = files[i];


      const attachment = {};

      attachment.name = file.name;
      attachment.mime_type = file.type;

      _attachments.push(attachment);

      const process = new Promise(async (resolve) => {
        file = await processImage(file, imageCompression).then(blob => new File([blob], file.name, { type: 'image/jpeg' }));

        const reader = new FileReader();

        reader.onload = () => {
          const dataUrl = reader.result;

          attachment.file_base64 = dataUrl.split('base64,')[1];

          if (preUpload) {
            auth.postRequest(base_uri + '/attachments', attachment)
              .then(response => {
                Object.assign(attachment, response.data.data);

                delete attachment.file_base64;

                resolve();
              });
          }
          else {
            attachment.url = dataUrl;
            resolve();
          }
        };

        reader.readAsDataURL(file);
      });

      process.then(_ => refreshAttachments());
    }

    attachmentsSetRef.current(_attachments);
    triggerChange();
    event.target.value = null;
  };

  attachmentsRef.current = attachments;
  attachmentsSetRef.current = setAttachments;

  const removeAttachment = (index) => {
    const _attachments = [].concat(attachmentsRef.current || []);

    _attachments.splice(index, 1);


    attachmentsSetRef.current(_attachments);
    triggerChange();
  }

  const _props = {
    ref: (element) => {
      ref.current = element;

      if (typeof forwardedRef == 'function') {
        forwardedRef(dummyInterface.current);
      }
      else
        if (forwardedRef) {
          forwardedRef.current = dummyInterface.current;
        }
    },
  };

  return (
    <div className={`attachments-input ${className || ''}`}  {..._props}>
      <Row className="g-3 p-3">
        {attachments?.map && attachments.map((attachment, i) => (
          <div key={i}>
            <div className="aspect-container attachment">
              {attachment.url && <img src={attachment.url}  alt={attachment.name}/> || <Spinner className="aspect-subject" />}

              <Button className="attachment-remove" size="sm" variant="dark" onClick={_ => removeAttachment(i)}>
                &times;
              </Button>
            </div>

          </div>
        ))}
      </Row>

      <label className="btn btn-success btn-sm">
        <input type="file" accept="image/*" multiple className="d-none" onChange={onFileUpload}

        />
        {uploadText || (
          <>
            <CameraIcon classDefined="me-2" />
            {t('take_a_photo')}
          </>
        )}
      </label>

    </div>
  )
});

export default Attachments;

