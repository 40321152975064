import Users            from "../../views/pages/users/Users";
import UserCreate       from "../../views/pages/users/UserCreate";
import UserSingle       from "../../views/pages/users/UserSingle";
import UserEdit         from "../../views/pages/users/UserEdit";
import UsersIcon        from "../../components/icons/UsersIcon";
import ImportUsers      from "../../views/pages/users/ImportUsers";

const usersRoutes = [
    {
        path         : "/users",
        component    : Users,
        exact        : true,
        gate         : 'view_users',
        title        : 'Users',
        translate_key: 'users',
        // icon         : 'bi-people',
        icon         : <UsersIcon/>,
        main         : true,
    },
    {
        path     : "/users/import",
        component: ImportUsers,
        exact    : true,
        gate     : 'view_users',
        main     : false,
    },
    {
        path     : "/users/create",
        component: UserCreate,
        exact    : true,
        gate     : 'view_users',
        main     : false,
        parent   : '/users'
    },
    {
        path     : "/users/create/:type/:id",
        component: UserCreate,
        exact    : true,
        gate     : 'view_users',
        main     : false,
        parent   : '/users'
    },
    {
        path     : "/:type/:id/users/create",
        component: UserCreate,
        exact    : true,
        gate     : 'view_users',
        main     : false,
        parent   : '/users'
    },
    {
        path     : "/users/:id",
        component: UserSingle,
        exact    : true,
        gate     : 'view_users',
        main     : false,
        parent   : '/users'
    },
    {
        path     : "/users/edit/:id",
        component: UserEdit,
        exact    : true,
        gate     : 'view_users',
        main     : false,
        parent   : '/users'
    },
    {
        path     : "/users/:type/:id",
        component: Users,
        exact    : true,
        gate     : 'view_users',
        main     : false,
    },
    {
        path     : "/:type/:id/users",
        component: Users,
        exact    : true,
        gate     : 'view_users',
        main     : false,
    },
    {
        path     : "/:type/:id/users/import",
        component: ImportUsers,
        exact    : true,
        gate     : 'view_users',
        main     : false,
    },
]

export default usersRoutes;
