import {Link, useParams}            from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAuth}                    from "@/services/Auth";
import {Card, Col, Row, Table, Button}      from "react-bootstrap";
import LoadingPage                  from "@/components/LoadingPage";
import {useTranslation}             from "react-i18next";
import * as moment                    from 'moment';
import { Helmet } from "react-helmet";
import OrderItems from "@/components/Tables/OrderItems";
import Vehicles from "@/components/Tables/Vehicles";
import ConfirmDialog from "@/components/Modals/ConformDialog";
import SuccessDialog from "@/components/Modals/SuccessDialog";

function UserSingle(props) {

    const {t, i18n} = useTranslation();

    let auth = useAuth();

    const getRequest = auth.getRequest;

    let {id} = useParams();

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [canUpdate, setCanUpdate] = useState(true);
    const [canDelete, setCanDelete] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const deleteUser = () => {

        setConfirmDelete(false);
        setLoading(true);
        auth.deleteRequest(`/users/${id}`)
            .then(response => {
                setLoading(false);
                setUser(null);
                setDeleteSuccess(true);
            })
            .catch(error => {
                setLoading(false);
            })
    }

    useEffect(() => {
        if(user === null)
        getRequest('/users/' + id, { include: ['entity_roles', 'sites', 'fleets', 'vehicles'] })
            .then(response => {
                setUser(response.data.user)
                setCanUpdate(response.data.can_update)
                setCanDelete(response.data.can_delete)
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })

    }, [user]);

    return (
        !user || loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{user?.deleted_at && t('archived') || ''} {user.name} / {t('drawer_link_titles.users')} - {t('app')}</title>
                </Helmet>

                <SuccessDialog
                    show={deleteSuccess}
                    message="record_deleted"
                    handleClose={_ => setDeleteSuccess(false)}
                />

                <ConfirmDialog
                    cancel='conform_dialog.cancel'
                    accept='conform_dialog.delete'
                    acceptAction={deleteUser}
                    show={confirmDelete}
                    handleClose={_ => setConfirmDelete(false)}
                />

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h3 className="text-primary">
                        {user?.deleted_at && t('archived') || ''} {user.name} {user.company != null ? ' - ' + user.company.name : (user.supplier != null? ' - ' + user.supplier.company_name : '')}
                    </h3>

                    <div>
                    {canDelete && <Button variant="danger" className="mx-1" onClick={() => setConfirmDelete(true)}>{t('delete')}</Button>}
                    {
                        canUpdate && <Link to={`/users/edit/${user.id}`} className="btn btn-primary">{t('edit')}</Link>
                    }
                    </div>
                </Card.Subtitle>
                <Row className="mt-5">
                    <Col md={8}>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-between mb-3">
                                    <h5>{t('orders.recent_orders')}</h5>
                                    <Link to={`/orders/user/${user.id}`} className="btn btn-primary">{t('orders.view_all_orders')}</Link>
                                </div>

                                <OrderItems url={`/users/${id}/order_items`} perPage={10} paginate={false} exclude={['user', 'fleet', 'account_number']} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table striped bordered hover className="mt-1">
                                    <thead>
                                    <tr>
                                        <th>{t('entity')}</th>
                                        <th>{t('role')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        user?.user_entity_role?.map && user.user_entity_role.map((entity, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><Link to={`/${entity.type}/${entity.type_id}`}>{entity.name}</Link></td>
                                                    <td>{entity.role_name}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        user?.entity_roles?.length === 0 &&
                                        <tr>
                                            <td colSpan={2} className="text-center">{t('no_data')}</td>
                                        </tr>
                                    }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        {
                            (user.primary_role === 8) &&
                            <Row>

                                <Col className="mt-4">
                                    <div className="d-flex justify-content-between">
                                        <h5>{t('drawer_link_titles.vehicles')}</h5>
                                    </div>

                                    <Vehicles url={`/users/${id}/vehicles`} exclude={['drivers', 'fleet', 'client']} />
                                </Col>
                            </Row>
                        }

                    </Col>
                    <Col md={4}>
                        <ol className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('name')}</div>
                                    {user.name}
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('email_address')}</div>
                                    {user.email}
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('phone_number')}</div>
                                    {user.phone_number ?? '-'}
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('type_of_user')}</div>
                                    {user.user_type}
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('account_status')}</div>
                                    {user.account_status}
                                </div>
                            </li>
                            {
                                user.supplier &&
                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className=" text-black-50">{t('supplier')}</div>
                                        <Link to={`/suppliers/${user.supplier.id}`}>{user.supplier.company_name}</Link>
                                    </div>
                                </li>
                            }
                            {
                                user.supplier &&
                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className=" text-black-50">{t('account_number')}</div>
                                        {user.supplier.account_number}
                                    </div>
                                </li>
                            }
                            {
                                user.company &&
                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className=" text-black-50">{t('client')}</div>
                                        <Link to={`/clients/${user.company.id}`}>{user.company.name}</Link>
                                    </div>
                                </li>
                            }
                            {
                                user.company &&
                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className=" text-black-50">{t('account_number')}</div>
                                        {user.company.account_number}
                                    </div>
                                </li>
                            }
                        </ol>
                    </Col>
                </Row>
            </Card>
        )
    );
}

export default UserSingle;
