import React, { useEffect, useState, useRef } from "react";
import Table, { useColumns } from "@/components/Tables/Reports";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Form, Badge, Row } from "react-bootstrap";
import LoadingPage from "@/components/LoadingPage";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "@/services/Auth";
import { Helmet } from "react-helmet";
import Filters from "@/components/Forms/Search";
import moment from 'moment-timezone';

function Journeys() {
  const history = useHistory();
  const route = useLocation();

  let auth = useAuth();
  const request = useRef(null);

  const { t } = useTranslation();
  const [records, setRecords] = useState(false);

  const [query, setQuery] = useState(route?.state?.query || {});

  const getRecords = () => {
    request?.current?.abort && request.current.abort();

    const critera = { ... (query || {}) };

    critera.type = 'JourneyReport';
    critera.take = 10;
    critera.include = 'detail';

    request.current = auth.getRequest('/reports', critera);

    setRecords(false);

    request.current
      .then(response => {
        setRecords(response.data.data);
      })
  }

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,

        query,
      }
    )
  }, [query]);


  useEffect(() => {
    if (records === false) {
      getRecords();
    }

    return () => request?.current?.abort && request.current.abort();
  }, [query]);


  // TODO
  return (

    <div className="mx-2 my-2 p-2">
      <Helmet>
        <title>{t('compliance.JourneyReport.title')} - {t('app')}</title>
      </Helmet>

      <h4 className="text-primary">{t('compliance.JourneyReport.title')}</h4>

      <Filters searching={!records} values={query} onSubmit={setQuery} />

      {records?.map && records.map((report, index) => (
        <div key={report?.created_at} >
          <Card className="bg-light">
            <Card.Body className="bg-none">
              <div className="d-flex flex-row justify-content-between">
                <Card.Title className="text-primary">{report?.detail?.name}</Card.Title>
                <Badge className={`p-2
                  ${report?.status === 'Incomplete' ? 'bg-primary' : ''}
                  ${report?.status === 'Pending' ? 'bg-warning' : ''}
                  ${report?.status === 'Complete' ? 'bg-success' : ''}
                  ${report?.status === 'Resolved' ? 'bg-info' : ''}
                  ${report?.status === 'Waiting' ? 'bg-dark' : ''}
                `}>{t(`compliance.report_status.${report?.status}`)}
                </Badge>
              </div>
              <div className="d-flex flex-column justify-content-evenly">
                <div><strong>Journey ID:</strong> {report?.detail?.report_id}</div>
                <div className="pt-2">{moment(report?.created_at).format(t('formats.full_date_and_time'))}</div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Link className="btn btn-success w-100" to={`/compliance/${report.guid}`}>
                {t('view_details')}
              </Link>
            </Card.Footer>
          </Card>
          {index < records.length - 1 && <hr />}
        </div>
      ))}
    </div>
  )
}

export default Journeys;
