import {useTranslation}                    from "react-i18next";
import {useAuth}                           from "@/services/Auth";
import React, {useEffect, useState}        from "react";
import LoadingPage                         from "@/components/LoadingPage";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Select                              from "react-select";
import {Button, Card, Spinner}             from "react-bootstrap";
import * as Yup                            from "yup";
import { Helmet } from "react-helmet";

function Help() {
    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const postRequest = auth.postRequest;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const validation = Yup.object().shape({
        name   : Yup.string()
                    .required(t('form_validation.name_is_required')),
        message: Yup.string()
                    .required(t('form_validation.message_is_required')),
        email  : Yup.string()
                    .email(t('form_validation.invalid_email_address'))
                    .required(t('form_validation.email_address_is_required')),
    });

    const handleSubmit = (values) => {
        setLoading(true);
        postRequest('/help', values)
            .then(response => {
                setLoading(false);
                if (response.data.message === 'OK') {
                    setSuccess(true);
                }

            })
            .catch(error => {
                setLoading(false);

            })
    }

    return (
        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{t('drawer_link_titles.need_help')} - {t('app')}</title>
            </Helmet>

            <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                <h3 className="text-primary">{t('drawer_link_titles.need_help')}</h3>
            </Card.Subtitle>
            {
                success ?
                <div className="alert alert-success" role="alert">
                    {t('help_message_receive')}
                </div> :
                <Formik
                    initialValues={{
                        name   : auth.user.name,
                        email  : auth.user.email,
                        message: '',
                    }}
                    validationSchema={validation}
                    onSubmit={handleSubmit}
                >
                    {({values, errors, touched}) => (
                        <Form>
                            <div className="form-group mt-4">
                                {/*<p>{JSON.stringify(errors)}</p>*/}

                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <label htmlFor="name" className="my-2">{t('name')}</label>
                                        <Field
                                            type="text"
                                            name="name"
                                            placeholder={t('enter_name')}
                                            className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                                        />
                                        <small>
                                            <ErrorMessage component="div" name="name" className="text-danger  py-1"/>
                                        </small>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label htmlFor="name" className="my-2">{t('email_address')}</label>
                                        <Field
                                            type="text"
                                            name="email"
                                            placeholder={t('enter_email_address')}
                                            className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                        />
                                        <small>
                                            <ErrorMessage component="div" name="email" className="text-danger  py-1"/>
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <label htmlFor="username" className="my-2">{t('message')}</label>
                                        <Field
                                            as="textarea"
                                            type="text"
                                            name="message"
                                            placeholder={t('enter_message')}
                                            className={`form-control ${errors.message && touched.message ? 'is-invalid' : ''}`}
                                        />
                                        <small>
                                            <ErrorMessage component="div" name="message" className="text-danger  py-1"/>
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mt-4">
                                <div className="d-grid gap-2">
                                    {!loading ?
                                     <Button type="submit" variant="primary">{t('submit')}</Button>
                                              :
                                     <Button variant="primary" disabled>
                                         <Spinner
                                             as="span"
                                             animation="border"
                                             size="sm"
                                             role="status"
                                             aria-hidden="true"
                                         />
                                         <span className="mx-2">{t('please_wait')}</span>
                                     </Button>
                                    }
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            }

        </Card>
    )
}

export default Help;
