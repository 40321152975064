function AlertIcon({ variant, classDefined }) {
  return (
    <div className={`custom-logo ${classDefined || ''}`}>
      <svg width="52" height="48" viewBox="0 0 52 48" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
        <path d="M51.749 45.0612L27.6075 0.979592C27.2477 0.32449 26.6268 0 26 0C25.3733 0 24.7465 0.32449 24.3925 0.979592L0.25101 45.0612C-0.462789 46.3714 0.43091 48 1.85851 48H50.1415C51.5691 48 52.4628 46.3714 51.749 45.0612ZM24.143 18.1224C24.143 17.8531 24.3519 17.6327 24.6072 17.6327H27.3928C27.6481 17.6327 27.857 17.8531 27.857 18.1224V29.3878C27.857 29.6571 27.6481 29.8776 27.3928 29.8776H24.6072C24.3519 29.8776 24.143 29.6571 24.143 29.3878V18.1224ZM26 39.6735C25.2711 39.6578 24.577 39.3413 24.0668 38.7918C23.5565 38.2424 23.2707 37.5039 23.2707 36.7347C23.2707 35.9655 23.5565 35.227 24.0668 34.6776C24.577 34.1281 25.2711 33.8116 26 33.7959C26.7289 33.8116 27.423 34.1281 27.9332 34.6776C28.4435 35.227 28.7293 35.9655 28.7293 36.7347C28.7293 37.5039 28.4435 38.2424 27.9332 38.7918C27.423 39.3413 26.7289 39.6578 26 39.6735Z" fill="white" style={{ fill: variant ? 'var(--bs-' + variant + ')' : 'white'}} />
      </svg>
    </div>
  );
}

export default AlertIcon;
