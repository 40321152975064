import { useAuth } from "@/services";
import { VehiclesIcon } from "@/components/icons";
import { Badge } from 'react-bootstrap';


function VehicleBadges()
{
  const auth = useAuth();

  return (
    <div className="badges">
      {auth?.complianceFigures?.severe && (
        <Badge bg="danger">{auth.complianceFigures.severe}</Badge>
      ) || ''}

      {auth?.complianceFigures?.minor && (
        <Badge bg="warning">{auth.complianceFigures.minor}</Badge>
      ) || ''}

      <VehiclesIcon classDefined="vehicle-icon"/>
    </div>
  );
}


export default VehicleBadges;
