function UsersIcon({classDefined}) {
    return (
        <div className={`custom-logo ${classDefined}`}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.25 4.73684C5.25 7.34842 7.38117 9.47368 10 9.47368C12.6188 9.47368 14.75 7.34842 14.75 4.73684C14.75 2.12526 12.6188 0 10 0C7.38117 0 5.25 2.12526 5.25 4.73684ZM18.4444 20H19.5V18.9474C19.5 14.8853 16.1845 11.5789 12.1111 11.5789H7.88889C3.81444 11.5789 0.5 14.8853 0.5 18.9474V20H18.4444Z" fill="white"/>
            </svg>
        </div>
    );
}

export default UsersIcon;
