import {useTranslation}             from "react-i18next";
import {useAuth}                    from "@/services/Auth";
import React, {useEffect, useState} from "react";
import {Link}                       from "react-router-dom";
import ToolkitProvider, {Search}    from "react-bootstrap-table2-toolkit";
import LoadingPage                  from "@/components/LoadingPage";
import {Col, Form, Row}             from "react-bootstrap";
import BootstrapTable               from "react-bootstrap-table-next";
import paginationFactory            from "react-bootstrap-table2-paginator";
import filterFactory                from "react-bootstrap-table2-filter";
import AsyncSelect                  from 'react-select/async';
import DatePicker                   from "react-datepicker";
import * as moment                  from "moment";
import OrderItemsTable              from "@/components/Tables/OrderItems";

var getRequest;

function FleetManagerDashboard() {

    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const [query, setQuery] = useState({});
    const [fleet, setFleet] = useState('');
    const [from_date, setStartDate] = useState(new Date());
    const [to_date, setEndDate] = useState(null);

    const formatRecord = (company) => {
        const  object = {...company};

        object.value = company.id;
        object.label = company.name;

        return object;
    };

    const getFleets = (search) => {
        getRequest && getRequest.abort && getRequest.abort();

        return new Promise((resolve, reject) => {
            getRequest = auth.getRequest('fleets', { search });

            getRequest.then(response => {
                    resolve(response.data.fleets.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    useEffect(() => {
        setQuery({
            fleet: fleet?.id,
            from_date,
            to_date
        });
    }, [fleet, from_date, to_date]);

    return (
            <div className="site-find-card position-relative">
                {
                    auth.user.is_client_admin &&
                    <h3 className="text-primary">{t('dashboard.company_dashboard')}</h3>
                }
                {
                    auth.user.is_fleet_manager &&
                    <h3 className="text-primary">{t('dashboard.fleet_manager_dashboard')}</h3>
                }
                {
                    auth.user.is_client_operator &&
                    <h3 className="text-primary">{t('dashboard.client_operator_dashboard')}</h3>
                }
                <Row>
                    <Col sm={6} md={4} lg={3} className="col-12 mt-2">

                        <AsyncSelect
                            className="react-select"
                            isClearable={true}
                            value={fleet}
                            onChange={(e) => setFleet(e)}
                            placeholder={t('fleet')}
                            defaultOptions={true}
                            loadOptions={getFleets}
                        />
                    </Col>
                    <Col sm={6} className="col-12 mt-2">
                        <Row className="my-md-0 my-2">
                            <Col className="col-12 d-flex align-items-center">
                                <Form.Control
                                    placeholder={t('from')}
                                    type="date"
                                    max={to_date ? moment(to_date).format('YYYY-MM-DD') : null}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <span className="mx-2">{t('to')}</span>
                                <Form.Control
                                    placeholder={t('from')}
                                    type="date"
                                    min={from_date ? moment(from_date).format('YYYY-MM-DD') : null}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="mt-2">
                    <OrderItemsTable query={query} />
                </div>
            </div>

    )


}

export default FleetManagerDashboard;
