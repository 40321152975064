import Orders             from "../../views/pages/orders/Orders";
import OrderSingle        from "../../views/pages/orders/OrderSingle";
import OrdersIcon         from "../../components/icons/OrdersIcon";
import UpdateExchangeRate from "../../views/pages/orders/UpdateExchangeRate";
import VatRates from "../../views/pages/vat-rates";

const orderRoutes = [
    {
        path         : "/orders",
        component    : Orders,
        exact        : true,
        gate         : 'view_orders',
        title        : 'Orders',
        translate_key: 'orders',
        // icon         : 'bi-bag',
        icon         : <OrdersIcon/>,
        main         : true,
    },
    {
        path     : "/orders/update-exchange-rates",
        component: UpdateExchangeRate,
        exact    : true,
        gate     : 'view_orders',
        main     : false,
        parent   : "/orders",
    },
    {
        path     : "/orders/vat-rates",
        component: VatRates,
        exact    : true,
        gate     : 'view_vat_rates',
        main     : false,
        parent   : "/orders",
    },
    {
        path     : "/orders/:id",
        component: OrderSingle,
        exact    : true,
        gate     : 'view_orders',
        main     : false,
        parent   : "/orders",
    },
    {
        path     : "/orders/:type/:id",
        component: Orders,
        exact    : true,
        gate     : 'view_orders',
        main     : false,
        parent   : "/orders",
    },
];

export default orderRoutes;
