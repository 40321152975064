import React, {useEffect, useState, useRef}   from "react";
import {Link}                         from "react-router-dom";
import BootstrapTable                 from "react-bootstrap-table-next";
import {useTranslation}               from "react-i18next";
import {useAuth}                      from "@/services/Auth";
import Paginate from "../Paginate";

function useColumns(filter, isExport = false) {
    let auth = useAuth();
    const {t} = useTranslation();

    let columns = [
        {
            dataField: 'id',
            text     : t('id'),
        },
        {
            dataField: 'company_name',
            text     : t(isExport ? 'company_name' : 'supplier'),
            formatter: (cell, row) => <Link to={`/suppliers/${row.id}`}>{cell}</Link>,
        },
        isExport && {
            dataField: 'contact_name',
            text     : t('primary_contact')
        },
        {
            dataField: 'email',
            text     : t('email_address'),
        },

        isExport && {
            dataField: 'phone_number',
            text     : t('phone_number')
        },

        isExport && {
            dataField: 'vat_registration_number',
            text     : t('vat_registration_number')
        },

        {
            dataField: 'address',
            text     : t(isExport ? 'first_line_address' : 'address')
        },


        isExport && {
            dataField: 'address_line_2',
            text     : t('address_line_2')
        },

        isExport && {
            dataField: 'city',
            text     : 'City'
        },

        isExport && {
            dataField: 'county',
            text     : t('county')
        },

        isExport && {
            dataField: 'country',
            text     : t('country'),
            exportFormatter: (country, row) => country.label
        },

        isExport && {
            dataField: 'post_code',
            text     : t('post_code')
        },

        {
            dataField: 'account_status',
            text     : t('account_status')
        },

        {
            dataField: 'account_number',
            text     : t('account_number')
        },

        isExport && {
            dataField: 'client_payment_terms',
            text     : t('client_payment_terms')
        },

        isExport && {
            dataField: 'client_payment_terms_days',
            text     : t('client_payment_terms_days')
        },

        isExport && {
            dataField: 'currency',
            text     : t('currency')
        },

        {
            dataField: 'credit_balance',
            text     : t('balance'),
            formatter: (credit_balance, row) => <div className="text-end">{credit_balance?.toLocaleString && credit_balance.toLocaleString(undefined, {minimumFractionDigits: 2}) + ' ' + row.currency || 'N/A'}</div>,
        },
    ].filter(Boolean)

    return filter && columns.filter(filter) || columns;
}

//TODO Add Supplier

export {
    useColumns
};
