import { getRequest } from "./Api";

let order_status_request;
let order_statuses;

export default {
  get: function () {
    return new Promise((resolve, reject) => {

      if(order_statuses)
      {
        resolve(order_statuses);
      }
      else
      {
        if(!order_status_request)
        {
          order_status_request = getRequest('/order-statuses');
        }

        order_status_request
          .then((response) => {
            order_statuses = response.data.order_statuses;

            resolve(order_statuses);
          })
          .catch(error => reject(error));
      }
    });
  }

};
