import React from "react";
import {Link}                         from "react-router-dom";
import {useTranslation}               from "react-i18next";
import {useAuth}                      from "@/services/Auth";
import useMoment from "@/hooks/useMoment";

function useColumns(filter, isExport = false) {
    let auth = useAuth();
    const {t} = useTranslation();

    const {momentLocal} = useMoment();

    const is_platform_user = auth.user.is_platform_admin || auth.user.is_platform_operator;

    let columns = [
        {
            dataField: 'id',
            text     : t('id'),
        },
        {
            dataField: 'name',
            text     : t('company_name'),
            formatter: (cell, row) => <Link to={`clients/${row.id}`}>{cell}</Link>,
        },

        {
            dataField: 'primary_contact',
            text     : t('primary_contact'),
        },

        {
            dataField: 'email',
            text     : t('email_address'),
        },

        isExport && {
            dataField: 'phone_number',
            text     : t('phone_number')
        },

        {
            dataField: 'account_number',
            text     : t('account_number')
        },

        {
            dataField: 'account_status_defined',
            text     : t('account_status')
        },

        isExport && {
            dataField: 'account_type',
            text     : t('account_type')
        },

        isExport && {
            dataField: 'address',
            text     : t('first_line_address')
        },

        isExport && {
            dataField: 'address_line_2',
            text     : t('address_line_2')
        },

        isExport && {
            dataField: 'city',
            text     : 'City'
        },

        isExport && {
            dataField: 'county',
            text     : t('county')
        },

        isExport && {
            dataField: 'country',
            text     : t('country'),
            exportFormatter: (country, row) => country.label
        },

        isExport && {
            dataField: 'post_code',
            text     : t('post_code')
        },

        isExport && {
            dataField: 'client_payment_terms',
            text     : t('client_payment_terms')
        },

        isExport && {
            dataField: 'client_payment_terms_days',
            text     : t('client_payment_terms_days')
        },

        isExport && {
            dataField: 'currency',
            text     : t('currency')
        },

        is_platform_user && {
            dataField: 'compliance_licences',
            text     : t('compliance_licences'),
            formatter: licences => licences || '',
            align    : 'right',
        },

        is_platform_user && {
            dataField: 'compliance_price_per_licence',
            text     : t('price_per_licence'),
            formatter: price => price && price.toFixed(2) || '',
            align    : 'right',
        },

        is_platform_user && {
            dataField: 'compliance_last_updated',
            text     : t('last_updated'),
            formatter: last_updated => last_updated && momentLocal(last_updated).format(t('formats.date')) || '',
        },

        {
            dataField: 'credit',
            text     : t('credit'),
            align    : 'right',
            formatter : (credit, row) => credit && <span>{credit.balance.toLocaleString(undefined, {minimumFractionDigits: 2})} / {credit.limit == 0 ? t('unlimited') : credit.limit.toLocaleString(undefined, {minimumFractionDigits: 2}) + ' ' + row.currency}</span>,
            exportFormatter: (value, row) => value?.balance || null
        },
    ].filter(Boolean)

    return filter && columns.filter(filter) || columns;
}

//TODO Add Supplier

export {
    useColumns
};
