import {Button, Card, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../services";
import LoadingPage from "../../../components/LoadingPage";
import QRCode from "qrcode";
import {Helmet} from "react-helmet";

function GenerateQr() {
    const {t} = useTranslation();
    let auth = useAuth();
    const history = useHistory();
    const route = useLocation();
    const [vehicle, setVehicle] = useState(route.state?.vehicle ?? null);
    const [src, setSrc] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (vehicle === null) {
            const sessionVehicle = sessionStorage.getItem('vehicle');
            if (!sessionVehicle || sessionVehicle == undefined) {
                auth.getRequest('vehicles')
                    .then((response) => {
                        if (response.data.vehicles.length !== 0) {
                            let selectedVehicle = response.data.vehicles[0];
                            setVehicle(selectedVehicle);
                            sessionStorage.setItem('vehicle', JSON.stringify(selectedVehicle));
                        } else {
                            history.replace('instant-booking/select-vehicle');
                        }
                    })
                    .catch(() => console.error(arguments))
            } else {
                setVehicle(JSON.parse(sessionVehicle));
            }
        }
        if (vehicle && !src && vehicle.id) {
            setLoading(true);
            auth.postRequest('/instant_booking/create_code', {vehicle_id: vehicle.id})
                .then(response => {
                    QRCode.toDataURL(JSON.stringify({
                        instant_booking: {
                            token: response.data.instant_booking_token,
                        }
                    })).then(data => {
                        setSrc(data);
                    })
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => setLoading(false));
        }
    }, [vehicle, src]);

    if (src === null || loading) {
        return <LoadingPage></LoadingPage>
    }

    return (
        <Card>
            <Helmet>
                <title> {t('drawer_link_titles.pay_m_park')} - {t('app')}</title>
            </Helmet>

            <h4 className="text-center">
                {vehicle?.registration_number}
                <div
                    className="align-items-center d-inline-flex justify-content-between mx-2 small text-primary"
                    onClick={() => history.replace('/instant-booking/select-vehicle', {vehicle})}
                >
                    <span className="me-2">{t('change')}</span>
                    <img src="/images/edit-icon.png" alt=""/>
                </div>
            </h4>

            <div className="qr-code-contend d-flex text-center flex-column">
                <img src={src} className="img-fluid mx-auto w-auto"/>

                <p className="text-primary">{t('instant_booking.present_message')}</p>
            </div>

            <div className="mt-4">
                <Button className="w-100" onClick={() => history.goBack()}>
                    {t('back')}
                </Button>
            </div>

        </Card>
    )
}

export default GenerateQr;
