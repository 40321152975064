import {useTranslation} from "react-i18next";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useAuth} from "@/services/Auth";
import {useEffect} from "react";

function Error401() {
    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const updateErrorPage = auth.updateErrorPage;
    useEffect(() => {
        auth?.errorPage !== false || (auth?.updateErrorPage && auth.updateErrorPage(true));

        return () => {
            updateErrorPage(false);
        }
    }, []);
    return (
        <div className="d-flex justify-content-center align-items-center" style={{height: "100%"}}>
            <Card border="light">
                <Card.Body className="text-center">
                    <i className="bi bi-emoji-frown" style={{fontSize: "10rem"}}/>
                    <Card.Title className="text-uppercase">{t('errors.error_401_message')}</Card.Title>
                    <Card.Text>
                        {t('errors.you_are_not_authorized_to_access_the_page_you_requested')}
                    </Card.Text>
                    <div className="d-grid gap-2">
                        <Link variant="primary" className="btn btn-primary" to="/">{t('go_to_dashboard')}</Link>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Error401;
