import {Button, Card, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../services";
import LoadingPage from "../../../components/LoadingPage";
import {Helmet} from "react-helmet";

function SelectVehicle() {
    const {t} = useTranslation();
    let auth = useAuth();
    const history = useHistory();
    const route = useLocation();
    const [searchQuery, setSearchQuery] = useState('');
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicle, _] = useState(route.state.vehicle);
    const [totalVehicles, setTotalVehicles] = useState([]);
    useEffect(() => {
        if (vehicles.length === 0) {
            auth.getRequest('vehicles')
                .then((response) => {
                    setVehicles(response.data.vehicles);
                    setTotalVehicles(response.data.vehicles);
                })
                .catch(() => console.error(arguments))
        }
    }, [vehicles]);

    if (vehicles.length === 0) {
        return <LoadingPage></LoadingPage>
    }

    const search = () => {
        const filteredVehicles = totalVehicles.slice()
            .filter(vehicle => vehicle.registration_number.toLowerCase().includes(searchQuery.toLowerCase()));
        setVehicles(filteredVehicles);
    }

    const selectVehicle = (vehicle) => {
        sessionStorage.setItem('vehicle', JSON.stringify(vehicle));
        history.replace('/instant-booking/generate-qr', {vehicle: vehicle});
    }

    return (
        <Card>
            <Helmet>
                <title> {t('drawer_link_titles.pay_m_park')} - {t('app')}</title>
            </Helmet>
            <h4 className="text-center text-primary">{t('instant_booking.select_vehicle')}</h4>
            <Row>
                <div className="input-group mb-3">
                    <Form.Control
                        placeholder={t('instant_booking.search_registration')}
                        className="shadow-none"
                        type="text"
                        onInput={(ev) => setSearchQuery(ev.target.value)}
                        aria-describedby="button-search"
                    ></Form.Control>
                    <Button type="button" id="button-search" onClick={search}>
                        <i className="bi bi-search"></i>
                    </Button>
                </div>
            </Row>

            {vehicles.map((vehicle, key) => (
                <label key={key}
                       className="col-12 col-lg-4 col-xl-3">
                    <Form.Check.Input
                        type="radio"
                        onClick={() => selectVehicle(vehicle)}
                        defaultChecked={vehicle.id === selectedVehicle.id}
                        value={vehicle.id}
                        className="d-none"
                    />

                    <span
                        className="btn btn-outline-primary w-100 mb-4">{vehicle.registration_number}</span>
                </label>
            ))}

            <Button
                className="w-100 bg-danger mt-2"
                onClick={() => history.replace('/instant-booking/generate-qr')}
            >
                {t('cancel')}
            </Button>

        </Card>
    )
}

export default SelectVehicle;
