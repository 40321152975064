import {Badge}          from "react-bootstrap";
import React            from "react";
import {useTranslation} from "react-i18next";


function FacilitiesList({facilities, type, prebookable}) {
    const {t, i18n} = useTranslation();

    facilities = facilities
      .filter((value, index, self) => self.findIndex(_ => _.id == value.id) === index)
      .sort((a, b) => ("" + a.name).localeCompare(b.name))

    return (
        <div className="d-flex align-items-center flex-wrap mb-1">
            {
                facilities.map((facility, index) => {
                    if ((facility.name !== "24/7" && facility.name !== "Parking" && type === 'FACILITIES') || type === 'SECURITY') {
                        return (
                            <div className="d-flex align-items-center mb-1 my-2" key={index}>
                                <img className="service-icon" src={`/images/${type === 'FACILITIES' ? 'facilities' : 'security_facilities'}/${facility.id}.svg`}/>
                                <span className="me-3 ms-1 text-nowrap facility-name-site-card">{facility.name}</span>
                            </div>
                        )
                    }
                })
            }
            {
                prebookable &&
                <div className="d-flex align-items-center mb-1 my-2">
                    <img className="service-icon" src={`/images/facilities/prebook.svg`}/>
                    <span className="me-3 ms-1 text-nowrap facility-name-site-card">{t('prebookable_facility')}</span>
                </div>
            }
        </div>
    )
}

export default FacilitiesList;
