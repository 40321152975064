import {useTranslation}                                   from "react-i18next";
import {useAuth}                                          from "@/services/Auth";
import {useParams}                                        from "react-router-dom";
import React, {useEffect, useState}                       from "react";
import LoadingPage                                        from "@/components/LoadingPage";
import {Button, Card, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import ClientForm                                         from "./components/ClientForm";
import SuccessDialog                                      from "../../../components/Modals/SuccessDialog";
import { Helmet } from "react-helmet";

function ClientCreate(props) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const getRequest = auth.getRequest;
    const postRequest = auth.postRequest;


    const [loading, setLoading] = useState(true);
    const [creatingClient, setCreatingClient] = useState(false);
    const [createSuccess, setCreateSuccess] = useState(false);


    const [accountStatusOptions, setAccountStatusOptions] = useState([]);
    const [accountTypeOptions, setAccountTypeOptions] = useState([]);
    const [paymentTerms, setPaymentTerms] = useState([]);
    const [supportedCurrency, setSupportedCurrency] = useState([]);

    const [countries, setCountries] = useState([]);
    const [serverErrors, setServerErrors] = useState([]);
    const [createdClient, setCreatedClient] = useState('');

    const paymentTermsDays = [7, 14];


    useEffect(() => {
        getRequest('/clients/prepare')
            .then(response => {
                //options
                setAccountStatusOptions(response.data.account_status_options);
                setAccountTypeOptions(response.data.account_type_options);
                setPaymentTerms(response.data.payment_terms);
                setSupportedCurrency(response.data.supported_currency);
                setSupportedCurrency(response.data.supported_currency);
                setCountries(response.data.countries);

                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error)
            })

    }, []);

    const handleSubmit = (payload) => {
        setCreatingClient(true);
        postRequest('/clients', payload)
            .then(response => {
                setCreatingClient(false);
                if (response.data.message && response.data.message === "OK") {
                    setCreateSuccess(true);
                    setCreatedClient(response.data.client_id);
                }
            })
            .catch(error => {
                setCreatingClient(false);

                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    setServerErrors(error.response.data.errors)
                }
            })
    };

    const handleClose = () => {
        setCreateSuccess(false)
        if (createdClient !== '' && createdClient != null) {
            props.history.push(`/clients/${createdClient}`)
        }
    }

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('create')} / {t('drawer_link_titles.clients')} - {t('app')}</title>
                </Helmet>

                <SuccessDialog
                    show={createSuccess}
                    message="success_dialog.client_created"
                    handleClose={handleClose}
                />

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h4 className="text-primary">{t('new_client')}</h4>
                </Card.Subtitle>
                <ClientForm
                    onSubmit={handleSubmit}
                    accountStatusOptions={accountStatusOptions}
                    supportedCurrency={supportedCurrency}
                    paymentTerms={paymentTerms}
                    accountTypeOptions={accountTypeOptions}
                    client={null}
                    countries={countries}
                    processingSubmit={creatingClient}
                    serverErrors={serverErrors}
                    buttonText="save_new_client"
                />
            </Card>
        )
    )
}

export default ClientCreate;
