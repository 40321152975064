import React                   from "react";
import {useHistory, Link}                 from "react-router-dom";
import {useTranslation}                                from "react-i18next";
import { Helmet } from "react-helmet";
import {Button} from "react-bootstrap";

function SuccessBooking({booking, onViewBooking}) {
    const {t} = useTranslation();
    const history = useHistory();

    return (
        <div className="booking booking-checkout container-fluid">
            <Helmet>
                <title>{t('booking_summary')} / {t('drawer_link_titles.bookings')} - {t('app')}</title>
            </Helmet>
            <img src="/images/success-icon.png" alt="success-icon" className="d-block pt-5 mx-auto"/>
            <h6 className="mb-0 mt-3 pb-5 text-center">{t('instant_booking.booking_complete')}</h6>
            <div className="mt-4">
                <Button className="w-100" onClick={onViewBooking}>
                    {t('instant_booking.view_booking')}
                </Button>
            </div>
            <div className="mt-4">
                <Button className="w-100" variant="outline-primary" onClick={() => history.replace('/scan-qr-code')}>
                    {t('instant_booking.back_to_scanner')}
                </Button>
            </div>
        </div>
    )
}

export default SuccessBooking;
