import React, {useState} from "react";
import {useTranslation}      from "react-i18next";
import {Form, Button}              from "react-bootstrap";

function ParkingPriceFields({ register, watch, index, remove, errors }) {
    const {t, i18n} = useTranslation();

    const parkingPrice = watch(`parking_prices.${index}.price`);

    return (
        <tr>
            <td>
                <Form.Control
                    { ...register(`parking_prices.${index}.price`) }
                    type="number"
                    step=".0000001"
                    min="0.00"
                    isInvalid={errors.parking_prices && !!errors.parking_prices[index]?.price}
                />
                <small className='text-success'>
                    {
                        (parkingPrice && parseFloat(parkingPrice) === 0) ?
                        'The duration after the free period will be used to calculate the final price.' : ''
                    }
                </small>

            </td>

            <td>
                <Form.Control
                    { ...register(`parking_prices.${index}.period_value`) }
                    type="number"
                    step="1"
                    min="1"
                    isInvalid={errors.parking_prices && !!errors.parking_prices[index]?.period_value}
                />
            </td>

            <td>
                <Form.Select
                    { ...register(`parking_prices.${index}.per_period_type`) }
                    isInvalid={errors.parking_prices && !!errors.parking_prices[index]?.per_period_type}
                >
                    <option value="">--{t('choose')}--</option>
                    <option value="Hour">{t('hour')}</option>
                    <option value="Day">{t('day')}</option>
                </Form.Select>
            </td>

            <td>
                <Form.Control
                    { ...register(`parking_prices.${index}.up_to_value`) }
                    type="number"
                    step="1"
                    min="1"
                    isInvalid={errors.parking_prices && !!errors.parking_prices[index]?.up_to_value}
                />
            </td>

            <td>
                <Form.Select
                    { ...register(`parking_prices.${index}.up_to_period`) }
                    isInvalid={errors.parking_prices && !!errors.parking_prices[index]?.up_to_period}
                >
                    <option value="">--{t('choose')}--</option>
                    <option value="Hour">{t('hour')}</option>
                    <option value="Day">{t('day')}</option>
                </Form.Select>
            </td>

            <td>
                {
                    remove &&
                    <Button variant="light" className="btn-icon btn-rounded" size="sm" onClick={remove}>
                        <i className="bi bi-x text-danger"/>
                    </Button>
                }
            </td>
        </tr>
    )

}

export default ParkingPriceFields;
