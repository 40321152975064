import { useHistory, useLocation, Redirect, Link } from "react-router-dom";
import React, { useEffect, useState, useCallback, useRef }   from "react";
import { useTranslation }               from "react-i18next";
import { Helmet } from "react-helmet";
import { useAuth, Countries, Storage }   from "@/services";
import { Card, Form, Button, ProgressBar } from "react-bootstrap";
import moment from 'moment-timezone';
import LoadingPage from "@/components/LoadingPage";
import Wysiwyg, { validateWysiwyg, validateImg } from "@/components/Inputs/Wysiwyg";
import { useForm, useFieldArray } from 'react-hook-form';
import { useJsApiLoader } from '@react-google-maps/api';
import * as yup from 'yup';
import Select from "react-select";
import { yupResolver } from '@hookform/resolvers/yup';
import DisplayWysiwyg from "@/components/Elements/DisplayWysiwyg";
import PoliceReport from "./components/PoliceReport";
import DisplayLocation from "@/components/Elements/DisplayLocation";
import LocationFields, { useLocationFieldsSchema } from "@/components/Forms/LocationFields";
import { CheckIcon } from "@/components/icons";
import { useLayout } from "@/layouts/Layout";
import YesNoToggle from "@/components/Inputs/YesNoToggle";

function TrafficIncidentReport() {
  const auth = useAuth();
  const main = useLayout();
  const {t} = useTranslation();
  const route = useLocation();
  const history = useHistory();

  const vehicle = route?.state?.vehicle || undefined;
  const occurance_date = route?.state?.occurance_date || undefined;
  const storage_key = route?.state?.storage_key;

  const [report, setReport] = useState(undefined);

  const steps = ['incident_information', 'incident_description', 'injuries_damage', 'police_report', 'parties_involved', 'summary'];
  const step = route?.state?.step || 'incident_information';
  const step_index = steps.findIndex(_ => step == _);


  const updateStep = step => {
    history.push(route.pathname, {
      ...(route.state || {}),

      step,
    });
  };

  const onBack = () => {
    var target = steps.findIndex(_ => _ == step);

    if(target < 0 || target > steps.length - 1)
    {
      target = steps.length;
    }

    target --;

    updateStep(steps[target]);
  };

  const next = () => {
    var target = steps.findIndex(_ => _ == step);

    if(target < 0 || target > steps.length - 1)
    {
      target = -1;
    }

    target ++;

    updateStep(steps[target]);
  };


  const [loading, setLoading] = useState(false);

  const onSave = values => {
    const _report = { ...report, ...values };

    if(_report.detail && !_report.detail.passengers_injuried)
    {
      _report.detail.passenger_injuries = `<i>${t('compliance.TrafficIncidentReport.no_injuries')}</i>`;
    }

    if(_report.detail && !_report.detail.vehicle_damaged)
    {
      _report.detail.vehicle_damage = `<i>${t('compliance.TrafficIncidentReport.no_damange')}</i>`;
    }

    setReport(_report);
  };

  const onSubmit = values => {
    onSave(values);

    next();
  };

  const save = _ => {
    const data = {
      type: 'TrafficIncidentReport',
      vehicle_id: vehicle.id,
      ...report,
      persons: JSON.parse(JSON.stringify(report.persons)),
    };

    data.persons.forEach(_ => _.type = 'PartyInvolved');

    setLoading(true);

    auth.postRequest('reports', data)
        .then(response => {
          setReport(response.data.data);
        })
        .finally(_ => setLoading(false));
  };



  useEffect(() => {
    if(report?.guid)
    {
      Storage.Session.removeItem(storage_key);
    }

    const onUnload = _ => {
      if(report && !report.guid)
      {
        Storage.Session.setItem(storage_key, report);
      }
    };

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [report]);

  useEffect(() => {
    if(report === undefined)
    {
      const defaultValues = {
        occurance_at: occurance_date,
      };

      if(storage_key)
      {
        Storage.Session.getItem(storage_key).then(value => {
          setReport(value || defaultValues);
        });
      }
      else
      {
        setReport(defaultValues);
      }
    }
    else
    if(Object.keys(report).length <= 0)
    {
      if(step != steps[0])
      {
        updateStep(steps[0]);
      }
    }
  }, [report, step]);

  useEffect(() => {
    if(!storage_key)
    {
      history.replace(route.pathname, {
        ...(route.state || {}),

        storage_key: 'traffic-incident-report-' + vehicle?.id + '-' + moment().toISOString(),
      });
    }

  }, [storage_key])

  useEffect(() => {
    main.scrollTop();
  }, [step])

  if(loading || !report)
  {
    return <LoadingPage />;
  }

  if(report.guid)
  {
    return (
      <div className="container">
        <Helmet>
          <title>{t('compliance.TrafficIncidentReport.title')} - {t('app')}</title>
        </Helmet>

        <h1 className="h4 mb-3 text-primary">
          {t('compliance.TrafficIncidentReport.title')}
        </h1>

        <ProgressBar variant="success" now={100} />

        <div className="large-icon-message">
          <CheckIcon variant="success" />

          <h3 className="h6 text-success strong mt-3 text-center">
            {t('n_have_now_been_completed', { n: t('compliance.TrafficIncidentReport.title') })}
          </h3>
        </div>

        <Link className="btn btn-primary w-100" to={{ pathname: '/compliance', state: { vehicle_id: vehicle.id, occurance_date } }}>
          {t('back')}
        </Link>
      </div>
    );
  }

  return (
    <div className="container">
      <Helmet>
        <title>{t('compliance.TrafficIncidentReport.title')} - {t('app')}</title>
      </Helmet>

      <h1 className="h4 mb-3 text-primary">
        {t('compliance.TrafficIncidentReport.title')}
      </h1>

      <ProgressBar variant="success" now={(step_index + 1) / steps.length * 100} />

      {(_ => {
        switch(step)
        {
          case 'incident_information':
          default:
            return <IncidentDetails {...{ vehicle, report, onBack, onSave, onSubmit }} />;

          case 'incident_description':
            return <IncidentDecription {...{ report, onBack, onSave, onSubmit }} />;

          case 'injuries_damage':
            return <InjuriesDamage {...{ report, onBack, onSave, onSubmit }} />;

          case 'police_report':
            return <PoliceReport {...{ report, onBack, onSave, onSubmit }} />;

          case 'parties_involved':
            return <Persons {...{ report, onBack, onSave, onSubmit }} />;

          case 'summary':
            return <Summary {...{ report, onBack, onSave, onSubmit: save }} />;
        }

      })()}
    </div>
  );
}

export default TrafficIncidentReport;


function IncidentDetails({ vehicle, report, onBack, onSave, onSubmit, ...props}) {
  const auth = useAuth();
  const {t} = useTranslation();

  const schema = yup.object().shape({
    occurance_at: yup.date()
      .required(t('form_validation.is_required', { attribute: t('compliance.TrafficIncidentReport.date') })),

    location: useLocationFieldsSchema(),
  });


  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: (() => {
      const values = {... report};

      if(values?.detail?.occurance_at)
      {
        values.detail.occurance_at = moment(values.detail.occurance_at).format('YYYY-MM-DD');
      }

      return values;
    })(),
    resolver: yupResolver(schema),
  });

  const detail = watch('detail');
  const location = watch('location');

  useEffect(() => {
    onSave({
      detail,
      location,
    });
  }, [
    detail,
    location,
  ]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>

      <div className="mt-3">
        {t('reported_by') + ' : '}

        <strong>{auth.user.name}</strong>
      </div>

      <div>
        {t('vehicle_registration_number') + ' : '}

        <strong>{vehicle.registration_number}</strong>
      </div>

      <div className="mb-3">
        {t('compliance.BorderReport.time') + ' : '}

        <strong>{moment().format(t('formats.datetime'))}</strong>
      </div>



      <h3>{t('compliance.incident_information')}</h3>


      <Form.Group className="mb-3">
        <label htmlFor="occurance_at" className="my-2">{t('compliance.TrafficIncidentReport.date')}</label>
        <Form.Control
          {...register('occurance_at')}
          type="datetime-local"
          isInvalid={!!errors?.occurance_at}
        />

        <Form.Control.Feedback type="invalid">
          {errors?.occurance_at && errors?.occurance_at.message}
        </Form.Control.Feedback>
      </Form.Group>

      <LocationFields {...{ register, errors, setValue, path: 'location.', useCurrentLocation: true }} />


      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" type="submit">
          {t('continue')}
        </Button>
      </Form.Group >
    </Form>
  );
}


function IncidentDecription({ report, onBack, onSave, onSubmit, ...props}) {
  const {t} = useTranslation();

  const schema = yup.object().shape({
    detail: yup.object().shape({
      description: yup.string()
        .required(t('form_validation.is_required', { attribute: t('description') }))
        .test(
          'max-content',
          t('form_validation.max_length', { attribute: t('description'), max: 65000 }),
          validateWysiwyg
        )
        .test(
          'image',
          t('form_validation.image_required', { attribute: t('content') }),
          validateImg
        ),
    }),
  });


  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: report,
    resolver: yupResolver(schema),
  });

  const detail = watch('detail');

  useEffect(() => {
    onSave({
      detail
    });
  }, [detail]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <h3 className="h6 text-primary my-3">
        {t('compliance.TrafficIncidentReport.description')}
      </h3>

      <p>{t('compliance.TrafficIncidentReport.description_instructions')}</p>

      <Form.Group className="mb-3 wysiwyg-image">
        <Wysiwyg
          {...register('detail.description')}
          placeholder={t('description')}
          preUpload={true}
          imageCompression={{ maxWidth: 1000 }}
          blockEmbeds={false}
          toolbar={false}
          isInvalid={!!errors?.detail?.description?.message} />

        <Form.Control.Feedback type="invalid">
          {errors?.detail?.description?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" type="submit">
          {t('continue')}
        </Button>
      </Form.Group >
    </Form>
  );
}

function InjuriesDamage({ report, onBack, onSave, onSubmit, ...props}) {
  const {t} = useTranslation();

  const schema = yup.object().shape({
    detail: yup.object().shape({
      driver_injuried: yup.boolean()
        .typeError(t('form_validation.is_required', { attribute: t('compliance.TrafficIncidentReport.driver_injuries') })),
      driver_injuries: yup.string()
        .when(['driver_injuried'], (driver_injuried, schema) => driver_injuried == 1 && schema.required(t('form_validation.is_required', { attribute: t('compliance.TrafficIncidentReport.driver_injuries') })) || schema)
        .test(
          'max-content',
          t('form_validation.max_length', { attribute: t('compliance.TrafficIncidentReport.driver_injuries'), max: 65000 }),
          validateWysiwyg
        )
        .test(
          'image',
          t('form_validation.image_required', { attribute: t('compliance.TrafficIncidentReport.driver_injuries') }),
          validateImg
        ),
      passengers_injuried: yup.boolean()
        .typeError(t('form_validation.is_required', { attribute: t('compliance.TrafficIncidentReport.passengers_injuried') })),
      passenger_injuries: yup.string()
        .when(['passengers_injuried'], (value, schema) => (
          value == 1 && schema.required(t('form_validation.is_required', { attribute: t('compliance.TrafficIncidentReport.passengers_injuried') }))
          || schema.nullable()
        ))
        .test(
          'max-content',
          t('form_validation.max_length', { attribute: t('compliance.TrafficIncidentReport.injured_passengers'), max: 65000 }),
          validateWysiwyg
        ),
      vehicle_damaged: yup.boolean()
        .typeError(t('form_validation.is_required', { attribute: t('compliance.TrafficIncidentReport.vehicle_damaged') })),
      vehicle_damage: yup.string()
        .when(['vehicle_damaged'], (value, schema) => (
          value == 1 &&
            schema
              .required(t('form_validation.is_required', { attribute: t('compliance.TrafficIncidentReport.vehicle_damage') }))
              .test(
                'image',
                t('form_validation.image_required', { attribute: t('compliance.TrafficIncidentReport.vehicle_damage') }),
                validateImg
              )
          || schema.nullable()
        ))
        .test(
          'max-content',
          t('form_validation.max_length', { attribute: t('compliance.TrafficIncidentReport.vehicle_damage'), max: 65000 }),
          validateWysiwyg
        ),
    }),
  });


  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: (() => {
      const detail = { ...(report.detail || {}) };

      detail.driver_injuried = detail.driver_injuried === true ? '1' : (detail.driver_injuried === false ? '0' : null)
      detail.passengers_injuried = detail.passengers_injuried === true ? '1' : (detail.passengers_injuried === false ? '0' : null)
      detail.vehicle_damaged = detail.vehicle_damaged === true ? '1' : (detail.vehicle_damaged === false ? '0' : null)

      return { detail };
    })()
  });

  const detail = watch('detail');

  useEffect(() => {
    onSave({
      detail
    });
  }, [detail]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <h3 className="h6 text-primary my-3">
        {t('compliance.TrafficIncidentReport.injuries_damage')}
      </h3>

      <Form.Group className="mb-3 wysiwyg-image">
        <label htmlFor="driver_injuries" className="my-2">{t('compliance.TrafficIncidentReport.driver_injuries')}</label>

        <YesNoToggle
          yesValue={1}
          noValue={0}
          {...register('detail.driver_injuried')}
          isInvalid={!!errors?.detail?.driver_injuried?.message} />

        <Form.Control.Feedback type="invalid">
          {errors?.detail?.driver_injuried?.message}
        </Form.Control.Feedback>

        <Wysiwyg
          key={'detail.driver_injuries'}
          className={detail.driver_injuried == 1 ? '' : 'd-none'}
          {...register('detail.driver_injuries')}
          blockEmbeds={false}
          preUpload={true}
          imageCompression={{ maxWidth: 320 }}
          toolbar={false}
          placeholder={t('please_specify')}
          isInvalid={!!errors?.detail?.driver_injuries?.message} />

        <Form.Control.Feedback type="invalid">
          {errors?.detail?.driver_injuries?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3 wysiwyg-image">
        <label htmlFor="injured_passengers" className="my-2">{t('compliance.TrafficIncidentReport.passengers_injuried')}</label>

        <YesNoToggle
          yesValue={1}
          noValue={0}
          {...register('detail.passengers_injuried')}
          isInvalid={!!errors?.detail?.passengers_injuried?.message} />

        <Form.Control.Feedback type="invalid">
          {errors?.detail?.passengers_injuried?.message}
        </Form.Control.Feedback>

        <Wysiwyg
          key={'detail.passenger_injuries'}
          {...register('detail.passenger_injuries')}
          className={detail.passengers_injuried == 1 ? '' : 'd-none'}
          toolbar={false}
          preUpload={true}
          imageCompression={{ maxWidth: 320 }}
          placeholder={t('please_specify')}
          isInvalid={!!errors?.detail?.passenger_injuries?.message} />

        <Form.Control.Feedback type="invalid">
          {errors?.detail?.passenger_injuries?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3 wysiwyg-image">
        <label htmlFor="vehicle_damage" className="my-2">{t('compliance.TrafficIncidentReport.vehicle_damaged')}</label>

        <YesNoToggle
          yesValue={1}
          noValue={0}
          {...register('detail.vehicle_damaged')}
          isInvalid={!!errors?.detail?.vehicle_damaged?.message} />

        <Form.Control.Feedback type="invalid">
          {errors?.detail?.vehicle_damaged?.message}
        </Form.Control.Feedback>


        <Wysiwyg
          key={'detail.vehicle_damage'}
          {...register('detail.vehicle_damage')}
          className={detail.vehicle_damaged == 1 ? '' : 'd-none'}
          blockEmbeds={false}
          preUpload={true}
          imageCompression={{ maxWidth: 320 }}
          toolbar={false}
          placeholder={t('please_specify')}
          isInvalid={!!errors?.detail?.vehicle_damage?.message} />

        <Form.Control.Feedback type="invalid">
          {errors?.detail?.vehicle_damage?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" type="submit">
          {t('continue')}
        </Button>
      </Form.Group >
    </Form>
  );
}


function Persons({ report, onBack, onSave, onSubmit, type, ...props}) {
  const {t} = useTranslation();

  const schema = yup.object().shape({
    persons: yup.array().of(
      yup.object().shape({
        name: yup.string()
          .required(t('form_validation.is_required', { attribute: t('name') }))
          .max(255, t('form_validation.max_length', { attribute: t('name'), max: 255 })),

        location: useLocationFieldsSchema(),

        phone: yup.string()
          .required(t('form_validation.is_required', { attribute: t('phone') }))
          .max(255, t('form_validation.max_length', { attribute: t('phone'), max: 255 })),

        email: yup.string()
          .required(t('form_validation.is_required', { attribute: t('email') }))
          .max(255, t('form_validation.max_length', { attribute: t('email'), max: 255 }))
          .email(t('form_validation.invalid_email_address')),


        registration: yup.string()
          .required(t('form_validation.is_required', { attribute: t('registration') }))
          .max(255, t('form_validation.max_length', { attribute: t('registration'), max: 255 })),

        description: yup.string()
          .required(t('form_validation.is_required', { attribute: t('compliance.TrafficIncidentReport.vehicle_damage') }))
          .test(
            'max-content',
            t('form_validation.max_length', { attribute: t('compliance.TrafficIncidentReport.vehicle_damage'), max: 65000 }),
            validateWysiwyg
          )
          .test(
            'image',
            t('form_validation.image_required', { attribute: t('compliance.TrafficIncidentReport.vehicle_damage') }),
            validateImg
          ),

      })
    ),
  });


  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: report,
    resolver: yupResolver(schema),
  });

  const { fields: persons, append, remove } = useFieldArray({
    control,
    name: 'persons',
  });


  useEffect(() => {
    onSave({
      persons
    });
  }, [persons]);

  useEffect(() => {
    register('persons');
  }, []);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <h3 className="h6 text-primary my-3">
        {t('compliance.TrafficIncidentReport.parties_involved')}
      </h3>


      {persons.map((row, index) => (
        <div key={row.id} className="mb-3">
          <hr />

          {t('person_n', { n: index + 1})}

          <Form.Group className="mb-3">
            <label htmlFor="name" className="my-2">{t('name')}</label>
            <Form.Control
              {...register(`persons.${index}.name`)}
              type="text"
              isInvalid={!!(errors.persons && errors.persons[index] && errors.persons[index].name)}
            />
            <Form.Control.Feedback type="invalid">
              {errors.persons && errors.persons[index] && errors.persons[index].name?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <LocationFields {...{ register, errors, setValue, path: `persons.${index}.location.` }} />

          <Form.Group className="mb-3">
            <label htmlFor="registration" className="my-2">{t('registration')}</label>
            <Form.Control
              {...register(`persons.${index}.registration`)}
              type="text"
              isInvalid={!!(errors.persons && errors.persons[index] && errors.persons[index].registration)}
            />
            <Form.Control.Feedback type="invalid">
              {errors.persons && errors.persons[index] && errors.persons[index].registration?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <label htmlFor="phone" className="my-2">{t('phone_number')}</label>
            <Form.Control
              {...register(`persons.${index}.phone`)}
              type="text"
              isInvalid={!!(errors.persons && errors.persons[index] && errors.persons[index].phone)}
            />
            <Form.Control.Feedback type="invalid">
              {errors.persons && errors.persons[index] && errors.persons[index].phone?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <label htmlFor="email" className="my-2">{t('email_address')}</label>
            <Form.Control
              {...register(`persons.${index}.email`)}
              type="text"
              isInvalid={!!(errors.persons && errors.persons[index] && errors.persons[index].email)}
            />
            <Form.Control.Feedback type="invalid">
              {errors.persons && errors.persons[index] && errors.persons[index].email?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <label htmlFor="description" className="my-2">{t('compliance.TrafficIncidentReport.vehicle_damage')}</label>
            <Wysiwyg
              {...register(`persons.${index}.description`)}
              blockEmbeds={false}
              preUpload={true}
              imageCompression={{ maxWidth: 320 }}
              toolbar={false}
              isInvalid={!!(errors.persons && errors.persons[index] && errors.persons[index].description)}
            />
            <Form.Control.Feedback type="invalid">
              {errors.persons && errors.persons[index] && errors.persons[index].description?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Button className="mx-2 w-100" variant="danger" onClick={_ => remove(index)}>
            {t('remove_person')}
          </Button>

        </div>

      ))}

      <Button className="mx-2 w-100" variant="success" onClick={_ => append({
        name: '',
        location: null,
        registration: '',
        email: '',
        phone: '',
        description: '',
      })}>
        {t('add_person')}
      </Button>

      <Form.Group className="my-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" type="submit">
          {t('continue')}
        </Button>
      </Form.Group >
    </Form>
  );
}



function Summary({ report, onBack, onSubmit, ...props}) {
  const {t} = useTranslation();

  return (
    <>
      <h3 className="h6 text-primary my-3">
        {t('summary')}
      </h3>

      <Form.Group className="mb-3">
        <label htmlFor="occurance_at" className="my-2"><strong>{t('compliance.TrafficIncidentReport.date')}</strong></label>

        {moment(report.occurance_at).format(t('formats.date_long'))}
      </Form.Group>


      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('location')}</strong></label>

        <DisplayLocation location={report.location} />
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.TrafficIncidentReport.description')}</strong></label>

          <DisplayWysiwyg html={report.detail.description} />
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.TrafficIncidentReport.driver_injuries')}</strong></label>
          <p>{report.detail.driver_injuried && t('yes') || t('no')}</p>

          <DisplayWysiwyg html={report.detail.driver_injuries} />
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.TrafficIncidentReport.injured_passengers')}</strong></label>

          <DisplayWysiwyg html={report.detail.passenger_injuries} />
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.TrafficIncidentReport.vehicle_damage')}</strong></label>

          <DisplayWysiwyg html={report.detail.vehicle_damage} />
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.TrafficIncidentReport.parties_involved')}</strong></label>

        {report?.persons?.map && report.persons.map((person, index) => (
          <div key={index} className="mb-2">
          <div>{person.name}</div>
          <div>{person.role}</div>
          <div>{person.phone}</div>
          <div>{person.email}</div>
          <div>{person.registration}</div>
          <DisplayWysiwyg html={person.description} />
          </div>
        ))}
      </Form.Group>


      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.CrimeReport.filed_with_police')}</strong></label>

        {report?.police_report?.filed_with_police ? t('yes') : t('no')}
      </Form.Group>


      {report?.police_report?.filed_with_police && (
      <>
      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.CrimeReport.police_branch_name')}</strong></label>

        {report.police_report.police_branch_name}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.CrimeReport.police_reference')}</strong></label>

        {report.police_report.police_reference}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.CrimeReport.officer_name')}</strong></label>

        {report.police_report.officer_name}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.CrimeReport.police_contact_number')}</strong></label>

        {report.police_report.police_contact_number}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.CrimeReport.follow_up_actions')}</strong></label>

        <DisplayWysiwyg html={report.police_report.follow_up_actions} />
      </Form.Group>
      </>
      ) || ''}

      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" onClick={_ => onSubmit({})}>
          {t('continue')}
        </Button>
      </Form.Group >
    </>
  );
}
