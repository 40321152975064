import {useTranslation}                    from "react-i18next";
import {useAuth}                           from "@/services/Auth";
import React, {useEffect, useState}        from "react";
import * as Yup                            from "yup";
import {Button, Card, Col, Row, Spinner}   from "react-bootstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Link, useParams}                   from "react-router-dom";
import LoadingPage                         from "@/components/LoadingPage";
import * as moment from 'moment';
import { Helmet } from "react-helmet";

function SiteFeedback() {
    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const postRequest = auth.postRequest;
    const getRequest = auth.getRequest;
    let {id} = useParams();

    const [sendingFeedback, setSendingFeedback] = useState(false);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [booking, setBooking] = useState(null);

    const validation = Yup.object().shape({
        name   : Yup.string()
                    .required(t('form_validation.name_is_required')),
        message: Yup.string()
                    .required(t('form_validation.message_is_required')),
    });

    const getBooking = () => {
        auth.getRequest('/bookings/' + id)
            .then(response => {
                setBooking(response.data.booking);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error)
            })
    }

    const handleSubmit = (values) => {
        let feedbackData = values;
        feedbackData['site_id'] = booking.site.id;
        setSendingFeedback(true);
        auth.postRequest('/site-feedback', feedbackData)
            .then(response => {
                setLoading(false);
                if (response.data.message === 'OK') {
                    setSendingFeedback(true);
                    setSuccess(true);
                }

            })
            .catch(error => {
                setSendingFeedback(false);

            })
    }
    useEffect(() => {
        getBooking();
    }, []);

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('site_feedback')} - {t('app')}</title>
                </Helmet>

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h3 className="text-primary">{t('site_feedback')}</h3>
                </Card.Subtitle>
                <Row className="mx-0 mt-2">
                    <Col className="col-6 mt-2 p-0">
                        <div className="me-auto">
                            <div className="text-black font-weight-bold order-heading">{t('truck_park')}</div>
                            <h5 className="font-weight-normal">{booking.site.name}</h5>
                        </div>
                    </Col>
                    <Col className="col-6 mt-2 p-0">
                        <div className="me-auto">
                            <div className="text-black font-weight-bold order-heading">{t('vehicle')}</div>
                            <h5 className="font-weight-normal">{booking.vehicle.registration_number}</h5>
                        </div>
                    </Col>
                </Row>
                <Row className="mx-0 mt-2">
                    {
                        booking.from_datetime !== "-" &&
                        <Col className="col-6 mt-2 p-0">
                            <div className="me-auto">
                                <div className="text-black font-weight-bold order-heading">{t('from')}</div>
                                <h5 className="font-weight-normal">{booking.from_datetime && moment(booking.from_datetime).format(t('formats.datetime'))}</h5>
                            </div>
                        </Col>
                    }
                    {
                        booking.to_datetime !== "-" &&
                        <Col className="col-6 mt-2 p-0">
                            <div className="me-auto">
                                <div className="text-black font-weight-bold order-heading">{t('to')}</div>
                                <h5 className="font-weight-normal">{booking.to_datetime && moment(booking.to_datetime).format(t('formats.datetime'))}</h5>
                            </div>
                        </Col>
                    }
                </Row>
                {
                    success ?
                    <div className="my-2">
                        <div className="alert alert-success" role="alert">
                            {t('site_feedback_sent')}
                        </div>
                        <div className="d-grid gap-2">
                            <Link variant="primary" className="btn btn-primary" to="/">{t('go_to_dashboard')}</Link>
                        </div>
                    </div> :
                    <Formik
                        initialValues={{
                            name   : auth.user.name,
                            email  : auth.user.email,
                            message: '',
                        }}
                        validationSchema={validation}
                        onSubmit={handleSubmit}
                    >
                        {({values, errors, touched}) => (
                            <Form>
                                <div className="form-group mt-4">
                                    {/*<p>{JSON.stringify(errors)}</p>*/}

                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="name" className="my-2">{t('name')}</label>
                                            <Field
                                                type="text"
                                                name="name"
                                                placeholder={t('enter_name')}
                                                className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                                            />
                                            <small>
                                                <ErrorMessage component="div" name="name" className="text-danger  py-1"/>
                                            </small>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="name" className="my-2">{t('email_address')}</label>
                                            <Field
                                                type="text"
                                                name="email"
                                                placeholder={t('enter_email_address')}
                                                className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                            />
                                            <small>
                                                <ErrorMessage component="div" name="email" className="text-danger  py-1"/>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12">
                                            <label htmlFor="username" className="my-2">{t('message')}</label>
                                            <Field
                                                as="textarea"
                                                type="text"
                                                name="message"
                                                placeholder={t('enter_message')}
                                                className={`form-control ${errors.message && touched.message ? 'is-invalid' : ''}`}
                                            />
                                            <small>
                                                <ErrorMessage component="div" name="message" className="text-danger  py-1"/>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mt-4">
                                    <div className="d-grid gap-2">
                                        {!sendingFeedback ?
                                         <Button type="submit" variant="primary">{t('submit')}</Button>
                                                          :
                                         <Button variant="primary" disabled>
                                             <Spinner
                                                 as="span"
                                                 animation="border"
                                                 size="sm"
                                                 role="status"
                                                 aria-hidden="true"
                                             />
                                             <span className="mx-2">{t('please_wait')}</span>
                                         </Button>
                                        }
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                }

            </Card>
        )
    )
}

export default SiteFeedback;
