import { useEffect }   from "react";
import { useTranslation }               from "react-i18next";
import { Form, Button } from "react-bootstrap";
import Wysiwyg, { validateWysiwyg, validateImg } from "@/components/Inputs/Wysiwyg";
import { useForm, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

function PoliceReport({ report, onBack, onSave, onSubmit, ...props}) {
  const {t} = useTranslation();

  const schema = yup.object().shape({
    police_report: yup.object().shape({
      filed_with_police: yup.boolean()
        .typeError(t('form_validation.is_required', { attribute: t('compliance.CrimeReport.filed_with_police') })),

      police_branch_name: yup.string()
        .max(255, t('form_validation.max_length', { attribute: t('compliance.CrimeReport.police_branch_name'), max: 255 }))
        .when(['filed_with_police'], (filed_with_police, schema) => (
          filed_with_police && schema.required(t('form_validation.is_required', { attribute: t('compliance.CrimeReport.police_branch_name') })) || schema.optional()
        )),

      police_reference: yup.string()
        .max(255, t('form_validation.max_length', { attribute: t('compliance.CrimeReport.police_reference'), max: 255 }))
        .when(['filed_with_police'], (filed_with_police, schema) => (
          filed_with_police && schema.required(t('form_validation.is_required', { attribute: t('compliance.CrimeReport.police_reference') })) || schema.optional()
        )),

      officer_name: yup.string()
        .max(255, t('form_validation.max_length', { attribute: t('compliance.CrimeReport.officer_name'), max: 255 }))
        .when(['filed_with_police'], (filed_with_police, schema) => (
          filed_with_police && schema.required(t('form_validation.is_required', { attribute: t('compliance.CrimeReport.officer_name') })) || schema.optional()
        )),

      police_contact_number: yup.string()
        .max(255, t('form_validation.max_length', { attribute: t('compliance.CrimeReport.police_contact_number'), max: 255 }))
        .when(['filed_with_police'], (filed_with_police, schema) => (
          filed_with_police && schema.required(t('form_validation.is_required', { attribute: t('compliance.CrimeReport.police_contact_number') })) || schema.optional()
        )),

      follow_up_actions: yup.string()
        .when(['filed_with_police'], (filed_with_police, schema) => (
          filed_with_police && schema.required(t('form_validation.is_required', { attribute: t('compliance.CrimeReport.follow_up_actions') })) || schema.optional()
        ))
        .max(65000, t('form_validation.max_length', { attribute: t('compliance.CrimeReport.follow_up_actions'), max: 65000 }))
    }),
  });


  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: (() => {
      const police_report = {...(report.police_report || {})};

      police_report.filed_with_police = police_report.filed_with_police === true ? '1' : (police_report.filed_with_police === false ? '0' : police_report.filed_with_police)

      return { police_report };
    })(),
    resolver: yupResolver(schema),
  });

  const police_report = watch('police_report');

  useEffect(() => {
    onSave({
      police_report,
    });
  }, [
    police_report,
  ]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <h3 className="h6 text-primary my-3">
        {t('compliance.CrimeReport.police_report')}
      </h3>

      <Form.Group className="mb-3">
        <label htmlFor="filed_with_police" className="my-2">{t('compliance.CrimeReport.filed_with_police')}</label>

        <div className={`row gx-3 mb-3 ${!!errors?.police_report?.filed_with_police?.message && 'is-invalid' || ''}`}>
          <label
              key={'police_reports.filed_with_police.true'}
              className="col">
              <Form.Check.Input
                type="radio"
                className="d-none"
                value={1}
                {...register('police_report.filed_with_police')}
              />

              <span className="btn btn-outline-primary w-100">{t('yes')}</span>
          </label>

          <label
              key={'police_reports.filed_with_police.false'}
              className="col">
              <Form.Check.Input
                type="radio"
                className="d-none"
                value={0}
                {...register('police_report.filed_with_police')}
              />

              <span className="btn btn-outline-primary w-100">{t('no')}</span>
          </label>
        </div>

        <Form.Control.Feedback type="invalid">
          {errors?.police_report?.filed_with_police?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className={`mb-3 ${police_report?.filed_with_police != '1' && 'd-none' || ''}`}>
        <label htmlFor="police_branch_name" className="my-2">{t('compliance.CrimeReport.police_branch_name')}</label>
        <Form.Control
          {...register('police_report.police_branch_name')}
          type="text"
          isInvalid={!!errors?.police_report?.police_branch_name}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.police_report?.police_branch_name && errors?.police_report?.police_branch_name.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className={`mb-3 ${police_report?.filed_with_police != '1' && 'd-none' || ''}`}>
        <label htmlFor="police_reference" className="my-2">{t('compliance.CrimeReport.police_reference')}</label>
        <Form.Control
          {...register('police_report.police_reference')}
          type="text"
          isInvalid={!!errors?.police_report?.police_reference}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.police_report?.police_reference && errors?.police_report?.police_reference.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className={`mb-3 ${police_report?.filed_with_police != '1' && 'd-none' || ''}`}>
        <label htmlFor="officer_name" className="my-2">{t('compliance.CrimeReport.officer_name')}</label>
        <Form.Control
          {...register('police_report.officer_name')}
          type="text"
          isInvalid={!!errors?.police_report?.officer_name}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.police_report?.officer_name && errors?.police_report?.officer_name.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className={`mb-3 ${police_report?.filed_with_police != '1' && 'd-none' || ''}`}>
        <label htmlFor="police_contact_number" className="my-2">{t('compliance.CrimeReport.police_contact_number')}</label>
        <Form.Control
          {...register('police_report.police_contact_number')}
          type="text"
          isInvalid={!!errors?.police_report?.police_contact_number}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.police_report?.police_contact_number && errors?.police_report?.police_contact_number.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className={`mb-3 ${police_report?.filed_with_police != '1' && 'd-none' || ''}`}>
        <label htmlFor="follow_up_actions" className="my-2">{t('compliance.CrimeReport.follow_up_actions')}</label>
        <Wysiwyg
          {...register('police_report.follow_up_actions')}
          blockEmbeds={false}
          preUpload={true}
          imageCompression={{ maxWidth: 1000 }}
          toolbar={false}
          isInvalid={!!errors?.police_report?.follow_up_actions?.message} />

        <Form.Control.Feedback type="invalid">
          {errors?.police_report?.follow_up_actions?.message}
        </Form.Control.Feedback>
      </Form.Group>


      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => onBack()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" type="submit">
          {t('continue')}
        </Button>
      </Form.Group >
    </Form>
  );
}

export default PoliceReport;
