import {ErrorMessage, Field}   from "formik";
import {Button, Form, Spinner} from "react-bootstrap";
import React, {useState}       from "react";
import {useTranslation}        from "react-i18next";
import {useAuth}               from "@/services/Auth";

function SuggestAParkingSite({handleSuggestSuccess}) {


    const {t, i18n} = useTranslation();

    let auth = useAuth();
    const postRequest = auth.postRequest;


    const [storingSiteSuggest, setStoringSiteSuggest] = useState(false);

    const [parkingSiteName, setParkingSiteName] = useState('');
    const [parkingSiteWebsite, setParkingSiteWebsite] = useState('');
    const [parkingSiteCity, setParkingSiteCity] = useState('');

    const [siteSuggestionServerErrors, setSiteSuggestionServerErrors] = useState([]);

    const suggestParkingSite = () => {
        setStoringSiteSuggest(true);
        setSiteSuggestionServerErrors([]);
        postRequest('suggest-site', {
            parking_site_name   : parkingSiteName,
            parking_site_website: parkingSiteWebsite,
            parking_site_city   : parkingSiteCity,

        })
            .then(response => {
                if (response.data.message === 'OK') {
                    setStoringSiteSuggest(false);
                    setParkingSiteName('');
                    setParkingSiteWebsite('');
                    handleSuggestSuccess('success_dialog.site_suggestion_sent');
                }
            })
            .catch(error => {
                setStoringSiteSuggest(false);
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }
                    setSiteSuggestionServerErrors(serverErrors)
                }
            })

    }

    return (
        <div className="form-group mt-4">
            <h5>{t('suggest_a_parking_site')}</h5>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-3">
                    <label htmlFor="parking_site_name" className="my-2">{t('parking_site_name')}</label>
                    <Form.Control type="text" placeholder={t('enter_parking_site_name')} value={parkingSiteName} onChange={e => setParkingSiteName(e.target.value)}/>
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                    <label htmlFor="parking_site_website" className="my-2">{t('parking_site_website')}</label>
                    <Form.Control type="text" placeholder={t('enter_parking_site_website')} value={parkingSiteWebsite} onChange={e => setParkingSiteWebsite(e.target.value)}/>
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                    <label htmlFor="enter_tow_city" className="my-2">{t('town_city')}</label>
                    <Form.Control type="text" placeholder={t('enter_town_city')} value={parkingSiteCity} onChange={e => setParkingSiteCity(e.target.value)}/>
                </div>
                <div className="col-12 col-sm-6 col-md-3 d-flex align-items-end mt-sm-0 mt-4">
                    {!storingSiteSuggest ?
                     <Button type="button" style={{width: '100%'}} variant="primary" onClick={suggestParkingSite}>{t('suggest_a_parking_site')}</Button>
                                         :
                     <Button variant="primary" style={{width: '100%'}} disabled>
                         <Spinner
                             as="span"
                             animation="border"
                             size="sm"
                             role="status"
                             aria-hidden="true"
                         />
                         <span className="mx-2">{t('please_wait')}</span>
                     </Button>
                    }
                </div>
            </div>
            <div className="row">
                {
                    (siteSuggestionServerErrors.length !== 0) &&
                    <div className="form-group mt-4">
                        {
                            siteSuggestionServerErrors.map((error, index) => <p className="text-danger font-weight-bold" key={index}>{error}</p>)
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default SuggestAParkingSite;
