import {useTranslation}             from "react-i18next";
import {useAuth}                    from "@/services/Auth";
import {useParams}                  from "react-router-dom";
import React, {useEffect, useState} from "react";
import LoadingPage                  from "@/components/LoadingPage";
import {Badge, Card, Col, Row}      from "react-bootstrap";
import QRCode from 'qrcode';
import SiteDetails                  from "../bookings/SiteDetails";
import { Helmet } from "react-helmet";

function SiteShowQRCode() {
    const {t, i18n} = useTranslation();
    let auth = useAuth();

    let {id} = useParams();

    const [site, setSite] = useState(null);
    const [src, setSrc] = useState(null);


    const getSite = () => {
        auth.getRequest('/sites/' + id)
            .then(response => {
                setSrc(null);
                setSite(response.data.data);
            })
            .catch(error => {
                console.log(error)
            })
    }

    const updateCurrentPageState = () => {

    }

    useEffect(() => {
        getSite();
    }, []);

    useEffect(() => {
        if(site && !src && site.token)
        {
            QRCode.toDataURL(process.env.REACT_APP_URL + '/scan-qr-code?code=' + btoa(JSON.stringify({
                site: {
                    id: site.id,
                    token: site.token,
                }
            }))).then(data => {
                setSrc(data);
            })
        }
    }, [site, src])

    return (
        <Card className="mx-2 my-2 p-2 order-card">
            <Helmet>
                <title>{site ? site.name : t('loading')} / {t('drawer_link_titles.sites')} - {t('app')}</title>
            </Helmet>

            {!site && <LoadingPage/> || ''}

            {site?.token && src && (
                <Card.Subtitle className="d-flex justify-content-center" style={{width: '100%'}}>

                    <div className="qr-code-contend d-flex text-center flex-column">
                        <img src={src}/>

                        <h4 className="text-uppercase">{t('site_id')}: {site.id}</h4>

                        <a href={src} className="btn btn-primary" download={`qrcode_site_${site.name}.png`}>{t('export_code')}</a>
                    </div>
                </Card.Subtitle>
            ) || ''}

            <Card.Body className="px-2">
                { site && <SiteDetails site={site} distance={null} duration={null} updateCurrentPageState={updateCurrentPageState} canRequest={false}/> || ''}
            </Card.Body>
        </Card>
    )
}

export default SiteShowQRCode
