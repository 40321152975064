import {Alert, Button, Card, Col, Row, Spinner} from "react-bootstrap";
import { useState, useEffect }                  from "react";
import { Link, useParams }                      from "react-router-dom";
import { useLayout }                            from "@/layouts/Layout";
import {useAuth}                           from '../../services/Auth'
import {useTranslation}                    from "react-i18next";
import * as Yup                            from "yup";
import {ErrorMessage, Field, Formik, Form} from "formik";
import Logo                                from "@/components/Logo";

function ResetPassword({email, code}) {

    const {t, i18n} = useTranslation();

    let auth = useAuth();
    const layout = useLayout();

    const [passwordIsReset, setPasswordIsReset] = useState(false);
    const [loading, setLoading] = useState(false);

    let passwordMinLength = 6;

    const validation = Yup.object().shape({
        password             : Yup.string()
                                  .min(passwordMinLength, t('form_validation.password_invalid_min_length', {length: passwordMinLength}))
                                  .required(t('form_validation.password_is_required')),
        password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], t('form_validation.password_is_must_match'))
                                  .required(t('form_validation.password_confirm_required')),
    });


    const resetPassword = auth.resetPassword;

    const onSubmit = (values) => {
        let resetValues = values;
        resetValues.email = email;
        resetValues.code = code;

        setLoading(true);

        resetPassword(resetValues)
            .then(response => {
                setLoading(false);
                setPasswordIsReset(true);
            })
            .catch(error => {
                setLoading(false);
                setPasswordIsReset(false);
            });
    };

    useEffect(() => {
        layout.setVideoBackground(true);

        return _ => layout.setVideoBackground(false);
    }, []);

    return (
        <div className="d-flex align-items-center justify-content-center my-2 px-2 p-md-4" style={{minHeight: '600px', height: '100%', width: '100%'}}>
            {passwordIsReset ? (
                <Card style={{maxWidth: '450px', width: '100%'}} className="login-card px-2 px-md-0 login-card-height login-card-md-height">
                    <Card.Title className="d-flex justify-content-center align-items-center py-5 d-md-none d-block">
                        <Logo as="div" className="mobile-logo" size="123"/>
                    </Card.Title>
                    <Card.Body>
                        <Alert variant="success" className="mb-0">
                            <h4>{t('password_is_reset')}</h4>
                            <p>{t('continue_sign_in')}</p>
                        </Alert>
                        <div className="d-grid gap-2 mt-3">
                            <Link to={`/login/${email}`} className="btn btn-primary">
                                {t('continue')}
                            </Link>
                        </div>
                    </Card.Body>
                </Card>

            ) : (
                 <Card style={{maxWidth: '450px', width: '100%'}} className="login-card px-2 px-md-0 login-card-height login-card-md-height">
                     <Card.Title className="d-flex justify-content-center align-items-center py-5 d-md-none d-block">
                         <Logo as="div" className="mobile-logo" size="123"/>
                     </Card.Title>
                     <Card.Body>
                         <Card.Title className="text-white">{t('create_password_header')}</Card.Title>

                         <Formik
                             initialValues={{
                                 password             : '',
                                 password_confirmation: '',
                             }}
                             validationSchema={validation}
                             onSubmit={onSubmit}
                         >
                             {({errors, touched}) => (
                                 <Form>

                                     <div className="form-group my-4">
                                         <div className="row">
                                             <div className="col-12">
                                                 <label htmlFor="password" className="my-2">{t('password')}</label>
                                                 <Field
                                                     type="password"
                                                     name="password"
                                                     placeholder={t('password')}
                                                     className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`}
                                                 />
                                                 <small>
                                                     <ErrorMessage component="div" name="password" className="text-danger  py-1"/>
                                                 </small>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="form-group my-4">
                                         <div className="row">
                                             <div className="col-12">
                                                 <label htmlFor="password_confirmation" className="my-2">{t('repeat_password')}</label>
                                                 <Field
                                                     type="password"
                                                     name="password_confirmation"
                                                     placeholder={t('password')}
                                                     className={`form-control ${errors.password_confirmation && touched.password_confirmation ? 'is-invalid' : ''}`}
                                                 />
                                                 <small>
                                                     <ErrorMessage component="div" name="password_confirmation" className="text-danger  py-1"/>
                                                 </small>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="form-group my-4">
                                         <div className="d-grid gap-2">
                                             {!loading ?
                                              <Button type="submit" variant="primary">
                                                  {t('reset_password')}
                                              </Button>
                                                       :
                                              <Button variant="primary" disabled>
                                                  <Spinner
                                                      as="span"
                                                      animation="border"
                                                      size="sm"
                                                      role="status"
                                                      aria-hidden="true"
                                                  />
                                                  <span className="mx-2">{t('please_wait')}</span>
                                              </Button>
                                             }
                                         </div>
                                     </div>
                                 </Form>
                             )}
                         </Formik>
                     </Card.Body>
                 </Card>
             )}
        </div>
    );
}

export default ResetPassword;
