import React, {useEffect, useState} from "react";
import {useTranslation}             from "react-i18next";
import {useAuth}                    from "@/services/Auth";
import LoadingPage                  from "@/components/LoadingPage";
import SuccessDialog                from "../../../components/Modals/SuccessDialog";
import ConfirmDialog                from "../../../components/Modals/ConformDialog";
import {Card}                       from "react-bootstrap";
import SiteForm                     from "./components/SiteForm";
import {useParams}                  from "react-router-dom";
import { Helmet } from "react-helmet";

function SiteCreate(props) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();

    let { supplier: id } = useParams();
    const postRequest = auth.postRequest;

    const [createSuccess, setCreateSuccess] = useState(false);

    const [storingSite, setStoringSite] = useState(false);
    const [createdSiteID, setCreatedSiteID] = useState(false);
    const [serverErrors, setServerErrors] = useState([]);

    const handleSubmit = (payload) => {
        setStoringSite(true);
        setServerErrors([]);
        console.log(payload);
        postRequest('sites', payload)
            .then(response => {
                setStoringSite(false);
                setCreateSuccess(true);
                setCreatedSiteID(response.data.site_id);
            })
            .catch(error => {
                setStoringSite(false);
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }
                    setServerErrors(serverErrors)
                }
            });
    }


    const handleSuccess = (value) => {
        setCreateSuccess(value);
    }

    const handleClose = () => {
        setCreateSuccess(false);
        props.history.push(`/sites/${createdSiteID}`)
    };

    return (
        <div>
            <Helmet>
                <title>{t('create')} / {t('drawer_link_titles.sites')} - {t('app')}</title>
            </Helmet>

            <SuccessDialog
                show={createSuccess}
                message="site_created"
                handleClose={handleClose}
            />
            <Card className="mx-2 my-2 p-2">
                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h4 className="text-primary">{t('new_site')}</h4>
                </Card.Subtitle>
                <SiteForm
                    handleOnSubmit={handleSubmit}
                    handleSuccess={handleSuccess}
                    buttonLoading={storingSite}
                    serverErrors={serverErrors}
                    siteAssignedSupplier={id}
                    action="create"
                    site={{
                        images             : [],
                        facilities         : [],
                        security_facilities: [],
                        supplier_id            : id,
                        name                   : '',
                        email                  : '',
                        phone_number           : '',
                        security_rating        : 0,
                        description            : '',
                        total_spaces           : 0,
                        currency               : 'GBP',
                        parking_available      : false,
                        vat_registration_number: '',
                        longitude              : '',
                        latitude               : '',
                        address                : '',
                        city                   : '',
                        country_id             : '',
                        county                 : '',
                        post_code              : '',
                        supplier_payment_terms              : '',
                        supplier_payment_terms_days         : '',
                        prebookable            : false,
                        parking_prices         : [],
                        services               : [],
                        commission_rates       : [],
                        commission_rate_type   : '',
                        commission_rate_value  : '',
                    }}
                    isEdit={false}
                />
            </Card>
        </div>
    )
}

export default SiteCreate;
