import {Button, Card, Col, Container, Row, Spinner} from "react-bootstrap";
import {useEffect, useState}                        from "react";
import {Link, Redirect, useParams, useLocation}                 from "react-router-dom";
import {useAuth}                                    from '@/services';
import { useLayout }                                from "@/layouts/Layout";
import {useTranslation}                             from "react-i18next";
import {ErrorMessage, Field, Form, Formik}          from 'formik';
import * as Yup                                     from "yup";
import Logo                                         from "@/components/Logo";
import ReCAPTCHA                                    from "react-google-recaptcha";

function Login(props) {
    const {t, i18n} = useTranslation();
    let {sentMail} = useParams();
    const location = useLocation();

    let auth = useAuth();
    const layout = useLayout();

    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState('');
    let passwordMinLength = 6;

    const validation = Yup.object().shape({
        email   : Yup.string()
                     .required(t('form_validation.is_required',{attribute: t('either_or', {attribute1: t('email_address'), attribute2: t('phone_number')})})),
        password: Yup.string()
                     .min(passwordMinLength, t('form_validation.password_invalid_min_length', {length: passwordMinLength}))
                     .required(t('form_validation.password_is_required')),
        // recaptcha: Yup.string().required()
    });

    const handleSubmit = (values) => {
        setLoading(true);
        setServerError('');
        auth.signIn(values)
            .then(response => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                if (error?.response?.status === 401) {
                    setServerError(error.response.data.message)
                }
            })
    };

    const prepareReCaptchaScript = () => {
        const script = document.createElement("script");
        script.src =
            "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    useEffect(() => {
        prepareReCaptchaScript();
    }, [])

    useEffect(() => {
        layout.setVideoBackground(true);

        return _ => layout.setVideoBackground(false);
    }, []);

    return (
        auth.user ? (
            <Redirect to={
                location?.state?.next ||
                {
                    pathname: '/',
                    state   : {
                        from: props.location
                    }
                }
            }/>
        ) : (
            <Container className="login-container">
                <Row style={{height: '100%'}}>
                    <Col className="col-12 d-none d-md-flex align-items-center justify-content-center" md={6}>
                        <Logo className="desktop-logo" size="250"/>
                    </Col>
                    <Col className="col-12 d-flex align-items-center justify-content-center my-2" md={6}>
                        <Card style={{width: '450px', minWidth: '300px'}} className="login-card px-2 px-md-0 login-card-height login-card-md-height">
                            <Card.Title className="d-flex justify-content-center align-items-center py-5 d-md-none d-block">
                                <Logo as="div" className="mobile-logo" size="150"/>
                            </Card.Title>
                            <Card.Body>
                                <Formik
                                    initialValues={{
                                        email    : sentMail,
                                        password : '',
                                        recaptcha: '',
                                    }}
                                    validationSchema={validation}
                                    onSubmit={handleSubmit}
                                >
                                    {({errors, touched}) => (
                                        <Form>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label htmlFor="email" className="my-2">{t('either_or', {attribute1: t('email_address'), attribute2: t('phone_number_intl')})}</label>
                                                        <Field
                                                            type="text"
                                                            name="email"
                                                            placeholder={t('either_or', {attribute1: t('enter_email_address'), attribute2: t('enter_phone_number')})}
                                                            className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                                        />
                                                        <small>
                                                            <ErrorMessage component="div" name="email" className="text-danger  py-1"/>
                                                        </small>
                                                        {
                                                            serverError !== '' &&
                                                            <p className="text-danger py-1 mb-0">{serverError}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mt-2">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label htmlFor="email" className="my-2">{t('password')}</label>
                                                        <Field
                                                            type="password"
                                                            name="password"
                                                            placeholder={t('password')}
                                                            className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`}
                                                        />
                                                        <small>
                                                            <ErrorMessage component="div" name="password" className="text-danger py-1"/>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group my-3">
                                                <Link to="/forgot-password" className="mx-0 px-0">{t('forgot_password')}</Link>
                                            </div>
                                            <div className="d-grid gap-2 mt-2">
                                                {/*<ReCAPTCHA*/}
                                                {/*    sitekey="6LfVo2cdAAAAADzfwBSy3zCGNhNVSH9s1TlDIWOS"*/}
                                                {/*    render="explicit"*/}
                                                {/*    verifyCallback={(response) => {*/}
                                                {/*        console.log(response);*/}
                                                {/*        // setFieldValue("recaptcha", response);*/}
                                                {/*    }}*/}
                                                {/*    onloadCallback={() => {*/}
                                                {/*        console.log("done loading!");*/}
                                                {/*    }}*/}
                                                {/*/>*/}
                                                {/*{errors.recaptcha*/}
                                                {/* && touched.recaptcha && (*/}
                                                {/*     <p>{errors.recaptcha}</p>*/}
                                                {/* )}*/}
                                                {
                                                    !loading ?
                                                    <Button type="submit" variant="success">
                                                        {t('login')}
                                                    </Button>
                                                             :
                                                    <Button variant="success" disabled>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        <span className="mx-2"> {t('please_wait')}</span>
                                                    </Button>
                                                }
                                            </div>
                                        </Form>

                                    )}
                                </Formik>
                            </Card.Body>
                            {/*<Card.Footer>*/}
                            {/*    <div className="d-grid gap-2 my-3">*/}
                            {/*        <Link to="/sign-up" className="btn btn-light">*/}
                            {/*            {t('sign_up')}*/}
                            {/*        </Link>*/}
                            {/*    </div>*/}
                            {/*</Card.Footer>*/}
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    );
}

export default Login;


