import { useAuth } from "@/services";
import { CheckIcon } from "@/components/icons";
import { Badge } from 'react-bootstrap';


function ComplianceBadges()
{
  const auth = useAuth();

  return (
    <div className="badges">
      {auth?.complianceFigures?.outstanding && (
        <Badge bg="warning">{auth.complianceFigures.outstanding}</Badge>
      ) || ''}

      <CheckIcon />
    </div>
  );
}


export default ComplianceBadges;
