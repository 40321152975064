import React, { useEffect, useState, useRef }   from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";


function DisplayAttachments({ attachments, preview })
{
  const { t, i18n } = useTranslation();
  const [img, setImg] = useState(null);

  const onImgClick = (event, attachment) => {
    setImg(attachment.url);
  };

  return (
    <>
      <div className="attachments">
        {attachments?.map && attachments.map((attachment, i) => (
          <div key={i}>
            {preview && (
              <button className="btn btn-link aspect-container d-print-none" onClick={(e) => onImgClick(e, attachment)} style={{ width: '120px' }}>
                <img src={attachment.url} loading="lazy" className="d-print-none" />
              </button>
            ) || (
              <Button size="sm" onClick={(e) => onImgClick(e, attachment)} className=" d-print-none">
                {t('compliance.view_image')}
              </Button>
            )}

            <img src={attachment.url} loading="lazy" width="320" className="d-none d-print-inline-block" />

          </div>
        ))}
      </div>

     <Modal show={!! img} onHide={_ => setImg(null)} fullscreen={true}>
      <Modal.Header closeButton>
      </Modal.Header>

      <Modal.Body className="d-flex align-items-center justify-content-center">
        <img src={img} style={{ maxWidth: '100%' }} />
      </Modal.Body>

    </Modal>
    </>
  );
}


export default DisplayAttachments;
