import Dashboard from "../views/pages/dashboard/Dashboard";
import errors    from './routes/errorsRoutes'

import authRoutes      from "./routes/authRoutes";
import clientsRoutes   from "./routes/clientsRoutes";
import usersRoutes     from "./routes/usersRoutes";
import favouriteRoutes from "./routes/favouritesRoutes";
import fleetsRoutes    from "./routes/fleetsRoutes";
import helpRoutes      from "./routes/helpRoutes";
import orderRoutes     from "./routes/ordersRoutes";
import promotionRoutes from "./routes/promotionRoutes";
import reportRoutes    from "./routes/reportRoutes";
import settingsRoutes  from "./routes/settingsRoutes";
import sitesRoutes     from "./routes/sitesRoutes";
import suppliersRoutes from "./routes/suppliersRoutes";
import vehiclesRoutes  from "./routes/vehiclesRoutes";
import bookingRoutes   from "./routes/bookingRoutes";
import instantBookingRoutes   from "./routes/instantbookingRoutes";
import qrCodeRoutes    from "./routes/qrCodeRoutes";
import paymentMethods  from "./routes/paymentMethodRoutes";
import complianceRoutes  from "./routes/complianceRoutes";
import notifications   from "./routes/notificationRoutes";
import myHistory       from "./routes/myHistoryRoutes";
import SuppliersIcon   from "../components/icons/SuppliersIcon";
import DashboardIcon   from "../components/icons/DashboardIcon";


const commonRoutes = [
    {
        path         : "/",
        component    : Dashboard,
        exact        : true,
        title        : 'Dashboard',
        translate_key: 'dashboard',
        // icon         : 'bi-speedometer',
        icon         : <DashboardIcon/>,
        main         : true,
    },
];

const routes = [
    ...authRoutes,
    ...commonRoutes,
    ...paymentMethods,
    ...usersRoutes,
    ...clientsRoutes,
    ...fleetsRoutes,
    ...vehiclesRoutes,
    ...suppliersRoutes,
    ...sitesRoutes,
    ...promotionRoutes,
    ...orderRoutes,
    ...reportRoutes,
    ...favouriteRoutes,
    ...settingsRoutes,
    ...complianceRoutes,
    ...helpRoutes,
    ...bookingRoutes,
    ...myHistory,
    ...qrCodeRoutes,
    ...notifications,
    ...instantBookingRoutes,
    ...errors,
];

// console.log('routes');
// console.log(routes);

export default routes;
