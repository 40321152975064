import {Button, Form} from "react-bootstrap";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from 'react-hook-form';
import * as moment from 'moment-timezone';
import LoadingPage from "@/components/LoadingPage";
import {useAuth} from "@/services/Auth";
import {Helmet} from "react-helmet";

function Quote({site, booking, onSubmit, setBookingErrors, vehicle, driver, cancelBooking, amendBooking}) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const {t} = useTranslation();
    let auth = useAuth();

    const [pricing, setPricing] = useState(false);

    const {
        handleSubmit,
    } = useForm();

    useEffect(() => {
        if (!pricing) {
            auth.postRequest('bookings/quote', booking)
                .then(response => {
                    setPricing(response.data);
                })
                .catch(error => {
                    if (error.response.status == 422) {
                        setBookingErrors(error.response.data.errors);
                    }
                })
        }

    }, [pricing]);

    const notOnAccountServices = pricing?.services?.filter && pricing.services.filter(_ => !_.on_account) || [];

    if (!pricing) {
        return <LoadingPage/>;
    }

    const validateBooking = () => {
        if (notOnAccountServices.length > 0) {
            return;
        }
        onSubmit();
    }

    return (
        <Form noValidate onSubmit={handleSubmit(validateBooking)}
              className="booking booking-quote container-fluid" style={{"fontSize": '1.2rem'}}>
            <Helmet>
                <title>{t('booking_summary')} / {t('drawer_link_titles.bookings')} - {t('app')}</title>
            </Helmet>

            <h6 className="text-primary">
                {t('booking.instant_booking')}
            </h6>

            <p className="extra-small">{site.name}</p>
            <p className="extra-small">
                {moment.utc(moment.now()).tz(timezone).format(t('formats.short_datetime_reverse'))}
            </p>
            <p className="extra-small">{driver.name}</p>
            <p className="extra-small">{vehicle.registration_number}</p>
            {
                pricing.services.length > 0 && (
                    pricing.services.map((service, key) => {
                        return service.name === 'parking' &&
                            <p key={key} className={service.on_account ? 'extra-small' : 'extra-small text-danger'}>
                                {t('instant_booking.parking')}: {booking.duration < 24 ? t('n_hours', {count: booking.duration}) : t('n_days', {count: 1})}
                            </p>
                    })
                )
            }
            {
                (
                    (booking.parking_booking == '1' && pricing.services.length > 1) ||
                    (booking.parking_booking == '0' && pricing.services.length > 0)
                )
                && (
                    <>
                        <p className="extra-small">{t('addons')}</p>

                        <ul>
                            {pricing.services.map((service, key) => {
                                return service.name !== 'parking' &&
                                    <li key={key}
                                        className={service.on_account ? 'extra-small' : 'extra-small text-danger'}>
                                        {service.name}
                                    </li>
                            })}
                        </ul>
                    </>
                )
            }

            {
                notOnAccountServices.length !== 0 && (
                    <div className="text-danger d-inline-flex extra-small gap-2">
                        <img src="/images/error-icon.png" alt="error-icon" className="img-fluid error-icon"/>
                        {t('instant_booking.error')}
                    </div>
                )
            }

            <div className="mt-4">
                {notOnAccountServices.length > 0 ? (
                    <div className="mt-4">
                        <Button className="w-100" onClick={amendBooking}>
                            {t('instant_booking.amend')}
                        </Button>
                    </div>
                ) : (
                    <div className="mt-4">
                        <Button type="submit" variant="success" className="w-100">
                            {t('confirm_booking')}
                        </Button>
                    </div>
                )}
                <div className="mt-4">
                    <Button className="w-100" variant="danger" onClick={cancelBooking}>
                        {t('cancel')}
                    </Button>
                </div>
            </div>
        </Form>
    );
}

export default Quote;
