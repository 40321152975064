import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAuth } from "@/services/Auth";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Form, Modal } from "react-bootstrap";


function RequestOptions({ booking, setLoading, updatedBooking }) {
  const {t} = useTranslation();
  const auth = useAuth();

  const schema = yup.object().shape({
    site_comments: yup.string().nullable().max(65000, t('form_validation.max_length', { attribute: t('comments'), length: 65000} )),
  });

  const approveOrder = (values) => {
    const data = { action: orderAction, ...values };

    setOrderAction(null);
    setLoading && setLoading(true);

    auth.postRequest(`/bookings/${booking.id}/respond`, data)
      .then(response => {
        if (response.data.message === 'OK') {
          updatedBooking && updatedBooking(response.data.booking);
        }
      })
      .finally(error => {
        setLoading && setLoading(false);
      })
  }



  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });


  const [orderAction, setOrderAction] = useState(false);

  return (
    <div className="d-flex flex-md-row flex-column">
      <Button variant="success" className="mx-1 my-1 my-md-0" onClick={() => setOrderAction('APPROVE')}>{t('approve')}</Button>
      <Button variant="danger" className="mx-1 my-1 my-md-0" onClick={() => setOrderAction('REJECT')}>{t('reject')}</Button>

      <Modal show={!!orderAction} onHide={_ => setOrderAction(null)}>

        <Form noValidate onSubmit={handleSubmit(approveOrder)}>

          <Modal.Header closeButton>
            <Modal.Title>
              {orderAction == 'APPROVE' &&
                t('conform_dialog.order_confirm.message') ||
                t('conform_dialog.order_reject.message')}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group>
              <Form.Label className="h4">
                {t('comments')}
              </Form.Label>

              <Form.Control
                as="textarea"
                rows={3}
                {...register('site_comments')}
                placeholder={t('comments_to_driver_placeholder')}
                />

              <Form.Control.Feedback type="invalid" className={!!errors.site_comments && 'd-block'}>
                {errors.site_comments?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={_ => setOrderAction(null)}>
              {orderAction == 'APPROVE' &&
                t('conform_dialog.order_confirm.cancel') ||
                t('conform_dialog.order_reject.cancel')}
            </Button>

            <Button variant="primary" type="submit">
              {orderAction == 'APPROVE' &&
                t('conform_dialog.order_confirm.accept') ||
                t('conform_dialog.order_reject.message')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

    </div>

  );
}


export default RequestOptions;
