import {Badge}          from "react-bootstrap";
import React            from "react";
import {useTranslation} from "react-i18next";

function OpenHours({facilities}) {
    const {t, i18n} = useTranslation();
    return (
        <div>
            {
                facilities.map(facility => {
                    if (facility.name === "24/7") {
                        return (
                            <div className="d-flex align-items-center mb-1" key={facility.id}>
                                <img className="service-icon" src={`/images/facilities/${2}.svg`}/>
                                <span className="mx-1 text-nowrap facility-name-site-card">{t('open_24_hours')}</span>
                            </div>
                        )
                    }
                })
            }
        </div>
    )
}

export default OpenHours;
