import React, { useEffect, useState, useRef }   from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

function Buttons({ buttons, edit, updateButtons }) {
  /**
   * IMPORTANT!!!!
   * Make sure to update the counter part in the laravel blade template
   */


  const { t } = useTranslation();

  const buttonContainer = useRef(null);

  const [editButton, setEditButton] = useState(false);

  const addButton = () => {
    const newButtons = buttons?.map && [].concat(buttons) || [];

    newButtons.push({
      variant: 'light',
      title: 'new button',
    });

    updateButtons(newButtons);

    setEditButton(newButtons.length - 1);
  };


  const updateButton = (button) => {
    const newButtons = buttons?.map && [].concat(buttons) || [];

    Object.assign(newButtons[editButton], button);

    updateButtons(newButtons);

    setEditButton(false);
  };

  const deleteButton = () => {
    const newButtons = buttons?.map && [].concat(buttons) || [];

    newButtons.splice(editButton, 1);

    updateButtons(newButtons);

    setEditButton(false);
  };

  const moveButton = ({oldIndex, newIndex}) => {
    const newButtons = buttons?.map && [].concat(buttons) || [];

    const items = newButtons.splice(oldIndex, 1);

    newButtons.splice(newIndex, 0, items[0]);

    updateButtons(newButtons);
  };


  const schema = yup.object().shape({

    href: yup.string()
      .required(t('form_validation.is_required', { attribute: t('marketing.button.href') })),

    title: yup.string()
      .required(t('form_validation.is_required', { attribute: t('marketing.button.title') })),

    variant: yup.string()
      .required(t('form_validation.is_required', { attribute: t('marketing.button.variant') })),

    target: yup.string()
      .oneOf(['_self', '_blank'])
      .required(t('form_validation.is_required', { attribute: t('marketing.button.target') })),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const title = watch('title');

  useEffect(() => {
    if(editButton !== false)
    {
      reset(buttons[editButton]);
    }
  }, [editButton]);

  return (
    <div className="buttons" ref={buttonContainer}>


      {edit && (
        <>
          <Modal show={editButton !== false} onHide={_ => setEditButton(false)}>
            <Form noValidate onSubmit={handleSubmit(updateButton)}>

            <Modal.Header closeButton>
              <Modal.Title>{t('marketing.button.edit')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Group className="mb-3">
                <label htmlFor="title" className="my-2">{t('marketing.button.title')}</label>
                <Form.Control
                  {...register('title')}
                  type="text"
                  isInvalid={!!errors?.title}
                />

                <Form.Control.Feedback type="invalid">
                  {errors?.title && errors?.title.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <label htmlFor="href" className="my-2">{t('marketing.button.href')}</label>
                <Form.Control
                  {...register('href')}
                  type="text"
                  isInvalid={!!errors?.href}
                />

                <Form.Control.Feedback type="invalid">
                  {errors?.href && errors?.href.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <label htmlFor="target" className="my-2">{t('marketing.button.target')}</label>
                <Form.Select
                  {...register('target')}
                  isInvalid={!!errors?.target}
                >
                  <option value="_self">{t('marketing.button.targets._self')}</option>
                  <option value="_blank">{t('marketing.button.targets._blank')}</option>
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  {errors?.target && errors?.target.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <label htmlFor="variant" className="my-2">{t('marketing.button.variant')}</label>


                <Row>
                {['primary', 'secondary', 'danger', 'warning', 'info', 'light', 'dark', 'outline-primary', 'outline-secondary', 'outline-danger', 'outline-warning', 'outline-info', 'outline-light', 'outline-dark',].map(variant => (
                  <label key={variant} className={`col-6 col-lg-4 col-xl-3 pb-3 d-flex align-items-center ${/^outline/.test(variant) ? 'bg-dark' : ''} `}>
                    <span className={`btn btn-${variant} w-100 ms-2 order-2`}>{title}</span>

                    <span className="d-block">
                      <Form.Check.Input
                          type="radio"
                          {...register('variant')}
                          value={variant}
                      />
                    </span>
                  </label>


                ))}
                </Row>

                <Form.Control.Feedback type="invalid">
                  {errors?.variant && errors?.variant.message}
                </Form.Control.Feedback>
              </Form.Group>

            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={deleteButton}>
                  {t('delete')}
              </Button>

              <Button variant="success" type="submit">
                  {t('save')}
              </Button>
            </Modal.Footer>
            </Form>
          </Modal>

          <SortableContainer useDragHandle axis="xy" onSortEnd={moveButton} helperContainer={_ => buttonContainer.current}>
            {buttons?.map && buttons.map((button, index) => (
              <SortableItem {...{ key: `block-${index}`, index, setEditButton: _ => setEditButton(index), button }} />
            ))}
          </SortableContainer>

          <Button variant="success" onClick={addButton}>
            <span className="bi bi-plus-lg" />
          </Button>
        </>
      ) || buttons?.map && (
        <div className="buttons-container">
          {buttons.map(({title, ...button}, index) => (
            <span key={index} className="button-container">
              <Button {...button}>
                {title}
              </Button>
            </span>
          ))}
        </div>
      ) || ''}
    </div>
  );
};

const SortableContainer = sortableContainer(({ children }) => (
  <div className="buttons-container">
    {children}
  </div>
));

const SortableHandle = sortableHandle(({children, items, ...props}) => (
  <span className="sort-handle btn btn-sm btn-primary btn-outline-light block-option">
    <span className="bi bi-arrows-move" />
  </span>
))


const SortableItem = sortableElement(({ button: { title, href, target, ...button }, setEditButton, ...props }) => {
  const { t } = useTranslation();

  return (
    <span className="button-container">
      <Button {...button} onClick={_ => setEditButton()} title={t('marketing.button.click_to_edit')}>
        {title}
      </Button>

      <span className="button-nav">
        <SortableHandle />
      </span>
    </span>
  );
});

export default Buttons;
