import { useHistory, useLocation, Redirect, Link } from "react-router-dom";
import React, { useEffect, useState, useCallback, useRef }   from "react";
import { useTranslation }               from "react-i18next";
import { Helmet } from "react-helmet";
import { useAuth, Countries, Storage }   from "@/services";
import { Card, Form, Button, ProgressBar } from "react-bootstrap";
import moment from 'moment-timezone';
import LoadingPage from "@/components/LoadingPage";
import Wysiwyg, { validateWysiwyg } from "@/components/Inputs/Wysiwyg";
import { useForm, useFieldArray } from 'react-hook-form';
import { useJsApiLoader } from '@react-google-maps/api';
import * as yup from 'yup';
import Select from "react-select";
import { yupResolver } from '@hookform/resolvers/yup';
import PoliceReport from "./components/PoliceReport";
import DisplayWysiwyg from "@/components/Elements/DisplayWysiwyg";
import DisplayLocation from "@/components/Elements/DisplayLocation";
import LocationFields, { useLocationFieldsSchema } from "@/components/Forms/LocationFields";
import { CheckIcon } from "@/components/icons";
import { useLayout } from "@/layouts/Layout";

function CrimeReport() {
  const auth = useAuth();
  const main = useLayout();
  const {t} = useTranslation();
  const route = useLocation();
  const history = useHistory();

  const vehicle = route?.state?.vehicle || undefined;
  const occurance_date = route?.state?.occurance_date || undefined;
  const storage_key = route?.state?.storage_key;

  const [report, setReport] = useState(undefined);

  const steps = ['incident_information', 'incident_description', 'parties_involved', 'witnesses', 'police_report', 'summary'];
  const step = route?.state?.step || 'incident_information';
  const step_index = steps.findIndex(_ => step == _);


  const updateStep = step => {
    history.push(route.pathname, {
      ...(route.state || {}),

      step,
    });
  };

  const onBack = () => {
    var target = steps.findIndex(_ => _ == step);

    if(target < 0 || target > steps.length - 1)
    {
      target = steps.length;
    }

    target --;

    updateStep(steps[target]);
  };

  const next = () => {
    var target = steps.findIndex(_ => _ == step);

    if(target < 0 || target > steps.length - 1)
    {
      target = -1;
    }

    target ++;

    updateStep(steps[target]);
  };


  const [loading, setLoading] = useState(false);

  const onSave = values => {
    const _report = { ...report, ...values };

    setReport(_report);
  };

  const onSubmit = values => {
    const _report = { ...report, ...values };

    setReport(_report);

    next();
  };

  const save = _ => {
    const data = {
      type: 'CrimeReport',
      vehicle_id: vehicle.id,
      ...report,
      persons: JSON.parse(JSON.stringify(report.persons)),
    };

    data.persons.forEach(_ => _.type = _.type === 'parties_involved' ? 'PartyInvolved' : 'Witness');

    setLoading(true);

    // TODO convert times to UTC

    auth.postRequest('reports', data)
        .then(response => {
          setReport(response.data.data);
        })
        .finally(_ => setLoading(false));
  };

  useEffect(() => {
    if(report?.guid)
    {
      Storage.Session.removeItem(storage_key);
    }

    const onUnload = _ => {
      if(report && !report.guid)
      {
        Storage.Session.setItem(storage_key, report);
      }
    };

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [report]);

  useEffect(() => {
    if(report === undefined)
    {
      const defaultValues = {
        occurance_at: occurance_date,
      };

      if(storage_key)
      {
        Storage.Session.getItem(storage_key).then(value => {
          setReport(value || defaultValues);
        });
      }
      else
      {
        setReport(defaultValues);
      }
    }
    else
    if(Object.keys(report).length <= 0)
    {
      if(step != steps[0])
      {
        updateStep(steps[0]);
      }
    }
  }, [report, step]);

  useEffect(() => {
    if(!storage_key)
    {
      history.replace(route.pathname, {
        ...(route.state || {}),

        storage_key: 'crime-report-' + vehicle?.id + '-' + moment().toISOString(),
      });
    }

  }, [storage_key])

  useEffect(() => {
    main.scrollTop();
  }, [step])

  if(loading || !report)
  {
    return <LoadingPage />;
  }

  if(report.guid)
  {
    return (
      <div className="container">
        <Helmet>
          <title>{t('compliance.CrimeReport.title')} - {t('app')}</title>
        </Helmet>

        <h1 className="h4 mb-3 text-primary">
          {t('compliance.CrimeReport.title')}
        </h1>

        <ProgressBar variant="success" now={100} />


        <div className="large-icon-message">
          <CheckIcon variant="success" />

          <h3 className="h6 text-success strong mt-3 text-center">
            {t('n_have_now_been_completed', { n: t('compliance.CrimeReport.title') })}
          </h3>
        </div>


        <Link className="btn btn-primary w-100" to={{ pathname: '/compliance', state: { vehicle_id: vehicle.id, occurance_date } }}>
          {t('back')}
        </Link>
      </div>
    );
  }

  return (
    <div className="container">
      <Helmet>
        <title>{t('compliance.CrimeReport.title')} - {t('app')}</title>
      </Helmet>

      <h1 className="h4 mb-3 text-primary">
        {t('compliance.CrimeReport.title')}
      </h1>

      <ProgressBar variant="success" now={(step_index + 1) / steps.length * 100} />

      {(_ => {
        switch(step)
        {
          case 'incident_information':
          default:
            return <IncidentDetails {...{ report, onBack, onSave, onSubmit }} />;

          case 'incident_description':
            return <IncidentDecription {...{ report, onBack, onSave, onSubmit }} />;

          case 'parties_involved':
          case 'witnesses':
            return <Persons key={step} {...{ report, onBack,
            onSave: values => {
              values.persons.forEach(_ => _.type = step)

              values.persons = values.persons.concat(
                report?.persons?.filter && report.persons.filter(_ => _.type != step) || []
              ).filter(_ => ['parties_involved', 'witnesses'].includes(_.type));

              onSave(values);
            },
            onSubmit: values => {
              values.persons.forEach(_ => _.type = step)

              values.persons = values.persons.concat(
                report?.persons?.filter && report.persons.filter(_ => _.type != step) || []
              ).filter(_ => ['parties_involved', 'witnesses'].includes(_.type));

              onSubmit(values);
            }, type: step }} />;

          case 'police_report':
            return <PoliceReport {...{ report, onBack, onSave, onSubmit }} />;
          case 'summary':
            return <Summary {...{ report, onBack, onSave, onSubmit: save }} />;
        }

      })()}
    </div>
  );
}

export default CrimeReport;


function IncidentDetails({ report, onBack, onSave, onSubmit, ...props}) {
  const {t} = useTranslation();

  const schema = yup.object().shape({
    occurance_at: yup.date()
      .required(t('form_validation.is_required', { attribute: t('compliance.CrimeReport.date') })),

    detail: yup.object().shape({
      type: yup.string()
        .required(t('form_validation.is_required', { attribute: t('compliance.CrimeReport.type') })),

    }),

    location: useLocationFieldsSchema(),
  });


  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: (() => {
      const values = {... report};

      if(values?.detail?.occurance_at)
      {
        values.detail.occurance_at = moment(values.detail.occurance_at).format('YYYY-MM-DD');
      }

      return values;
    })(),
    resolver: yupResolver(schema),
  });

  const detail = watch('detail');
  const location = watch('location');

  useEffect(() => {
    onSave({
      detail,
      location,
    });
  }, [
    detail,
    location,
  ]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>

      <h3 className="h6 text-primary my-3">
        {t('compliance.incident_information')}
      </h3>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2">{t('compliance.CrimeReport.type')}</label>
        <Form.Select
          {...register('detail.type')}
          isInvalid={!!errors?.detail?.type}
        >

            <option value="">--{t('choose')}--</option>

            <option value="Robbery">{t('compliance.CrimeReport.types.Robbery')}</option>
            <option value="Mugging">{t('compliance.CrimeReport.types.Mugging')}</option>
            <option value="Assault">{t('compliance.CrimeReport.types.Assault')}</option>
            <option value="VehicleTheft">{t('compliance.CrimeReport.types.VehicleTheft')}</option>
            <option value="VehicleDamage">{t('compliance.CrimeReport.types.VehicleDamage')}</option>
            <option value="FuelTheft">{t('compliance.CrimeReport.types.FuelTheft')}</option>
            <option value="Hijacking">{t('compliance.CrimeReport.types.Hijacking')}</option>
            <option value="Terrorist">{t('compliance.CrimeReport.types.Terrorist')}</option>
            <option value="Stowaways">{t('compliance.CrimeReport.types.Stowaways')}</option>
        </Form.Select>

        <Form.Control.Feedback type="invalid">
          {errors?.detail?.type && errors?.detail?.type.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="occurance_at" className="my-2">{t('compliance.CrimeReport.date')}</label>
        <Form.Control
          {...register('occurance_at')}
          type="date"
          isInvalid={!!errors?.occurance_at}
        />

        <Form.Control.Feedback type="invalid">
          {errors?.occurance_at && errors?.occurance_at.message}
        </Form.Control.Feedback>
      </Form.Group>

      <LocationFields {...{ register, errors, setValue, path: 'location.', useCurrentLocation: true }} />


      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" type="submit">
          {t('continue')}
        </Button>
      </Form.Group >
    </Form>
  );
}


function IncidentDecription({ report, onBack, onSave, onSubmit, ...props}) {
  const {t} = useTranslation();

  const wysiwyg = useRef({});

  const schema = yup.object().shape({
    detail: yup.object().shape({
      description: yup.string()
        .required(t('form_validation.is_required', { attribute: t('description') }))
        .test(
          'max-content',
          t('form_validation.max_length', { attribute: t('description'), max: 65000 }),
          validateWysiwyg
        ),
    }),
  });


  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: report,
    resolver: yupResolver(schema),
  });

  const detail = watch('detail');

  useEffect(() => {
    onSave({
      detail
    });
  }, [detail]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <h3 className="h6 text-primary my-3">
        {t('compliance.CrimeReport.description')}
      </h3>

      <Form.Group className="mb-3 wysiwyg-image">
        <Wysiwyg
          {...register('detail.description')}
          placeholder={t('description')}
          blockEmbeds={false}
          preUpload={true}
          imageCompression={{ maxWidth: 1000 }}
          toolbar={false}
          isInvalid={!!errors?.detail?.description} />

        <Form.Control.Feedback type="invalid">
          {errors?.detail?.description?.message}
        </Form.Control.Feedback>
      </Form.Group>



      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" type="submit">
          {t('continue')}
        </Button>
      </Form.Group >
    </Form>
  );
}


function Persons({ report, onBack, onSave, onSubmit, type, ...props}) {
  const {t} = useTranslation();

  const schema = yup.object().shape({
    persons: yup.array().of(
      yup.object().shape({
        name: yup.string()
          .required(t('form_validation.is_required', { attribute: t('name') }))
          .max(255, t('form_validation.max_length', { attribute: t('name'), max: 255 })),

        role: yup.string()
          .required(t('form_validation.is_required', { attribute: t('role') }))
          .max(255, t('form_validation.max_length', { attribute: t('role'), max: 255 })),

        phone: yup.string()
          .required(t('form_validation.is_required', { attribute: t('phone') }))
          .max(255, t('form_validation.max_length', { attribute: t('phone'), max: 255 })),

        email: yup.string()
          .required(t('form_validation.is_required', { attribute: t('email') }))
          .max(255, t('form_validation.max_length', { attribute: t('email'), max: 255 }))
          .email(t('form_validation.invalid_email_address')),

      })
    ),
  });


  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: (() => {
      const values = {... report};

      if(values.persons instanceof Array)
      {
        values.persons = values.persons.filter(_ => _.type == type);
      }
      else
      {
        values.persons = [];
      }

      return values;
    })(),
    resolver: yupResolver(schema),
  });

  const persons = watch('persons');

  useEffect(() => {
    onSave({
      persons
    });
  }, [persons]);

  const { fields: rows, append, remove } = useFieldArray({
      control,
      name: 'persons',
  });

  useEffect(() => {
    register('persons');
  }, []);


  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <h3 className="h6 text-primary my-3">
        {t('compliance.CrimeReport.' + type)}
      </h3>

      {rows.map((row, index) => (
        <div key={index} className="mb-3">
          <hr />

          {t('person_n', { n: index + 1})}

          <Form.Group className="mb-3">
            <label htmlFor="name" className="my-2">{t('name')}</label>
            <Form.Control
              {...register(`persons.${index}.name`)}
              type="text"
              isInvalid={!!(errors.persons && errors.persons[index] && errors.persons[index].name)}
            />
            <Form.Control.Feedback type="invalid">
              {errors.persons && errors.persons[index] && errors.persons[index].name?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <label htmlFor="role" className="my-2">{t('role')}</label>
            <Form.Control
              {...register(`persons.${index}.role`)}
              type="text"
              isInvalid={!!(errors.persons && errors.persons[index] && errors.persons[index].role)}
            />
            <Form.Control.Feedback type="invalid">
              {errors.persons && errors.persons[index] && errors.persons[index].role?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <label htmlFor="phone" className="my-2">{t('phone')}</label>
            <Form.Control
              {...register(`persons.${index}.phone`)}
              type="text"
              isInvalid={!!(errors.persons && errors.persons[index] && errors.persons[index].phone)}
            />
            <Form.Control.Feedback type="invalid">
              {errors.persons && errors.persons[index] && errors.persons[index].phone?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <label htmlFor="email" className="my-2">{t('email')}</label>
            <Form.Control
              {...register(`persons.${index}.email`)}
              type="text"
              isInvalid={!!(errors.persons && errors.persons[index] && errors.persons[index].email)}
            />
            <Form.Control.Feedback type="invalid">
              {errors.persons && errors.persons[index] && errors.persons[index].email?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Button className="mx-2 w-100" variant="danger" onClick={_ => remove(index)}>
            {t('remove_person')}
          </Button>

        </div>

      ))}

      <Button className="mx-2 w-100" variant="success" onClick={_ => append({})}>
        {t('add_person')}
      </Button>

      <Form.Group className="my-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" type="submit">
          {t('continue')}
        </Button>
      </Form.Group >
    </Form>
  );
}

function Summary({ report, onBack, onSubmit, ...props}) {
  const {t} = useTranslation();

  return (
    <>
      <h3 className="h6 text-primary my-3">
        {t('summary')}
      </h3>


      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2"><strong>{t('compliance.CrimeReport.type')}</strong></label>


        {t('compliance.CrimeReport.types.' + report.detail.type)}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="occurance_at" className="my-2"><strong>{t('compliance.CrimeReport.date')}</strong></label>

        {moment(report.occurance_at).format(t('formats.date_long'))}
      </Form.Group>


      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('location')}</strong></label>

        <DisplayLocation location={report.location} />
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.CrimeReport.description')}</strong></label>

        <DisplayWysiwyg html={report.detail.description} />
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.CrimeReport.parties_involved')}</strong></label>

        {report.persons.filter(_ => _.type == 'parties_involved').map((person, index) => (
          <div key={index} className="mb-2">
          <div>{person.name}</div>
          <div>{person.role}</div>
          <div>{person.phone}</div>
          <div>{person.email}</div>
          </div>
        ))}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.CrimeReport.witnesses')}</strong></label>

        {report.persons.filter(_ => _.type == 'witnesses').map((person, index) => (
          <div key={index} className="mb-2">
          <div>{person.name}</div>
          <div>{person.role}</div>
          <div>{person.phone}</div>
          <div>{person.email}</div>
          </div>
        ))}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="filed_with_police" className="my-2 "><strong>{t('compliance.CrimeReport.filed_with_police')}</strong></label>

        {report.police_report.filed_with_police ? t('yes') : t('no')}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="police_branch_name" className="my-2 "><strong>{t('compliance.CrimeReport.police_branch_name')}</strong></label>

        {report.police_report.police_branch_name}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="police_reference" className="my-2 "><strong>{t('compliance.CrimeReport.police_reference')}</strong></label>

        {report.police_report.police_reference}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="officer_name" className="my-2 "><strong>{t('compliance.CrimeReport.officer_name')}</strong></label>

        {report.police_report.officer_name}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="police_contact_number" className="my-2 "><strong>{t('compliance.CrimeReport.police_contact_number')}</strong></label>

        {report.police_report.police_contact_number}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="follow_up_actions" className="my-2 "><strong>{t('compliance.CrimeReport.follow_up_actions')}</strong></label>

        <DisplayWysiwyg html={report.police_report.follow_up_actions} />
      </Form.Group>

      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => onBack()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" onClick={_ => onSubmit({})}>
          {t('continue')}
        </Button>
      </Form.Group >
    </>
  );
}
