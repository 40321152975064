export default {
    "Apr": "Aprilie",
    "Aug": "August",
    "Credit": "Credit",
    "Dec": "decembrie",
    "Feb": "februarie",
    "Jan": "ianuarie",
    "Jul": "iulie",
    "Jun": "iunie",
    "Mar": "Martie",
    "May": "Mai",
    "Nov": "noiembrie",
    "Oct": "octombrie",
    "Sep": "Septembrie",
    "a_new_order_has_been_made": "O noua comanda a fost plasata",
    "account_number": "Numar de cont",
    "account_payment": "Plata contului",
    "account_payment_method": "Metoda de plata a contului",
    "account_status": "starea contului",
    "account_type": "Tip de cont",
    "activate": "Activati",
    "active": "Activ",
    "add": "Adauga",
    "add_addon": "Ad\u0103uga\u021bi Addon",
    "add_client_pricing": "Adaugati preturi pentru clienti",
    "add_driver": "Adaugati sofer",
    "add_drivers": "Ad\u0103uga\u021bi soferi",
    "add_favourite": "Adauga favorit",
    "add_parking_price": "Ad\u0103uga\u021bi pre\u021bul de parcare",
    "add_parking_prices": "Add Parking Prices",
    "add_payment_method": "Ad\u0103uga\u021bi o metod\u0103 de plat\u0103",
    "add_person": "Adauga persoana",
    "add_promotion": "Adaug\u0103 promo\u021bie",
    "add_region": "Ad\u0103uga\u021bi o regiune",
    "add_service": "Ad\u0103uga\u021bi serviciu",
    "add_single_payout": "Adauga o singura plata",
    "add_site": "Ad\u0103uga\u021bi site",
    "add_site_pricing": "Adauga pretul site-ului",
    "add_to_order": "Adauga la comanda",
    "addon": "Add-on",
    "addon_purchase_using_fleet_account": "Suplimentul pentru achizi\u021bii de \u0219oferi utiliz\u00e2nd contul de flot\u0103",
    "addon_specific": "Specific pentru servicii \u0219i suplimente",
    "addon_spending_cap_instructions": "Instructiuni pentru plafonul de cheltuieli suplimentare",
    "addons": "Suplimente",
    "addons_services": "Servicii suplimentare",
    "address": "Adresa",
    "address_1": "Adresa 1",
    "address_2": "Adresa 2",
    "address_3": "Adresa 3",
    "address_line_2": "Adresa 2",
    "admin_dashboard": "Tabloul de bord administrativ",
    "all": "Toate",
    "all_clients_are_imported": "Toti clientii sunt importanti",
    "all_drivers_are_imported": "Toti soferii sunt importanti",
    "all_fleets_are_imported": "Toate flotele au fost importate cu succes",
    "all_payments_are_imported": "Toate platile au fost importate cu succes",
    "all_payouts_are_imported": "Toate retragerile au fost importate cu succes",
    "all_promotions_are_imported": "Toate promotiile au fost importate cu succes",
    "all_sites_are_imported": "Toate site-urile au fost importate cu succes",
    "all_suppliers_are_imported": "Toti furnizorii au fost importati cu succes",
    "all_users_are_imported": "Toti Utilizatorii au fost importati cu succes",
    "all_vehicles_are_imported": "Toate vehiculele au fost importate cu succes",
    "alternative_payment_method": "Metod\u0103 alternativ\u0103 de plat\u0103",
    "amount": "Cantitate",
    "app": "Aplicatia M-Park",
    "approve": "Aproba",
    "archived": "Arhivat",
    "back": "Intoarcere",
    "back_to_map_view": "Reveniti la vizualizarea hartii",
    "back_to_n": "Inapoi la {{n}}",
    "balance": "Echilibru",
    "begin": "Incepe",
    "best": "Cel mai bun",
    "billing_address": "Adresa de facturare",
    "billing_address_line_2": "Adresa de facturare 2",
    "billing_address_same_as_registered_address": "Adresa de facturare aceea\u0219i cu adresa \u00eenregistrat\u0103?",
    "billing_county": "Judet",
    "billing_currency": "Moneda de facturare",
    "billing_information": "Informatii de facturare",
    "book_now": "Rezerva acum",
    "booking_cant_be_extend_to_before_initial_expire_date": "Rezervarea nu poate fi prelungita inainte de data initiala de expirare",
    "booking_cant_end_end_before_it_stated": "Rezervarea nu poate fi finalizata inainte de inceperea acesteia",
    "booking_cant_start_on_the_past": "Rezervarea nu poate incepe intrecut si trebuie sa dureze cel putin 45 de minute de la ora curenta",
    "booking_checked_in": "Rezervare inregistrata",
    "booking_checked_out": "Rezervare verificata",
    "booking_duration": "Pentru cat timp?",
    "booking_duration_must_be_at_lest_one_hour": "Timpul de rezervare trebuie sa fie de cel putin 1 ora",
    "booking_extended": "Rezervare extinsa",
    "booking_from": "Introduce\u021bi data\/ora sosirii...",
    "booking_id": "ID rezervare",
    "booking_summary": "Rezumat",
    "booking_to": "Introduce\u021bi data\/ora plec\u0103rii",
    "call_to_action": "Apel la ac\u021biune",
    "call_to_action_link_text": "Text \u00eendemn\/link",
    "cancel": "Anulare",
    "cancel_extend": "Anula\u021bi extensia",
    "cancel_question": "Anulare?",
    "card": "Card",
    "card_expiry": "Expirarea cardului",
    "card_holder_name": "Nume De\u021bin\u0103tor Card",
    "card_number": "Num\u0103r de card",
    "category": "Categorie",
    "ccv": "CCV",
    "change": "Schimbare",
    "check_email": "V\u0103 rug\u0103m s\u0103 v\u0103 verifica\u021bi e-mailul",
    "check_in": "Verifica",
    "check_off_what_customer_has_received": "V\u0103 rug\u0103m s\u0103 verifica\u021bi ceea ce a primit clientul.",
    "check_out": "Verific\u0103",
    "checked_in_at": "S-a \u00eenregistrat la",
    "checked_out_at": "Verificat la",
    "checkout_now": "verifica acum",
    "choose": "Alege",
    "choose_client": "Selecta\u021bi un client",
    "choose_csv_file_to_upload": "Selecta\u021bi fi\u0219ierul CSV pentru a \u00eenc\u0103rca",
    "choose_driver": "Alege\u021bi Soferul",
    "choose_facility": "Alege\u021bi Facilitatea",
    "choose_fleet": "Alege\u021bi Flot\u0103",
    "choose_type": "Selecta\u021bi tipul",
    "city": "Ora\u0219",
    "city_country": "Oras-tara",
    "client": "Client",
    "client_deleted": "Client \u0219ters!",
    "client_name": "Numele clientului",
    "client_payment_terms": "Condi\u021bii de plat\u0103 pentru clien\u021bi",
    "client_payment_terms_days": "Zilele de plat\u0103 ale clientului",
    "client_pricing": "Preturi pentru clienti",
    "client_pricing_reset": "Resetarea pre\u021burilor clien\u021bilor",
    "client_pricing_saved": "Pre\u021bul clientului a fost salvat",
    "client_supplier": "Client\/Furnizor",
    "clients_imported": "Clien\u021bi importa\u021bi",
    "close": "\u00cenchide",
    "column": "Coloan\u0103",
    "comma_separated_available_options": "Separat prin virgul\u0103, op\u021biuni disponibile",
    "commission_amount": "Suma comisionului",
    "commission_percentage": "Procentul comisionului",
    "commission_rate_options": {
        "percentage": "Procent ",
        "flat_rate": "Procent global"
    },
    "commission_rate_type": "Tipul ratei comisionului",
    "commission_rate_value": "Valoarea ratei comisionului",
    "commission_rates": "Tarife comisioane",
    "company": "Companie",
    "company_details": "detaliile companiei",
    "company_name": "Numele companiei",
    "complete": "Complet",
    "complete_purchase": "finaliza\u021bi achizi\u021bia \u0219i pl\u0103ti\u021bi ",
    "compliance": {
        "options": "Op\u021biuni de compatibilitate",
        "licence_price": "Pre\u021bul licen\u021bei automate per utilizator",
        "licences_hit_limit": "A\u021bi atins limita de licen\u021b\u0103 de compatibilitate",
        "increase_licences": "M\u0103ri\u021bi pentru a ad\u0103uga un utilizator",
        "licences_description": "\u00cen timp ce conformitatea este activ\u0103, trebuie s\u0103 ave\u021bi suficiente licen\u021be pentru to\u021bi \u0219oferii din flota dvs",
        "next_section": "Urm\u0103toarea sec\u021biune",
        "title": "Respectarea reglement\u0103rilor legale existente",
        "active": "Activati",
        "defects": "Defecte",
        "dashboard_link": "Inspec\u021bia zilnic\u0103 a vehiculului \u0219i respectarea reglement\u0103rilor",
        "dashboard_advert": "Dori\u021bi o modalitate mai u\u0219oar\u0103 de a efectua o inspec\u021bie a conformit\u0103\u021bii vehiculului?",
        "dashboard_advert_button": "Afl\u0103 mai multe",
        "incident_information": "Informa\u021bii despre eveniment",
        "datetime": "Data\/ora raportului",
        "override_warning": "V\u0103 rug\u0103m s\u0103 re\u021bine\u021bi c\u0103 factura dvs. lunar\u0103 va fi suprascris\u0103 dac\u0103 se modific\u0103 num\u0103rul de licen\u021be.",
        "report_status": {
            "Incomplete": "Incomplet",
            "Pending": "\u00cen curs",
            "Waiting": "A\u015fteptarea",
            "Resolved": "Lejer",
            "Complete": "Complet"
        },
        "severity": {
            "None": "F\u0103r\u0103 probleme",
            "Fault": "Probleme minore",
            "Severe": "Probleme serioase"
        },
        "severity_label": "Conformitate",
        "reporting_to": "Subiect",
        "report_type": "Tip control",
        "view_image": "Vezi imaginea",
        "answer": "R\u0103spuns",
        "check_key_name": "Verifica",
        "details": "Detalii",
        "DailyReport": {
            "title": "Inspec\u021bii zilnice ale vehiculelor",
            "dashboard_summary": "Cardul de inspec\u021bie zilnic\u0103 a vehiculului este o list\u0103 de elemente specifice care ar trebui verificate \u00een fiecare zi \u00eenainte de a utiliza vehiculul. Sistemul anun\u021b\u0103 managerul de flot\u0103 cu privire la orice problem\u0103 \u0219i \u021bine o eviden\u021b\u0103 a tuturor inspec\u021biilor.",
            "complete": "Complet",
            "incomplete": "Incomplet",
            "no_issues": "Totul este in regula",
            "minor_issues": "Probleme minore",
            "severe_issues": "Probleme serioase",
            "group_key_name": "grupul de control",
            "completed": "A\u021bi efectuat verific\u0103ri zilnice pentru {{\u00eenregistrare}}",
            "minor_issues_long": "Problemele minore sunt \u00eenregistrate, contacta\u021bi managerul de flot\u0103 \u0219i solicita\u021bi-le remediate \u00een timp util.",
            "severe_issues_long": "Au fost \u00eenregistrate mai multe probleme grave. V\u0103 rug\u0103m s\u0103 contacta\u021bi managerul de flot\u0103 \u00eenainte de a conduce.",
            "inside": {
                "title": "\u00cen interiorul vehiculului",
                "mirrors_glass": {
                    "title": "Oglinzi \u0219i ferestre",
                    "is_window_cracked": "Parbrizul este cr\u0103pat\/zg\u00e2riat\/decolorat?",
                    "cracked": "At\u00e2t de rupt",
                    "scratched": "At\u00e2t de zg\u00e2riat",
                    "discoloured": "At\u00e2t de decolorat",
                    "is_vision_obsured": "Acest lucru \u00eempiedic\u0103 vizibilitatea \u0219oferului?",
                    "is_mirrors_acceptable": "Toate oglinzile necesare sunt montate, func\u021bionale \u0219i nedeteriorate?",
                    "not_fitted": "Nemontat",
                    "is_camera_working": "Dac\u0103 se folose\u0219te un sistem de camer\u0103 \u00een locul unei oglinzi, acestea nu func\u021bioneaz\u0103 sau vizualizarea este incorect\u0103?"
                },
                "wipers": {
                    "title": "Stergatoare si spalatoare de parbriz",
                    "is_working": "\u0218terg\u0103toarele de parbriz sunt instalate \u0219i func\u021bioneaz\u0103 corect?",
                    "is_missing": "\u00ce\u021bi lipsesc \u0219terg\u0103toarele de parbriz?",
                    "is_damaged": "Sunt \u0219terg\u0103toarele dvs. de parbriz deteriorate sau uzate?"
                },
                "front_view": {
                    "title": "Vedere din fa\u021b\u0103",
                    "object_obstruction": "V\u0103 \u00eempiedic\u0103 vreun obiect vederea \u00eenainte?",
                    "wiper_obstruction": "Exist\u0103 obstacole \u00een zona de operare a \u0219terg\u0103toarelor de parbriz?"
                },
                "dashboard": {
                    "title": "Comenzi \u0219i indicatoare pe tabloul de bord",
                    "is_reading_working": "Toate instrumentele \u0219i manometrele func\u021bioneaz\u0103 corect?",
                    "is_warning_working": "Comenzile dvs. func\u021bioneaz\u0103 corect?"
                },
                "steering": {
                    "title": "Sistem de direc\u021bie",
                    "is_moving": "Volanul se misca corect?",
                    "is_powered": "Servodirec\u021bia func\u021bioneaz\u0103 corect?",
                    "has_play": "Direc\u021bia are joc excesiv?",
                    "does_jam": "Se blocheaza directia?"
                },
                "horn": {
                    "title": "Claxon",
                    "is_working": "Func\u021bioneaz\u0103 claxonul?",
                    "is_accessible": "Claxonul este accesibil de pe scaunul \u0219oferului?"
                },
                "breaks_air": {
                    "title": "Fr\u00e2ne \u0219i acumulare de aer",
                    "working": "Aerul se colecteaz\u0103 corect?",
                    "warning": "Sistemul de avertizare functioneaza?",
                    "leaks": "Exist\u0103 scurgeri de aer?",
                    "footwell": "Zona pentru picioare este goal\u0103?",
                    "service": "Fr\u00e2na de serviciu suport\u0103 at\u00e2t fr\u00e2nele tractorului, c\u00e2t \u0219i ale remorcii?",
                    "parking": "Fr\u00e2na de parcare a tractorului func\u021bioneaz\u0103?",
                    "loose": "Fr\u00e2na de serviciu este sl\u0103bit\u0103, are joc lateral excesiv sau are o banda de rulare anti-alunecare incomplet\u0103?"
                },
                "height_marker": {
                    "title": "Marcator de \u00een\u0103l\u021bime",
                    "is_correct": "\u00cen\u0103l\u021bimea corect\u0103 este afi\u0219at\u0103 pe marcatorul de \u00een\u0103l\u021bime al vehiculului din cabin\u0103?"
                },
                "seatbelts": {
                    "title": "Centuri de siguran\u021b\u0103",
                    "is_damaged": "Centurile de siguran\u021b\u0103 au t\u0103ieturi, deterior\u0103ri sau zg\u00e2rieturi care le-ar putea \u00eempiedica s\u0103 func\u021bioneze?",
                    "is_secure": "Centurile de siguran\u021b\u0103 r\u0103m\u00e2n fixate c\u00e2nd sunt conectate?",
                    "does_retract": "Centurile de siguran\u021b\u0103 se retrag c\u00e2nd sunt instalate \u0219i se retrag complet c\u00e2nd le scoate\u021bi?"
                }
            },
            "outside": {
                "title": "\u00cen afara vehiculului",
                "lights": {
                    "title": "Lumini \u0219i indicatoare",
                    "is_working": "Toate luminile \u0219i indicatoarele func\u021bioneaz\u0103 corect?",
                    "is_colour_correct": "Sunt toate lentilele montate, curate \u0219i de culoarea corect\u0103?",
                    "brakes": "Se aprind luminile de fr\u00e2n\u0103 c\u00e2nd fr\u00e2na de serviciu este aplicat\u0103 \u0219i se sting c\u00e2nd fr\u00e2na de serviciu este eliberat\u0103?",
                    "side": "Sunt instalate lumini de delimitare laterale \u0219i func\u021bioneaz\u0103?"
                },
                "fuel_oil": {
                    "title": "Scurgeri de combustibil \u0219i ulei",
                    "cap": "Bu\u0219onul rezervorului de combustibil este instalat corect?",
                    "leaks": "Exist\u0103 scurgeri de combustibil sau ulei sub vehicul c\u00e2nd motorul este pornit?"
                },
                "battery": {
                    "title": "Siguran\u021ba bateriei \u0219i condi\u021biile de utilizare",
                    "is_secure": "Bateria este asigurat\u0103?",
                    "is_good": "Bateria este in stare buna?",
                    "does_leak": "Bateria curge?"
                },
                "diesel_exhaust": {
                    "title": "Lichid DEF (AdBlue)",
                    "has_adblue": "Vehiculul dumneavoastr\u0103 diesel are suficient AdBlue (dac\u0103 este necesar)?"
                },
                "exhaust": {
                    "title": "Fum excesiv din sistemul de evacuare",
                    "excessive": "Sistemul de evacuare produce fum excesiv?"
                },
                "body_security": {
                    "title": "Siguran\u021ba corpului",
                    "fasteners_working": "Func\u021bioneaz\u0103 toate elementele de fixare?",
                    "doors": "Sunt u\u0219ile cabinei \u0219i remorcii securizate c\u00e2nd sunt \u00eenchise?",
                    "panels": "Sunt panourile caroseriei de pe tractor sau remorc\u0103 securizate \u0219i risc\u0103 s\u0103 cad\u0103?",
                    "legs": "Picioarele de aterizare (dac\u0103 sunt prev\u0103zute) sunt sigure \u0219i nu vor c\u0103dea \u00een timpul conducerii?",
                    "guards": "Verifica\u021bi dac\u0103 capacele laterale \u0219i capacele din spate sunt instalate, dac\u0103 este necesar, \u0219i nu sunt neasigurate sau deteriorate"
                },
                "spray": {
                    "title": "Clape de stropire",
                    "required": "Sunt necesare clapete pentru stropire?",
                    "secure": "Sunt asigurate?",
                    "damaged": "Sunt deteriorate?",
                    "clogged": "Sunt \u00eenfundate cu noroi sau resturi?"
                },
                "wheels": {
                    "title": "Montare anvelope \u0219i ro\u021bi",
                    "secure": "Sunt anvelopele \u0219i ro\u021bile fixate?",
                    "tread": "Anvelopele au o ad\u00e2ncime a benzii de rulare de cel pu\u021bin 1 mm?",
                    "inflated": "Anvelopele sunt umflate corect?",
                    "cuts": "Exist\u0103 t\u0103ieturi ad\u00e2nci \u00een peretele lateral al anvelopei?",
                    "cord": "Exist\u0103 fir vizibil pe undeva pe anvelop\u0103?",
                    "nuts": "Toate piuli\u021bele ro\u021bilor sunt str\u00e2nse suficient? - Pentru a face acest lucru, pute\u021bi verifica dac\u0103 indicatoarele piuli\u021bei ro\u021bilor (dac\u0103 sunt instalate) s-au mi\u0219cat.",
                    "twin": "Exist\u0103 obiecte sau resturi \u00eentre ro\u021bile gemene?"
                },
                "brakes": {
                    "title": "Conducte de fr\u00e2n\u0103 \u0219i fr\u00e2n\u0103 de parcare ale remorcii",
                    "couplings": "Sunt conectorii lipsi\u021bi de contaminare \u0219i \u00een loca\u021bia corect\u0103?",
                    "damage": "Conductele de fr\u00e2n\u0103 sunt deteriorate sau uzate?",
                    "trailer_brake": "Fr\u00e2na de parcare a remorcii func\u021bioneaz\u0103 corect?"
                },
                "electrical": {
                    "title": "Leg\u0103turile electrice",
                    "insulated": "Sunt firele vizibile izolate?",
                    "risk": "Ar putea fi ciupite sau deteriorate fire vizibile?",
                    "coupling": "Sunt toate conexiunile electrice ale remorcii conectate \u00een siguran\u021b\u0103?",
                    "switches": "Toate \u00eentrerup\u0103toarele electrice func\u021bioneaz\u0103 corect?"
                },
                "coupling": {
                    "title": "Protec\u021bia cuplajului",
                    "primary": "Este remorca pozi\u021bionat\u0103 corect \u00een a cincea roat\u0103 sau \u00een c\u00e2rlig?",
                    "secondary": "Dispozitivele secundare de blocare sunt \u00een pozi\u021bia corect\u0103?"
                },
                "load": {
                    "title": "Securitatea \u00eenc\u0103rc\u0103turii",
                    "secure": "Marfa dumneavoastr\u0103 este asigurat\u0103 \u00eempotriva mi\u0219c\u0103rii?",
                    "guidance": "Ave\u021bi nevoie de \u00eendrumare cu privire la siguran\u021ba \u00eenc\u0103rc\u0103turii dvs.?"
                },
                "numberplate": {
                    "title": "Numar de inmatriculare",
                    "broken": "Num\u0103rul de \u00eenmatriculare este deteriorat?",
                    "incorrect": "Num\u0103rul de \u00eenmatriculare este incorect sau are spa\u021bierea gre\u0219it\u0103?",
                    "dirty": "Num\u0103rul de \u00eenmatriculare este murdar?",
                    "faded": "Num\u0103rul de \u00eenmatriculare este decolorat?",
                    "covered": "Num\u0103rul de \u00eenmatriculare este acoperit de ceva?"
                },
                "reflectors": {
                    "title": "Reflectori (inclusiv lumini laterale)",
                    "missing": "Lipsesc reflectoare?",
                    "broken": "Reflectoarele sunt deteriorate?",
                    "insecure": "Reflectoarele sunt neasigurate?",
                    "fitted": "Reflectoarele sunt instalate corect?",
                    "colour": "Sunt reflectoarele de culoarea gre\u0219it\u0103?",
                    "covered": "Reflectoarele sunt acoperite de murd\u0103rie sau alte obiecte?"
                },
                "plates": {
                    "title": "Semnalizare \u0219i panouri de avertizare (inclusiv marcaje reflectorizante)",
                    "colour": "Sunt semnele \u0219i semnele de avertizare culoarea corect\u0103?",
                    "visible": "Sunt semnele \u0219i semnele de avertizare vizibile?",
                    "secured": "Semnele \u0219i semnele de avertizare sunt fixate \u00een siguran\u021b\u0103?",
                    "obscured": "Semnele \u0219i semnele de avertizare sunt acoperite de murd\u0103rie sau alte obiecte?",
                    "hazard": "Dac\u0103 vehiculul transport\u0103 m\u0103rfuri periculoase, semnele de pericol arat\u0103 informa\u021biile corecte despre marf\u0103?",
                    "hazard_visible": "Dac\u0103 vehiculul transport\u0103 m\u0103rfuri periculoase, sunt vizibile semnele de pericol?",
                    "hazard_secured": "Dac\u0103 vehiculul transport\u0103 m\u0103rfuri periculoase, semnele de pericol sunt bine fixate?",
                    "hazard_covered": "Dac\u0103 vehiculul transport\u0103 m\u0103rfuri periculoase, semnele de avertizare sunt ascunse de murd\u0103rie sau alte obiecte?"
                }
            },
            "before_use": {
                "title": "Inainte de folosire",
                "general": {
                    "title": "General",
                    "previous": "Au fost eliminate toate defec\u021biunile raportate anterior?"
                },
                "leaks": {
                    "title": "Scurgeri evidente",
                    "any": "Exist\u0103 semne de scurgere?"
                },
                "hydraulics": {
                    "title": "Nivelul lichidului hidraulic",
                    "fluid_level": "Rezervorul este la nivelul corect?"
                },
                "carriage": {
                    "title": "Catarg \u0219i ambulan\u021b\u0103",
                    "damage": "Exist\u0103 daune sau p\u0103r\u021bi str\u0103ine pe catarg \u0219i pe c\u0103rucior?",
                    "lubrication": "Sunt catargul \u0219i c\u0103ruciorul lubrifiate corespunz\u0103tor?"
                },
                "chains_bolts": {
                    "title": "Lan\u021buri \u0219i \u0219uruburi de fixare",
                    "damage": "Cablurile sau \u0219uruburile de montare sunt deteriorate?",
                    "stretching": "Exist\u0103 vreo \u00eentindere evident\u0103?",
                    "lubrication": "Este asigurat\u0103 o lubrifiere adecvat\u0103?"
                },
                "forks": {
                    "title": "Furca",
                    "cracks": "Exist\u0103 semne de fisuri pe furci, \u00een special pe \u201ec\u0103lc\u00e2ie\u201d, sau alte semne de deteriorare sau uzur\u0103 excesiv\u0103",
                    "damage": "Exist\u0103 alte semne de deteriorare sau uzur\u0103 excesiv\u0103 a furcilor?",
                    "pins": "Exist\u0103 alte semne de deteriorare sau de uzur\u0103 excesiv\u0103 pe ace de siguran\u021b\u0103?"
                },
                "backrest": {
                    "title": "Sp\u0103tar\/Extensie",
                    "damage": "Exist\u0103 semne de deteriorare sau resturi pe sp\u0103tare \u0219i pe prelungitor?"
                },
                "attachments": {
                    "title": "Accesorii",
                    "secure": "Sunt fixate accesoriile la punctele de fixare \u0219i de blocare?",
                    "hydraulics": "Sunt sigure instala\u021biile sanitare \u0219i alte conexiuni?"
                },
                "wheels": {
                    "title": "Anvelope \/ ro\u021bi \/ piuli\u021be",
                    "tyres": "Exist\u0103 semne de deteriorare a anvelopelor, inclusiv t\u0103ieturi \u0219i uzura benzii de rulare?",
                    "pressure": "Anvelopele pneumatice au presiunea corect\u0103?",
                    "damage": "Sunt ro\u021bile, inclusiv jantele, deteriorate?",
                    "nuts": "Piuli\u021bele ro\u021bilor sunt sl\u0103bite?"
                },
                "seat": {
                    "title": "Scaune \u0219i centuri de siguran\u021b\u0103",
                    "secure": "Scaunul este fixat \u00een mod corespunz\u0103tor pe vehicul \u0219i nu este sl\u0103bit sau deteriorat?",
                    "belt": "Centura de siguran\u021b\u0103 sau alt sistem de re\u021binere este bine fixat\u0103 \u0219i func\u021bioneaz\u0103 corect?"
                },
                "steering": {
                    "title": "Sistem de direc\u021bie",
                    "acceptable": "Se comport\u0103 direc\u021bia \u00een mod normal, f\u0103r\u0103 joc excesiv sau mi\u0219c\u0103ri neobi\u0219nuite?"
                },
                "service_brakes": {
                    "title": "Fr\u00e2ne de serviciu",
                    "acceptable": "Fr\u00e2nele func\u021bioneaz\u0103 bine?"
                },
                "parking_brakes": {
                    "title": "Fr\u00e2ne de parcare",
                    "acceptable": "Fr\u00e2na de parcare func\u021bioneaz\u0103 bine \u0219i se elibereaz\u0103 corect?"
                },
                "controls": {
                    "title": "Controale",
                    "acceptable": "Toate comenzile manuale \u0219i cu piciorul func\u021bioneaz\u0103 corect \u0219i nu sunt blocate \u00een niciun fel?"
                },
                "system": {
                    "title": "Sistem de operare",
                    "acceptable": "\u00cen cazul \u00een care vehiculul este echipat cu sisteme informatice, acestea func\u021bioneaz\u0103 corect?"
                },
                "warning_lights": {
                    "title": "Lumini de avertizare",
                    "operating": "Toate luminile de avertizare func\u021bioneaz\u0103 corect?",
                    "any_warning": "Se aprinde vreo lumin\u0103 de avertizare?"
                },
                "dashboard": {
                    "title": "Indicatori\/instrumente",
                    "acceptable": "Func\u021bioneaz\u0103 corect to\u021bi indicatorii \u0219i alte instrumente?"
                },
                "lights": {
                    "title": "Lumini\/lovite de avertizare",
                    "acceptable": "Toate luminile de avertizare func\u021bioneaz\u0103 corect?"
                },
                "horn": {
                    "title": "Corn",
                    "acceptable": "Claxonul func\u021bioneaz\u0103 corect?"
                },
                "alarms": {
                    "title": "Alarm\u0103",
                    "acceptable": "Alarma func\u021bioneaz\u0103 corect?"
                },
                "warnings": {
                    "title": "Alte dispozitive de avertizare",
                    "acceptable": "\u00cen cazul \u00een care au fost instalate alte sisteme de avertizare, acestea func\u021bioneaz\u0103 corect?"
                },
                "guards": {
                    "title": "M\u0103suri de siguran\u021b\u0103 \u0219i de protec\u021bie",
                    "acceptable": "Toate protec\u021biile \u0219i capacele de siguran\u021b\u0103 sunt montate \u0219i fixate corespunz\u0103tor?"
                },
                "body": {
                    "title": "Corp",
                    "acceptable": "Exist\u0103 deterior\u0103ri vizibile ale caroseriei, inclusiv ale rafturilor \u0219i ale altor echipamente?"
                }
            }
        },
        "LoadingReport": {
            "title": "Raport de \u00eenc\u0103rcare",
            "location_name": "Denumirea locului de \u00eenc\u0103rcare",
            "dashboard_summary": "Vehiculele ar trebui s\u0103 fie adecvate pentru \u00eenc\u0103rc\u0103turile transportate \u0219i este deosebit de important ca vehiculul s\u0103 aib\u0103 puncte de ancorare adecvate pentru a se asigura c\u0103 \u00eenc\u0103rc\u0103turile sunt transportate \u00een siguran\u021b\u0103. Utiliza\u021bi acest raport pentru a \u00eenregistra observa\u021biile dumneavoastr\u0103 \u0219i pentru a dovedi c\u0103 au fost luate toate m\u0103surile de precau\u021bie necesare.",
            "date": "Data raportului",
            "cargo": "Marf\u0103"
        },
        "JourneyReport": {
            "title": "Raportul de siguran\u021b\u0103 al vehiculului",
            "new": "\u00cencepe\u021bi un nou",
            "list": "Acces salvat",
            "report_id": "Identificatorul raportului",
            "start": "Start",
            "stop": "Verificare",
            "end": "Finish",
            "start_time": "Ora de \u00eencepere",
            "end_time": "Ora de \u00eencheiere",
            "dashboard_summary": "Raportul de securitate a vehiculului permite o modalitate simpl\u0103 \u0219i eficient\u0103 de \u00eenregistrare a marcajelor geografice \u0219i temporale ale controalelor efectuate de fiecare dat\u0103 c\u00e2nd un vehicul este l\u0103sat nesupravegheat sau se intervine asupra \u00eenc\u0103rc\u0103turii, pentru a reduce la minimum riscul de a avea imigran\u021bi ilegali la bord.",
            "name": "Titlul c\u0103l\u0103toriei",
            "date_time": "Data \u0219i ora",
            "trailer_number": "Numerele remorcii",
            "security_device_type": "Tipul de dispozitiv de siguran\u021b\u0103",
            "security_device_type_other": "V\u0103 rug\u0103m s\u0103 preciza\u021bi",
            "security_device_number": "Num\u0103rul de serie al dispozitivului de siguran\u021b\u0103",
            "security_device_photos": "Fotografii ale dispozitivului de siguran\u021b\u0103",
            "device_type": {
                "Seal": "Sigiliul",
                "Lock": "\u00cencuietoare",
                "Other": "Alt"
            },
            "check_load_space": "Verifica\u021bi dac\u0103 zona de marf\u0103 este securizat\u0103 eficient \u00eempotriva accesului neautorizat?",
            "check_cargo": "Este \u00eenc\u0103rc\u0103tura securizat\u0103 eficient \u00eempotriva accesului neautorizat? (dac\u0103 este cazul)",
            "check_devices": "Verifica\u021bi dac\u0103 toate \u00eencuietorile, sigiliile \u0219i alte dispozitive de securitate prezint\u0103 semne de manipulare, deteriorare \u0219i reparare sau \u00eenlocuire neautorizat\u0103?",
            "check_cables": "S-au verificat cablurile, chingile \u0219i ochiurile aferente pentru a se detecta semne de manipulare, deteriorare sau semne de repara\u021bii sau \u00eenlocuiri neautorizate? (dac\u0103 este cazul)",
            "check_shell": "A fost verificat\u0103 \u00eenveli\u0219ul exterior sau \u00eenveli\u0219ul din \u021bes\u0103tur\u0103 pentru a se vedea dac\u0103 exist\u0103 semne de acces neautorizat? (dac\u0103 este cazul)",
            "check_external_storage": "Verifica\u021bi toate dulapurile externe pentru a vedea dac\u0103 exist\u0103 semne de acces neautorizat?",
            "check_wind_deflectors": "Verifica\u021bi deflectoarele de v\u00e2nt pentru a vedea dac\u0103 exist\u0103 semne de acces neautorizat?",
            "check_beneath": "Verifica\u021bi dac\u0103 sub vehicul exist\u0103 semne de acces neautorizat?",
            "check_inside": "Verifica\u021bi dac\u0103 \u00een interiorul vehiculului exist\u0103 semne de acces neautorizat?",
            "check_roof_inside": "Verifica\u021bi acoperi\u0219ul din interiorul vehiculului pentru a vedea dac\u0103 exist\u0103 semne de acces neautorizat?",
            "check_person": "Verifica\u021bi dac\u0103 nicio persoan\u0103 nu a avut acces neautorizat?",
            "check_documentaion": "Verifica\u021bi dac\u0103 toate documentele necesare sunt corecte?",
            "any_signs": "Au fost observate indicii c\u0103 cineva a ob\u021binut sau a \u00eencercat s\u0103 ob\u021bin\u0103 acces neautorizat la vehicul?",
            "endorsement": "Confirmare din partea ter\u021bului care a efectuat verific\u0103rile de mai sus (dac\u0103 este cazul)",
            "instructions_complete_journey": "Pentru a vizualiza verific\u0103rile de c\u0103l\u0103torie salvate, selecta\u021bi pur \u0219i simplu elementul relevant de mai jos. Dac\u0103 dori\u021bi s\u0103 le partaja\u021bi cu ter\u021be p\u0103r\u021bi, pute\u021bi exporta cu u\u0219urin\u021b\u0103 controalele de c\u0103l\u0103torie \u00eentr-un fi\u0219ier PDF. Pur \u0219i simplu face\u021bi clic pe butonul \u201ePDF report\u201d, apoi selecta\u021bi butonul \u201eShare\u201d (Partajare) \u00een browserul dvs. \u0219i alege\u021bi modul de partajare de pe dispozitivul dvs.",
            "add_stop": "Ad\u0103uga\u021bi un nou control",
            "add_final": "Completa\u021bi c\u0103l\u0103toria"
        },
        "CrimeReport": {
            "title": "Raport de infrac\u021biune",
            "dashboard_summary": "\u00cenregistrarea, \u00een vederea inspec\u021biei \u0219i \u00een scopuri legale, a oric\u0103ror incidente penale care au loc \u00een timpul exercit\u0103rii atribu\u021biilor dumneavoastr\u0103. P\u0103stra\u021bi o eviden\u021b\u0103 a m\u0103surilor luate, a autorit\u0103\u021bilor implicate \u0219i a detaliilor relevante.",
            "date": "Data evenimentului",
            "type": "Tipul de incident",
            "types": {
                "Robbery": "Furt",
                "Mugging": "Agresiune",
                "Assault": "Atac",
                "VehicleTheft": "Furt de vehicule",
                "VehicleDamage": "Deteriorarea vehiculului",
                "FuelTheft": "Furt de combustibil",
                "Hijacking": "R\u0103pirea",
                "Terrorist": "Incident terorist",
                "Stowaways": "Pasageri clandestini"
            },
            "description": "Descrierea incidentului",
            "parties_involved": "P\u0103r\u021bile implicate",
            "witnesses": "Martori",
            "police_report": "Raportul poli\u021biei",
            "filed_with_police": "Raporta\u021bi la poli\u021bie?",
            "police_branch_name": "Sec\u021bia de poli\u021bie",
            "police_reference": "Num\u0103rul raportului de infrac\u021biune",
            "officer_name": "Ofi\u021berul raportor",
            "police_contact_number": "Num\u0103rul de contact",
            "follow_up_actions": "M\u0103suri suplimentare"
        },
        "BorderReport": {
            "title": "Raport la frontier\u0103",
            "dashboard_summary": "Dovada c\u0103 a\u021bi luat toate m\u0103surile necesare pentru a asigura integritatea \u00eenc\u0103rc\u0103turii \u0219i a vehiculului atunci c\u00e2nd trece\u021bi frontierele interna\u021bionale cu vehiculul dumneavoastr\u0103.",
            "time": "Momentul raportului",
            "general_checks": "Verific\u0103ri generale ale vehiculului",
            "inside": "\u00cen interiorul vehiculului",
            "outside": "Exteriorul vehiculului",
            "is_damage": "Exist\u0103 deterior\u0103ri la nivelul acoperi\u0219ului vehiculului sau al panourilor\/acoperi\u0219urilor caroseriei?",
            "other_checks": "Alte verific\u0103ri",
            "external_compartments": "Compartimentele de depozitare exterioare sunt goale \u0219i bine \u00eenchise?",
            "underside_accessible": "Partea inferioar\u0103 a vehiculului \u0219i orice alte ascunz\u0103tori disponibile sunt goale?",
            "seals_intact": "Toate sigiliile sunt intacte?",
            "locks_intact": "Toate lac\u0103tele \u0219i \u00eencuietorile u\u0219ilor sunt \u00eenchise?",
            "tir_intact": "Cablul TIR este montat \u0219i sigilat?",
            "life_signs_checked": "Verific\u0103rile Co2, ale camerei \u0219i ale monitorului de ritm cardiac au fost efectuate de o agen\u021bie extern\u0103?"
        },
        "TrafficIncidentReport": {
            "title": "Raport de accident",
            "dashboard_summary": "Dac\u0103 a\u021bi fost implicat \u00eentr-un accident \u00een care a fost implicat vehiculul dumneavoastr\u0103, utiliza\u021bi acest raport pentru a \u00eenregistra toate informa\u021biile \u0219i dovezile relevante pentru a v\u0103 sus\u021bine \u00een scopuri de audit \u0219i juridice.",
            "date": "Data\/ora accidentului",
            "description": "Descrierea accidentului",
            "description_instructions": "V\u0103 rug\u0103m s\u0103 ad\u0103uga\u021bi un desen care s\u0103 descrie accidentul",
            "injuries_damage": "Leziuni \u0219i daune",
            "driver_injuries": "\u0218oferul a suferit leziuni?",
            "passengers_injuried": "A fost r\u0103nit vreunul dintre pasageri?",
            "no_injuries": "Nu sunt r\u0103ni\u021bi",
            "injured_passengers": "Numele \u0219i adresele pasagerilor r\u0103ni\u021bi",
            "no_damange": "Nu exist\u0103 daune",
            "vehicle_damaged": "Vehiculul a fost avariat?",
            "vehicle_damage": "Descrierea daunelor suferite de vehicul",
            "parties_involved": "Detalii despre \u0219oferul ter\u021b"
        },
        "inactive": {
            "title": "Verific\u0103ri de conformitate",
            "message_to_drivers": "Dac\u0103 sunte\u021bi interesat de aceste func\u021bii, adresa\u021bi-v\u0103 managerului dumneavoastr\u0103 de parc auto.",
            "message_to_clients": "Dac\u0103 sunte\u021bi interesat de aceste caracteristici, le pute\u021bi activa pe una dintre flotele dvs. din pagina de editare.",
            "button_text": "Activa\u021bi compatibilitatea \u00een set\u0103ri"
        }
    },
    "confirm_booking": "Confirma\u021bi rezervarea",
    "confirm_your_email": "Confirma\u021bi adresa dumneavoastr\u0103 de email",
    "conform_dialog": {
        "are_you_sure": "Esti sigur?",
        "order_confirm": {
            "message": "Aproba\u021bi comanda?",
            "cancel": "Anulare",
            "accept": "Bine"
        },
        "order_reject": {
            "message": "Respinge\u021bi comanda?",
            "cancel": "Anulare",
            "accept": "Bine"
        },
        "cancel": "Anulare",
        "delete": "\u0218terge",
        "accept": "Bine",
        "reset": "Resetare",
        "no": "Nu",
        "yes": "Da",
        "cancel_order": "Anulare comand\u0103",
        "extend": "Extinde\u021bi rezervarea dvs.",
        "new_parking_price_will_be_calculated_and_order_will_be_extended": "Se va calcula noul pre\u021b al parc\u0103rii \u0219i rezervarea va fi prelungit\u0103 pentru data \u0219i ora selectate.",
        "clear_all_selected_vehicles": "Elimina\u021bi toate vehiculele selectate?"
    },
    "contact_name": "nume de contact",
    "continue": "Continua",
    "continue_sign_in": "V\u0103 rug\u0103m s\u0103 continua\u021bi s\u0103 v\u0103 conecta\u021bi cu noua parol\u0103",
    "cost": "Cost",
    "country": "\u021aar\u0103",
    "county": "jud",
    "covered_costs": "Costuri acoperite",
    "create": "Crea",
    "create_a_booking": "Crea\u021bi o rezervare",
    "create_account": "Creeaz\u0103 cont",
    "create_password_header": "Crea\u021bi o nou\u0103 parol\u0103 mai jos",
    "create_user": "Creaza utilizator",
    "credit": "Credit",
    "credit_limit": "Limita de credit",
    "credit_zero_definition": "defini\u021bie\u201d",
    "csv_file_should_contain_the_following_columns": "Fi\u0219ierul CSV trebuie s\u0103 con\u021bin\u0103 urm\u0103toarele coloane:",
    "currencies": "Valute",
    "currency": "Moneda",
    "custom": "Personalizat",
    "daily": "Zilnic",
    "dashboard": {
        "dashboard": "Bord",
        "total_m_park_fee": "Taxa total\u0103 M-Park",
        "parking_occurrences": "Evenimente de parcare",
        "non_parking_occurrences": "Evenimente non-parcare",
        "active_sites": "Site-uri active",
        "active_users": "Utilizatori activi",
        "active_accounts": "Conturi active",
        "total_site_revenue": "Venitul total al site-ului",
        "total_revenue": "Veniturile totale",
        "total_non_parking_revenue": "Venituri totale non-parcare",
        "average_order_value": "Valoarea medie a comenzii",
        "platform_dashboard": "Tabloul de bord al platformei",
        "driver_dashboard": "Tabloul de bord pentru \u0219ofer",
        "company_dashboard": "Tabloul de bord client",
        "fleet_manager_dashboard": "Tabloul de bord Fleet Manager",
        "supplier_dashboard": "Tabloul de bord al furnizorului",
        "site_manager_dashboard": "Tabloul de bord Site Manager",
        "operator_dashboard": "Tabloul de bord al operatorului",
        "supplier_operator_dashboard": "Tabloul de bord al furnizorului",
        "client_operator_dashboard": "Tabloul de bord al operatorului client",
        "platform_operator_dashboard": "Tabloul de bord al operatorului platformei",
        "platform_finance_dashboard": "Tabloul de bord al platformei financiare"
    },
    "date": "Data",
    "date_first_issued": "Data primei utiliz\u0103ri",
    "date_first_used": "Data primei utiliz\u0103ri",
    "date_time": "Data \u0219i ora",
    "date_time_completed": "Data\/ora de executare",
    "day": "Zi",
    "delete": "\u0218terge\u021bi",
    "delete_current_client": "\u0218terge\u021bi un client?",
    "delete_payment_method": "\u0218terge\u021bi metoda de plat\u0103?",
    "delete_selected_image": "\u0218terge\u021bi imaginea selectat\u0103?",
    "delete_selected_vat_record": "\u0218terge\u021bi \u00eenregistrarea TVA selectat\u0103?",
    "description": "Descriere",
    "did_not_receive_security_code": "Nu s-a primit click aici pentru a retrimite",
    "disapprove": "Dezaproba",
    "discount": "Reducere",
    "discount_type": "Tipul de reducere",
    "discount_value": "Valoarea reducerii",
    "dismiss": "Respinge\u021bi",
    "dismiss_all": "Respinge\u021bi toate",
    "dismiss_all_notification": "Respinge\u021bi toate notific\u0103rile?",
    "dismiss_this_notification": "Respinge\u021bi aceast\u0103 notificare?",
    "distance": "Distan\u021ba",
    "distance_units": "Unit\u0103\u021bi de distan\u021b\u0103",
    "downgrade": "Reducerea valorii",
    "download_reports": "Desc\u0103rca\u021bi raportul",
    "download_sample_file": "Desc\u0103rca\u021bi fi\u0219ierul e\u0219antion",
    "drawer_link_titles": {
        "dashboard": "tablou de bord ",
        "users": "Utilizatori",
        "vehicles": "Vehicule",
        "favourite_sites": "Site-uri preferate",
        "settings": "Setari",
        "need_help": "Aveti nevoie de ajutor?",
        "clients": "Clienti",
        "fleets": "Flote",
        "suppliers": "Furnizori",
        "sites": "Site-uri",
        "promotion": "Promotie",
        "promotions": "Promotii",
        "bookings": "Rezerv\u0103ri",
        "orders": "Comenzi",
        "reports": "Rapoarte",
        "find_site": "Gaseste un site",
        "scan_qr_code": "Scaneaza codul QR",
        "my_history": "Povestea mea",
        "compliance": "Conformitate"
    },
    "driver": "Sofer",
    "driver_checked_out_at": "\u0218oferul a f\u0103cut check-out la {{checkedOutTime}}",
    "driver_have_been_checked_in": "\u0218oferul a fost \u00eenregistrat",
    "driver_have_been_checked_out": "\u0218oferul a fost verificat",
    "driver_parking_period_expires_on": "Perioada de parcare pl\u0103tit\u0103 a \u0219oferului expir\u0103 {{expireDate}",
    "driver_settings": "Set\u0103ri sofer",
    "drivers": "\u0218ofer(i)",
    "drivers_imported": "Drivere importate",
    "duration": "Durata",
    "east": "Est",
    "east_short": "WSCH",
    "edit": "Edita\u021bi ",
    "edit_payment_method": "Edita\u021bi metoda de plat\u0103",
    "email_address": "Adresa de email",
    "email_notifications": "notific\u0103ri prin email",
    "enter_card_holder_name": "Introduce\u021bi numele titularului cardului",
    "enter_card_number": "Introduce\u021bi num\u0103rul cardului",
    "enter_email_address": "Introduce\u021bi adresa de email",
    "enter_expire": "Introduce\u021bi data de expirare",
    "enter_message": "Introduce\u021bi mesajul",
    "enter_name": "Introdu numele",
    "enter_order_id_manually": "Introduce\u021bi manual ID-ul comenzii",
    "enter_parking_site_name": "Introduce\u021bi un nume de parcare",
    "enter_parking_site_website": "Accesa\u021bi site-ului de parcare",
    "enter_phone_number": "Introduceti numarul de telefon",
    "enter_post_code": "Introduce\u021bi codul po\u0219tal",
    "enter_postcode_town": "Introduce\u021bi un cod po\u0219tal\/ora\u0219",
    "enter_town_city": "Introduce\u021bi un ora\u0219\/localitate",
    "enter_username": "Introduceti numele de utilizator",
    "enter_your_security_code": "Introduce\u021bi codul dvs. de securitate",
    "entity": "Entitate",
    "errors": {
        "error_401_message": "Cerere neautorizata",
        "error_404_message": "404 | PAGINA NU A FOST G\u0102SIT\u0102",
        "error_0_message": "f\u0103r\u0103 acces la internet",
        "page_error": "ERROR DE SITE!",
        "sorry_an_error_has_occurred_to_this_page": "Ne pare r\u0103u, aceast\u0103 pagin\u0103 nu a putut fi \u00eenc\u0103rcat\u0103",
        "you_are_not_authorized_to_access_the_page_you_requested": "Ne pare r\u0103u, nu sunte\u021bi autorizat s\u0103 accesa\u021bi pagina pe care a\u021bi solicitat-o",
        "page_not_available": "Ne pare r\u0103u, pagina pe care o c\u0103uta\u021bi nu exist\u0103, a fost eliminat\u0103, i s-a schimbat numele sau este temporar indisponibil\u0103"
    },
    "example": "Exemplu",
    "expire": "Expira",
    "expires": "Expir\u0103",
    "expiry_date": "Data de expirare",
    "export": "Export",
    "export_code": "Exporta\u021bi codul QR",
    "export_payouts": "Pl\u0103\u021bi de export",
    "export_payouts_file_format": "Formatul fi\u0219ierelor de plat\u0103 la export",
    "export_pdf": "Raport de export",
    "extend_booking": "Extinderea unei rezerv\u0103ri",
    "facilities": "Facilit\u0103\u0163i",
    "field": "Domeniul",
    "filter_by_site_name": "Filtreaz\u0103 dup\u0103 numele sitului",
    "filters_labels": {
        "show_advance_filters": "Afi\u0219a\u021bi filtrele avansate",
        "hide_advance_filters": "Ascunde filtrele avansate",
        "filter_by_creation_date": "Filtra\u021bi dup\u0103 data cre\u0103rii contului",
        "filter_by_country": "Filtra\u021bi dup\u0103 Numele \u021b\u0103rii",
        "filter_by_region": "Filtra\u021bi dup\u0103 regiune",
        "filter_by_account_status": "Filtra\u021bi dup\u0103 starea contului",
        "search_for_email_address": "C\u0103uta\u021bi adresa de e-mail",
        "filter_by_company_name": "Filtra\u021bi dup\u0103 numele companiei",
        "filter_by_site": "Filtra\u021bi dup\u0103 site",
        "filter_by_user": "Filtra\u021bi dup\u0103 utilizator",
        "filter_by_client": "Filtra\u021bi dup\u0103 client",
        "filter_by_suppliers": "Filtra\u021bi dup\u0103 furnizor",
        "filter_by_fleet": "Filtra\u021bi dup\u0103 flot\u0103",
        "filter_by_product": "Filtreaz\u0103 dup\u0103 produs",
        "start_date": "Data de \u00eenceput",
        "end_date": "Data de \u00eencheiere",
        "filter_by_primary_contact_name": "Filtra\u021bi dup\u0103 numele contactului principal",
        "credit_status": "Starea creditului",
        "between_these_dates": "\u00centre Aceste Date",
        "company_name": "Numele companiei",
        "show_promotion": "Afi\u0219eaz\u0103 promo\u021bia?",
        "active_date": "Data activ\u0103",
        "expire_date": "Data expir\u0103rii",
        "order_date": "Data comenzii",
        "due_date": "Data pl\u0103\u021bii",
        "balance": "Soldul",
        "figures": "Date",
        "any": "Orice",
        "no_min": "F\u0103r\u0103 minim",
        "no_max": "F\u0103r\u0103 maxim",
        "show_filters": "Afi\u0219a\u021bi filtrele",
        "apply": "Utiliza\u021bi filtrele"
    },
    "find_site": "G\u0103si\u021bi o pagin\u0103",
    "find_site_to_book": "G\u0103si\u021bi o pagin\u0103 pentru a rezerva",
    "find_site_with_promotion_to_book": "G\u0103si\u021bi site-uri de promovare",
    "find_sites_with_promotions": "G\u0103si\u021bi pagina de promo\u021bii",
    "find_user": "G\u0103si\u021bi utilizator",
    "first_line_address": "Linia de adres\u0103 1",
    "first_line_billing_address": "Adresa de facturare linia 1",
    "first_name": "Prenume",
    "fleet": "Flota",
    "fleet_name": "Numele flotei",
    "fleet_name_company": "Client | Numele flotei",
    "fleet_spending_caps": "Limite implicite de cheltuieli pentru flot\u0103",
    "fleets": "Flote",
    "fleets_imported": "Flote importate",
    "forgot_password": "Ti-ai uitat parola?",
    "form_validation": {
        "is_required": "{{attribut}} este necesar",
        "min": "{{atributul}} trebuie s\u0103 fie mai mare sau egal cu {{valoare}}",
        "max_length": "Nu trebuie s\u0103 fie mai mare dec\u00e2t {{max}} de {{length}} caractere.",
        "invalid_email_address": "Va rugam sa utilizati o adresa de e-mail valida",
        "password_invalid_min_length": "Parola nu trebuie sa aiba mai putin de {{length}} caractere",
        "code_invalid_min_length": "Codul nu trebuie sa fie mai mic de {{length}} numere",
        "code_invalid_max_length": "Codul nu trebuie sa contina mai mult {{length}} numere",
        "password_is_required": "Parola este necesara",
        "code_is_required": "Codul este necesar",
        "password_is_must_match": "Parola trebuie sa se potriveasca",
        "password_confirm_required": "Este necesara confirmarea parolei",
        "email_address_is_required": "Adresa de e-mail este necesara",
        "name_is_required": "Numele este necesar",
        "username_is_required": "Numele de utilizator este necesar",
        "user_name_is_required": "Numele de utilizator este necesar",
        "phone_number_is_required": "Numarul de telefon este necesar",
        "user_type_is_required": "Tipul de utilizator este necesar",
        "site_is_required": "Site-ul este necesar",
        "message_is_required": "Mesajul este obligatoriu",
        "product_is_required": "Produsul este necesar",
        "discount_is_required": "Reducerea este necesara",
        "start_date_is_required": "Data de incepere este obligatorie",
        "expiry_date_is_required": "Data de expirare este obligatorie",
        "expiry_date_min": "Data de expirare nu poate fi mai mic\u0103 dec\u00e2t data curent\u0103",
        "call_to_action_required": "Este necesar un apel la ac\u021biune",
        "link_to_action_required": "Link-ul este necesar",
        "site_name_is_required": "Numele site-ul este necesar",
        "description_is_required": "Este necesara descrierea",
        "total_spaces_available_is_required": "Este necesar un total de locuri disponibile",
        "site_currency_is_required": "Moneda site-ului este necesara",
        "supplier_is_required": "Furnizorul este obligat",
        "longitude_is_required": "Longitudinea este necesar\u0103",
        "latitude_is_required": "Este necesar\u0103 latitudinea",
        "address_is_required": "Adresa este obligatorie",
        "address_line_2_is_required": "2 linii Adresa este necesar\u0103",
        "city_town_is_required": "Ora\u0219ul\/ora\u0219ul este obligatoriu",
        "country_is_required": "\u021aara este obligatorie",
        "county_is_required": "\u021aara este obligatorie",
        "post_code_is_required": "Codul po\u0219tal este necesar",
        "parking_price_is_required": "Pre\u021bul de parcare este obligatoriu",
        "parking_price_period_value_is_required": "Este necesar\u0103 valoarea perioadei",
        "parking_price_per_period_type_is_required": "Este necesar\u0103 valoarea pe perioad\u0103",
        "parking_price_up_to_value_is_required": "P\u00e2n\u0103 la valoarea este necesar\u0103",
        "parking_price_up_to_period_is_required": "Este necesar\u0103 o perioad\u0103 de p\u00e2n\u0103 la",
        "category_is_required": "Categoria este obligatorie",
        "price_is_required": "Pre\u021bul este obligatoriu",
        "commission_rate_type_is_required": "Este necesar tipul de rat\u0103 de comision",
        "commission_value_is_required": "Rata comisionului este necesar\u0103",
        "vehicle_registration_number_is_required": "Num\u0103rul de \u00eenmatriculare al vehiculului este necesar",
        "primary_contact_name_is_required": "Numele persoanei de contact este obligatoriu",
        "contact_name_is_required": "Numele persoanei de contact este obligatoriu",
        "account_status_is_required": "Statutul contului este obligatoriu",
        "account_number_is_required": "Num\u0103rul de cont este obligatoriu",
        "currency_is_required": "Moneda este obligatorie",
        "account_type_is_required": "Tipul de cont este obligatoriu",
        "vat_registration_number_required": "Num\u0103rul de \u00eenregistrare \u00een scopuri de TVA este obligatoriu",
        "client_credit_limit_required": "Limita de credit este necesar\u0103",
        "client_payment_terms_required": "Perioada de plat\u0103 necesar\u0103",
        "client_payment_terms_days_required": "Zilele termenului de plat\u0103 necesare",
        "expire_date_is_required": "Data de expirare este necesar\u0103",
        "security_code_is_required": "Este necesar codul de securitate",
        "card_number_is_required": "Num\u0103rul cardului este obligatoriu",
        "invalid_user_email": "Nu s-a g\u0103sit niciun utilizator cu acest e-mail",
        "account_number_required": "Num\u0103rul de cont este necesar",
        "order_id_is_required": "ID-ul comenzii este necesar",
        "payment_date_is_required": "Data pl\u0103\u021bii este necesar\u0103",
        "amount_is_required": "Suma este necesar\u0103",
        "ccv_is_required": "CCV este necesar",
        "discount_type_is_required": "Tipul de reducere este obligatoriu",
        "company_is_required": "Client este obligatoriu",
        "parking_limit_reached": "Aceasta va dep\u0103\u0219i limita de parcare ",
        "credit_limit_reached": "Acest lucru va determina dep\u0103\u0219irea limitei de credit a contului dvs.",
        "invalid_card_number": "Num\u0103rul cardului este incorect",
        "invalid_expire": "Cardul a expirat",
        "invalid_ccv": "Codul CCV este incorect",
        "image_required": "V\u0103 rug\u0103m s\u0103 \u00eenc\u0103rca\u021bi o fotografie",
        "invalid_qr_code": "Scana\u021bi codul QR M-Park corect",
        "addons_required_without_parking": "Dac\u0103 nu parca\u021bi, trebuie s\u0103 selecta\u021bi cel pu\u021bin un add-on",
        "booking": {
            "invalid_instant_order_token": "Sesiunea dvs. a expirat, v\u0103 rug\u0103m s\u0103 scana\u021bi codul QR",
            "invalid_location": "Pentru a scana codul QR, trebuie s\u0103 fi\u021bi la fa\u021ba locului"
        },
        "not_enough_licences": "Ave\u021bi nevoie de suficiente permise de conformitate pentru to\u021bi \u0219oferii din aceast\u0103 flot\u0103"
    },
    "formats": {
        "date": "Do mmm yy",
        "datetime": "Do mmm yy hh: mm",
        "short_datetime": "Dd\/mm\/aaaa hh: mm"
    },
    "free": "gratuit ",
    "from": "Din",
    "full_period": "Perioada complet\u0103",
    "get_directions": "A primi direc\u021bii",
    "global_commission": "Comisii globale",
    "go_to_booking": "Merge\u021bi la rezerv\u0103ri",
    "go_to_clients": "Merge\u021bi la clien\u021bi",
    "go_to_dashboard": "Accesa\u021bi Tabloul de bord",
    "go_to_fleet": "Merge\u021bi la flot\u0103",
    "go_to_fleets": "Merge\u021bi la flote",
    "go_to_promotions": "Merge\u021bi la promo\u021bii",
    "go_to_sites": "Merge\u021bi la site-uri",
    "go_to_suppliers": "Merge\u021bi la furnizori",
    "go_to_users": "Merge\u021bi la utilizatori",
    "go_to_vehicles": "Merge\u021bi la vehicule",
    "got_to_settings": "Mergi la Setari",
    "gr_code_location_error": "Nu am reu\u0219it s\u0103 afl\u0103m unde v\u0103 afla\u021bi. Activa\u021bi serviciul de localizare pentru a scana codul QR",
    "grand_total": "Total general",
    "help_message_receive": "V\u0103 mul\u021bumim c\u0103 ne-a\u021bi contactat, mesajul dvs. a fost primit",
    "home": "Acas\u0103",
    "hour": "Ora",
    "id": "ID",
    "images": "Imagini",
    "import": "Import",
    "import_clients": "Importa\u021bi clien\u021bi",
    "import_drivers": "Importa\u021bi \u0219oferi",
    "import_fleets": "Import flote",
    "import_payments": "Import pl\u0103\u021bi",
    "import_payouts": "Pl\u0103\u021bi de import",
    "import_promotions": "Promo\u021bii de import",
    "import_sites": "Importa\u021bi site-uri",
    "import_suppliers": "Furnizori de import",
    "import_users": "Importa\u021bi utilizatori",
    "import_vehicles": "Import vehicule",
    "inactive": "Inactiv",
    "incl_vat": "inclusiv factura cu TVA",
    "instant_order": "Rezerva\u021bi acum",
    "journey_time": "Timp de c\u0103l\u0103torie",
    "last_name": "Numele",
    "last_ten_orders": "Ultimele 10 comenzi",
    "last_updated": "Ultima actualizare:",
    "latitude": "Latitudine",
    "leave_feedback": "L\u0103sa\u021bi un feedback",
    "link": "Link",
    "list": "list\u0103",
    "list_of_site_names_separated_by_comma": "List\u0103 de nume de siteuri separate prin virgule.",
    "list_of_vehicles_registration_numbers_separated_by_comma": "List\u0103 de numere de \u00eenmatriculare a vehiculelor, separate prin virgul\u0103. este obligatorie dac\u0103 tipul de utilizator este \u0219ofer.",
    "list_of_vehicles_registration_numbers_separated_by_comma_driver": "lista numerelor de \u00eenmatriculare a vehiculelor separate prin virgul\u0103 \u0219ofer",
    "loading": "\u00cenc\u0103rcare ...",
    "location": "Loca\u021bie",
    "login": "Autentificare",
    "logout": "Delogare",
    "longitude": "Longitudine",
    "longitude_latitude": "Latitudine \u0219i longitudine",
    "manager": "Administrator",
    "marketing": {
        "title": "Titlu",
        "directory": "Directoare",
        "page": "Pagin\u0103",
        "active": "Activ",
        "description": "Descriere",
        "edit_mode": "Mod de editare",
        "options": "Op\u021biuni",
        "licence_price": "Pre\u021bul licen\u021bei",
        "switch_sides": "Schimba\u021bi p\u0103r\u021bile",
        "colours": {
            "change": "Schimba\u021bi culorile",
            "primary": "Culori de baza",
            "secondary": "Culori secundare"
        },
        "button": {
            "click_to_edit": "Face\u021bi clic pentru a edita",
            "edit": "Buton de editare",
            "title": "Nume",
            "href": "Link",
            "target": "Obiectiv",
            "targets": {
                "_self": "fila curent\u0103",
                "_blank": "fila nou\u0103"
            },
            "variant": "varianta"
        },
        "blocks": {
            "Addons": "Extras",
            "CallToAction": "Apel la ac\u021biune",
            "Details": "Detalii",
            "Gallery": "Galerie",
            "MediaWithContent": "Imagine de con\u021binut",
            "Pricing": "Lista de pre\u021buri",
            "Services": "Servicii"
        }
    },
    "max_journey_duration": "Durata maxim\u0103 a c\u0103l\u0103toriei",
    "media_selector": {
        "title": "Selecta\u021bi imaginea",
        "save": "Salva\u021bi",
        "upload": "\u00cenc\u0103rca\u021bi"
    },
    "message": "Mesaj",
    "messages": {
        "user_updated": "Utilizator actualizat",
        "user_created": "Creat de utilizator"
    },
    "month": "Lun\u0103",
    "monthly": "Lunar",
    "monthly_bill": "Factur\u0103 lunar\u0103",
    "more_information_about_the_site": "Mai multe informa\u021bii despre site",
    "more_than_500_spaces": "Peste 5000 de locuri",
    "more_than_hours": "mai mult de c\u00e2teva ore",
    "more_than_two_hours": "Mai mult de 2 ore",
    "mpark_credit": "Credit M-Park",
    "mpark_credit_account": "cont de credit M-park",
    "mpark_revenue": "Venituri M-Park",
    "n_days": "n zile",
    "n_days_plural": "n zile plural",
    "n_have_now_been_completed": "n au fost acum finalizate",
    "n_hours": "n ore",
    "n_hours_plural": "n ore plural",
    "na": "nu se aplic\u0103",
    "name": "Nume",
    "name_on_the_card": "Numele de pe card",
    "net_amount": "Cantitate net\u0103",
    "new_client": "Ad\u0103uga\u021bi un client nou",
    "new_fleet": "Ad\u0103uga\u021bi o flot\u0103 nou\u0103",
    "new_promotion": "Adaug\u0103 promo\u021bie",
    "new_site": "Ad\u0103uga\u021bi site nou",
    "new_supplier": "Ad\u0103uga\u021bi un furnizor nou",
    "new_user": "Utilizator nou",
    "new_vat_rate": "Noua cot\u0103 de TVA",
    "new_vehicle": "Ad\u0103uga\u021bi un vehicul nou",
    "no": "Nu",
    "no_data": "Nu exist\u0103 date disponibile",
    "no_new_notification": "Nici o notificare nou\u0103",
    "no_orders_available": "Nu exist\u0103 comenzi disponibile",
    "no_parking_prices_defined": "f\u0103r\u0103 pre\u021buri de parcare definite",
    "no_payment_method_found": "Nu a fost g\u0103sit\u0103 o metod\u0103 de plat\u0103",
    "no_report_data": "Nu exist\u0103 date disponibile pentru acest raport",
    "no_sites_found_with_selected_filters": "Nu exist\u0103 site-uri cu filtre selectate",
    "none": "Niciunul",
    "north": "Nord",
    "north_east": "Nord-est",
    "north_east_short": "Nord-est-scurt",
    "north_short": "Nord-scurt",
    "north_west": "Nord-vest",
    "north_west_short": "Nord-vest-scurt",
    "not_payouts_found_for_today": "Nu s-au g\u0103sit retrageri pentru ast\u0103zi",
    "notes": "Observa\u021bii",
    "notifications": "Notific\u0103ri",
    "notifications_types": {
        "BookingConfirmed": {
            "header": "Rezervare confirmat\u0103",
            "body": "Rezervarea dvs. #{{{num\u0103rulrezerv\u0103rii}} a fost aprobat\u0103 {{siteName}} pentru {{ora rezerv\u0103rii}}."
        },
        "BookingRejected": {
            "header": "Rezervare respins\u0103",
            "body": "Rezervarea dvs. #{{{Num\u0103rul rezerv\u0103rii}} a fost refuzat\u0103 pe {{siteName}}. Rezerva\u021bi din nou sau contacta\u021bi-ne"
        },
        "BookingCheckedIn": {
            "header": "Rezervarea Checked in",
            "body": "Checked in la {{siteName}} \u00een baza rezerv\u0103rii #{bookingNumber}} pe {{bookingTime}}."
        },
        "BookingCheckedOut": {
            "header": "Rezervarea Checked out",
            "body": "Checked out {{siteName}} pe rezervarea #{bookingNumber}} pentru {{bookingTime}}."
        },
        "BookingRequested": {
            "header": "Rezervare nou\u0103",
            "body": "O nou\u0103 rezervare #{{{Num\u0103rul rezerv\u0103rii}} a fost f\u0103cut\u0103 pe {{siteName}} pentru {{ora rezerv\u0103rii}}."
        }
    },
    "notify": {
        "None": "Notific\u0103ri\/Niciunul",
        "Inherit": "Notific\u0103ri\/Implicit",
        "Instant": "Notific\u0103ri\/Imediat",
        "Daily": "Notific\u0103ri\/Zilnic",
        "Weekly": "Notific\u0103ri\/S\u0103pt\u0103m\u00e2nal"
    },
    "notify_border_report": "Notific\u0103ri privind \u00eencheierea raportului de trecere a frontierei",
    "notify_crime_report": "Notific\u0103ri privind completarea unui raport de infrac\u021biune",
    "notify_daily_report": "Notificarea privind finalizarea verific\u0103rilor zilnice ale vehiculelor",
    "notify_default": "Valoarea implicit\u0103 pentru notific\u0103ri",
    "notify_fault_daily_report": "Notific\u0103ri implicite",
    "notify_loading_report": "Notificare de completare a raportului de \u00eenc\u0103rcare a vehiculului",
    "notify_new_orders": "Comenzi noi",
    "notify_orders_status": "Actualiz\u0103ri privind starea comenzii",
    "notify_severe_daily_report": "Notificarea unei defec\u021biuni grave a vehiculului",
    "notify_traffic_incident_report": "Notific\u0103ri privind \u00eentocmirea unui raport de accident rutier",
    "now": "Acum",
    "number_of_bookings": "Num\u0103r de rezerv\u0103ri",
    "number_of_drivers": "Num\u0103rul de \u0219oferi",
    "number_of_licences": "Numerele de inmatriculare",
    "number_of_vehicles": "Num\u0103rul de vehicule",
    "of_preposition": "de prepozi\u021bie",
    "okay": "OK",
    "on_account": "pe contul",
    "on_card": "pe card",
    "only_show_sales": "Afi\u0219a\u021bi doar v\u00e2nz\u0103rile?",
    "open_24_hours": "Deschis 24 de ore",
    "options": "Op\u021biuni",
    "order_approved": "Comand\u0103 aprobat\u0103",
    "order_canceled": "Comand\u0103 anulat\u0103",
    "order_declined": "Comand\u0103 respins\u0103",
    "order_has_been_updated": "Comanda a fost actualizat\u0103",
    "order_id": "Comanda ID",
    "order_item_id": "Identificatorul articolului",
    "order_items": "Comanda\u021bi lucrurile",
    "order_received": "Comand\u0103 acceptat\u0103",
    "order_status": {
        "pending": "In asteptare",
        "paid": "Pl\u0103tit",
        "approved": "Aprobat",
        "completed": "Efectuat",
        "rejected": "Respins",
        "started": "\u00eenceput",
        "canceled": "Anulat",
        "cancelled": "Anulat"
    },
    "order_success_waiting_approval": "Comanda a avut loc cu succes. \u00een a\u0219teptarea aprob\u0103rii",
    "order_summary": "Rezumatul comenzii",
    "order_summary_and_checkout": "Rezumatul comenzii \u0219i checkout",
    "order_updated": "Comand\u0103 actualizat\u0103",
    "orders": {
        "recent_orders": "comenzi recente",
        "view_all_orders": "Vezi toate comenzile",
        "order_reference": "Comand\u0103",
        "last_ten_orders": "Ultimele 10 comenzi"
    },
    "over_cap": "Peste Cap",
    "paid": "pl\u0103tit",
    "parking_available": "Parcare disponibil\u0103?",
    "parking_available_described": "Parcare disponibil\u0103 (da\/nu)",
    "parking_booking_question": "Ave\u021bi nevoie de parcare?",
    "parking_hourly_price": "Pre\u021b parcare pe or\u0103",
    "parking_price": "Pret parcare",
    "parking_price_configuration_note": "V\u0103 rug\u0103m s\u0103 re\u021bine\u021bi: se folose\u0219te cel mai mic pre\u021b p\u00e2n\u0103 la care se afl\u0103 \u00een interval, iar Perioada perceput\u0103 este rotunjit\u0103 la cea mai apropiat\u0103 \u201ePe perioad\u0103\u201d",
    "parking_prices": "Pre\u021burile parc\u0103rilor (f\u0103r\u0103 TVA)",
    "parking_prices_vat": "Pre\u021buri de parcare (inclusiv TVA)",
    "parking_site_name": "Numele locului de parcare",
    "parking_site_website": "Site-ul de parcare",
    "parking_specific": "Detalii privind parcarea",
    "parking_with_trailer": "Ave\u021bi nevoie de spa\u021biu pentru o remorc\u0103?",
    "password": "Parola",
    "password_is_reset": "Parola dvs. este resetat\u0103",
    "payment": "Plat\u0103",
    "payment_date": "Termen de plat\u0103",
    "payment_details": "Detaliile platii",
    "payment_method": "Metoda de plat\u0103",
    "payment_methods": "Metode de plata",
    "payment_terms_days": "Zilele de plat\u0103 ale clientului",
    "payments_imported": "pl\u0103\u021bi importate",
    "payout": "Plat\u0103",
    "payout_saved": "Plata a fost salvat\u0103!",
    "payouts_imported": "Pl\u0103\u021bi importate",
    "percentage": "procentaj",
    "period_per": "Perioada (per)",
    "period_value": "Valoarea perioadei",
    "person_n": "Persoana ",
    "phone_number": "Numar de telefon",
    "place_order": "Plasa\u021bi o comand\u0103",
    "please_enter_a_valid_code": "Introduce\u021bi un cod corect",
    "please_set_up_a_valid_payment_method": "V\u0103 rug\u0103m s\u0103 ad\u0103uga\u021bi o metod\u0103 de plat\u0103 valid\u0103 pentru a face o rezervare",
    "please_set_up_a_valid_payment_method_for_your_fleet": "V\u0103 rug\u0103m s\u0103 configura\u021bi o metod\u0103 de plat\u0103 valabil\u0103 pentru flota dvs.",
    "please_specify": "V\u0103 rug\u0103m s\u0103 preciza\u021bi",
    "please_wait": "Te rog asteapta...",
    "post_code": "Cod po\u0219tal",
    "postcode_town": "Cod po\u0219tal \/ ora\u0219",
    "prebookable": "Se poate rezerva \u00een avans?",
    "prebookable_facility": "Facilit\u0103\u021bi cu posibilitatea de rezervare \u00een avans",
    "present_qr_code_for_payment": "V\u0103 rug\u0103m s\u0103 prezenta\u021bi codul QR p\u00e2n\u0103 la plata",
    "price": "Pre\u021b",
    "price_per_twelve_hours": "{{pre\u021b}} {{valut\u0103}} pentru 12 ore",
    "price_range": "intervalul de pre\u021b",
    "price_range_between": "intervalul de pre\u021b intre",
    "primary_contact": "Contact primar",
    "print": "Imprimare",
    "print_report": "Imprimare raport",
    "product": "Produs",
    "product_category": "Categoria de produse",
    "profile": "Profil",
    "promotion_cant_expire_before_it_is_started": "Promo\u021bia nu poate expira \u00eenainte de a incepe",
    "promotion_message": "Mesaj de promovare",
    "promotions_available": "Promo\u021bii disponibile",
    "promotions_imported": "Promo\u021bii importate",
    "provide_email_address_to_sent_reset_request": "V\u0103 rug\u0103m s\u0103 furniza\u021bi adresa de e-mail mai jos pentru a solicita codul de resetare",
    "quantity": "Cantitate",
    "question_n": "\u00centrebare {{count}}",
    "rate_base_currency": "rata de baz\u0103 a monedei",
    "rebook": "Rezerva\u021bi din nou",
    "receive_marketing_emails": "Primi\u021bi e-mailuri de marketing",
    "record_deleted": "\u00eenregistrare \u0219tears\u0103",
    "reference": "Referin\u0163\u0103",
    "region": "Regiune",
    "region_country": "Regiune (\u021bara)",
    "region_specific": "Specific regiune",
    "region_spending_cap_instructions": "\u00een cazul \u00een care sunt specificate limite regionale, comenzile pentru p\u0103r\u021bile din afara regiunii specificate nu vor fi luate \u00een considerare.",
    "registered_address": "Adresa inregistrata",
    "registration": "\u00cenregistrare",
    "reject": "Respinge\u021bi",
    "remaining": "R\u0103mas",
    "remove_all_filters": "Elimina\u021bi toate filtrele",
    "remove_favourite": "\u0218terge\u021bi ca favorit",
    "remove_person": "\u00cendep\u0103rta\u021bi persoana",
    "renew_date": "Data re\u00eennoirii",
    "repeat_password": "Repeta parola",
    "reported_by": "Raportat de",
    "request_order": "Cerere de comanda",
    "reset": "Resetarea",
    "reset_link_sent": "Linkul de resetare a parolei este trimis",
    "reset_parking_pricing_for_this_site_client": "Reseta\u021bi pre\u021burile de parcare pentru acest loc \u0219i client",
    "reset_password": "Reseteaza parola",
    "reset_to_default_pricing": "Revenire la pre\u021burile de baz\u0103",
    "role": "Rol",
    "roles": {
        "platform_admin": "Admin",
        "platform_finance": "Finan\u0163a",
        "supplier_admin": "Administrator furnizor",
        "site_manager": "Manager de loca\u0163ie",
        "fleet_manager": "Manager de flot\u0103",
        "operator": "Operator",
        "client_admin": "Admin client",
        "driver": "Sofer",
        "client_operator": "Operator client",
        "supplier_operator": "Furnizor operator",
        "platform_operator": "Operator de platform\u0103"
    },
    "save": "Salva\u021bi",
    "save_client_pricing": "Salva\u021bi pre\u021burile clien\u021bilor",
    "save_new_client": "Ad\u0103uga\u021bi un client nou",
    "save_new_fleet": "Ad\u0103uga\u021bi o flot\u0103 nou\u0103",
    "save_new_promotion": "Adaug\u0103 promo\u021bie",
    "save_new_site": "Ad\u0103uga\u021bi site nou",
    "save_new_supplier": "Ad\u0103uga\u021bi un furnizor nou",
    "save_new_user": "Utilizator nou",
    "save_new_vehicle": "Ad\u0103uga\u021bi un vehicul nou",
    "save_payment_method": "Salva\u021bi metoda de plat\u0103",
    "save_payout": "Salva\u021bi plata",
    "search": "C\u0103utare",
    "search_for_anything": "Caut\u0103 orice",
    "search_placeholder": "C\u0103utare\u2026",
    "second_line_address": "Linie de adres\u0103 secundara",
    "security_facilities": "Facilit\u0103\u021bi de securitate",
    "security_rating": "Evaluare de securitate",
    "security_rating_described": "Evaluarea siguran\u021bei (1-5)",
    "select_vehicle": "Alege\u021bi vehiculul dvs. pentru a \u00eencepe\u2026",
    "send_email": "Trimite email",
    "service": "Serviciu",
    "share_app_on_social_media": "Partaja\u021bi aplica\u021bia pe re\u021belele de socializare",
    "share_location": "Distribuie locatia?",
    "show_all": "Arat\u0103 tot",
    "show_only": "Arat\u0103 doar ",
    "show_order_details": "Afi\u0219a\u021bi detaliile comenzii",
    "show_qr_code": "Afi\u0219a\u021bi codul QRC",
    "sign_up": "Inscrie-te",
    "site": "Site",
    "site_created": "Site creat",
    "site_currency": "Moneda site-ului",
    "site_description": "Descrierea siteului",
    "site_feedback": "Feedback site",
    "site_feedback_sent": "Feedback-ul pentru locatie a fost trimis",
    "site_fees": "Taxe locatie",
    "site_id": "Identificatorul locatiei",
    "site_name": "Numele site-ului",
    "site_pricing": "Preturi locatie",
    "site_size": "Dimensiunea site-ului",
    "site_specific": "Site specific",
    "site_spending_cap_instructions": "instruc\u021biuni privind plafonul de cheltuieli pe locatie",
    "site_updated": "Site actualizat",
    "sites": "locatii",
    "sites_found_with_current_filters": "Locatii gasite cu filtrele actuale",
    "sites_imported": "Locatii importate",
    "south": "Sud",
    "south_east": "Sud-est",
    "south_east_short": "Sud-est-scurt",
    "south_short": "Sud-scurt",
    "south_west": "Sud-vest",
    "south_west_short": "Sud-vest-scurt",
    "spaces_available": "Total spa\u021bii disponibile",
    "spaces_available_display": "{{spaces}} spa\u021bii disponibile",
    "spending_cap": "plafon de cheltuieli",
    "spending_caps": "Plafoane de cheltuieli",
    "spending_caps_addon_not_defined_warning": "Aloca\u021biile care nu sunt definite \u00een aceste intervale de cheltuieli nu vor fi acoperite de companie.",
    "start_date": "Data de \u00eenceput",
    "state_code": "Cod de stare",
    "state_code_placeholder": "simbolul codului de \u021bar\u0103",
    "status": "stare",
    "steps": "({{current}} de {{pasi}})",
    "stopped": "Oprit",
    "submit": "Trimite",
    "subtotal": "Subtotal",
    "success_dialog": {
        "success": "Succes!",
        "error": "Gre\u0219it!",
        "fleet_setting_updated": "Set\u0103rile flotei au fost actualizate",
        "driver_setting_updated": "Set\u0103rile au fost actualizate",
        "site_suggestion_sent": "Sugestie de site a fost trimis\u0103",
        "client_created": "Client creat!",
        "client_updated": "Client actualizat!",
        "fleet_created": "Flot\u0103 creat\u0103!",
        "fleet_updated": "Flot\u0103 actualizat\u0103!",
        "vehicle_created": "Vehicul creat!",
        "vehicle_updated": "Vehicul actualizat!",
        "supplier_created": "Furnizor creat!",
        "supplier_updated": "Furnizor actualizat!",
        "promotion_created": "Promo\u021bie creat\u0103!",
        "promotion_updated": "Promo\u021bie actualizat\u0103!"
    },
    "suggest_a_parking_site": "Sugera\u021bi un loc de parcare",
    "summary": "Rezumat",
    "supplier": "Furnizor",
    "supplier_payment_terms": "Condi\u021bii de plat\u0103 a furnizorului",
    "supplier_payment_terms_days": "Termenii de plat\u0103 a furnizorului zile",
    "supplier_site": "Furnizor | Locatie",
    "suppliers_imported": "Furnizori importa\u021bi",
    "switch_to_list_view": "Comuta\u021bi la vizualizarea list\u0103",
    "switch_to_map_view": "Comuta\u021bi la vizualizarea Hart\u0103",
    "take_a_photo": "Face\u021bi o fotografie",
    "tapa_rating": "Evaluarea TAPA",
    "tapa_rating_display": "{{evaluare}} Evaluare TAPA",
    "terms_condition": "Termeni \u0219i condi\u021bii",
    "this_order_has_been_completed_on": "Aceast\u0103 comand\u0103 a fost finalizat\u0103 p\u00e2n\u0103 la {{time}}",
    "this_order_has_been_rejected": "Aceast\u0103 comand\u0103 a fost respins\u0103",
    "this_order_is_not_approved_yet": "Aceast\u0103 comand\u0103 nu este \u00eenc\u0103 aprobat\u0103",
    "time": "Timp",
    "title": "Nume",
    "to": "La",
    "total": "Total",
    "total_addons": "Total Addons",
    "total_inc_vat": "Total (inclusiv TVA)",
    "total_parking": "Parcare total\u0103",
    "total_revenue": "Venituri totale",
    "total_spaces": "Spa\u021bii totale",
    "total_to_pay_on_card": "Total de pl\u0103tit cu cardul",
    "town_city": "Ora\u0219\/Ora\u0219",
    "trailer": "Remorc\u0103",
    "transactions": "Tranzac\u021bii",
    "truck_park": "Parcul de camioane",
    "twelve_hours": "12 ore",
    "type": "Tip",
    "type_of_user": "Tip de utilizator",
    "uncertified": "Necertificat",
    "unit_price_gbp": "Pre\u021b unitar (GBP)",
    "unlimited": "Nelimitat",
    "unpaid": "Nepl\u0103tit",
    "up_to_500_spaces": "P\u00e2n\u0103 la 500 de locuri",
    "up_to_hours": "P\u00e2n\u0103 la {{or\u0103}}",
    "up_to_one_hour": "P\u00e2n\u0103 la 1 or\u0103",
    "up_to_two_hours": "P\u00e2n\u0103 la 2 ore",
    "update": "Actualiza\u021bi",
    "update_client": "Actualiza\u021bi clientul",
    "update_exchange_rates": "Actualiza\u021bi cursurile de schimb",
    "update_fleet": "Actualiza\u021bi flota",
    "update_fleet_settings": "Actualiza\u021bi set\u0103rile flotei",
    "update_promotion": "Actualizarea promo\u021biei",
    "update_settings": "Actualiza\u021bi set\u0103rile",
    "update_site": "Actualiza\u021bi site-ul",
    "update_supplier": "Actualiza\u021bi furnizorul",
    "update_user": "Actualiza\u021bi utilizatorul",
    "update_vehicle": "Actualiza\u021bi vehiculul",
    "upgrade": "Actualizare",
    "upload": "\u00cenc\u0103rca\u021bi",
    "upload_a_photo": "\u00cenc\u0103rca\u021bi o fotografie",
    "upload_payments": "\u00cenc\u0103rca\u021bi pl\u0103\u021bi",
    "upto": "p\u00e2n\u0103 la",
    "upto_period": "P\u00e2n\u0103 la Perioada",
    "upto_value": "P\u00e2n\u0103 la valoare",
    "use_current_location": "Utiliza\u021bi loca\u021bia curent\u0103",
    "use_fleet_defaults": "Utiliza\u021bi set\u0103rile implicite ale flotei",
    "use_standard_price": "Utiliza\u021bi pre\u021bul standard",
    "used": "Folosit?",
    "user": "Utilizator",
    "username": "Nume de utilizator",
    "users_imported": "Utilizatori importa\u021bi",
    "valid_fleet_name_that_is_created_for_this_driver_supplier": "Numele corect al flotei asociat cu contul de client al \u0219oferului",
    "vat": "TVA",
    "vat_rate": "cota TVA",
    "vat_rates": "Cotele de TVA",
    "vat_registration_number": "Num\u0103r de \u00eenregistrare \u00een scopuri de TVA",
    "vehicle": "Vehicul",
    "vehicle_registration_nr": "Num\u0103rul de \u00eenmatriculare",
    "vehicle_registration_number": "Num\u0103r de \u00eenmatricluare al vehiculului",
    "vehicle_type": "Tipul de vehicul",
    "vehicle_types": {
        "Forklift": "Stivuitor",
        "Van": "Van",
        "Truck": "Camion",
        "TractorUnit": "Cap de tractor"
    },
    "vehicles": "Vehicule",
    "vehicles_imported": "Vehicule importate",
    "view_all_bookings": "Vezi toate rezerv\u0103rile",
    "view_all_sites": "Vizualiza\u021bi toate site-urile",
    "view_all_users": "Vede\u021bi to\u021bi utilizatorii",
    "view_details": "Vezi detalii",
    "view_order": "Vezi comanda",
    "waiting_approval_title": "Astept aprobarea site-ului...",
    "we_have_sent_a_security_code": "Am trimis un cod de securitate c\u0103tre {{email}}",
    "weekly": "S\u0103pt\u0103m\u00e2nal",
    "west": "Vest",
    "west_short": "Vest-scurt",
    "year": "An",
    "yearly": "Anual",
    "yes": "da",
    "you_have_been_checked_in": "Ai fost \u00eenregistrat",
    "you_have_been_checked_out": "Ai fost verificat",
    "you_have_checked_in": "Ati facut check in la {{siteName}} cu comanda #{{{orderId}} la {{bookingTime}}.",
    "you_have_checked_out": "A\u021bi facut check out la {{siteName}} comanda dvs. #{{{Num\u0103rul rezerv\u0103rii}} la {{timpul rezerv\u0103rii}}.",
    "you_have_not_selected_any_site_as_favourite": "Nu a\u021bi selectat niciun site ca favorit",
    "your_account_is_current_on_stop": "Contul dvs. este blocat \u00een prezent. V\u0103 rug\u0103m s\u0103 v\u0103 contacta\u021bi managerul.",
    "your_booking_has_been_extended": "Rezervarea a fost prelungit\u0103 p\u00e2n\u0103 la {{expirare}}.",
    "your_booking_is_ready": "Rezervarea dumneavoastr\u0103 este gata",
    "your_order_has_been_approved": "Comanda dvs. #{{{orderId}} a fost aprobat\u0103 pe {{siteName}} pentru {{bookingTime}}.",
    "your_order_has_been_declined": "Comanda dvs. #{{{orderId}} a fost refuzat\u0103 la {{siteName}}. V\u0103 rug\u0103m s\u0103 solicita\u021bi o reprogramare sau s\u0103 ne contacta\u021bi",
    "your_order_is_successful_show_qr_code": "Rezervarea dvs. a fost aprobat\u0103, prezenta\u021bi codul QR pe {{siteName}} pentru procesare",
    "your_parking_period_expires_on": "Perioada dvs. de parcare pl\u0103tit\u0103 expir\u0103 {{expireDate}}"
}

