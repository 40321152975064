import {useTranslation}                                   from "react-i18next";
import {useAuth}                                          from "@/services/Auth";
import {useParams}                                        from "react-router-dom";
import React, {useEffect, useState}                       from "react";
import LoadingPage                                        from "@/components/LoadingPage";
import {Button, Card, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import SupplierForm                                       from "./components/SupplierForm";
import ClientForm                                         from "../clients/components/ClientForm";
import SuccessDialog                                      from "../../../components/Modals/SuccessDialog";
import { Helmet } from "react-helmet";

function SupplierCreate(props) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const getRequest = auth.getRequest;
    const postRequest = auth.postRequest;
    let {id} = useParams();


    const [supplier, setSupplier] = useState(null);
    const [loading, setLoading] = useState(true);
    const [storingSupplier, setStoringSupplier] = useState(false);
    const [name, setName] = useState('');
    const [contactName, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [accountStatus, setAccountStatus] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [billingCurrency, setBillingCurrency] = useState('');
    const [accountType, setAccountType] = useState('');
    const [creditLimit, setCreditLimit] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [postCode, setPotsCode] = useState('');
    const [billingSameAsRegisteredAddress, setBillingSameAsRegisteredAddress] = useState(false);
    const [addressBilling, setAddressBilling] = useState('');
    const [cityBilling, setCityBilling] = useState('');
    const [countryBilling, setCountryBilling] = useState('');
    const [postCodeBilling, setPotsCodeBilling] = useState('');
    const [vatRegisteredNumber, setVatRegisteredNumber] = useState('');


    const [accountStatusOptions, setAccountStatusOptions] = useState([]);
    const [supportedCurrency, setSupportedCurrency] = useState([]);

    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [serverErrors, setServerErrors] = useState([]);

    const [paymentTerms, setPaymentTerms] = useState([]);

    const [createSuccess, setCreateSuccess] = useState(false);
    const [createdSupplier, setCreatedSupplier] = useState('');


    useEffect(() => {
        getRequest('/suppliers/options')
            .then(response => {
                //options
                setAccountStatusOptions(response.data.account_status_options);
                setSupportedCurrency(response.data.supported_currency);
                setPaymentTerms(response.data.payment_terms);

                setCities(response.data.cities);
                setCountries(response.data.countries);

                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })

    }, []);

    const handleSubmit = (payload) => {
        setStoringSupplier(true);
        console.log(payload);
        postRequest(`/suppliers`, payload)
            .then(response => {
                setStoringSupplier(false);
                if (response.data.message && response.data.message === "OK") {
                    setCreateSuccess(true);
                    setCreatedSupplier(response.data.supplier_id)
                }
            })
            .catch(error => {
                setStoringSupplier(false);


                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }
                    setServerErrors(serverErrors)
                }
            })
    };

    const handleClose = () => {
        setCreateSuccess(false)
        if (createdSupplier !== '' && createdSupplier != null) {
            props.history.push(`/suppliers/${createdSupplier}`)
        }
    }

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('create')} / {t('drawer_link_titles.suppliers')} - {t('app')}</title>
                </Helmet>

                <SuccessDialog
                    show={createSuccess}
                    message="success_dialog.supplier_created"
                    handleClose={handleClose}
                />
                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h4 className="text-primary">{t('new_supplier')}</h4>
                </Card.Subtitle>
                <SupplierForm
                    initialValues={{
                        'company_name'                              : '',
                        'contact_name'                              : '',
                        'email'                                     : '',
                        'phone_number'                              : '',
                        'account_status'                            : '',
                        'account_number'                            : '',
                        'currency'                                  : '',
                        'credit_limit'                              : '',
                        'vat_registration_number'                   : '',
                        'address'                                   : '',
                        'city'                                      : '',
                        'country_id'                                : '',
                        'post_code'                                 : '',
                        'billing_address_same_as_registered_address': true,
                        'address_billing'                           : '',
                        'billing_city'                              : '',
                        'billing_country_id'                        : '',
                        'post_code_billing'                         : '',
                    }}
                    handleSubmit={handleSubmit}
                    supplier={supplier}
                    loading={storingSupplier}
                    accountStatusOptions={accountStatusOptions}
                    supportedCurrency={supportedCurrency}
                    cities={cities}
                    countries={countries}
                    buttonText="save_new_supplier"
                    serverErrors={serverErrors}
                    paymentTerms={paymentTerms}
                />
            </Card>
        )
    )
}

export default SupplierCreate;
