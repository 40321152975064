import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import * as moment                    from "moment";
import Select                         from "react-select";
import AsyncSelect                    from "react-select/async";
import React, { useEffect, useState, useRef }   from "react";
import {useTranslation}               from "react-i18next";
import {useAuth}                      from "@/services/Auth";
import { Cities, Countries }          from "../../../../services";
import {useForm}                      from "react-hook-form";

function UsersFilters({options,searching,managing, ...props}) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const getSitesRequest = useRef(null);
    const getClientsRequest = useRef(null);

    const formatRecord = (company) => {
        const  object = {...company};

        object.value = company.id;
        object.label = company.name;

        return object;
    };

    const [countries, setCountries] = useState(null);

    const onSubmit = (values) => {
        let updatedValues = values;
        if (country && typeof country === 'object') {
            updatedValues.country_id = country.value
        }
        if (site && typeof site === 'object') {
            updatedValues.site_id = site.value
        }
        if (client && typeof client === 'object') {
            updatedValues.company_id = client.value
        }
        props.onSubmit(updatedValues)
    };

    const {
              handleSubmit,
              register,
              watch,
              setValue
          } = useForm({
        defaultValues: {
            client   : null,
            country  : null,
            from_date: null,
            to_date  : null,
            site     : null,
            ...props.values
        }
    });


    const [advanceFilters, setAdvanceFilters] = useState(false);

    useEffect(() => {
        register('country');
        register('site');
        register('client');
    }, []);

    const toDate = watch('to_date');
    const country = watch('country');
    const site = watch('site');
    const client = watch('client');


    useEffect(() => {
        if (countries === null) {
            Countries.active()
                .then(countries => setCountries(countries.map(formatRecord)))
                .catch(_ => setCountries([]));
        }
    }, [countries]);



    const getSites = (search) => {
        getSitesRequest.current && getSitesRequest?.current?.abort && getSitesRequest.current.abort();

        return new Promise((resolve, reject) => {
            getSitesRequest.current = auth.getRequest('sites', { search, managing });

            getSitesRequest.current.then(response => {
                    resolve(response.data.sites.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    const getClients = (search) => {
        getClientsRequest.current && getClientsRequest?.current?.abort && getClientsRequest.current.abort();

        return new Promise((resolve, reject) => {
            getClientsRequest.current = auth.getRequest('clients', { search });

            getClientsRequest.current.then(response => {
                    resolve(response.data.clients.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    useEffect(() => {
        getSitesRequest.current && getSitesRequest?.current?.abort && getSitesRequest.current.abort();
        getClientsRequest.current && getClientsRequest?.current?.abort && getClientsRequest.current.abort();
    }, []);


    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="my-4">
                <Col sm={4} className="col-12">
                    <Form.Control
                        type="text"
                        {...register('search_for_anything')}
                        placeholder={t('search_for_anything')}
                        />
                </Col>
                <Col sm={3} className="col-12 mt-md-0 mt-3">
                    <div className="d-grid gap-2">
                    {
                        !searching ?
                            <Button type="submit" variant="success" className="w-100">
                                {advanceFilters ? t('filters_labels.apply') : t('search')}
                            </Button>
                            :
                            <Button variant="success" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="mx-2">{t('please_wait')}</span>
                            </Button>
                    }
                    </div>
                </Col>
                <Col sm={4} className="col-12">
                    <Button variant="link" className="my-md-0 my-3" onClick={(e) => {
                        e.preventDefault();
                        setAdvanceFilters(!advanceFilters)
                    }}>{!advanceFilters ? t('filters_labels.show_advance_filters') : t('filters_labels.hide_advance_filters')}</Button>
                </Col>
            </Row>
            {advanceFilters &&
             <div>
                 <Row className="my-md-4 my-2">
                     <Col sm={6}>
                         <Row className="">
                             <Form.Label className="mb-2">{t('filters_labels.filter_by_creation_date')}</Form.Label>
                         </Row>
                         <Row className="my-md-0 my-2">
                             <Col className="col-12 d-flex align-items-center">
                                 <Form.Control
                                     placeholder={t('from')}
                                     type="date"
                                     {...register('from_date')}
                                     max={toDate ? moment(toDate).format('YYYY-MM-DD') : null}
                                 />
                                 <span className="mx-2">{t('to')}</span>

                                 <Form.Control
                                     placeholder={t('to')}
                                     type="date"
                                     {...register('to_date')}
                                     min={toDate ? moment(toDate).format('YYYY-MM-DD') : null}
                                 />
                             </Col>
                         </Row>
                     </Col>
                     {
                         (auth.user.is_fleet_manager || auth.user.is_client_admin || auth.user.is_platform_admin) &&
                         <Col sm={6}>
                             <Row>
                                 <Col md={6} className="my-md-0 my-2">
                                     <Form.Label className="mb-2">{t('filters_labels.filter_by_country')}</Form.Label>
                                     <Select
                                         className="react-select"
                                         isClearable={true}
                                         value={country}
                                         onChange={(e) => setValue('country', e)}
                                         placeholder={t('country')}
                                         options={countries}/>
                                 </Col>
                                 <Col md={6} className="my-md-0 my-2">
                                     <Form.Label className="mb-2">{t('filters_labels.filter_by_region')}</Form.Label>
                                     <Form.Control
                                         type="text"
                                         {...register('city')}
                                         placeholder={t('city')}
                                     />
                                 </Col>
                             </Row>
                         </Col>
                     }
                     {
                         (auth.user.is_site_manager || auth.user.is_supplier_admin || auth.user.is_platform_admin) &&

                         <Col sm={6} md={3} className="my-md-0 my-1">
                             <Row className="">
                                 <Col className="">
                                     <Form.Label className="mb-2">{t('site')}</Form.Label>
                                 </Col>
                             </Row>
                             <Row className="">
                                 <Col className="">
                                    <AsyncSelect
                                        className="react-select"
                                        isClearable={true}
                                        value={site}
                                        onChange={(e) => setValue('site', e)}
                                        placeholder={t('Site')}
                                        defaultOptions={true}
                                        loadOptions={getSites} />
                                 </Col>
                             </Row>
                         </Col>
                     }
                     {
                         (auth.user.is_platform_admin || auth.user.is_platform_operator) &&

                         <Col sm={6} md={3} className="my-md-0 my-1">
                             <Row className="">
                                 <Col className="">
                                     <Form.Label className="mb-2">{t('company')}</Form.Label>
                                 </Col>
                             </Row>
                             <Row className="">
                                 <Col className="">
                                    <AsyncSelect
                                        className="react-select"
                                        isClearable={true}
                                        value={client}
                                        onChange={(e) => setValue('client', e)}
                                        placeholder={t('company')}
                                        defaultOptions={true}
                                        loadOptions={getClients} />
                                 </Col>
                             </Row>
                         </Col>
                     }
                     <Col sm={6} md={3} className="my-md-0 my-1">
                         <Row className="">
                             <Col className="">
                                 <Form.Label className="mb-2">{t('filters_labels.search_for_email_address')}</Form.Label>
                             </Col>
                         </Row>
                         <Row className="">
                             <Col className="">
                                 <Form.Control
                                    type="text"
                                    {...register('email')}
                                    placeholder={t('search')}
                                    />
                             </Col>
                         </Row>
                     </Col>
                     <Col sm={6} md={3} className="my-md-0 my-1">
                         <Row className="">
                             <Col className="">
                                 <Form.Label className="mb-2">{t('account_status')}</Form.Label>
                             </Col>
                         </Row>
                         <Row className="">
                             <Col className="">
                                 <Form.Select
                                    {...register('account_status')}
                                    className="form-control"
                                    type="number">
                                     <option value="">{t('all')}</option>
                                     <option value={1}>{t('active')}</option>
                                     <option value={0}>{t('stopped')}</option>
                                 </Form.Select>
                             </Col>
                         </Row>
                     </Col>
                 </Row>
             </div>
            }
        </Form>
    )
}

export default UsersFilters;
