import SpendingCapsFields from "@/components/SpendingCapsFields";
import SpendingCaps from "@/components/SpendingCaps";
import {Row, Col, Form} from "react-bootstrap";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import { useForm } from 'react-hook-form';

function DriverFormOptions({
        errors,
        watch,
        control,
        register,
        setValue,
        fleet,
    }) {
    const {t} = useTranslation();


    useEffect(() => {
        register('driver_use_fleet_spending_cap');
    }, []);

    const driver_use_fleet_spending_cap = watch('driver_use_fleet_spending_cap');

    return (
        <div className="col-12">
            <div className="form-group my-4">
                <h4>{t('spending_caps')}</h4>

                <Row>
                    <Col xs={6}>
                        <Row>
                            <Col sm={4}>
                                <Form.Label className="my-2"  htmlFor="driver_use_fleet_spending_cap">
                                    {t('use_fleet_defaults')}
                                </Form.Label>
                            </Col>

                            <Col sm={8}>
                                <Form.Select
                                    {...register('driver_use_fleet_spending_cap')}
                                    isInvalid={!!errors.driver_use_fleet_spending_cap}
                                >
                                    <option value={1}>{t('yes')}</option>
                                    <option value={0}>{t('no')}</option>
                                </Form.Select>

                                <Form.Control.Feedback type="invalid">
                                    {errors.driver_use_fleet_spending_cap && errors.driver_use_fleet_spending_cap.message}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            {
                driver_use_fleet_spending_cap == 0
                &&
                <SpendingCapsFields
                    {...{
                        errors,
                        watch,
                        control,
                        register,
                        setValue,
                    }}

                />
                ||
                <PreviewSpendingCaps fleet={fleet} />
            }
        </div>
    )
}

export default DriverFormOptions;


function PreviewSpendingCaps({ fleet }) {
    const {t} = useTranslation();

    return <>
        <h4 className="mt-4">{t('fleet_spending_caps')}</h4>
        <SpendingCaps spending_caps={fleet.spending_caps} />
    </>;
}
