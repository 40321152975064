import Reports        from "../../views/pages/Report";
import PromotionsIcon from "../../components/icons/PromotionsIcon";
import ReportsIcon    from "../../components/icons/ReportsIcon";

const reportRoutes = [
    {
        path         : "/report",
        component    : Reports,
        exact        : true,
        gate         : 'view_figures',
        title        : 'Reports',
        translate_key: 'reports',
        // icon         : 'bi-pie-chart',
        icon         : <ReportsIcon/>,
        main         : true,
    }
]
export default reportRoutes;
