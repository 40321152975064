function BackIcon({classDefined}) {
    return (
        <div className={`custom-logo ${classDefined}`}>
            <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.67078 15.9999C6.50006 16.0005 6.33138 15.9628 6.17714 15.8897C6.02289 15.8165 5.88699 15.7097 5.77944 15.5771L0.259962 8.72061C0.0918842 8.51613 0 8.25965 0 7.99496C0 7.73027 0.0918842 7.47379 0.259962 7.26932L5.97371 0.412821C6.16768 0.179452 6.44641 0.0326955 6.74858 0.00483554C7.05075 -0.0230244 7.35162 0.0702947 7.58498 0.264263C7.81835 0.458232 7.96511 0.736962 7.99297 1.03913C8.02083 1.34131 7.92751 1.64217 7.73354 1.87554L2.62545 8.00068L7.56213 14.1258C7.70187 14.2936 7.79063 14.4978 7.81792 14.7144C7.84521 14.931 7.80988 15.1509 7.71611 15.3481C7.62234 15.5452 7.47406 15.7114 7.28881 15.8269C7.10356 15.9424 6.88909 16.0025 6.67078 15.9999Z" fill="white"/>
            </svg>
        </div>
    );
}

export default BackIcon;
