
const DataBase = (database, session) => {
  var db;

  const init =  (maybeCreate) => {
    return new Promise(async (resolve, reject) => {
      if(db)
      {
        resolve(db);
        return;
      }

      window.indexedDB.databases()
        .then(databases => {
          if(session)
          {
            if(databases.find(_ => _.name == database) && !window.sessionStorage.getItem('indexedDB-session-' + database))
            {
              window.indexedDB.deleteDatabase(database);
            }
          }

          if(!databases.find(_ => _.name == database) && !maybeCreate)
          {
            reject();
            return;
          }

          if(session)
          {
            window.sessionStorage.setItem('indexedDB-session-' + database, true);
          }

          const request = window.indexedDB.open(database);

          request.onerror = (event) => {
            console.error("Why didn't you allow my web app to use IndexedDB?!");
          };

          request.onsuccess = (event) => {
            db = event.target.result;

            db.onerror = (event) => {
              // Generic error handler for all errors targeted at this database's
              // requests!
              console.error(`Database error: ${event.target.errorCode}`);
            };

            resolve(db);
          };

          request.onupgradeneeded = (event) => {
            const db = event.target.result;

            // Create an objectStore to hold information about our customers. We're
            // going to use "ssn" as our key path because it's guaranteed to be
            // unique - or at least that's what I was told during the kickoff meeting.
            const objectStore = db.createObjectStore("keyValues", { keyPath: "name" });
         };
        })


    });
  }

  return {

    getItem: (name) => {
      return new Promise((resolve) => {
        init().then(db => {
          const objectStore = db.transaction(["keyValues"], "readonly").objectStore("keyValues");

          const request = objectStore.get(name);

          request.onerror = (event) => {
            // Handle errors!
            throw event;
          };

          request.onsuccess = (event) => {
            // Do something with the request.result!
            resolve(request.result?.value);
          };
        }).catch(_ => resolve(null));
      });
    },

    setItem: (name, value) => {
      return new Promise((resolve) => {
        init(true).then(db => {
          const objectStore = db.transaction(["keyValues"], "readwrite").objectStore("keyValues");


          const request = objectStore.get(name);

          const add = () => {
            console.log('add', { name, value });
            const addRequest = objectStore.add({ name, value });

            addRequest.onerror = (event) => {
              // Handle errors!
              throw event;
            };

            addRequest.onsuccess = (event) => {
              // Do something with the request.result!

              resolve();
            };
          };

          const update = () => {
            const data = request.result;

            data.value = value;

            const putRequest = objectStore.put(data);

            putRequest.onerror = (event) => {
              // Handle errors!
              throw event;
            };

            putRequest.onsuccess = (event) => {
              // Do something with the request.result!
              console.log('updateSuccess');
              resolve();
            };

          };


          request.onerror = (event) => {
            // Handle errors!

            add();
          };

          request.onsuccess = (event) => {
            // Do something with the request.result!


            if(request.result)
            {
              update();
            }
            else
            {
              add();
            }
          };
        });
      });
    },

    removeItem: (name) => {
      return new Promise((resolve) => {
        init().then(db => {
          const objectStore = db.transaction(["keyValues"], "readwrite").objectStore("keyValues");

          const request = objectStore.delete(name);

          request.onerror = (event) => {
            // Handle errors!
          };

          request.onsuccess = (event) => {
            // Do something with the request.result!
            resolve();
          };
        }).catch(_ => resolve());
      });
    },
  }
};


const sessionStorageDB = DataBase('sessionStorage', true);
const Session = {
  setItem: (name, value) => {
    value = JSON.stringify(value);

    return new Promise(function (resolve, reject) {
      if(value.length > 1_000_000)
      {
        sessionStorageDB.setItem(name, value).then(resolve);
        window.sessionStorage.removeItem(name);
      }
      else
      {
        window.sessionStorage.setItem(name, value);

        resolve();
      }
    });
  },

  getItem: (name) => {
    return new Promise((resolve) => {
      const value = window.sessionStorage.getItem(name);

      if(value !== null)
      {
        try {
          resolve(value ? JSON.parse(value) : null);
        } catch (error) {
          resolve(value);
        }
      }
      else
      {
        sessionStorageDB.getItem(name).then(value => {
          resolve(value ? JSON.parse(value) : null);
        });
      }
    });
  },

  removeItem: (name) => {
    return new Promise(function (resolve, reject) {
      window.sessionStorage.removeItem(name);

      sessionStorageDB.removeItem(name).then(resolve);
    });
  },

};

const longStorageDB = DataBase('longStorage');
const Long = {
  setItem: (name, value) => {
    value = JSON.stringify(value);

    return new Promise(function (resolve, reject) {
      if(value.length > 1_000_000)
      {
        longStorageDB.setItem(name, value).then(resolve);
        window.localStorage.removeItem(name);
      }
      else
      {
        window.localStorage.setItem(name, value);

        resolve();
      }
    });
  },

  getItem: (name) => {
    return new Promise((resolve) => {
      const value = window.localStorage.getItem(name);

      if(value !== null)
      {
        try {
          resolve(value ? JSON.parse(value) : null);
        } catch (error) {
          resolve(value);
        }
      }
      else
      {
        longStorageDB.getItem(name).then(value => {
          resolve(value ? JSON.parse(value) : null);
        });
      }
    });
  },

  removeItem: (name) => {
    return new Promise(function (resolve, reject) {
      window.localStorage.removeItem(name);

      longStorageDB.removeItem(name).then(resolve);
    });
  },
};


export default {
  Session,
  Long,
};

export {
  Session,
  Long
};
