import React, {useEffect, useReducer, useState}        from "react";
import {Link, useParams}                               from "react-router-dom";
import {Form, Button, Card, Col, Row, Table, Modal, Spinner} from "react-bootstrap";
import {useTranslation}                                from "react-i18next";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup                                        from "yup";
import { useForm, useFieldArray } from 'react-hook-form';
import moment from 'moment-timezone';
import Select              from 'react-select';
import {useAuth}           from "@/services/Auth";
import SpendingCapsFields, { useSpendingCapsRules }  from "@/components/SpendingCapsFields";
import LoadingPage         from "@/components/LoadingPage";
import LoadingButton         from "@/components/LoadingButton";
import SuccessDialog       from "@/components/Modals/SuccessDialog";
import NumberFormat        from 'react-number-format';
import SuggestAParkingSite from "@/components/Forms/SuggestAParkingSite";
import PaymentMethodForm   from "@/components/Forms/PaymentMethodForm";

function FleetSettings(props) {
    const {t} = useTranslation();

    let auth = useAuth();

    let {id} = useParams();

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [serverErrors, setServerErrors] = useState([]);


    const [fleets, setFleets] = useState(null);
    const [fleet, setCurrentFleet] = useState(null);

    const formatRecord = fleet => fleet && { ...fleet, value: fleet.id, label: fleet.name };

    const getFleets = () => {
        auth.getRequest('/fleets', { include: ['account_items', 'spending_caps'] })
            .then(response => {
                setFleets(response.data.fleets);

                if (response.data.fleets.length === 1) {
                    let fleet = response.data.fleets[0];
                    setCurrentFleet(fleet)
                }
            });
    }

    const updateFleet = (payload) => {
        return new Promise((resolve, reject) => {
            // const emptyIndex = payload.spending_caps && payload.spending_caps.findIndex(_ => !_.cap_period && !_.cost_cap)
            // if(emptyIndex !== false && emptyIndex > -1){
            //     payload.spending_caps.splice(emptyIndex,1);
            // }

            auth.putRequest(`/fleets/${fleet.id}?include[]=account_items&include[]=spending_caps`, payload)
                .then(response => {
                    setSuccessMessage('success_dialog.fleet_setting_updated');
                    setShowSuccessDialog(true);
                    setCurrentFleet(response.data.fleet);

                    resolve(response.data.fleet);
                })
                .catch(error => {
                    if (error.response.status === 422) {

                        let serverErrors = [];

                        for (const key in error.response.data.errors) {
                            serverErrors.push(
                                error.response.data.errors[key][0]
                            )
                        }

                        setServerErrors(serverErrors)
                    }

                    reject();
                });
        });
    };

    const success = (successMessage) => {
        setSuccessMessage(successMessage)
        setShowSuccessDialog(true);
    }


    useEffect(() => {
        if(fleets === null)
        {
            getFleets();
        }
    }, [fleets]);

    return (
        <Card className="mx-2 my-2 p-2 fleet-setting-card">
            {!fleets && <LoadingPage/> || ''}

            <SuccessDialog
                message={successMessage}
                handleClose={() => setShowSuccessDialog(false)}
                show={showSuccessDialog}
            />

            <Modal show={serverErrors.length !== 0} onHide={_ => setServerErrors([])}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('success_dialog.error')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {
                        serverErrors.map((error, index) => <p className="text-danger font-weight-bold" key={index}>{t(error)}</p>)
                    }

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={_ => setServerErrors([])}>
                        {t('okay')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Card.Subtitle className="" style={{width: '100%'}}>
                <div className="row d-flex flex-md-row flex-column-reverse">
                    <div className="col-12 col-md-6">
                        {
                            fleet && <h3>{fleet.name}</h3>
                        }
                    </div>
                    <div className="col-12 col-md-6 my-4 my-md-0">
                        {fleets && <Select
                            className="react-select"
                            value={formatRecord(fleet)}
                            onChange={(e) => setCurrentFleet(e)}
                            placeholder={t('choose_fleet')}
                            options={fleets.map(formatRecord)}/> || ''}
                    </div>
                </div>
            </Card.Subtitle>

            <div className="card-body site-find-card position-relative px-2" style={{height: '100%'}}>
                {
                    fleet && <>

                        <FleetPaymentMethod {...{ fleet, updateFleet }} />

                        <SuggestAParkingSite handleSuggestSuccess={success} />

                        <FleetCompliance {...{ fleet, updateFleet }} />

                        <FleetForm {...{ fleet, updateFleet }} />
                    </> || ''
                }
            </div>

        </Card>
    )
}

function FleetPaymentMethod({ fleet, updateFleet })
{
    const {t} = useTranslation();

    const auth = useAuth();

    const paymentMethod = fleet.payment_method
    const [paymentMethods, setPaymentMethods] = useState(null)

    const [changePaymentMethod, setChangePaymentMethod] = useState(false);
    const [addPaymentMethod, setAddPaymentMethod] = useState(false);

    const changeDefaultPaymentMethods = (payment_method_id) => {
       updateFleet({ payment_method_id });
    }

    const getPaymentMethods = () => {
        auth.getRequest('/payment-methods')
            .then(response => {
                setPaymentMethods(response.data.payment_methods);
            });
    }

    useEffect(() => {
        if(paymentMethods === null)
        {
            getPaymentMethods();
        }
    }, [paymentMethods]);


    const [creatingPaymentMethod, setCreatingPaymentMethod] = useState(false);

    const createPaymentMethod = (values, { setError }) => {
        const attributes = { ...values, fleet_id: fleet?.id };

        setCreatingPaymentMethod(true)
        auth.postRequest('payment-methods', attributes)
            .then(response => {
                setChangePaymentMethod(false);
                setAddPaymentMethod(false);
                setCreatingPaymentMethod(false);
                updateFleet({ payment_method_id: response.data.payment_method.id });
            })
            .catch(error => {
                if(error?.response?.status === 422)
                {
                    for(var attribute in error?.response?.data.errors)
                    {
                        const errors = error?.response?.data?.errors[attribute] || [];

                        setError(attribute, { type: 'manual', message: t(errors[0]) });
                    }
                }

                setCreatingPaymentMethod(false)
            })

    }

    if(paymentMethods === null)
    {
        return <LoadingPage/>;
    }

    return (
        <div className="form-group mt-2">
            <h5>{t('payment_method')}</h5>

            {fleet.client && fleet.client.account_type === 'Credit' && (
                <div className="row d-flex mt-2">
                    <label>{ t('mpark_credit') }</label>
                </div>
            ) || (
            <>
            <div className="row d-flex">
                {
                    paymentMethod &&
                    <div className="col-12 d-flex">
                        <h4 className="text-primary mb-0 d-flex align-items-center text-uppercase">{paymentMethod.brand}&nbsp;{paymentMethod.number}&nbsp;-&nbsp;{paymentMethod.expire}</h4>
                        <button onClick={() => {
                            setChangePaymentMethod(!changePaymentMethod)
                            setAddPaymentMethod(false)
                        }} className="btn btn-link ms-4">{t('change')}</button>
                    </div>
                }
            </div>
            {(changePaymentMethod || !paymentMethod) &&
            <div className="row d-flex mt-2">
                {paymentMethods.map((payment_method, index) => (
                    (!paymentMethod || paymentMethod.id !== payment_method.id) ?

                        <Col className="col-6" md={2} key={payment_method.id}>
                            <Card role="button"
                                  onClick={() => changeDefaultPaymentMethods(payment_method.id)}
                                  className="border-1 payment-card credit-card d-flex justify-content-center align-items-center"
                                  style={{height: '100px', width: '100%'}}>

                                <div className="d-flex flex-column align-items-center">
                                    <h6>{payment_method.number}</h6>
                                    <img alt={payment_method.brand} className="mx-1 card-image" src={`/images/${payment_method.brand}.png`}/>
                                </div>
                            </Card>
                        </Col>
                        :
                        null

                ))}
                {
                    auth?.roles?.create_payment_method && <Col className="col-6" md={2}>
                        <Card role="button"
                              onClick={() => setAddPaymentMethod(true)}
                              className="border-1 payment-card credit-card d-flex justify-content-center align-items-center"
                              style={{height: '100px', width: '100%'}}>

                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <i className="bi bi-plus" style={{fontSize: "3.5rem"}}/>
                            </div>
                        </Card>
                    </Col> || ''
                }


             </div>
            }
            {
                addPaymentMethod && auth?.roles?.create_payment_method &&
                <PaymentMethodForm handleSubmit={createPaymentMethod} loadingButton={creatingPaymentMethod} />
            }
            </>

            )}
        </div>

    );
}

function FleetCompliance({ fleet, updateFleet })
{
    const [updating, setUpdating] = useState(false);

    const {t} = useTranslation();

    const auth = useAuth();

    const schema = yup.object().shape({
        compliance_status: yup.string().oneOf(['Active', 'Inactive', 'Cancelled']),
        compliance_licences: yup.number()
            .transform(_ => isNaN(_) ? null : _)
            .nullable()
            .typeError(t('form_validation.is_required', { attribute: t('number_of_licences') }))
            .when(['compliance_status'], (compliance_status, schema) => {
                return compliance_status != 'Inactive' && schema.min(1, t('form_validation.min', { attribute: t('number_of_licences'), value: 1 })) || schema;
            }),

    }).noUnknown(true);

    const onSubmit = (payload) => {
        setUpdating(true);

        updateFleet(payload)
            .finally(_ => setUpdating(false));
    };

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
        setError,
        watch,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });


    const { fields: account_items, replace } = useFieldArray({
        control,
        name: 'account_items',
    });

    const compliance_status = watch('compliance_status');
    const compliance_licences = watch('compliance_licences');

    useEffect(() => {
        if(compliance_status == 'Active' && compliance_licences > 0 && fleet.compliance_auto_price)
        {
            replace([
                {
                    name: 'Compliance Licence',
                    renew_date: account_items[0] && account_items[0].renew_date || moment().format('YYYY-MM-DD'),
                    unit_price: auth.compliance_licence_price,
                    quantity: compliance_licences,
                }
            ])
        }
    }, [compliance_status, compliance_licences]);

    useEffect(() => {
        reset(fleet)
    }, [fleet])

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <h4 className="mt-4">{t('compliance.title')}</h4>

            <div className="row">
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                    <Form.Label className="my-2">
                        {t('compliance.active')}
                    </Form.Label>

                    <Form.Select
                        {...register('compliance_status')}
                        isInvalid={!!errors.compliance_status}
                        disabled={!fleet.compliance_auto_price}
                        >
                        {
                            ['Active', 'Cancelled'].includes(fleet.compliance_status) && <option value="Cancelled">{t('cancel')}</option>
                        }

                        {
                            ['Inactive'].includes(fleet.compliance_status || 'Inactive') && <option value="Inactive">{t('no')}</option>
                        }

                        <option value="Active">{t('yes')}</option>
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                        {errors.compliance_status && errors.compliance_status.message}
                    </Form.Control.Feedback>
                </div>

                <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                    <Form.Label className="my-2">
                        {t('number_of_licences')}
                    </Form.Label>


                    <Form.Control
                        {...register('compliance_licences')}
                        type="number"
                        isInvalid={!!errors.compliance_licences}
                        disabled={!fleet.compliance_auto_price}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.compliance_licences && errors.compliance_licences.message}
                    </Form.Control.Feedback>

                    <p><small className="text-muted">{t('compliance.licences_description')}</small></p>
                </div>

                <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                    <Form.Label className="my-2">
                        &nbsp;
                    </Form.Label>


                    {(compliance_status != fleet.compliance_status || compliance_licences != fleet.compliance_licences) && (
                    <LoadingButton type="submit" loading={updating}
                        variant={(compliance_status == 'Cancelled' || compliance_licences < fleet.compliance_licences) && 'danger' || 'success'}>
                        {compliance_status == 'Cancelled' && t('cancel') || fleet.compliance_status != compliance_status && t('activate') || compliance_licences < fleet.compliance_licences && t('downgrade') || t('upgrade')}
                    </LoadingButton>
                    ) || ''}
                </div>

            </div>

            {compliance_status != 'Inactive' && (
                <>
                <h5>{t('monthly_bill')}</h5>

                <Table>
                    <thead>
                        <tr>
                            <th>
                                {t('title')}
                            </th>

                            <th>
                                {t('renew_date')}
                            </th>

                            <th>
                                {t('unit_price_gbp')}
                            </th>


                            <th>
                                {t('quantity')}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                    {account_items.map((account_item, index) => (

                        <tr key={index}>
                            <td>
                                {account_item.name}
                            </td>

                            <td>
                                {moment(account_item.renew_date).format(t('formats.date'))}
                            </td>

                            <td>
                                {account_item.unit_price}
                            </td>


                            <td>
                                {account_item.quantity}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                </>
            )}
        </Form>
    );
}


function FleetForm({ fleet, updateFleet })
{
    const {t} = useTranslation();
    const [updating, setUpdating] = useState(false);

    const auth = useAuth();


    const schema = yup.object().shape({
        notify_default: yup.string().oneOf(['None', 'Instant', 'Daily', 'Weekly']),
        notify_new_orders: yup.string().oneOf(['None', 'Inherit', 'Instant', 'Daily', 'Weekly']),
        notify_orders_status: yup.string().oneOf(['None', 'Inherit', 'Instant', 'Daily', 'Weekly']),
        notify_daily_report: yup.string().oneOf(['None', 'Inherit', 'Instant', 'Daily', 'Weekly']),
        notify_fault_daily_report: yup.string().oneOf(['None', 'Inherit', 'Instant', 'Daily', 'Weekly']),
        notify_severe_daily_report: yup.string().oneOf(['None', 'Inherit', 'Instant', 'Daily', 'Weekly']),
        notify_traffic_incident_report: yup.string().oneOf(['None', 'Inherit', 'Instant', 'Daily', 'Weekly']),
        notify_loading_report: yup.string().oneOf(['None', 'Inherit', 'Instant', 'Daily', 'Weekly']),
        notify_border_report: yup.string().oneOf(['None', 'Inherit', 'Instant', 'Daily', 'Weekly']),
        notify_crime_report: yup.string().oneOf(['None', 'Inherit', 'Instant', 'Daily', 'Weekly']),

        notify_journey_report: yup.string().oneOf(['None', 'Inherit', 'Instant', 'Daily', 'Weekly']),
        notify_journey_report_completion: yup.string().oneOf(['None', 'Inherit', 'Instant', 'Daily', 'Weekly']),
        notify_journey_leg_completion: yup.string().oneOf(['None', 'Inherit', 'Instant', 'Daily', 'Weekly']),

        receive_marketing_emails: yup.boolean(),
        addon_purchase_using_fleet_account: yup.boolean(),

        spending_caps: useSpendingCapsRules(),
    }).noUnknown(true);

    const onSubmit = (payload) => {
        setUpdating(true);

        updateFleet(payload)
            .finally(_ => setUpdating(false));
    };

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
        setError,
        watch,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const values = {...fleet};

        values.receive_marketing_emails = values.receive_marketing_emails ? 1 : 0;
        values.addon_purchase_using_fleet_account = values.addon_purchase_using_fleet_account ? 1 : 0;

        reset(values)
    }, [fleet])

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mt-4">
                <h5>{t('email_notifications')}</h5>

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_default" className="my-2">{t('notify_default')}</label>
                        <Form.Select
                            {...register('notify_default')}
                            isInvalid={errors.notify_default}
                        >
                            <option value="Weekly">{t('notify.Weekly')}</option>
                            <option value="Daily">{t('notify.Daily')}</option>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_default && errors.notify_default.message}
                        </Form.Control.Feedback>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_new_orders" className="my-2">{t('notify_new_orders')}</label>
                        <Form.Select
                            {...register('notify_new_orders')}
                            isInvalid={errors.notify_new_orders}
                        >
                            <option value="Weekly">{t('notify.Weekly')}</option>
                            <option value="Daily">{t('notify.Daily')}</option>
                            <option value="Inherit">{t('notify.Inherit')}</option>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_new_orders && errors.notify_new_orders.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_orders_status" className="my-2">{t('notify_orders_status')}</label>
                        <Form.Select
                            {...register('notify_orders_status')}
                            isInvalid={errors.notify_orders_status}
                        >
                            <option value="Weekly">{t('notify.Weekly')}</option>
                            <option value="Daily">{t('notify.Daily')}</option>
                            <option value="Inherit">{t('notify.Inherit')}</option>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_orders_status && errors.notify_orders_status.message}
                        </Form.Control.Feedback>
                    </div>

                    {fleet.compliance_status !== 'Inactive' && (
                        <>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_daily_report" className="my-2">{t('notify_daily_report')}</label>
                        <Form.Select
                            {...register('notify_daily_report')}
                            isInvalid={errors.notify_daily_report}
                        >
                            <option value="Weekly">{t('notify.Weekly')}</option>
                            <option value="Daily">{t('notify.Daily')}</option>
                            <option value="Inherit">{t('notify.Inherit')}</option>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_daily_report && errors.notify_daily_report.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_fault_daily_report" className="my-2">{t('notify_fault_daily_report')}</label>
                        <Form.Select
                            {...register('notify_fault_daily_report')}
                            isInvalid={errors.notify_fault_daily_report}
                        >
                            <option value="Weekly">{t('notify.Weekly')}</option>
                            <option value="Daily">{t('notify.Daily')}</option>
                            <option value="Inherit">{t('notify.Inherit')}</option>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_fault_daily_report && errors.notify_fault_daily_report.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_severe_daily_report" className="my-2">{t('notify_severe_daily_report')}</label>
                        <Form.Select
                            {...register('notify_severe_daily_report')}
                            isInvalid={errors.notify_severe_daily_report}
                        >
                            <option value="Weekly">{t('notify.Weekly')}</option>
                            <option value="Daily">{t('notify.Daily')}</option>
                            <option value="Inherit">{t('notify.Inherit')}</option>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_severe_daily_report && errors.notify_severe_daily_report.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_traffic_incident_report" className="my-2">{t('notify_traffic_incident_report')}</label>
                        <Form.Select
                            {...register('notify_traffic_incident_report')}
                            isInvalid={errors.notify_traffic_incident_report}
                        >
                            <option value="Weekly">{t('notify.Weekly')}</option>
                            <option value="Daily">{t('notify.Daily')}</option>
                            <option value="Inherit">{t('notify.Inherit')}</option>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_traffic_incident_report && errors.notify_traffic_incident_report.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_loading_report" className="my-2">{t('notify_loading_report')}</label>
                        <Form.Select
                            {...register('notify_loading_report')}
                            isInvalid={errors.notify_loading_report}
                        >
                            <option value="Weekly">{t('notify.Weekly')}</option>
                            <option value="Daily">{t('notify.Daily')}</option>
                            <option value="Inherit">{t('notify.Inherit')}</option>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_loading_report && errors.notify_loading_report.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_border_report" className="my-2">{t('notify_border_report')}</label>
                        <Form.Select
                            {...register('notify_border_report')}
                            isInvalid={errors.notify_border_report}
                        >
                            <option value="Weekly">{t('notify.Weekly')}</option>
                            <option value="Daily">{t('notify.Daily')}</option>
                            <option value="Inherit">{t('notify.Inherit')}</option>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_border_report && errors.notify_border_report.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_crime_report" className="my-2">{t('notify_crime_report')}</label>
                        <Form.Select
                            {...register('notify_crime_report')}
                            isInvalid={errors.notify_crime_report}
                        >
                            <option value="Weekly">{t('notify.Weekly')}</option>
                            <option value="Daily">{t('notify.Daily')}</option>
                            <option value="Inherit">{t('notify.Inherit')}</option>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_crime_report && errors.notify_crime_report.message}
                        </Form.Control.Feedback>
                    </div>


                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_journey_report" className="my-2">{t('notify_journey_report')}</label>
                        <Form.Select
                            {...register('notify_journey_report')}
                            isInvalid={errors.notify_journey_report}
                        >
                            <option value="Weekly">{t('notify.Weekly')}</option>
                            <option value="Daily">{t('notify.Daily')}</option>
                            <option value="Inherit">{t('notify.Inherit')}</option>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_journey_report && errors.notify_journey_report.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_journey_report_completion" className="my-2">{t('notify_journey_report_completion')}</label>
                        <Form.Select
                            {...register('notify_journey_report_completion')}
                            isInvalid={errors.notify_journey_report_completion}
                        >
                            <option value="Weekly">{t('notify.Weekly')}</option>
                            <option value="Daily">{t('notify.Daily')}</option>
                            <option value="Inherit">{t('notify.Inherit')}</option>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_journey_report_completion && errors.notify_journey_report_completion.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_journey_leg_completion" className="my-2">{t('notify_journey_leg_completion')}</label>
                        <Form.Select
                            {...register('notify_journey_leg_completion')}
                            isInvalid={errors.notify_journey_leg_completion}
                        >
                            <option value="Weekly">{t('notify.Weekly')}</option>
                            <option value="Daily">{t('notify.Daily')}</option>
                            <option value="Inherit">{t('notify.Inherit')}</option>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_journey_leg_completion && errors.notify_journey_leg_completion.message}
                        </Form.Control.Feedback>
                    </div>

                        </>
                    ) || ''}

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="receive_marketing_emails" className="my-2">{t('receive_marketing_emails')}</label>
                        <Form.Select
                            {...register('receive_marketing_emails')}
                            isInvalid={errors.receive_marketing_emails}
                        >
                            <option value={1}>{t('yes')}</option>
                            <option value={0}>{t('no')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.receive_marketing_emails && errors.receive_marketing_emails.message}
                        </Form.Control.Feedback>
                    </div>
                </div>
            </div>


            <div className="form-group mt-4">
                <h4>{t('spending_caps')}</h4>

                <div>
                    <label htmlFor="addon_purchase_using_fleet_account" className="my-2">{t('addon_purchase_using_fleet_account')}</label>
                    <Form.Select
                        {...register('addon_purchase_using_fleet_account')}
                        isInvalid={errors.addon_purchase_using_fleet_account}
                    >
                        <option value={1}>{t('yes')}</option>
                        <option value={0}>{t('no')}</option>
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                        {errors.addon_purchase_using_fleet_account && errors.addon_purchase_using_fleet_account.message}
                    </Form.Control.Feedback>
                </div>

                <SpendingCapsFields
                    {...{
                        errors,
                        watch,
                        control,
                        register,
                        setValue,
                    }}

                />
            </div>

            <div className="form-group mt-4">
                <div className="d-grid gap-2">
                    <LoadingButton type="submit" variant="primary" loading={updating}>
                        {t('update_fleet_settings')}
                    </LoadingButton>
                </div>
            </div>
        </Form>
    );
}

export default FleetSettings;
