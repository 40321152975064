import FindSite      from "../../views/pages/bookings/FindSite";
import Create        from "../../views/pages/bookings/Create";
import Show        from "../../views/pages/bookings/Show";
import Edit        from "../../views/pages/bookings/Edit";
import SiteDetails   from "../../views/pages/bookings/SiteDetails";
import SuppliersIcon from "../../components/icons/SuppliersIcon";
import FindSiteIcon  from "../../components/icons/FindSiteIcon";

const bookingRoutes = [
    {
        path         : "/find-a-site",
        component    : FindSite,
        exact        : true,
        gate         : 'view_bookings',
        title        : 'Find a Site',
        translate_key: 'find_site',
        // icon         : 'bi-search',
        icon         : <FindSiteIcon classDefined="menu-logo"/>,
        main         : true,
    },
    {
        path     : "/bookings/site-card/:id",
        component: SiteDetails,
        exact    : true,
        gate     : 'view_bookings',
        main     : false,
        parent   : "/find-a-site",
    },
    {
        path     : "/bookings/create",
        component: Create,
        exact    : true,
        gate     : 'view_bookings',
        main     : false,
        parent   : "/find-a-site",
    },
    {
        path     : "/bookings/:id/edit",
        component: Edit,
        exact    : true,
        main     : false,
        parent   : "/orders",
    },
    {
        path     : "/bookings/:id",
        component: Show,
        exact    : true,
        main     : false,
        parent   : "/orders",
    },
]
export default bookingRoutes;
