import { useAuth } from "@/services/Auth";
import Dashboard from "./Dashboard";
import Reports from "./Reports";
import Inactive from "./Inactive";

function Compliance() {
  const auth = useAuth();

  if(auth.roles.create_compliance_report)
  {
    return <Dashboard />;
  }

  if(auth.roles.view_compliance)
  {
    return <Reports />;
  }

  return <Inactive />;
}

export default Compliance;
