import { useHistory, useLocation, Redirect, Link } from "react-router-dom";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useAuth, Countries, Storage } from "@/services";
import { Card, Form, Button, ProgressBar } from "react-bootstrap";
import moment from 'moment-timezone';
import LoadingPage from "@/components/LoadingPage";
import { Attachments } from "@/components/Inputs";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LocationFields, { useLocationFieldsSchema } from "@/components/Forms/LocationFields";
import { DisplayWysiwyg, DisplayLocation, DisplayAttachments, DisplayAttachment } from "@/components/Elements";
import { CheckIcon } from "@/components/icons";
import { useLayout } from "@/layouts/Layout";
import Checks from "./components/JourneyChecks";
import DisplayChecks from "./components/DisplayChecks";
import ValidationErrors from "./components/ValidationErrors";


function ShowJourneyReport({ report, setReport }) {
  const auth = useAuth();
  const main = useLayout();
  const { t } = useTranslation();
  const route = useLocation();
  const history = useHistory();
  const location = useLocation();
  const [dataUrl, setDataUrl] = useState(null);

  const action = route?.state?.journey_action || null;

  const [loading, setLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const updateAction = journey_action => {
    history.push(route.pathname, { journey_action });
  };

  const save = useCallback(leg => {
    return new Promise((resolve, error) => {
      const data = {
        ...leg,
      };

      setLoading(true);
      // submitting to end
      // if action is not null then we are saving a leg
      if (action !== null) {
        auth.postRequest(`reports/${report.guid}/${action}?include=all`, data)
          .then(response => {
            setReport(response.data.data);

            history.replace(route.pathname, {});

            resolve();
          })
          .catch(_ => {
            console.error(_);
            window.history.back();
            console.error('Error fetching data:', _);
            if (_.response) {
              setValidationErrors(_.response.data);
            }
            error(_);
            setShowErrors(true);
          })
          .finally(_ => setLoading(false));
      }
    });
  }, [action]);

  // use effect to check action and submit if action is end
  useEffect(_ => {

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (action === 'end') {
      save({}).then(_ => updateAction(null));
    }

    if(action === 'pdf') {
      window.location.replace(report.pdf_url + '?tz=' + tz);
    }
    if(action === 'export') {
      window.location.replace(report.pdf_url + '?download=true&tz=' + tz);
    }
  }, [action]);




  return (
    <div className="container">
      <Helmet>
        <title>{report?.detail?.name || ''} - {t('compliance.JourneyReport.title')} - {t('app')}</title>
      </Helmet>


      <h4>{report?.detail?.name || ''}</h4>

      {showErrors && <ValidationErrors errors={validationErrors} setShowErrors={setShowErrors} />}

      {action === 'pdf' &&
        <LoadingPage />
        ||
        action === 'export' &&
        <LoadingPage />
        ||
        action && typeof action === 'string' && action !== 'end' &&
        <NewStop {...{ report, setReport, save, action }} />
        ||
        action === 'end' &&
        <LoadingPage />
        ||
        action &&
        <LegSummary {...{ action, report }} />
        ||
        <Summary {...{ report, updateAction, save }} />
      }

    </div>
  )
}

function NewStop({ report, setReport, save, action }) {
  const auth = useAuth();
  const main = useLayout();
  const { t } = useTranslation();
  const route = useLocation();
  const history = useHistory();
  const storage_key = route?.state?.storage_key;

  const steps = ['create', 'checks', 'complete'];
  const step = route?.state?.step || 'create';
  const step_index = steps.findIndex(_ => step == _);

  const [leg, setLeg] = useState(undefined);

  const updateStep = step => {
    history.push(route.pathname, {
      ...(route.state || {}),

      step,
    });
  };

  const next = () => {
    var target = steps.findIndex(_ => _ == step);

    if (target < 0 || target > steps.length - 1) {
      target = -1;
    }

    target++;

    updateStep(steps[target]);
  };

  const onSave = values => {
    const _leg = { ...leg, ...values };

    setLeg(_leg);
  };

  const onSubmit = values => {
    const _leg = { ...leg, ...values };

    setLeg(_leg);

    next();
  };

  useEffect(() => {
    if (leg?.id) {
      Storage.Session.removeItem(storage_key);
    }

    const onUnload = _ => {
      if (leg && !leg.id) {
        Storage.Session.setItem(storage_key, leg);
      }
    };

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [leg]);

  useEffect(() => {
    if (leg === undefined) {
      var defaultValue = {}
      // TODO : prefilling details on new check
      const lastLeg = report?.legs && report?.legs[ report.legs.length - 1 ] || null;

      if (lastLeg) {
        defaultValue.security_device_type = lastLeg.security_device_type;
        defaultValue.security_device_type_other = lastLeg.security_device_type_other;
        defaultValue.security_device_number = lastLeg.security_device_number;
      }

      if (storage_key) {
        Storage.Session.getItem(storage_key).then(value => {
          setLeg(value || defaultValue);
        });
      }
      else {
        setLeg(defaultValue);
      }
    }
  }, [leg]);

  useEffect(() => {
    if(!storage_key)
    {
      history.replace(route.pathname, {
        ...(route.state || {}),

        storage_key: 'journey-leg-' + report.guid + '-' + moment().toISOString(),
      });
    }

  }, [storage_key])


  useEffect(() => {
    if (leg && Object.keys(leg).length <= 0) {
      if (step != steps[0]) {
        updateStep(steps[0]);
      }
    }
    else
      if (step == 'complete') {
        save(leg).then(_ => {
          Storage.Session.removeItem(storage_key);
        });
      }
  }, [leg, step]);

  const checks = leg?.checks || [];

  const setChecks = (checks) => {
    const _leg = { ...leg };

    _leg.checks = checks;

    setLeg(_leg);
  };

  useEffect(() => {
    main.scrollTop();
  }, [step])

  if(!leg)
  {
    return <LoadingPage />;
  }

  return (
    <>
      <h1 className="h4 mb-3 text-primary">
        {t('compliance.JourneyReport.title')} - {action == "stop" ? t('compliance.JourneyReport.add_stop') : t('compliance.JourneyReport.add_final')}
      </h1>

      {(_ => {
        switch (step) {
          case 'create':
          default:
            return <LegForm {...{ leg, onSave, onSubmit }} />;

          case 'checks':

            return <Checks {...{
              values: checks, setValues: setChecks, onSubmit: (checks) => {
                setChecks(checks);

                next();
              }
            }} />;

          case 'complete':
            return <LoadingPage />;
        }

      })()}
    </>
  );
};

function LegForm({ vehicle, report, onSave, onSubmit, ...props }) {
  const auth = useAuth();
  const { t } = useTranslation();

  const [time, setTime] = useState(moment());

  const schema = yup.object().shape({
    location: yup.object().shape({
      longitude: yup.number()
        .required(),

      latitude: yup.number()
        .required(),

      address_1: yup.string()
        .nullable()
        .max(255, t('form_validation.max_length', { attribute: t('address_1'), max: 255 })),
      address_2: yup.string()
        .nullable()
        .max(255, t('form_validation.max_length', { attribute: t('address_2'), max: 255 })),
      address_3: yup.string()
        .nullable()
        .max(255, t('form_validation.max_length', { attribute: t('address_2'), max: 255 })),
      city: yup.string()
        .nullable()
        .max(255, t('form_validation.max_length', { attribute: t('city'), max: 255 })),
      county: yup.string()
        .nullable()
        .max(255, t('form_validation.max_length', { attribute: t('county'), max: 255 })),

      country_id: yup.number()
        .nullable(),

      postcode: yup.string()
        .nullable()
        .max(255, t('form_validation.max_length', { attribute: t('post_code'), max: 255 })),
    }),


    security_device_type: yup.string()
      .required(t('form_validation.is_required', { attribute: t('compliance.JourneyReport.security_device_type') })),

    security_device_type_other: yup.string()
      .when(['security_device_type'], (type, schema) => (
        type == 'Other' &&
        schema.required(t('form_validation.is_required', { attribute: t('compliance.JourneyReport.security_device_type_other') }))
        ||
        schema.nullable()
      )),

    security_device_number: yup.string()
      .required(t('form_validation.is_required', { attribute: t('compliance.JourneyReport.security_device_number') })),


    attachments: yup.array().of(
      yup.object().shape({

        hash: yup.string()
          .required(),
      }),
    ).nullable(),
  });

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setError,
    setValue,
    watch,
    getValues
  } = useForm({
    defaultValues: props.leg,
    resolver: yupResolver(schema),
  });

  const leg = watch();

  useEffect(_ => {
    onSave(leg)
  }, [
    leg,
  ]);

  useEffect(() => {
    const updateTime = _ => setTime(moment());

    const interval = setInterval(1000, updateTime);

    return () => clearInterval(interval);
  }, []);

  const security_device_type = watch('security_device_type');

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>

      <Form.Group className="mb-3">
        <label htmlFor="name" className="my-2">{t('compliance.datetime')}</label>

        <div className="form-control disabled">{time.format(t('formats.datetime'))}</div>
      </Form.Group>

      <LocationFields {...{ register, errors, watch, setValue, path: 'location.', useCurrentLocation: true, previewAddressOnly: true }} />


      <Form.Group className="mb-3">
        <label htmlFor="security_device_type" className="my-2">{t('compliance.JourneyReport.security_device_type')}</label>
        <Form.Select
          {...register('security_device_type')}
          isInvalid={!!(errors?.security_device_type)}
        >
          <option value="">--{t('choose')}--</option>
          <option value="Seal">{t('compliance.JourneyReport.device_type.Seal')}</option>
          <option value="Lock">{t('compliance.JourneyReport.device_type.Lock')}</option>
          <option value="Other">{t('compliance.JourneyReport.device_type.Other')}</option>
        </Form.Select>

        <Form.Control.Feedback type="invalid">
          {errors?.security_device_type?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className={`mb-3 ${security_device_type == 'Other' ? '' : 'd-none'} `}>
        <label htmlFor="security_device_type_other" className="my-2">{t('compliance.JourneyReport.security_device_type_other')}</label>
        <Form.Control
          {...register('security_device_type_other')}
          type="text"
          isInvalid={!!(errors?.security_device_type_other)}
        />

        <Form.Control.Feedback type="invalid">
          {errors?.security_device_type_other?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="security_device_number" className="my-2">{t('compliance.JourneyReport.security_device_number')}</label>

        <Form.Control
          {...register('security_device_number')}
          type="text"
          isInvalid={!!(errors?.security_device_number)}
        />

        <Form.Control.Feedback type="invalid">
          {errors?.security_device_number?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Attachments
          {...register('attachments')}
          preUpload={true}
          type="text"
          isInvalid={!!(errors?.attachments)}
          imageCompression={{ maxWidth: 320 }}
        />

        <Form.Control.Feedback type="invalid">
          {errors?.attachments?.message}
        </Form.Control.Feedback>
      </Form.Group>


      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" type="submit"
          disabled={!(getValues('attachments')?.length)}
        >
          {t('continue')}
        </Button>
      </Form.Group >
    </Form>
  );
}


function Summary({ report, updateAction, save }) {
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <Form.Group className="my-3 d-flex">
        <Button className="w-100" onClick={_ => updateAction('pdf')}>
          {t('print_report')}
        </Button>
      </Form.Group>

      {report?.status === 'Incomplete' && auth?.roles?.create_compliance_report && (
        <>
          <Form.Group className="my-3 mx-n2 d-flex">
            <Button className="mx-2 w-50" variant="success" onClick={_ => updateAction('stop')}>
              {t('compliance.JourneyReport.add_stop')}
            </Button>

            <Button className="mx-2 w-50" variant="primary" onClick={_ => updateAction('end')}>
              {t('compliance.JourneyReport.add_final')}
            </Button>

          </Form.Group>
        </>
      )}


      {report?.legs?.map && report?.legs?.sort((a, b) => b.created_at.localeCompare(a.created_at)).map((leg, i) => (
        <Card onClick={_ => updateAction({ leg, i })} key={i} className="bg-light p-3 mt-4" style={{
          cursor: 'pointer'
        }}>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div>
              <h5>
                {moment.utc(leg.created_at).local().format(t('formats.full_date'))}
              </h5>
              <p>
                <strong className="me-2">
                  {i === 0 && report.status !== 'Incomplete' &&
                    t('compliance.JourneyReport.end')
                    ||
                    i >= report.legs.length - 1 &&
                    t('compliance.JourneyReport.start')
                    ||
                    t('compliance.JourneyReport.stop')
                  }:
                </strong>
                {moment.utc(leg.created_at).local().format(t('formats.time'))}
              </p>

              <p>
                {leg?.location?.address_1 && <>
                  {leg?.location?.address_1}
                  <br />
                </>}
                {leg?.location?.address_2 && <>
                  {leg?.location?.address_2}
                  <br />
                </>}
                {leg?.location?.address_3 && <>
                  {leg?.location?.address_3}
                  <br />
                </>}
                {leg?.location?.county && <>
                  {leg?.location?.county}
                  <br />
                </>}
                {leg?.location?.postcode && <>
                  {leg?.location?.postcode}
                  <br />
                </>}
                {leg?.location?.city && <>
                  {leg?.location?.city}
                  <br />
                </>}
                {leg?.location?.country && <>
                  {leg?.location?.country?.name}
                  <br />
                </>}
              </p>
            </div>
            <div>
              <i className="bi bi-chevron-right" />
            </div>
          </div>
        </Card>

      ))}
    </>
  );
}

function LegSummary({ action: { leg, i }, report }) {
  const { t } = useTranslation();

  return (
    <>

      <Card>
        <div className="mb-2">
          <p>
            <strong className="me-2">
              {i === 0 && report.status !== 'Incomplete' &&
                t('compliance.JourneyReport.end')
                ||
                i >= report.legs.length - 1 &&
                t('compliance.JourneyReport.start')
                ||
                t('compliance.JourneyReport.stop')
              }:
            </strong>
            {moment.utc(leg.created_at).local().format(t('formats.datetime'))}
          </p>
          <p>
            <strong className="me-2">{t('compliance.JourneyReport.security_device_type')}:</strong>
            {leg.security_device_type}
          </p>

          <p>
            <strong className="me-2">{t('compliance.JourneyReport.security_device_number')}:</strong>
            {leg.security_device_number}
          </p>
        </div>

        <div className="mb-4">
          <h5 className="text-primary">Location</h5>
          <p>
            <strong className="me-2">{t('address')}:</strong>
            {leg.location.address_1}
          </p>

          {leg.location.address_2 &&
            <p>
              <strong className="me-2">{t('address_2')}:</strong>
              {leg.location.address_2}
            </p>
          }

          {leg.location.address_3 &&
            <p>
              <strong className="me-2">{t('address_3')}:</strong>
              {leg.location.address_3}
            </p>
          }

          {leg.location.county &&
            <p>
              <strong className="me-2">{t('county')}:</strong>
              {leg.location.county}
            </p>
          }

          {leg.location.postcode &&
            <p>
              <strong className="me-2">{t('postcode')}:</strong>
              {leg.location.postcode}
            </p>
          }

          {leg.location.city &&
            <p>
              <strong className="me-2">{t('city')}:</strong>
              {leg.location.city}
            </p>
          }

          {leg?.location?.country?.name &&
            <p>
              <strong className="me-2">{t('country')}:</strong>
              {leg?.location?.country?.name}
            </p>
          }
        </div>

        {leg.attachments?.map && <div className="mb-4">
          <h5 className="text-primary">
            {t('compliance.JourneyReport.security_device_photos')}
          </h5>
          {leg.attachments.map((attachment, i) => (
            <div className="col-6 col-md-2 col-lg-2 mb-3" key={i}>
              <DisplayAttachment attachment={attachment} />
            </div>
          ))}
        </div>}

        {/*TODO details*/}
        <h5 className="text-primary">Checks</h5>
        <DisplayChecks {...leg} />
      </Card>

    </>
  );
}

export default ShowJourneyReport;







