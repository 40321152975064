import { useHistory, useLocation, Redirect, Link } from "react-router-dom";
import React, { useEffect, useState, useCallback, useRef }   from "react";
import { useTranslation }               from "react-i18next";
import { Helmet } from "react-helmet";
import { useAuth, Countries, Storage }   from "@/services";
import { Card, Form, Button, ProgressBar } from "react-bootstrap";
import moment from 'moment-timezone';
import LoadingPage                    from "@/components/LoadingPage";
import Wysiwyg from "@/components/Inputs/Wysiwyg";
import Attachments from "@/components/Inputs/Attachments";
import { useForm } from 'react-hook-form';
import { useJsApiLoader } from '@react-google-maps/api';
import * as yup from 'yup';
import Select from "react-select";
import { yupResolver } from '@hookform/resolvers/yup';
import DisplayLocation from "@/components/Elements/DisplayLocation";
import DisplayWysiwyg from "@/components/Elements/DisplayWysiwyg";
import DisplayAttachments from "@/components/Elements/DisplayAttachments";
import LocationFields, { useLocationFieldsSchema } from "@/components/Forms/LocationFields";
import { CheckIcon } from "@/components/icons";
import { useLayout } from "@/layouts/Layout";

function LoadingReport() {
  const auth = useAuth();
  const main = useLayout();
  const {t} = useTranslation();
  const route = useLocation();
  const history = useHistory();

  const vehicle = route?.state?.vehicle || undefined;
  const occurance_date = route?.state?.occurance_date || undefined;
  const storage_key = route?.state?.storage_key;

  const [report, setReport] = useState(undefined);

  const steps = ['form', 'summary'];
  const step = route?.state?.step || 'form';
  const step_index = steps.findIndex(_ => step == _);


  const updateStep = step => {
    history.push(route.pathname, {
      ...(route.state || {}),

      step,
    });
  };

  const onBack = () => {
    var target = steps.findIndex(_ => _ == step);

    if(target < 0 || target > steps.length - 1)
    {
      target = steps.length;
    }

    target --;

    updateStep(steps[target]);
  };

  const next = () => {
    var target = steps.findIndex(_ => _ == step);

    if(target < 0 || target > steps.length - 1)
    {
      target = -1;
    }

    target ++;

    updateStep(steps[target]);
  };


  const [loading, setLoading] = useState(false);

  const onSave = values => {
    const _report = { ...report, ...values };

    setReport(_report);
  };

  const onSubmit = values => {
    const _report = { ...report, ...values };

    setReport(_report);

    next();
  };

  const save = _ => {
    const data = {
      type: 'LoadingReport',
      vehicle_id: vehicle.id,
      ...report,
    };

    setLoading(true);

    auth.postRequest('reports', data)
        .then(response => {
          setReport(response.data.data);
        })
        .finally(_ => setLoading(false));
  };

  useEffect(() => {
    if(report?.guid)
    {
      Storage.Session.removeItem(storage_key);
    }

    const onUnload = _ => {
      if(report && !report.guid)
      {
        Storage.Session.setItem(storage_key, report);
      }
    };

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [report]);

  useEffect(() => {
    const defaultValue = { detail: { cargo: "<ol><li>&nbsp;</li></ol>" } };

    if(report === undefined)
    {
      if(storage_key)
      {
        Storage.Session.getItem(storage_key).then(value => {
          setReport(value || defaultValue);
        });
      }
      else
      {
        setReport(defaultValue);
      }
    }
    else
    if(Object.keys(report).length <= 0)
    {
      if(step != steps[0])
      {
        updateStep(steps[0]);
      }
    }
  }, [report, step]);

  useEffect(() => {
    if(!storage_key)
    {
      history.replace(route.pathname, {
        ...(route.state || {}),

        storage_key: 'loading-report-' + vehicle?.id + '-' + moment().toISOString(),
      });
    }
  }, [storage_key])


  useEffect(() => {
    main.scrollTop();
  }, [step])

  if(loading || !report)
  {
    return <LoadingPage />;
  }

  if(report.guid)
  {
    return (
      <div className="container">
        <Helmet>
          <title>{t('compliance.LoadingReport.title')} - {t('app')}</title>
        </Helmet>

        <h1 className="h4 mb-3 text-primary">
          {t('compliance.LoadingReport.title')}
        </h1>

        <ProgressBar variant="success" now={100} />


        <div className="large-icon-message">
          <CheckIcon variant="success" />

          <h3 className="h6 text-success strong mt-3 text-center">
            {t('n_have_now_been_completed', { n: t('compliance.LoadingReport.title') })}
          </h3>
        </div>

        <Link className="btn btn-primary w-100" to={{ pathname: '/compliance', state: { vehicle_id: vehicle.id, occurance_date } }}>
          {t('back')}
        </Link>
      </div>
    );
  }

  return (
    <div className="container">
      <Helmet>
        <title>{t('compliance.LoadingReport.title')} - {t('app')}</title>
      </Helmet>

      <h1 className="h4 mb-3 text-primary">
        {t('compliance.LoadingReport.title')}
      </h1>

      <ProgressBar variant="success" now={(step_index + 1) / steps.length * 100} />

      {(_ => {
        switch(step)
        {
          case 'form':
          default:
            return <LoadingForm {...{ report, onBack, onSave, onSubmit }} />;

          case 'summary':
            return <Summary {...{ report, onBack, onSave, onSubmit: save }} />;
        }

      })()}
    </div>
  );
}




function LoadingForm({ vehicle, report, onBack, onSave, onSubmit, ...props}) {
  const auth = useAuth();
  const {t} = useTranslation();

  const schema = yup.object().shape({

    detail: yup.object().shape({

      location_name: yup.string()
        .required(t('form_validation.is_required', { attribute: t('location_name') })),

      cargo: yup.string()
        .required(t('form_validation.is_required', { attribute: t('cargo') }))
        .test(
          'max-content',
          t('form_validation.max_length', { attribute: t('cargo'), max: 65000 }),
          (value, context) => {

            // This is want happens when stored, the raw attachment data is stored in a file and replaced with a hash 107 characters long, that output can't be more than 65000 characters long
            const doc = new DOMParser().parseFromString("<div>" + value + "</div>", "text/html");

            const imgs = doc.querySelectorAll('img');

            for(var i = 0; i < imgs.length; i++)
            {
              if(/^data:/.test(imgs[i].src))
              {
                imgs[i].src = "0".repeat(107);
              }
            }

            const parsed = doc.documentElement.innerHTML;

            return parsed.length < 65000;
          }
        ),
      attachments: yup.array().of(
        yup.object().shape({

          hash: yup.string()
            .required(),
        }),
      ).min(1),

    }),

    location: useLocationFieldsSchema(),
  });

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setError,
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: report,
    resolver: yupResolver(schema),
  });


  const detail = watch('detail');
  const location = watch('location');

  useEffect(_ => {
    onSave({
      detail,
      location,
    })
  }, [
    detail,
    location,
  ]);

  return (
    <div>
      <p>
        {t('date') + ' '}

        <strong>{moment().format(t('formats.datetime'))}</strong>
      </p>


      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <label htmlFor="location_name" className="my-2">{t('compliance.LoadingReport.location_name')}</label>
          <Form.Control
            {...register('detail.location_name')}
            type="text"
            isInvalid={!!errors?.detail?.location_name}
          />

          <Form.Control.Feedback type="invalid">
            {errors?.detail?.location_name && errors?.detail?.location_name.message}
          </Form.Control.Feedback>
        </Form.Group>

        <LocationFields {...{ register, errors, setValue, path: 'location.', useCurrentLocation: true }} />


        <Form.Group className="mb-3 wysiwyg-image">
          <label htmlFor="cargo" className="my-2">{t('compliance.LoadingReport.cargo')}</label>
          <Wysiwyg
            {...register('detail.cargo')}
            placeholder={t('description')}
            toolbar={[{'list': 'ordered'}]}
            className="mb-3"
            isInvalid={!!errors?.detail?.cargo} />

          <Form.Control.Feedback type="invalid">
            {errors?.detail?.cargo?.message}
          </Form.Control.Feedback>

          <Attachments
            {...register('detail.attachments')}
            preUpload={true}
            imageCompression={{ maxWidth: 320 }}

            isInvalid={!!errors?.detail?.attachments}
            />

          <Form.Control.Feedback type="invalid">
            {errors?.detail?.attachments?.message}
          </Form.Control.Feedback>
        </Form.Group>



        <Form.Group className="mb-3 mx-n2 d-flex">
          <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
            {t('back')}
          </Button>

          <Button className="mx-2 w-50" variant="success" type="submit">
            {t('continue')}
          </Button>
        </Form.Group >
      </Form>
    </div>
  );
}

export default LoadingReport;


function Summary({ report, onBack, onSubmit, ...props}) {
  const {t} = useTranslation();

  return (
    <>

      <h3 className="h6 text-primary my-3">
        {t('summary')}
      </h3>


      <Form.Group className="mb-3">
        <label htmlFor="location_name" className="my-2"><strong>{t('compliance.LoadingReport.location_name')}</strong></label>


        {report.detail.location_name}
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="occurance_at" className="my-2"><strong>{t('compliance.LoadingReport.date')}</strong></label>

        {moment(report.occurance_at).format(t('formats.date_long'))}
      </Form.Group>


      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('location')}</strong></label>


        <DisplayLocation location={report.location} />
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="type" className="my-2 "><strong>{t('compliance.LoadingReport.cargo')}</strong></label>

        <DisplayWysiwyg html={report.detail.cargo} />

        <DisplayAttachments preview={true} attachments={report.detail.attachments} />
      </Form.Group>

      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => onBack()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" onClick={_ => onSubmit({})}>
          {t('continue')}
        </Button>
      </Form.Group >
    </>
  );
}
