import React, {useEffect, useState, useRef}   from "react";
import {Link}                         from "react-router-dom";
import BootstrapTable                 from "react-bootstrap-table-next";
import * as moment                    from "moment-timezone";
import {useTranslation}               from "react-i18next";
import {useAuth}                      from "@/services/Auth";
import Paginate from "../Paginate";
import useMoment from "../../hooks/useMoment";

function useColumns(filter, isExport = false) {
    const {t} = useTranslation();

    const {momentLocal} = useMoment()

    let columns = [
        {
            dataField: 'id',
            text     : t('order_item_id'),
        },
        {
            dataField: 'entity_name',
            text     : t('entity'),
            formatter: (cell, row) => {
              if(row.fleet_id) {
                return <Link to={`/fleets/${row.fleet_id}`}>{cell}</Link>
              } else if (row.site_id) {
                return <Link to={`/sites/${row.site_id}`}>{cell}</Link>
              }
              return <span>{cell}</span>
            },
        },
        {
          dataField: 'renew_date',
          text     : t('renew_date'),
          formatter: date => date && momentLocal(date).format(t('formats.date')),
        },
        {
          dataField: 'name',
          text     : t('service'),
          formatter: (cell, row) => row.quantity > 1 && cell  + ' x ' + row.quantity || cell,
        },

        {
          dataField: 'unit_price',
          text     : t('unit_price_gbp'),
          align    : 'right',
          formatter: (total_amount, row) => <span>{total_amount && total_amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
        },
    ];

    return filter && columns.filter(filter) || columns;
}


function AccountItems({ data: recordsProp, paginate, url, query, ...props }) {
    let auth = useAuth();
    const {t} = useTranslation();

    const perPage = props.perPage || 20;
    const exclude = props.exclude || [];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);

    const getRecords = () => {
        request?.current?.abort && request.current.abort();

        const critera = { ... (query || {})};

        critera.take = perPage;
        critera.skip = page * perPage;

        request.current = auth.getRequest(url, critera);

        setRecords(false);

        request.current
            .then(response => {
                setRecords(response.data.data);
                setTotal(response.data.length);
            })
    }

    const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name));

    const changePage =(event) => {
        setPage(event.selected)
    }

    const records = recordsProp || recordsDownloaded;

    useEffect(() => {
        if(!recordsProp)
        {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <>
            <BootstrapTable
                keyField="id"
                striped
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={records instanceof Array ? t('no_data') : t('loading')}
                {...props}
            />
            {
                showPagination && records && totalRecords &&
                <Paginate {...{ changePage, totalRecords, page, perPage }} />
                || ''
            }
        </>
    );
}

export default AccountItems;

export {
    useColumns
};
