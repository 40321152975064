import React, { useEffect, useState, useCallback }   from "react";
import { Row, Col } from "react-bootstrap";

function Services({ site, edit, onChange, ...props })
{
  /**
   * IMPORTANT!!!!
   * Make sure to update the counter part in the laravel blade template
   */

  const categories = [].concat(
        site.security_facilities
      )
      .filter((value, index, self) => self.findIndex(_ => _.id == value.id) === index)
      .sort((a, b) => ("" + a.name).localeCompare(b.name));

  return (
    <section className="block block-services">
      <div className="container">

        <Row className="justify-content-center">
        {categories.map((facility, index) => (
          <Col xxl={2} xl={3} md={4} xs={6} className="addon d-flex align-items-center justify-content-center flex-column text-center" key={index}>
            <img className="service-icon" src={`/images/${'security_facilities'}/${facility.id}.svg`}/>
            <span className="name h5">{facility.name}</span>
          </Col>
        ))}
        </Row>
      </div>
    </section>
  );
}

export default Services;
