import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState, useMemo }   from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import LoadingPage from "@/components/LoadingPage";
import { useAuth, Style } from "@/services";
import { useLayout, ExtraNav } from "@/layouts/Layout";
import Blocks from "./blocks";
import JourneyDetails from './components/JourneyDetails';

function SitePage() {
  const { slug } = useParams();
  const { t } = useTranslation();

  const auth = useAuth();
  const layout = useLayout();

  const route = useLocation();
  const history = useHistory();

  const [site, setSite] = useState(false);
  const [can_update, setCanUpdate] = useState(false);


  useEffect(() => {
    if(!site)
    {
      auth.getRequest('/pages/' + slug, { include: 'all' })
        .then(response => {
          setSite(response.data.data)
          setCanUpdate(response.data.can_update)


          if(typeof response?.data?.data?.page_content == 'undefined')
          {
            auth.updateErrorPage(404);
          }
        });
    }
    else
    if(route?.state?.book && site?.id)
    {
      if(auth.user?.is_driver || auth.user?.is_client_admin || auth.user?.is_fleet_manager)
      {
        history.replace('/bookings/create', {
          site,
          booking: {
            site_id: site.id,
          }
        })
      }
      else
      {
        history.replace('/bookings/site-card/' + site.id);
      }
    }
  }, [site, can_update]);

  useEffect(() => {
    layout.setCollapsed(true);


    return _ => layout.setCollapsed(false);
  }, []);

  const primaryRGB = useMemo(_ => Style.hexToRGBString(site?.page_content?.colours?.primary), [site]);
  const secondaryRGB = useMemo(_ => Style.hexToRGBString(site?.page_content?.colours?.secondary), [site]);

  const primaryTextColour = useMemo(_ => Style.foregroundColour(site?.page_content?.colours?.primary), [site]);
  const secondaryTextColour = useMemo(_ => Style.foregroundColour(site?.page_content?.colours?.secondary), [site]);

  if(!site)
  {
    return <LoadingPage />;
  }

  return (
    <>
      <Helmet>
        <title>{site?.name || ''} - {t('app')}</title>
      </Helmet>

      <JourneyDetails coordinates={site} value={site.journey} onChange={journey => setSite({ ...site, journey })} className="d-none" />

      {can_update && <ExtraNav key={site}>
        <div className="actions d-flex justify-content-between align-items-center elevated">
          <Link to={{  pathname: `/sites/${site.id}/page/edit`, state: { site } }} className="btn btn-light">

            <span className="d-none d-lg-inline me-2">
              {t('marketing.edit_mode')}
            </span>

            <span className="bi bi-pencil" />
          </Link>
        </div>
      </ExtraNav> || ''}


      <div className="page-blocks position-relative" style={{
        "--primary-color": site?.page_content?.colours?.primary || '#000000',
        "--secondary-color": site?.page_content?.colours?.secondary || '#000000',
        "--primary-text-color": primaryTextColour,
        "--secondary-text-color": secondaryTextColour,
        "--bs-primary-rgb": primaryRGB,
        "--bs-secondary-rgb": secondaryRGB,
      }}>
      {Object.entries(site?.page_content?.blocks || []).map(([key, block]) => {
        const Block = Blocks[block.type];

        return Block && <Block key={key} site={site} {...block} /> || null;
      })}
      </div>
    </>
  );
}


export default SitePage;
