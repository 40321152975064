import React, {useEffect, useState, useRef} from "react";
import {Card, Button, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link, useHistory, useLocation} from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import {useAuth} from "@/services/Auth";
import SuccessDialog from "../../../components/Modals/SuccessDialog";
import { Helmet } from "react-helmet";
import * as csv from 'csv-stringify/lib/sync';
import FileSaver from 'file-saver';
import Paginate from "@/components/Paginate";
import Filters from "./components/Filters";
import {useColumns} from "@/components/Tables/Suppliers";

function Suppliers() {
    const history = useHistory();
    const route = useLocation();

    const {t} = useTranslation();
    let auth = useAuth();

    const request = useRef(null);


    const perPage = 15;

    const [exporting, setExporting] = useState(false);

    const [totalRecords, setTotal] = useState(false);
    const [suppliers, setSuppliers] = useState([]);

    const [query, setQuery] = useState(route?.state?.query || {});

    const page = (query?.skip || 0) / perPage;

    const getSuppliers = (query) => {
        request?.current?.abort && request.current.abort();

        setSuppliers(false);

        const critera = {...query};

        critera.take = perPage;

        request.current = auth.getRequest('/suppliers', critera)

        request.current
           .then(response => {
               setSuppliers(response.data.suppliers);
               setTotal(response.data.total);
           })
    };

    const [showDialog, setShowDialog] = useState(false);
    const [dialogType, setDialogType] = useState('SUCCESS');
    const [dialogMessage, setDialogMessage] = useState('');

    const columns = useColumns();
    const exportColumns = useColumns(undefined, true);

    const exportSuppliers = () => {
        let headers = exportColumns.map(_ => _.text);

        let exportRequest = { ...query };

        exportRequest.skip = 0;
        exportRequest.take = 0;

        setExporting(true);

        request.current = auth.getRequest('/suppliers', exportRequest)

        request.current
            .then(response => {

                const records = response.data.suppliers.map((record) => {
                    return exportColumns.map(_ => {
                        var value = record,
                        paths = _.dataField.split('.'),
                        name;

                        while((name = paths.shift()) && value)
                        {
                            value = value[name];
                        }

                       return _.exportFormatter ? _.exportFormatter(value, record) : value;
                    });
                });

                records.unshift(headers);

                const data = "\uFEFF" + csv.stringify(records, {});
                const blob = new Blob([data], {type: 'text/plain;charset=utf-8', encoding: 'UTF-8'});

                FileSaver.saveAs(blob, 'suppliers.csv', {});

                setExporting(false);
            })
            .catch(error => {
                console.error(error);

                setExporting(false);
            })
    }

    const changePage = ({ selected }) => {
        const critera = { ... query };

        critera.skip = selected * perPage;

        setQuery(critera);
    };

    useEffect(() => {
        getSuppliers(query);

        history.replace(
            route.pathname,
            {
                ...route.state,

                query,
            }
        )

        return _ => request?.current?.abort && request.current.abort();
    }, [query]);

    const handleDialogClose = () => {
        setShowDialog(false);
    }


    return (
        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{t('drawer_link_titles.suppliers')} - {t('app')}</title>
            </Helmet>

            <SuccessDialog
                type={dialogType}
                show={showDialog}
                message={dialogMessage}
                handleClose={handleDialogClose}
            />

            <Card.Subtitle className="d-flex justify-content-start justify-content-md-between flex-md-row flex-column">
                <h4 className="text-primary">{t('drawer_link_titles.suppliers')}</h4>
                <div className="d-flex  flex-md-row flex-column">

                    {
                        !exporting ?
                            <Button variant="success" className="mx-md-1 my-1" onClick={() => exportSuppliers()}>{t('export')} (.csv)</Button> :
                            <Button variant="success" className="mx-md-1 my-1" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="mx-2">{t('please_wait')}</span>
                            </Button>
                    }
                    {auth?.roles?.create_supplier &&
                        <Link to={`/suppliers/import`} className="btn btn-primary mx-1 my-1">{t('import_suppliers')}</Link>
                    }
                    {
                        (auth.user.is_platform_admin || auth.user.is_platform_finance) &&
                        <Link to={`/suppliers/export/payouts`} className="btn btn-primary mx-1 my-1">{t('export_payouts')}</Link>
                    }
                    {auth?.roles?.create_supplier &&
                        <Link to="/suppliers/create" className="btn btn-secondary mx-1 my-1">{t('new_supplier')}</Link>
                    }
                </div>
            </Card.Subtitle>

            <Filters
                searching={!suppliers || exporting}
                values={query}
                onSubmit={setQuery}
                />

            <BootstrapTable
                keyField="id"
                striped
                responsive
                hover
                columns={columns}
                data={suppliers || []}
                noDataIndication={suppliers && t('no_data') || t('loading')}
                />

            {totalRecords && suppliers && <Paginate {...{ changePage, totalRecords, page, perPage }} /> || ''}
        </Card>
    );
}

export default Suppliers;
