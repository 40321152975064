import React, {useEffect, useState, useRef}   from "react";
import {Link}                         from "react-router-dom";
import BootstrapTable                 from "react-bootstrap-table-next";
import {useTranslation}               from "react-i18next";
import {useAuth}                      from "@/services/Auth";
import Paginate from "../Paginate";
import moment from "moment-timezone";

function useColumns(filter, isExport = false) {
    let auth = useAuth();
    const {t} = useTranslation();

    const is_platform_user = auth.user.is_platform_admin || auth.user.is_platform_operator;

    let columns = [
        {
            dataField: 'name',
            text     : t(isExport ? 'fleet_name' : 'name'),
            formatter: (cell, row) => row?.id && <Link to={`/fleets/${row?.id}`}>{cell}</Link> || cell,
        },

        is_platform_user && {
            dataField: 'client',
            text     : t('client'),
            formatter: (cell, row) => cell?.id && <Link to={`/clients/${cell?.id}`}>{cell?.name}</Link> || cell?.name,
            exportFormatter: (cell, row) => cell?.name
        },

        is_platform_user && isExport && {
            dataField: 'client.account_number',
            text: t('account_number'),
        },

        {
            dataField: 'contact_name',
            text     : t('primary_contact'),
        },

        {
            dataField: 'contact_email',
            text     : t('email_address')
        },

        isExport && {
            dataField: 'phone_number',
            text     : t('phone_number')
        },

        {
            dataField: 'vehicles_count',
            text     : t('number_of_vehicles')
        },

        {
            dataField: 'drivers_count',
            text     : t('number_of_drivers'),
        },

        {
            dataField: 'compliance_licences',
            text     : t('compliance_licences'),
        },

        is_platform_user && {
            dataField: 'compliance_price_per_licence',
            text     : t('price_per_licence'),
            formatter: (total, row) => {
                total = row?.account_items?.reduce && row.compliance_licences ? (row.account_items?.reduce((total, item) => (item.unit_price * item.quantity) + total, 0) / row.compliance_licences ) : 0;

                return total ? total.toFixed(2) : '';
            },
            exportFormatter: (total, row) => {
                total = row?.account_items?.reduce && row.compliance_licences ? (row.account_items?.reduce((total, item) => (item.unit_price * item.quantity) + total, 0) / row.compliance_licences ) : 0;

                return total ? total.toFixed(2) : '';
            }
        },

        is_platform_user && {
            dataField: 'compliance_pricing_updated',
            text     : t('last_updated'),
            formatter: (last_updated, row) => {
                last_updated = row?.account_items?.reduce ? row.account_items?.reduce((last_updated, item) => last_updated ? moment.max(last_updated, moment(item.updated_at)) : moment(item.updated_at), '') : '';

                return last_updated ? last_updated.format(t('formats.date')) : '';
            },
            exportFormatter: (last_updated, row) => {
                last_updated = row?.account_items?.reduce ? row.account_items?.reduce((last_updated, item) => last_updated ? moment.max(last_updated, moment(item.updated_at)) : moment(item.updated_at), '') : '';

                return last_updated ? last_updated.format('YYYY-MM-DD') : '';
            }
        },
    ].filter(Boolean);

    return filter && columns.filter(filter) || columns;
}

function Fleets({ data: recordsProp, paginate, query, ...props }) {
    let auth = useAuth();
    const {t} = useTranslation();

    const is_platform_user = auth.user.is_platform_admin || auth.user.is_platform_operator;

    const perPage = props.perPage || 20;
    const exclude = props.exclude || [];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);

    const url = `${props.url || '/fleets'}${is_platform_user && '?include=account_items' || ''}`;

    const getRecords = () => {
        request?.current?.abort && request.current.abort();

        const critera = { ... (query || {})};

        critera.take = perPage;
        critera.skip = page * perPage;

        request.current = auth.getRequest(url, critera);

        setRecords(false);

        request.current
            .then(response => {
                setRecords(response.data.fleets);
                setTotal(response.data.total);
            })
    }

    const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name));

    const changePage = ({ selected }) => {
        setPage(selected)
    }

    const records = recordsProp || recordsDownloaded;

    useEffect(() => {
        if(!recordsProp)
        {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <>
            <BootstrapTable
                keyField="id"
                striped
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={records instanceof Array ? t('no_data') : t('loading')}
                {...props}
            />
            {
                showPagination && records && totalRecords &&
                <Paginate {...{ changePage, totalRecords, page, perPage }} />
                || ''
            }
        </>
    );
}

export default Fleets;

export {
    useColumns
};
