import { useHistory, useLocation, Redirect, Link } from "react-router-dom";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useAuth, Countries, Storage } from "@/services";
import { Card, Form, Button, ProgressBar, Alert } from "react-bootstrap";
import moment from 'moment-timezone';
import LoadingPage from "@/components/LoadingPage";
import { Attachments } from "@/components/Inputs";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LocationFields, { useLocationFieldsSchema } from "@/components/Forms/LocationFields";
import { CheckIcon } from "@/components/icons";
import { useLayout } from "@/layouts/Layout";
import Checks from "./components/JourneyChecks";
import ValidationErrors from "./components/ValidationErrors";


function JourneyReport() {
  const auth = useAuth();
  const main = useLayout();
  const { t } = useTranslation();
  const route = useLocation();
  const history = useHistory();

  const vehicle = route?.state?.vehicle || undefined;
  const occurance_date = route?.state?.occurance_date || undefined;
  const storage_key = route?.state?.storage_key;

  const [report, setReport] = useState(undefined);

  useEffect(() => {
    if (report?.guid) {
      Storage.Session.removeItem(storage_key);
    }

    const onUnload = _ => {
      if (report && !report.guid) {
        Storage.Session.setItem(storage_key, report);
      }
    };

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [report]);

  useEffect(() => {
    if (report === undefined) {
      var defaultValue = {
        vehicle_id: vehicle?.id
      }

      if (storage_key) {
        Storage.Session.getItem(storage_key).then(value => {
          setReport(value || defaultValue);
        });
      }
      else {
        setReport(defaultValue);
      }
    }
  }, [report]);

  useEffect(() => {
    if(!storage_key)
    {
      history.replace(route.pathname, {
        ...(route.state || {}),

        storage_key: 'journey-report-' + vehicle?.id + '-' + moment().toISOString(),
      });
    }

  }, [storage_key])

  if (!report) {
    return <LoadingPage />;
  }

  if (report.guid) {
    return <Redirect to={`/compliance/${report.guid}`} />;
  }

  return <NewJourney {...{ report, setReport }} />;
}

function NewJourney({ report, setReport }) {
  const auth = useAuth();
  const main = useLayout();
  const { t } = useTranslation();
  const route = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);


  const vehicle = route?.state?.vehicle || undefined;
  const steps = ['create', 'checks', 'complete'];
  const step = route?.state?.step || 'create';
  const step_index = steps.findIndex(_ => step == _);
  const [showErrors, setShowErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});


  const updateStep = step => {
    history.push(route.pathname, {
      ...(route.state || {}),

      step,
    });
  };

  const next = () => {
    var target = steps.findIndex(_ => _ == step);

    if (target < 0 || target > steps.length - 1) {
      target = -1;
    }

    target++;

    updateStep(steps[target]);
  };

  const onSave = values => {
    const _report = { ...report, ...values };

    setReport(_report);
  };

  const onSubmit = values => {
    const _report = { ...report, ...values };

    setReport(_report);

    next();
  };

  const save = _ => {
    return new Promise((resolve, error) => {
      const data = {
        type: 'JourneyReport',
        ...report,
      };

      if (data?.legs && data?.legs[0]) {
        data.legs[0].checks = checks;
      }

      setLoading(true);

      auth.postRequest('reports', data)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(_ => {
          // console log the error with the error message
          console.error('Error fetching data:', _);
          if (_.response) {
            setValidationErrors(_.response.data);
          }
          error(_)
          setShowErrors(true);
        })
        .finally(_ => setLoading(false));
    });
  };

  useEffect(() => {
    if (Object.keys(report).length <= 0) {
      if (step != steps[0]) {
        updateStep(steps[0]);
      }
    }
    else
      if (step == 'complete') {
        save().then(_report => {
          setReport(_report);
        }).catch(_ => {
          window.history.back();
        });
      }
  }, [report, step]);

  const checks = report?.legs && report?.legs[0] && report?.legs[0].checks || [];

  const setChecks = (checks) => {
    const _report = { ...report };

    if (!_report.legs || !_report.legs[0]) {
      _report.legs = [{}];
    }

    _report.legs[0].checks = checks;

    setReport(_report);
  };

  useEffect(() => {
    main.scrollTop();
  }, [step])

  return (
    <div className="container">
      <Helmet>
        <title>{t('compliance.JourneyReport.title')} - {t('app')}</title>
      </Helmet>


      <h1 className="h4 mb-3 text-primary">
        {t('compliance.JourneyReport.title')}
      </h1>


      {showErrors && <ValidationErrors errors={validationErrors} setShowErrors={setShowErrors} />}

      {(_ => {
        switch (step) {
          case 'create':
          default:
            return <JourneyForm {...{ report, onSave, onSubmit }} />;

          case 'checks':

            return <Checks {...{
              values: checks, setValues: setChecks, onSubmit: (checks) => {
                setChecks(checks);

                next();
              }
            }} />;

          case 'complete':
            return <LoadingPage />;
        }

      })()}
    </div>
  );
};

function JourneyForm({ vehicle, report, onSave, onSubmit, ...props }) {
  const auth = useAuth();
  const { t } = useTranslation();

  const [time, setTime] = useState(moment());

  const requestStore = useRef({});
  const { current: requests } = requestStore;

  const schema = yup.object().shape({

    vehicle_id: yup.number()
        .typeError(t('form_validation.is_required', { attribute: t('vehicle') }))
        .required(t('form_validation.is_required', { attribute: t('vehicle') })),

    detail: yup.object().shape({

      name: yup.string()
        .required(t('form_validation.is_required', { attribute: t('compliance.JourneyReport.name') })),

      trailer_number: yup.string()
        .required(t('form_validation.is_required', { attribute: t('compliance.JourneyReport.trailer_number') })),

    }),

    legs: yup.array().of(
      yup.object().shape({

        location: yup.object().shape({
          longitude: yup.number()
            .required(),

          latitude: yup.number()
            .required(),

          address_1: yup.string()
            .nullable()
            .max(255, t('form_validation.max_length', { attribute: t('address_1'), max: 255 })),
          address_2: yup.string()
            .nullable()
            .max(255, t('form_validation.max_length', { attribute: t('address_2'), max: 255 })),
          address_3: yup.string()
            .nullable()
            .max(255, t('form_validation.max_length', { attribute: t('address_2'), max: 255 })),
          city: yup.string()
            .nullable()
            .max(255, t('form_validation.max_length', { attribute: t('city'), max: 255 })),
          county: yup.string()
            .nullable()
            .max(255, t('form_validation.max_length', { attribute: t('county'), max: 255 })),

          country_id: yup.number()
            .nullable(),

          postcode: yup.string()
            .nullable()
            .max(255, t('form_validation.max_length', { attribute: t('post_code'), max: 255 })),
        }),

        security_device_type: yup.string()
          .required(t('form_validation.is_required', { attribute: t('compliance.JourneyReport.security_device_type') })),

        security_device_type_other: yup.string()
          .when(['security_device_type'], (type, schema) => (
            type == 'Other' &&
            schema.required(t('form_validation.is_required', { attribute: t('compliance.JourneyReport.security_device_type_other') }))
            ||
            schema.nullable()
          )),

        security_device_number: yup.string()
          .required(t('form_validation.is_required', { attribute: t('compliance.JourneyReport.security_device_number') })),


        attachments: yup.array().of(
          yup.object().shape({

            hash: yup.string()
              .required(),
          }),
        ).nullable(),

      })
    ),
  });

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setError,
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: report,
    resolver: yupResolver(schema),
  });

  const [vehicles, setVehicles] = useState(false);

  const detail = watch('detail');
  const legs = watch('legs');

  useEffect(_ => {
    onSave({
      detail,
      legs,
    })
  }, [
    detail,
    legs,
  ]);

  useEffect(() => {
    if (!vehicles) {
      requests.vehicles && requests?.vehicles?.abort && requests.vehicles.abort();

      requests.vehicles = auth.getRequest('vehicles', { take: 0 })

      requests.vehicles
        .then((response) => {
          setVehicles(response.data.vehicles);

          if (response.data.vehicles.length === 1) {
            setValue('vehicle_id', response.data.vehicles[0].id);
            setValue('vehicle', response.data.vehicles[0]);
          }
        })
        .catch(() => console.error(arguments))
    }
  }, [vehicles]);

  useEffect(() => {

    const updateTime = _ => setTime(moment());

    const interval = setInterval(1000, updateTime);

    return () => {
      clearInterval(interval);

      for (var key in requests) {
        requests[key] && requests[key].abort && requests[key].abort();
      }
    }
  }, [
    requests,

  ]);

  const security_device_type = watch('legs.0.security_device_type');


  if (!vehicles) {
    return <LoadingPage />;
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <label htmlFor="name" className="my-2">{t('compliance.JourneyReport.name')}</label>
        <Form.Control
          {...register('detail.name')}
          type="text"
          isInvalid={!!errors?.detail?.name}
        />

        <Form.Control.Feedback type="invalid">
          {errors?.detail?.name && errors?.detail?.name.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="name" className="my-2">{t('compliance.JourneyReport.date_time')}</label>

        <div className="form-control disabled">{time.format(t('formats.datetime'))}</div>
      </Form.Group>

      <LocationFields {...{ register, errors, watch, setValue, path: 'legs.0.location.', useCurrentLocation: true, previewAddressOnly: true }} />


      <Form.Group className="mb-3">
        <label htmlFor="name" className="my-2">{t('vehicle')}</label>

        <Form.Select {...register('vehicle_id')}>
          <option value="">--{t('choose')}--</option>
          {vehicles.map((vehicleData) =>
            <option value={vehicleData.id} key={vehicleData.id}>{t(vehicleData.registration_number)}</option>
          )}
        </Form.Select>
      </Form.Group>


      <Form.Group className="mb-3">
        <label htmlFor="trailer_number" className="my-2">{t('compliance.JourneyReport.trailer_number')}</label>
        <Form.Control
          {...register('detail.trailer_number')}
          type="text"
          isInvalid={!!errors?.detail?.trailer_number}
        />

        <Form.Control.Feedback type="invalid">
          {errors?.detail?.trailer_number && errors?.detail?.trailer_number.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="security_device_type" className="my-2">{t('compliance.JourneyReport.security_device_type')}</label>
        <Form.Select
          {...register('legs.0.security_device_type')}
          isInvalid={!!(errors?.legs && errors?.legs[0] && errors?.legs[0].security_device_type)}
        >
          <option value="">--{t('choose')}--</option>
          <option value="Seal">{t('compliance.JourneyReport.device_type.Seal')}</option>
          <option value="Lock">{t('compliance.JourneyReport.device_type.Lock')}</option>
          <option value="Other">{t('compliance.JourneyReport.device_type.Other')}</option>
        </Form.Select>

        <Form.Control.Feedback type="invalid">
          {errors?.legs && errors?.legs[0] && errors?.legs[0].security_device_type?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className={`mb-3 ${security_device_type == 'Other' ? '' : 'd-none'} `}>
        <label htmlFor="security_device_type_other" className="my-2">{t('compliance.JourneyReport.security_device_type_other')}</label>
        <Form.Control
          {...register('legs.0.security_device_type_other')}
          type="text"
          isInvalid={!!(errors?.legs && errors?.legs[0] && errors?.legs[0].security_device_type_other)}
        />

        <Form.Control.Feedback type="invalid">
          {errors?.legs && errors?.legs[0] && errors?.legs[0].security_device_type_other?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="security_device_number" className="my-2">{t('compliance.JourneyReport.security_device_number')}</label>

        <Form.Control
          {...register('legs.0.security_device_number')}
          type="text"
          isInvalid={!!(errors?.legs && errors?.legs[0] && errors?.legs[0].security_device_number)}
        />

        <Form.Control.Feedback type="invalid">
          {errors?.legs && errors?.legs[0] && errors?.legs[0].security_device_number?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Attachments
          {...register('legs.0.attachments')}
          preUpload={true}
          type="text"
          isInvalid={!!(errors?.legs && errors?.legs[0] && errors?.legs[0].attachments)}
          imageCompression={{ maxWidth: 320 }}
        />

        <Form.Control.Feedback type="invalid">
          {errors?.legs && errors?.legs[0] && errors?.legs[0].attachments?.message}
        </Form.Control.Feedback>
      </Form.Group>


      <Form.Group className="mb-3 mx-n2 d-flex">
        <Button className="mx-2 w-50" variant="primary" onClick={_ => window.history.back()}>
          {t('back')}
        </Button>

        <Button className="mx-2 w-50" variant="success" type="submit"
          disabled={!(getValues('legs.0.attachments') || []).length}
          >
          {t('continue')}
        </Button>
      </Form.Group >
    </Form>
  );
}

export default JourneyReport;




