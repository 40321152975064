function VehiclesIcon({classDefined}) {
    return (
        <div className={`custom-logo ${classDefined}`}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.99875 20C4.48625 20 0 15.5125 0 10C0 4.4875 4.485 0 9.99875 0C15.5138 0 20 4.4875 20 10C20 15.5125 15.5125 20 9.99875 20V20ZM10 2.5C5.8625 2.5 2.5 5.865 2.5 10C2.5 14.135 5.865 17.5 10 17.5C14.135 17.5 17.5 14.135 17.5 10C17.5 5.865 14.1375 2.5 10 2.5Z" fill="white"/>
                <path fillRule="levenodd" clipRule="evenodd" d="M9.98995 7.5C7.09495 7.5 4.86745 8.49625 3.7887 10.4325C3.83495 11.0238 3.9812 11.5925 4.2087 12.1225C5.73495 11.3075 8.64745 14.41 7.7312 15.7913C8.4562 16.0463 9.15745 16.235 9.97745 16.235C10.7798 16.2321 11.5764 16.0991 12.3362 15.8413C11.4187 14.46 14.2424 11.4225 15.7787 12.255C16.0187 11.7015 16.1641 11.1116 16.2087 10.51C15.1187 8.59375 12.8662 7.5 9.98995 7.5ZM10.0024 11.445C9.2212 11.445 8.5937 10.8075 8.5937 10.0188C8.5937 9.23 9.2212 8.5925 10.0024 8.5925C10.7762 8.5925 11.4062 9.23 11.4062 10.0188C11.4062 10.8075 10.7762 11.445 10.0024 11.445Z" fill="white"/>
            </svg>
        </div>
    );
}

export default VehiclesIcon;
