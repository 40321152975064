import React, { useEffect, useState, useRef, useCallback }   from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services";
import { CheckIcon } from "@/components/icons";


function MediaSelector({ show, base_uri, options, onSelect, onHide }) {
  const { t } = useTranslation();
  const auth = useAuth();


  const dropzone = useRef(null);

  const [items, setItems] = useState(null);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if(show && base_uri && !items)
    {
      auth.getRequest(base_uri + '/attachments')
        .then(response => setItems(response.data.data));
    }
  }, [show, items, base_uri]);

  useEffect(() => {
    setSelected([]);
  }, [show]);

  const onSelected = item => {
    if(options.multiple)
    {
      const _selected = [].concat(selected);
      const index = _selected.findIndex(_ => _.hash == item.hash);

      if(index >= 0)
      {
        _selected.splice(index, 1);
      }
      else
      {
        _selected.push(item);
      }

      setSelected(_selected);
    }
    else
    {
      setSelected([item]);
    }
  };

  const refreshItems = useCallback(() => {
    setItems([].concat(items));
  }, [items]);

  const uploadfiles = useCallback((files) => {
    const newItems = [].concat(items);

    for (var n = 0; n < files.length; n++) {
      const file = files[n];
      const reader = new FileReader();

      const item = {};

      newItems.push(item);

      reader.onload = () => {
          const file_base64 = reader.result.split(',')[1];

          auth.postRequest(base_uri + '/attachments', {
              name: file.name,
              mime_type: file.type,
              file_base64
          })
              .then(response => {
                Object.assign(item, response.data.data);
                setItems([].concat(newItems));
              });

      }

      reader.readAsDataURL(file);
    }

    setItems([].concat(newItems));
  }, [items]);

  const onDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    var files = event.dataTransfer && event.dataTransfer.files || event.originalEvent && event.originalEvent.dataTransfer.files;

    uploadfiles(files);
  }, [items]);


  const onFileChange = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    uploadfiles(event.target.files);

    event.target.value = null;
  }, [items]);

  useEffect(() => {
    if(dropzone.current)
    {

      dropzone.current.ondrop = onDrop;

      const preventDefault = (event) => {
        event.preventDefault();
        event.stopPropagation();
      };

      dropzone.current.ondrag = preventDefault;
      dropzone.current.ondragstart = preventDefault;
      dropzone.current.ondragend = preventDefault;
      dropzone.current.ondragover = preventDefault;
      dropzone.current.ondragenter = preventDefault;
      dropzone.current.ondragleave = preventDefault;
      dropzone.current.ondrag = preventDefault;
    }
  }, [dropzone.current]);

  // TODO pagination and search

  return (
    <Modal show={show} onHide={onHide} size="xl" className="media-selector">

      <Modal.Header closeButton>
        <Modal.Title>{t('media_selector.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body ref={dropzone}>
        <Row className="media-items g-3">
          {items && items.map && items.map((item, index) => (
            <Col xl={3} md={4}  key={item.hash || 'item-' + index}>
              <button onClick={_ => onSelected(item)} className="media-item">
                {item?.hash && <img src={item?.url} /> || <Spinner animation="border" variant="primary"/>}

                {selected.includes(item) && <CheckIcon variant="success" /> || ''}
              </button>
            </Col>
          ))}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <label className="btn btn-primary w-auto">
          <input type="file" onChange={onFileChange} className="d-none" />

          {t('media_selector.upload')}
        </label>

        <Button variant="success" type="submit" disabled={selected.length <= 0} onClick={_ => onSelect(selected)}>
            {t('media_selector.save')}
        </Button>
      </Modal.Footer>

    </Modal>
  );
}


export default MediaSelector;
