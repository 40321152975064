function SuppliersIcon({classDefined}) {
    return (
        <div className={`custom-logo ${classDefined}`}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.409 0.511953C10.5054 0.145592 9.49461 0.145592 8.591 0.511953L6.401 1.39995L15.993 5.12995L19.367 3.82695C19.2287 3.71217 19.0736 3.61942 18.907 3.55195L11.409 0.511953ZM20 5.18995L10.75 8.76395V19.687C10.974 19.642 11.194 19.575 11.409 19.488L18.907 16.448C19.23 16.3171 19.5065 16.0929 19.7013 15.8039C19.896 15.5149 20 15.1744 20 14.826V5.18995ZM9.25 19.687V8.76395L0 5.18995V14.826C-2.02553e-06 15.1744 0.104022 15.5149 0.298748 15.8039C0.493473 16.0929 0.770033 16.3171 1.093 16.448L8.591 19.488C8.806 19.575 9.026 19.642 9.25 19.688V19.687ZM0.633 3.82695L10 7.44595L13.917 5.93195L4.374 2.22195L1.093 3.55195C0.923 3.62095 0.769 3.71395 0.633 3.82695Z" fill="white"/>
            </svg>
        </div>
    );
}

export default SuppliersIcon;
