import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import PaymentMethodForm from "@/components/Forms/PaymentMethodForm";
import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import { Helmet } from "react-helmet";

function PaymentMethodCreate(props) {
    const {t, i18n} = useTranslation();
    let auth = useAuth();
    let getRequest = auth.getRequest;
    let postRequest = auth.postRequest;
    const [fleets, setFleets] = useState([]);
    const [creatingPaymentMethod, setCreatingPaymentMethod] = useState(false);

    const handleSubmit = (values) => {
        setCreatingPaymentMethod(true)
        postRequest('payment-methods', values)
            .then(response => {
                if (response.data.message === 'OK') {
                    props.history.push(`/settings/payment-methods`)
                }
                setCreatingPaymentMethod(false)
            })
            .catch(error => {
                setCreatingPaymentMethod(false)
            })

    }

    return (
        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{t('create')} / {t('payment_methods')} - {t('app')}</title>
            </Helmet>

            <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                <h3 className="text-primary">{t('add_payment_method')}</h3>
            </Card.Subtitle>
            <Card.Body className="px-2">
                <PaymentMethodForm
                    initialValues={{
                        card_holder_name: '',
                        card_number     : '',
                        expire          : '',
                        ccv             : '',
                    }}
                    currentFleets={null}
                    loadingButton={creatingPaymentMethod}
                    handleSubmit={handleSubmit}
                />
            </Card.Body>
        </Card>
    )
}

export default PaymentMethodCreate;
