function FleetsIcon({classDefined}) {
    return (
        <div className={`custom-logo ${classDefined}`}>
            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 14.5C16.6022 14.5 16.2206 14.342 15.9393 14.0607C15.658 13.7794 15.5 13.3978 15.5 13C15.5 12.6022 15.658 12.2206 15.9393 11.9393C16.2206 11.658 16.6022 11.5 17 11.5C17.3978 11.5 17.7794 11.658 18.0607 11.9393C18.342 12.2206 18.5 12.6022 18.5 13C18.5 13.3978 18.342 13.7794 18.0607 14.0607C17.7794 14.342 17.3978 14.5 17 14.5ZM18.5 5.5L20.46 8H16V5.5H18.5ZM5 14.5C4.60218 14.5 4.22064 14.342 3.93934 14.0607C3.65804 13.7794 3.5 13.3978 3.5 13C3.5 12.6022 3.65804 12.2206 3.93934 11.9393C4.22064 11.658 4.60218 11.5 5 11.5C5.39782 11.5 5.77936 11.658 6.06066 11.9393C6.34196 12.2206 6.5 12.6022 6.5 13C6.5 13.3978 6.34196 13.7794 6.06066 14.0607C5.77936 14.342 5.39782 14.5 5 14.5ZM19 4H16V0H2C0.89 0 0 0.89 0 2V13H2C2 13.7956 2.31607 14.5587 2.87868 15.1213C3.44129 15.6839 4.20435 16 5 16C5.79565 16 6.55871 15.6839 7.12132 15.1213C7.68393 14.5587 8 13.7956 8 13H14C14 13.7956 14.3161 14.5587 14.8787 15.1213C15.4413 15.6839 16.2044 16 17 16C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13H22V8L19 4Z" fill="white"/>
            </svg>

        </div>
    );
}

export default FleetsIcon;
