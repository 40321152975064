import {Card} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import PaymentMethodForm from "@/components/Forms/PaymentMethodForm";
import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import LoadingPage from "@/components/LoadingPage";
import { Helmet } from "react-helmet";

function PaymentMethodEdit(props) {
    const {t, i18n} = useTranslation();
    let auth = useAuth();
    let getRequest = auth.getRequest;
    let putRequest = auth.putRequest;
    const [fleets, setFleets] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [creatingPaymentMethod, setCreatingPaymentMethod] = useState(false);
    const [loading, setLoading] = useState(true);


    let {id} = useParams();

    const getPaymentMethod = () => {

        getRequest('/payment-methods/' + id)
            .then(response => {
                setPaymentMethod(response.data.payment_method)
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })
    }

    const handleSubmit = (values) => {
        setCreatingPaymentMethod(true)
        putRequest(`/payment-methods/${id}`, values)
            .then(response => {
                if (response.data.message === 'OK') {
                    props.history.push(`/settings/payment-methods`)
                }
                setCreatingPaymentMethod(false)
            })
            .catch(error => {
                setCreatingPaymentMethod(false)
            })

    }

    useEffect(() => {
        getPaymentMethod();
    }, []);

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('edit')} / {t('payment_methods')} - {t('app')}</title>
                </Helmet>

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h3 className="text-primary">{t('edit_payment_method')}</h3>
                </Card.Subtitle>
                <Card.Body className="px-2">
                    <PaymentMethodForm
                        action="EDIT"
                        initialValues={{
                            card_holder_name: paymentMethod.holder_name,

                            ...paymentMethod,
                        }}
                        cardNumber={paymentMethod.number}
                        brand={paymentMethod.brand}
                        currentFleets={paymentMethod.fleets}
                        loadingButton={creatingPaymentMethod}
                        handleSubmit={handleSubmit}
                    />
                </Card.Body>
            </Card>
        )
    )
}

export default PaymentMethodEdit;
