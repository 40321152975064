

import Compliance from "../../views/pages/compliance";

import Options from "../../views/pages/compliance/ComplianceOptions";
import DailyReport from "../../views/pages/compliance/DailyReport";
import LoadingReport from "../../views/pages/compliance/LoadingReport";
import JourneyReport from "../../views/pages/compliance/JourneyReport";
import Journeys from "../../views/pages/compliance/Journeys";
import CrimeReport from "../../views/pages/compliance/CrimeReport";
import BorderReport from "../../views/pages/compliance/BorderReport";
import TrafficIncidentReport from "../../views/pages/compliance/TrafficIncidentReport";

import ShowReport from "../../views/pages/compliance/ShowReport";

import ComplianceBadges from "@/components/ComplianceBadges";

const complianceRoutes = [
    {
        path         : "/compliance",
        component    : Compliance,
        exact        : true,
        title        : 'Compliance',
        translate_key: 'compliance',
        icon         : <ComplianceBadges />,
        gate         : 'view_bookings',
        main         : true,
    },

    {
        path     : "/compliance/options",
        component: Options,
        gate     : 'update_options',
        exact    : true,
    },

    {
        path     : "/compliance/daily-report",
        component: DailyReport,
        gate     : 'create_compliance_report',
        exact    : true,
    },
    
    {
        path     : "/compliance/loading-report",
        component: LoadingReport,
        gate     : 'create_compliance_report',
        exact    : true,
    },

    {
        path     : "/compliance/journey-report",
        component: JourneyReport,
        gate     : 'create_compliance_report',
        exact    : true,
    },

    {
        path     : "/compliance/journeys",
        component: Journeys,
        gate     : 'create_compliance_report',
        exact    : true,
    },
    
    {
        path     : "/compliance/crime-report",
        component: CrimeReport,
        gate     : 'create_compliance_report',
        exact    : true,
    },
    
    {
        path     : "/compliance/border-report",
        component: BorderReport,
        gate     : 'create_compliance_report',
        exact    : true,
    },
    
    {
        path     : "/compliance/traffic-incident-report",
        component: TrafficIncidentReport,
        gate     : 'create_compliance_report',
        exact    : true,
    },

    {
        path     : "/compliance/:id",
        component: ShowReport,
        gate     : 'view_compliance',
        exact    : true,
    },
];

export default complianceRoutes;
