import LoadingPage                   from "@/components/LoadingPage";
import LoadingButton                 from "@/components/LoadingButton";
import {Card, Col, Row, Table, Form, Button, Spinner} from "react-bootstrap";
import React, {useEffect, useState}  from "react";
import {useTranslation}              from "react-i18next";
import {useAuth}                     from "@/services/Auth";
import {Link}                        from "react-router-dom";
import { Helmet } from "react-helmet";
import { useForm, useFieldArray } from 'react-hook-form';
import * as moment from 'moment-timezone';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

function MarketingOptions() {
  const {t, i18n} = useTranslation();
  const auth = useAuth();

  const [options, setOptions] = useState(null);
  const [saving, setSaving] = useState(false);


  const schema = yup.object().shape({
    marketing_licence_price: yup.number()
      .typeError(t('form_validation.is_required', { attribute: t('marketing.licence_price') }))
      .required(t('form_validation.is_required', { attribute: t('marketing.licence_price') })),
  }).noUnknown(true).required();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getOptions = () => {
    setOptions(null);

    auth.getRequest('options')
      .then(response => {
        setOptions(response.data.options);
        reset(response.data.options);

        auth.checkToken();
      })
  }

  const onSave = (input) => {
    setSaving(true);

    auth.postRequest('options', input)
      .then(response => {

      })
      .finally(_ => setSaving(false));
  }

  useEffect(() => {
    if(options === null)
    {
      getOptions();
    }
  }, [options]);

  if(options === null)
  {
    return <LoadingPage/>;
  }

  return (
    <Card className="mx-2 my-2 p-2">
      <Helmet>
        <title>{t('marketing.options')} - {t('app')}</title>
      </Helmet>

      <Card.Subtitle className="d-flex justify-content-between" style={{ width: '100%' }}>
        <h3>{t('marketing.options')}</h3>
      </Card.Subtitle>

      <Form noValidate onSubmit={handleSubmit(onSave)}>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
          <Form.Label className="my-2">
            {t('marketing.licence_price')}
          </Form.Label>

          <Form.Control
            {...register('marketing_licence_price')}
            type="number"
            isInvalid={!!errors.marketing_licence_price}
           />

          <Form.Control.Feedback type="invalid">
            {errors.marketing_licence_price && errors.marketing_licence_price.message}
          </Form.Control.Feedback>
        </div>

        <div className="d-grid gap-2 mt-4">
          <LoadingButton type="submit" variant="primary" loading={saving}>
            {t('save')}
          </LoadingButton>
        </div>
      </Form>
    </Card>
  );
}

export default MarketingOptions;
