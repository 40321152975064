import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import React, {useState} from "react";
import {Form, Card, Col, Row} from "react-bootstrap";
import LoadingButton from "@/components/LoadingButton";
import {Helmet} from "react-helmet";
import {useForm} from "react-hook-form";

function ExportPayouts() {
    const {t, i18n: i18n} = useTranslation();
    let auth = useAuth();

    const [loading, setLoading] = useState(false);

    const exportPayouts = (params) => {
        setLoading(true);
        auth.getRequest('/payouts/export', params)
            .then(response => {
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'payouts.csv';
                link.click();
            })
            .catch(error => {

            }).finally(() => {
            setLoading(false);
        });
    }

    const onSubmit = (values) => {
        exportPayouts(values);
    };

    const {handleSubmit, register,} = useForm();

    return (

        <div>

            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('export_payouts')} / {t('drawer_link_titles.suppliers')} - {t('app')}</title>
                </Helmet>

                <Card.Body className="px-2">

                    <Row>
                        <h4 className="text-primary">{t('export_payouts')}</h4>
                    </Row>

                    <Row>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="my-md-4 my-2">
                                <Col sm={{span: 10, offset: 1}}>
                                    <Form.Label className="mb-2">
                                        {t('filters_labels.order_date')}
                                    </Form.Label>

                                    <div className="d-flex align-items-center">
                                        <Form.Control
                                            placeholder={t('from')}
                                            type="date"
                                            {...register('order_from')}
                                        />

                                        <span className="mx-4">{t('to')}</span>

                                        <Form.Control
                                            placeholder={t('to')}
                                            type="date"
                                            {...register('order_to')}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="my-md-4 my-2">
                                <Col sm={{span: 10, offset: 1}}>
                                    <Form.Label className="mb-2">
                                        {t('filters_labels.due_date')}
                                    </Form.Label>

                                    <div className="d-flex align-items-center">
                                        <Form.Control
                                            placeholder={t('from')}
                                            type="date"
                                            {...register('due_date_from')}
                                        />

                                        <span className="mx-4">{t('to')}</span>

                                        <Form.Control
                                            placeholder={t('to')}
                                            type="date"
                                            {...register('due_date_to')}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <div className="form-group mt-4 mx-5">
                                <LoadingButton loading={loading} titleTranslationKey={'export'} type="submit" />
                            </div>

                        </Form>
                    </Row>

                </Card.Body>
            </Card>
        </div>
    )
}

export default ExportPayouts;
