import {Button, Card, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import React, {useState} from "react";
import {Helmet} from "react-helmet";

function SelectSite() {
    const {t} = useTranslation();
    const route = useLocation();
    const history = useHistory();
    const [vehicle, _] = useState(route.state.vehicle);
    const [driver, __] = useState(route.state.driver);
    const [totalSites, ___] = useState(route.state.sites);
    const [searchQuery, setSearchQuery] = useState('');
    const [sites, setSites] = useState(route.state.sites);
    const [site, setSite] = useState({});
    const selectedSite = route.state.site;

    const selectSite = (site) => {
        sessionStorage.setItem('site', JSON.stringify(site));
        history.replace('/instant-booking/confirmation', {
            site,
            vehicle,
            driver,
            sites,
            canChange: true,
        });
    }

    const search = () => {
        const filteredVehicles = totalSites.slice()
            .filter(site => site.name.toLowerCase().includes(searchQuery.toLowerCase()));
        setSites(filteredVehicles);
    }

    return (
        <Card>
            <Helmet>
                <title> {t('drawer_link_titles.pay_m_park')} - {t('app')}</title>
            </Helmet>
            <h4 className="text-center text-primary">{t('instant_booking.select_site')}</h4>
            <Row>
                <div className="input-group mb-3">
                    <Form.Control
                        placeholder={t('instant_booking.search_site_name')}
                        className="shadow-none"
                        type="text"
                        onInput={(ev) => setSearchQuery(ev.target.value)}
                        aria-describedby="button-search"
                    ></Form.Control>
                    <Button type="button" id="button-search" onClick={search}>
                        <i className="bi bi-search"></i>
                    </Button>
                </div>
            </Row>

            {sites.map((site, key) => (
                <label key={key}
                       className="col-12 col-lg-4 col-xl-3">
                    <Form.Check.Input
                        type="radio"
                        onClick={() => selectSite(site)}
                        defaultChecked={site.id === selectedSite.id}
                        value={site.id}
                        className="d-none"
                    />

                    <span
                        className="btn btn-outline-primary w-100 mb-4">{site.name}</span>
                </label>
            ))}

                <Button className="w-100 mt-2 bg-danger" onClick={() => history.push('/')}>
                    {t('cancel')}
                </Button>

        </Card>
    )
}

export default SelectSite;
