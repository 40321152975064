import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import {useForm} from "react-hook-form";
import {Countries, OrderStatuses, BookingTypes} from "@/services";

function Filters({options,searching, ...props}) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const formatRecord = (company) => {
        const  object = {...company};

        object.value = company.id;
        object.label = company.name;

        return object;
    };

    function formatStatuses(key) {
        return ({value: key, label: t(`order_status.${statusList[key]}`)});
    }
    function formatBookingTypes(key) {
        return ({value: key, label: t(`booking_types.${bookingTypesList[key]}`)});
    }

    const [countries, setCountries] = useState(null);
    const [statusList, setStatusList] = useState(null);
    const [bookingTypesList, setBookingTypesList] = useState(null);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [selectedBookingType, setSelectedBookingType] = useState(null);

    const getSitesRequest = useRef(null);
    const getClientsRequest = useRef(null);

    const onSubmit = (values) => {
        let updatedValues = values;

        if (values.site && typeof values.site === 'object') {
            updatedValues.site = values.site.value
        }
        if (values.client && typeof values.client === 'object') {
            updatedValues.client = values.client.value
        }

        props.onSubmit(updatedValues)
    };

    const {
              handleSubmit,
              register,
              watch,
              setValue
          } = useForm({
        defaultValues: {
            ...props.values
        }
    });


    const [advanceFilters, setAdvanceFilters] = useState(false);

    useEffect(() => {
        if (countries === null) {
            Countries.active()
                .then(countries => setCountries(countries.map(formatRecord)))
                .catch(_ => setCountries([]));
        }
    }, [countries]);

    useEffect(() => {
        if(statusList === null)
        {
            OrderStatuses.get().then(_ => setStatusList(_));
        }
        if(bookingTypesList === null)
        {
            BookingTypes.get().then(_ => setBookingTypesList(_));
        }
        register('country_id');
        register('statuses')
        register('booking_type')
        register('site');
        register('client');
    }, []);

    const getSites = (search) => {
        getSitesRequest.current && getSitesRequest?.current?.abort && getSitesRequest.current.abort();

        return new Promise((resolve, reject) => {
            getSitesRequest.current = auth.getRequest('sites', { search, managing: true });

            getSitesRequest.current.then(response => {
                    resolve(response.data.sites.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    const getClients = (search) => {
        getClientsRequest.current && getClientsRequest?.current?.abort && getClientsRequest.current.abort();

        return new Promise((resolve, reject) => {
            getClientsRequest.current = auth.getRequest('clients', { search });

            getClientsRequest.current.then(response => {
                    resolve(response.data.clients.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    const country_id = watch('country_id');
    const site = watch('site');
    const client = watch('client');
    const statuses = watch('statuses')

    const country = countries?.find && countries.find(_ => _.id == country_id);

    useEffect(()=>{
        if(statusList){
            setSelectedStatuses(Object.keys(statusList).filter(key => {
                return statuses?.includes && statuses.map(value => value?.toString()).includes(key)
            }).map(formatStatuses));
        }
    },[statuses, statusList])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="my-4">
                <Col sm={4} className="col-12">
                    <Form.Control
                        type="text"
                        {...register('search')}
                        placeholder={t('search_for_anything')}
                        />
                </Col>
                <Col md={4}>
                    <Select
                        className="react-select"
                        isMulti
                        isClearable={true}
                        placeholder={t('status')}
                        value={selectedStatuses}
                        onChange={e =>{
                            e && setValue('statuses', e?.map(option => option.value))
                        }}
                        options={(!statusList ? [] : Object.keys(statusList)).map(formatStatuses)}/>
                </Col>
                <Col sm={2} className="col-12 mt-md-0 mt-3">
                    <div className="d-grid gap-2">
                    {
                        !searching ?
                            <Button type="submit" variant="success" className="w-100">
                                {advanceFilters ? t('filters_labels.apply') : t('search')}
                            </Button>
                            :
                            <Button variant="success" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="mx-2">{t('please_wait')}</span>
                            </Button>
                    }
                    </div>
                </Col>
                <Col sm={2} className="col-12">
                    <Button variant="link" className="my-md-0 my-3" onClick={(e) => {
                        e.preventDefault();
                        setAdvanceFilters(!advanceFilters)
                    }}>{!advanceFilters ? t('filters_labels.show_advance_filters') : t('filters_labels.hide_advance_filters')}</Button>
                </Col>
            </Row>
            {advanceFilters && <>
                <Row className="my-md-4 my-2">
                    <Col sm={6}>
                        <Form.Label className="mb-2">
                            {t('filters_labels.order_date')}
                        </Form.Label>

                        <div className="d-flex align-items-center">
                            <Form.Control
                                placeholder={t('from')}
                                type="date"
                                {...register('from')}
                                />

                            <span className="mx-2">{t('to')}</span>

                            <Form.Control
                                placeholder={t('to')}
                                type="date"
                                {...register('to')}
                                />
                        </div>
                    </Col>

                     {!!(auth.user.is_site_manager || auth.user.is_supplier_admin || auth.user.is_platform_admin) &&
                        <Col md={3}>
                            <Form.Label className="mb-2">
                                {t('filters_labels.filter_by_site')}
                            </Form.Label>

                            <AsyncSelect
                                className="react-select"
                                isClearable={true}
                                value={site}
                                onChange={(e) => setValue('site', e)}
                                placeholder={t('site')}
                                defaultOptions={true}
                                loadOptions={getSites} />
                        </Col>
                    }

                    {!!(auth.user.is_platform_admin || auth.user.is_platform_operator) &&
                        <Col md={3}>
                            <Form.Label className="mb-2">
                                {t('filters_labels.filter_by_client')}
                            </Form.Label>

                            <AsyncSelect
                                className="react-select"
                                isClearable={true}
                                value={client}
                                onChange={(e) => setValue('client', e)}
                                placeholder={t('company')}
                                defaultOptions={true}
                                loadOptions={getClients} />
                        </Col>
                    }

                    <Col md={3}>
                        <Form.Label className="mb-2">
                            {t('filters_labels.filter_by_region')}
                        </Form.Label>

                        <Select
                            className="react-select"
                            isClearable={true}
                            value={country}
                            onChange={_ => setValue('country_id', _?.value)}
                            placeholder={t('region_country')}
                            options={countries}
                            />
                    </Col>

                    <Col md={3}>
                        <Form.Label className="mb-2">
                            {t('account_number')}
                        </Form.Label>

                        <Form.Control
                            type="text"
                            placeholder={t('account_number')}
                            {...register('account_number')}
                            />
                    </Col>

                    <Col md={3}>
                        <Form.Label className="mb-2">
                            {t('booking_type')}
                        </Form.Label>

                        <Select
                            className="react-select"
                            isClearable={true}
                            placeholder={t('booking_type')}
                            value={selectedBookingType}
                            onChange={e =>{
                                setValue('booking_type', e?.value)
                                setSelectedBookingType(e);
                            }}
                            options={(!bookingTypesList ? [] : Object.keys(bookingTypesList)).map(formatBookingTypes)}/>
                    </Col>

                </Row>
            </>}
        </Form>
    )
}

export default Filters;
