import PaymentMethods from "../../views/pages/payment-methods/PaymentMethods";
import PaymentMethodCreate from "../../views/pages/payment-methods/PaymentMethodCreate";
import PaymentMethodEdit from "../../views/pages/payment-methods/PaymentMethodEdit";

const orderRoutes = [
    {
        path     : "/settings/payment-methods",
        component: PaymentMethods,
        exact    : true,
        gate     : 'view_payment_methods',
        main     : false,
    },
    {
        path     : "/clients/:clientId/payment-methods",
        component: PaymentMethods,
        exact    : true,
        gate     : 'view_payment_methods',
        main     : false,
    },
    {
        path     : "/settings/payment-methods/create",
        component: PaymentMethodCreate,
        exact    : true,
        gate     : 'view_payment_methods',
        main     : false,
    },
    {
        path     : "/settings/payment-methods/edit/:id",
        component: PaymentMethodEdit,
        exact    : true,
        gate     : 'view_payment_methods',
        main     : false,
    },
];

export default orderRoutes;
