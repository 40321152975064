import Fleets         from "../../views/pages/fleets/Fleets";
import FleetCreate    from "../../views/pages/fleets/FleetCreate";
import FleetSingle    from "../../views/pages/fleets/FleetSingle";
import FleetEdit      from "../../views/pages/fleets/FleetEdit";
import PaymentMethods from "../../views/pages/payment-methods/PaymentMethods";
import ClientsIcon    from "../../components/icons/ClientsIcon";
import FleetsIcon     from "../../components/icons/FleetsIcon";
import ImportFleet    from "../../views/pages/fleets/ImportFleet";

const fleetsRoutes = [
    {
        path         : "/fleets",
        component    : Fleets,
        exact        : true,
        gate         : 'view_fleets',
        title        : 'Fleets',
        translate_key: 'fleets',
        // icon         : 'bi-collection',
        icon         : <FleetsIcon/>,
        main         : true,
    },
    {
        path     : "/fleets/create",
        component: FleetCreate,
        exact    : true,
        gate     : 'view_fleets',
        main     : false,
    },
    {
        path     : "/fleets/import",
        component: ImportFleet,
        exact    : true,
        gate     : 'view_fleets',
        main     : false,
    },
    {
        path     : "/fleets/create/:id",
        component: FleetCreate,
        exact    : true,
        gate     : 'view_fleets',
        main     : false,
    },
    {
        path     : "/fleets/:id",
        component: FleetSingle,
        exact    : true,
        gate     : 'view_fleets',
        main     : false,
    },
    {
        path     : "/fleets/edit/:id",
        component: FleetEdit,
        exact    : true,
        gate     : 'view_fleets',
        main     : false,
    },
]

export default fleetsRoutes;
