import { Link } from "react-router-dom";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-bootstrap";
import { useAuth } from "@/services";
import { CheckIcon } from "@/components/icons";
import Markdown from "@/components/Markdown";

function Marketing()
{
  const {t} = useTranslation();
  const auth = useAuth();

  return (
    <div className="container">
      <Helmet>
        <title>{t('drawer_link_titles.marketing')} - {t('app')}</title>
      </Helmet>

      <Row>
        <Col xl={{ span: 6, order: 'last' }} className="pb-2">
          <img src="/images/site-marketing-cover.jpg" style={{ maxWidth: '100%' }} className="mb-3" />
        </Col>

        <Col xl={6}>
          <h1 className="text-primary mb-3 pb-1">{t('marketing.advert.title')}</h1>

          <Markdown content={t('marketing.advert.content')} />
        </Col>
      </Row>
    </div>
  );
}

export default Marketing;
