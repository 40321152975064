function foregroundColour(hex)
{
  if(typeof hex != 'string' || hex?.length < 7)
  {
    return '#ffffff';
  }

  var red = parseInt(hex.slice(1, 3), 16),
      green = parseInt(hex.slice(3, 5), 16),
      blue = parseInt(hex.slice(5, 7), 16);

  if(red*0.299 + green*0.587 + blue*0.114 > 186)
  {
    return '#000000';
  }
  else
  {
    return '#ffffff';
  }
}

function hexToRGBString(hex)
{
  if(typeof hex != 'string' || hex?.length < 7)
  {
    return;
  }

  var red = parseInt(hex.slice(1, 3), 16),
      green = parseInt(hex.slice(3, 5), 16),
      blue = parseInt(hex.slice(5, 7), 16);

  return red + ', ' + green + ', ' + blue;
}

export {
  hexToRGBString,
  foregroundColour,
};
