import {useAuth}                       from "@/services/Auth";
import React, {useEffect, useState}    from "react";
import LoadingPage                     from "@/components/LoadingPage";
import {Button, Card, Col, Row, Table} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {useTranslation}                from "react-i18next";
import ConfirmDialog                   from "../../../components/Modals/ConformDialog";
import { Helmet } from "react-helmet";

function PaymentMethods() {
    const {t, i18n} = useTranslation();
    let auth = useAuth();
    let getRequest = auth.getRequest;
    let deleteRequest = auth.deleteRequest;

    const [loading, setLoading] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);
    const [paymentMethodToDelete, setPaymentMethodToDelete] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);


    let {clientId} = useParams();

    const getPaymentMethods = () => {
        getRequest('/payment-methods',  {
            client_id: clientId,
            include: ['fleets'],
        })
            .then(response => {
                setLoading(false)
                setPaymentMethods(response.data.payment_methods)
            })
            .catch(error => {
                setLoading(false)

            })
    }

    const deletePayment = () => {
        setLoading(true)
        setShowConfirm(false);
        deleteRequest(`/payment-methods/${paymentMethodToDelete.id}`)
            .then(response => {
                if (response.data.message === 'OK') {
                    getPaymentMethods();
                } else {
                    setLoading(true)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const handleClose = () => {
        setShowConfirm(false);
    }

    const deleteMethod = (paymentMethod) => {
        setPaymentMethodToDelete(paymentMethod);
        setShowConfirm(true);
    }

    useEffect(() => {
        getPaymentMethods();
    }, [])

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <div>
                <Helmet>
                    <title>{t('payment_methods')} - {t('app')}</title>
                </Helmet>

                <ConfirmDialog
                    message='delete_payment_method'
                    cancel='conform_dialog.cancel'
                    accept='conform_dialog.delete'
                    acceptAction={deletePayment}
                    show={showConfirm}
                    handleClose={handleClose}
                />
                <Card className="mx-2 my-2 p-2">
                    <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                        <h3 className="text-primary">{t('payment_methods')}</h3>
                        { (auth.roles.create_payment_method && !auth.user.is_platform_admin) && <Link to={`/settings/payment-methods/create`} className="btn btn-warning me-2">{t('add_payment_method')}</Link>}
                    </Card.Subtitle>
                    <Card.Body className="px-0">
                        <Row>
                            <Col>
                                <Table striped bordered hover className="mt-1">
                                    <thead>
                                    <tr>
                                        <th>{t('id')}</th>
                                        <th>{t('card_number')}</th>
                                        <th>{t('card_expiry')}</th>
                                        <th>{t('name_on_the_card')}</th>
                                        {auth?.roles?.view_fleets && (
                                            <th>{t('fleets')}</th>
                                        ) || ''}
                                        {auth.user.is_client_admin && <th>&nbsp;</th>}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        paymentMethods.map((paymentMethod, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{paymentMethod.id}</td>
                                                    <td>{paymentMethod.number}</td>
                                                    <td>{paymentMethod.expire}</td>
                                                    <td>{paymentMethod.holder_name}</td>
                                                    {auth?.roles?.view_fleets && (
                                                        <td>{paymentMethod?.fleets?.map && paymentMethod.fleets.map(fleet => (
                                                            <Link to={{ pathname: `/fleets/${fleet.id}`, state: { fleet } }} className="me-2">
                                                                {fleet.name}
                                                            </Link>
                                                        )) || ''}</td>
                                                    ) || ''}
                                                    {
                                                        auth.user.is_client_admin &&
                                                        <td>
                                                            <div className="d-flex">

                                                                <Button variant="light" className="btn-icon btn-rounded  me-2" size="sm" onClick={() => deleteMethod(paymentMethod)}>
                                                                    <i className="bi bi-x text-danger"></i>
                                                                </Button>
                                                                <Link to={`/settings/payment-methods/edit/${paymentMethod.id}`} className="btn btn-light btn-sm btn-icon btn-rounded">
                                                                    <i className="bi bi-pencil-square text-success"></i>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        paymentMethods.length === 0 &&
                                        <tr>
                                            <td colSpan={6} className="text-center">{t('no_data')}</td>
                                        </tr>
                                    }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

            </div>
        )
    )
}

export default PaymentMethods;
