function CameraIcon({ variant, classDefined }) {
  return (
    <div className={`custom-logo ${classDefined}`}>
      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.333 2H10.609L8.80434 0.195334C8.74254 0.133306 8.66908 0.0841138 8.58818 0.0505903C8.50729 0.0170668 8.42057 -0.000126299 8.33301 6.98449e-07H5.66634C5.57878 -0.000126299 5.49206 0.0170668 5.41116 0.0505903C5.33027 0.0841138 5.25681 0.133306 5.19501 0.195334L3.39034 2H1.66634C0.931008 2 0.333008 2.598 0.333008 3.33333V10.6667C0.333008 11.402 0.931008 12 1.66634 12H12.333C13.0683 12 13.6663 11.402 13.6663 10.6667V3.33333C13.6663 2.598 13.0683 2 12.333 2ZM6.99967 10C5.19301 10 3.66634 8.47333 3.66634 6.66667C3.66634 4.86 5.19301 3.33333 6.99967 3.33333C8.80634 3.33333 10.333 4.86 10.333 6.66667C10.333 8.47333 8.80634 10 6.99967 10Z" fill="white" style={{ fill: variant ? 'var(--bs-' + variant + ')' : 'white'}}/>
      </svg>
    </div>
  );
}

export default CameraIcon;
