import Error401 from "../../views/errors/Error401";
import Error404 from "../../views/errors/Error404";

const errors = [
    {
        path     : "/401",
        component: Error401,
        public   : true,
        exact    : true,
    },
    {
        path     : "*",
        component: Error404,
        public   : true,
        exact    : true,
    },
];
export default errors
