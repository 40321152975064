import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import * as moment                    from "moment";
import Select                         from "react-select";
import AsyncSelect                    from "react-select/async";
import React, { useEffect, useState }   from "react";
import {useTranslation}               from "react-i18next";
import {useAuth}                      from "@/services/Auth";
import { Cities, Countries }          from "../../../../services";
import {useForm}                      from "react-hook-form";

function Filters({options,searching, ...props}) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const formatRecord = (company) => {
        const  object = {...company};

        object.value = company.id;
        object.label = company.name;

        return object;
    };

    const [countries, setCountries] = useState(null);

    const onSubmit = (values) => {
        let updatedValues = values;

        console.log(updatedValues);

        props.onSubmit(updatedValues)
    };

    const {
              handleSubmit,
              register,
              watch,
              setValue
          } = useForm({
        defaultValues: {
            ...props.values
        }
    });


    const [advanceFilters, setAdvanceFilters] = useState(false);

    useEffect(() => {
        if (countries === null) {
            Countries.active()
                .then(countries => setCountries(countries.map(formatRecord)))
                .catch(_ => setCountries([]));
        }
    }, [countries]);

    useEffect(() => {
        register('country_id');
    }, []);

    const country_id = watch('country_id');

    const country = countries?.find && countries.find(_ => _.id == country_id);


    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="my-4">
                <Col sm={4} className="col-12">
                    <Form.Control
                        type="text"
                        {...register('search')}
                        placeholder={t('search_for_anything')}
                        />
                </Col>
                <Col sm={3} className="col-12 mt-md-0 mt-3">
                    <div className="d-grid gap-2">
                    {
                        !searching ?
                            <Button type="submit" variant="success" className="w-100">
                                {advanceFilters ? t('filters_labels.apply') : t('search')}
                            </Button>
                            :
                            <Button variant="success" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="mx-2">{t('please_wait')}</span>
                            </Button>
                    }
                    </div>
                </Col>
                <Col sm={4} className="col-12">
                    <Button variant="link" className="my-md-0 my-3" onClick={(e) => {
                        e.preventDefault();
                        setAdvanceFilters(!advanceFilters)
                    }}>{!advanceFilters ? t('filters_labels.show_advance_filters') : t('filters_labels.hide_advance_filters')}</Button>
                </Col>
            </Row>
            {advanceFilters && <>
                <Row className="my-md-4 my-2">
                    <Col sm={6}>
                        <Form.Label className="mb-2">
                            {t('filters_labels.filter_by_creation_date')}
                        </Form.Label>

                        <div className="d-flex align-items-center">
                            <Form.Control
                                placeholder={t('from')}
                                type="date"
                                {...register('from')}
                                />

                            <span className="mx-2">{t('to')}</span>

                            <Form.Control
                                placeholder={t('to')}
                                type="date"
                                {...register('to')}
                                />
                        </div>
                    </Col>

                    <Col md={3}>
                        <Form.Label className="mb-2">
                            {t('filters_labels.search_for_email_address')}
                        </Form.Label>

                        <Form.Control
                            type="text"
                            placeholder={t('filters_labels.search_for_email_address')}
                            {...register('email_address')}
                            />
                    </Col>

                    <Col md={3}>
                        <Form.Label className="mb-2">
                            {t('filters_labels.filter_by_region')}
                        </Form.Label>

                        <Select
                            className="react-select"
                            isClearable={true}
                            value={country}
                            onChange={_ => setValue('country_id', _.value)}
                            placeholder={t('region_country')}
                            options={countries}
                            />
                    </Col>

                </Row>

                <Row className="my-4">
                    <Col md={3}>
                        <Form.Label className="mb-2">
                            {t('filters_labels.filter_by_company_name')}
                        </Form.Label>

                        <Form.Control
                            type="text"
                            placeholder={t('filters_labels.filter_by_company_name')}
                            {...register('company_name')}
                            />
                    </Col>

                    <Col md={3}>
                        <Form.Label className="mb-2">
                            {t('filters_labels.filter_by_account_status')}
                        </Form.Label>

                        <Form.Select
                            className="react-select"
                            {...register('account_status')}
                            >
                            <option value="">{t('all')}</option>
                            <option value="Active">{t('active')}</option>
                            <option value="Stopped">{t('stopped')}</option>
                        </Form.Select>
                    </Col>

                    <Col md={3}>
                        <Form.Label className="mb-2">
                            {t('filters_labels.credit_status')}
                        </Form.Label>

                        <Form.Select
                            className="react-select"
                            {...register('credit_status')}
                            >
                            <option value="">{t('all')}</option>
                            <option value="Inactive">{t('inactive')}</option>
                            <option value="Unpaid">{t('unpaid')}</option>
                            <option value="Paid">{t('paid')}</option>
                        </Form.Select>
                    </Col>
                </Row>
            </>}
        </Form>
    )
}

export default Filters;
