import React, {useEffect, useState}   from "react";
import {Button, Card, Spinner} from "react-bootstrap";
import {useTranslation}               from "react-i18next";
import {Link, useParams, useHistory, useLocation}              from "react-router-dom";
import {useAuth}                      from "@/services/Auth";
import { Helmet } from "react-helmet";
import Table, { useColumns }                      from "@/components/Tables/OrderItems";
import * as csv from 'csv-stringify/lib/sync';
import FileSaver from 'file-saver';
import Filters from './components/Filters';

let accountNumberFilter;

function Orders() {
    const history = useHistory();
    const route = useLocation();

    let auth = useAuth();

    const {t} = useTranslation();
    const columns = useColumns(undefined, true);

    const [query, setQuery] = useState({
        ...(route?.state?.query || {}),
        ...{'statuses': [2, 3] }
    });

    const [loading, setLoading] = useState(true);
    const [exporting, setExporting] = useState(false);

    const {type, id} = useParams();

    let url = '/order_items';

    switch(type)
    {
        case 'client':
        case 'clients':
            url = `/clients/${id}${url}`;
        break;

        case 'fleet':
        case 'fleets':
            url = `/fleets/${id}${url}`;
        break;

        case 'supplier':
        case 'suppliers':
            url = `/suppliers/${id}${url}`;
        break;

        case 'site':
        case 'sites':
            url = `/sites/${id}${url}`;
        break;
    }

    const exportOrders = () => {
        let headers = columns.map(_ => _.text);

        let exportRequest = { ...query };

        exportRequest.skip = 0;
        exportRequest.limit = 0;
        exportRequest.export = true;

        setExporting(true);

        auth.getRequest(url, exportRequest)
            .then(response => {

                const records = response.data.order_items.map((record) => {
                    return columns.map(_ => {
                        var value = record,
                        paths = _.dataField.split('.'),
                        name;

                        while((name = paths.shift()) && value)
                        {
                            value = value[name];
                        }

                       return _.exportFormatter ? _.exportFormatter(value, record) : value;
                    });
                });

                records.unshift(headers);

                const data = "\uFEFF" + csv.stringify(records, {});
                const blob = new Blob([data], {type: 'text/plain;charset=utf-8', encoding: 'UTF-8'});

                FileSaver.saveAs(blob, 'order_items.csv', {});

                setExporting(false);
            })
            .catch(error => {
                console.error(error);

                setExporting(false);
            })
    }

    useEffect(() => {
        history.replace(
            route.pathname,
            {
                ...route.state,

                query,
            }
        )
    }, [query]);

    return (
        <Card className="mx-2 my-2 p-2 card-has-loading position-relative">
            <Helmet>
                <title>{t('drawer_link_titles.orders')} - {t('app')}</title>
            </Helmet>

            <Card.Subtitle className="d-flex justify-content-between flex-md-row flex-column">
                <h4 className="text-primary">{t('drawer_link_titles.orders')}</h4>

                <div className="d-flex align-items-center">
                    {
                        !exporting ?
                            <Button variant="success" className="mx-md-1 my-1" onClick={() => exportOrders()}>{t('export')} (.csv)</Button> :
                            <Button variant="success" className="mx-md-1 my-1" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="mx-2">{t('please_wait')}</span>
                            </Button>
                    }
                    {
                        auth?.roles?.update_exchange_rate &&
                        <Link to="/orders/update-exchange-rates" className="btn btn-secondary mx-1 my-1 my-md-0">{t('update_exchange_rates')}</Link>
                    }
                    {
                        auth?.roles?.view_vat_rates &&
                        <Link to="/orders/vat-rates" className="btn btn-secondary mx-1 my-1 my-md-0">{t('vat_rates')}</Link>
                    }
                </div>

            </Card.Subtitle>

            <Filters searching={loading || exporting} values={query} onSubmit={setQuery} />

            <Table url={url} query={query} showPagination={true} onData={_ => setLoading(!_)} />
        </Card>
    );
}

export default Orders;
