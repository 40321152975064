import { hashJourney } from './Sites';
import Storage from './Storage';

var journeyStore;

function onUnload()
{
  for(var index in journeyStore)
  {
    if(journeyStore[index].calculated_at <= (new Date().getTime() - 1000 * 60 * 60))
    {
      delete journeyStore[index];
    }
  }

  Storage.Session.setItem('journeyStorage', journeyStore);
}


function init(force)
{
  return new Promise(resolve => {
    if(!journeyStore || force)
    {
      window.removeEventListener('beforeunload', onUnload);

      Storage.Session.getItem('journeyStorage').then(raw => {
        journeyStore = raw || {};

        resolve(journeyStore);

        window.addEventListener('beforeunload', onUnload);
      });
    }
    else
    {
      resolve(journeyStore);
    }
  });
}


function get(origin, destination)
{
  return new Promise(resolve => {
    init().then(journeyStore => resolve(journeyStore[ hashJourney(origin, destination) ]));
  });

}


function merge(hash)
{
  return new Promise(resolve => {
    init().then(journeyStore => {
      for(var index in hash)
      {
        journeyStore[ index ] = hash[index];
      }


      resolve();
    });
  });
}

function convertDistance(distance, user){
    if( user?.distance_units === 'mi' ){
        let distance_mi = (distance.value/1000) * 0.621371;
        distance.text = `${distance_mi.toLocaleString('en-GB', {maximumFractionDigits:2})} mi`
    }
    return distance;
}

export {
  get,
  merge,
    convertDistance
};
