import { useLocation } from "react-router-dom";
import React, { useState, useEffect, createContext, useContext, createRef } from "react";
import Main, { ProvideMain } from "./components/Main";
import Aside, { ProvideAside } from "./components/Aside";
import Error0 from "@/views/errors/Error0";
import Error401 from "@/views/errors/Error401";
import Error404 from "@/views/errors/Error404";
import FontEndError from "@/views/errors/FontEndError";
import {BrowserRouter as Router} from "react-router-dom";
import {useAuth} from "@/services/Auth";

const layoutContext = createContext();

function ProvideLayout({children}) {
    const ref = useProvideLayout();

    return <layoutContext.Provider value={ref}>{children}</layoutContext.Provider>;
}


function useProvideLayout() {
    const main = createRef();

    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [allowScrollTop, setAllowScrollTop] = useState(true);

    const [extraNav, setExtraNav] = useState(null);
    const [videoBackground, setVideoBackground] = useState(false);



    return {
        collapsed,
        setCollapsed,
        toggled,
        setToggled,

        allowScrollTop,
        setAllowScrollTop,

        extraNav,
        videoBackground,
        setVideoBackground,
        setExtraNav,

        main,

        scrollTop: () => {
            setTimeout(function () {
                if(!allowScrollTop)
                {
                    return;
                }
                main?.current?.scrollTo && main.current.scrollTo({
                    top     : 0,
                    behavior: "smooth"
                });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 5);
        },
    };
};

export const useLayout = () => {
    return useContext(layoutContext);
};

function Layout() {

    const location = useLocation()
    let auth = useAuth();
    let layout = useLayout();

    useEffect(() => {
        layout.scrollTop();
        auth.updateErrorPage(false);
    }, [location])


    return (
        <>
            {(() => {


                if(auth.errorPage !== false)
                {
                    switch(auth.errorPage)
                    {
                        case 0:
                            return <Error0 />;
                        break;

                        case 401:
                        case 403:
                            return <Error401 />;
                        break;

                        case 404:
                            return <Error404 />;
                        break;

                        case 500:
                            return <FontEndError />;
                        break;
                    }
                }


                return (
                    <div className={`app ${layout.toggled ? 'toggled' : ''} ${layout.collapsed ? 'collapsed' : ''}`}>

                    {auth?.user && auth?.user?.linked_status && auth?.user?.account_status &&
                     <Aside />
                    }
                    <Main />

                    </div>
                );

            })()}

        </>
    );
}

export default function () {
    return <ProvideLayout><Router><Layout /></Router></ProvideLayout>;
};


function ExtraNav({ children })
{
    const layout = useLayout();

    useEffect(() => {
        layout.setExtraNav(children);

        return _ => layout.setExtraNav(null);
    }, []);

    return <></>;
}

export { ExtraNav };
