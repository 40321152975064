import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import NavBar                                 from "./NavBar";
import routes                                 from "@/router/router";
import LoadingPage                            from "@/components/LoadingPage";
import Footer                                 from "./Footer";
import React, { useEffect }          from "react";
import {useAuth}                              from "@/services/Auth";
import Error401                               from "@/views/errors/Error401";
import AccountStopped                         from "@/views/auth/AccountStopped";
import ScrollToTop                            from "@/components/ScrollToTop";
import MparkHero                              from '@/assets/videos/MparkHero.mp4'
import ReactPlayer                            from "react-player";
import { RollbarContext } from '@rollbar/react';
import { useLayout } from "@/layouts/Layout";


function Main() {

    let auth = useAuth();

    const {pathname} = useLocation()

    const main = useLayout();

    useEffect(() => {
        main.scrollTop();
    }, [pathname]);

    return (
        <main ref={main.main}>
            {!main.videoBackground && <NavBar />}

            <div className={`content-holder`}>
                {
                    main.videoBackground && <>
                    <div className='player-wrapper'>
                        <ReactPlayer
                            className='player-element d-flex justify-content-center'
                            url={MparkHero}
                            playing
                            loop
                            muted
                            width="100%"
                            height="100%"
                        />
                    </div>
                    <div className="login-gradient"/>
                    </>
                }


                <div className={!main.videoBackground ? "main-contents" : "login-content"}>
                    {auth !== undefined && auth.user !== undefined && auth.roles !== undefined && (
                    <ScrollToTop>
                        <Switch>
                            {routes.map((route, i) => (
                                route.public ? <PublicRoute key={i} {...route}/> : <PrivateRoute key={i} {...route}/>
                            ))}
                        </Switch>
                    </ScrollToTop>
                    ) || (
                        <LoadingPage/>
                    )}
                </div>
            </div>

            {!main.videoBackground && <Footer/>}
        </main>
    )
}


function PublicRoute(route) {
    return (
        <Route
            path={route.path}
            render={props => (
                <RollbarContext context={route.path}>
                    <route.component {...props} routes={route.routes}/>
                </RollbarContext>
            )}
        />
    );
}

function PrivateRoute(route) {
    let auth = useAuth();

    const accountActive = auth.accountActive;

    return (
        <Route
            path={route.path}
            render={props =>
                auth.user ? (
                    accountActive ?
                    (

                        (route.gate === undefined || auth.roles[route.gate]) ? (
                            <RollbarContext context={route.path}>
                                <route.component {...props} routes={route.routes}/>
                            </RollbarContext>
                        ) : (
                            <Error401/>
                        )
                    )
                                  :
                    <AccountStopped/>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state   : {from: props.location}
                        }}
                    />
                )
            }
        />
    );
}

export default Main;
