import {useTranslation}                                   from "react-i18next";
import {useAuth}                                          from "@/services/Auth";
import {useParams}                                        from "react-router-dom";
import React, {useEffect, useState, useRef}                       from "react";
import LoadingPage                                        from "@/components/LoadingPage";
import {Button, Card, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import { Helmet } from "react-helmet";
import AsyncSelect from "react-select/async";

import Select        from 'react-select';
import SuccessDialog from "../../../components/Modals/SuccessDialog";

function VehicleEdit(props) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();
    let {id} = useParams();

    const getRequest = useRef(null);

    const [vehicle, setVehicle] = useState(null);
    const [updatingVehicle, setUpdatingVehicle] = useState(false);
    const [updatedSuccess, setUpdateSuccess] = useState(false);
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [drivers, setDrivers] = useState([]);
    const [fleet, setFleet] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([]);

    const formatRecord = (company) => {
        const  object = {...company};

        object.value = company.id;
        object.label = company.name;

        return object;
    };


    useEffect(() => {
        auth.getRequest('/vehicles/' + id)
            .then(response => {
                setVehicle(response.data.vehicle);
                //vehicle data
                setRegistrationNumber(response.data.vehicle.registration_number);
                setVehicleType(response.data.vehicle.vehicle_type_key);
                setFleet(response.data.vehicle.fleet && formatRecord(response.data.vehicle.fleet));
                setDrivers(response.data.vehicle.drivers);
                setVehicleTypes(response.data.vehicle_types || []);
            })
    }, []);


    const getDrivers = (search) => {
        getRequest.current && getRequest?.current?.abort && getRequest.current.abort();

        return new Promise((resolve, reject) => {
            getRequest.current = auth.getRequest(fleet && fleet.id && `fleets/${fleet.id}/users` || 'users', { search, drivers: true });

            getRequest.current.then(response => {
                    resolve(response.data.users.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    const getFleets = (search) => {
        getRequest.current && getRequest?.current?.abort && getRequest.current.abort();

        return new Promise((resolve, reject) => {
            getRequest.current = auth.getRequest('fleets', { search });

            getRequest.current.then(response => {
                    resolve(response.data.fleets.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };


    const onSubmit = () => {
        let payload = {
            'registration_number': registrationNumber,
            'fleet_id'           : fleet?.id,
            'vehicle_type'       : vehicleType,
            'drivers'            : drivers.length !== 0 ? drivers.map(driver => driver.id) : null,
        };
        setUpdatingVehicle(true);
        auth.putRequest(`/vehicles/${vehicle.id}`, payload)
            .then(response => {
                setUpdatingVehicle(false);
                if (response.data.message && response.data.message === "OK") {
                    setUpdateSuccess(true);
                }
            })
            .catch(error => {
                console.log(error);
            })
    };

    const addDrivers = (driver) => {
        let driversUpdated = [].concat(drivers);

        driversUpdated.push(driver);

        setDrivers(driversUpdated)
    };

    const removeDriver = (driverId) => {
        let driversUpdated = drivers.filter(driver => driver.id !== driverId);

        setDrivers(driversUpdated);
    };

    const handleClose = () => {
        setUpdateSuccess(false)
        if (id !== '' && id != null) {
            props.history.push(`/vehicles/${id}`)
        }
    }

    if(!vehicle)
    {
        return <LoadingPage />;
    }

    return (
        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{t('edit')} / {t('drawer_link_titles.vehicles')} - {t('app')}</title>
            </Helmet>


            <SuccessDialog
                show={updatedSuccess}
                message="success_dialog.vehicle_updated"
                handleClose={handleClose}
            />

            <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                <h4 className="text-primary">{vehicle.registration_number}</h4>
            </Card.Subtitle>
            <Form className="mt-5">
                <Row className="">
                    <Col md={6} lg={4} className="col-12">
                        <Form.Group as={Row} className="mb-5" controlId="formPlaintextCompanyName">
                            <Form.Label column className="col-12">
                                {t('vehicle_registration_number')}
                            </Form.Label>
                            <Col className="col-12">
                                <Form.Control type="text" placeholder={t('vehicle_registration_number')}
                                              value={registrationNumber}
                                              onChange={e => setRegistrationNumber(e.target.value)}/>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col md={6} lg={4} className="col-12">
                        <Form.Group as={Row} className="mb-5" controlId="formPlaintextCompanyName">
                            <Form.Label column className="col-12">
                                {t('add_drivers')}
                            </Form.Label>
                            <Col className="col-12">

                                <AsyncSelect
                                    className="react-select"
                                    value={null}
                                    onChange={(e) => addDrivers(e)}
                                    placeholder={`--${t('choose_driver')}--`}
                                    loadOptions={getDrivers}
                                    />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col md={6} lg={4} className="col-12">
                        <Form.Group as={Row} className="mb-5" controlId="formPlaintextCompanyName">
                            <Form.Label column className="col-12">
                                {t('fleet_name')}
                            </Form.Label>
                            <Col className="col-12">
                                <AsyncSelect
                                    className="react-select"
                                    value={fleet}
                                    onChange={(e) => setFleet(e)}
                                    placeholder={`--${t('choose_fleet')}--`}
                                    loadOptions={getFleets}
                                    />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col md={6} lg={4} className="col-12">
                        <Form.Group as={Row} className="mb-5" controlId="formPlaintextCompanyName">
                            <Form.Label column className="col-12">
                                {t('vehicle_type')}
                            </Form.Label>
                            <Col className="col-12">
                                <Form.Select className="mx-1 react-select" value={vehicleType} onChange={e => setVehicleType(e.target.value)}>
                                    <option value="">--{t('choose_type')}--</option>
                                    {vehicleTypes && Object.entries(vehicleTypes).map(([index, value]) => <option value={index} key={index}>{t(`vehicle_types.${value}`)} </option>) || ''}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="">
                    <Col className="col-12">
                        <Form.Group as={Row} className="mb-5" controlId="formPlaintextCompanyName">
                            <Form.Label column className="col-12">
                                {t('drivers')}
                            </Form.Label>
                            <Col className="col-12">
                                {drivers?.map && drivers.map((driver, index) =>
                                    <Button variant="light" className="btn-icon btn-rounded" key={index} onClick={() => removeDriver(driver.id)}
                                            key={driver.id} className="mx-1">
                                        <span className=" me-2"> {driver.name}</span>
                                        <i className="bi bi-x"></i>
                                    </Button>
                                )}
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <div className="d-grid gap-2">
                    {!updatingVehicle ?
                     <Button onClick={onSubmit} type="submit"
                             variant="primary">{t('save_new_vehicle')}</Button>
                                      :
                     <Button variant="primary" disabled>
                         <Spinner
                             as="span"
                             animation="border"
                             size="sm"
                             role="status"
                             aria-hidden="true"
                         />
                         <span className="mx-2">{t('please_wait')}</span>
                     </Button>
                    }
                </div>
            </Form>
        </Card>
    )
}

export default VehicleEdit;
