import React from "react";
import { Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const  YesNoToggle = React.forwardRef(({ className, yesValue, yesLabel, noValue, noLabel, isInvalid, ...props }, ref) => {
  const {t} = useTranslation();
  const _yesValue = typeof yesValue != 'undefined' ? yesValue : 'yes';
  const _yesLabel = typeof yesLabel != 'undefined' ? yesLabel : t('yes');

  const _noValue = typeof noValue != 'undefined' ? noValue : 'no';
  const _noLabel = typeof noLabel != 'undefined' ? noLabel : t('no');

  return (
    <Row className={`gx-3 mb-3 ${className || ''} ${isInvalid && 'is-invalid' || ''}`}>
      <label
          key={'yes'}
          className="col">
          <Form.Check.Input
            type="radio"
            className="d-none"
            value={_yesValue}
            ref={ref}
            {...props}
          />

          <span className="btn btn-outline-primary w-100">{_yesLabel}</span>
      </label>

      <label
          key={'no'}
          className="col">
          <Form.Check.Input
            type="radio"
            className="d-none"
            value={_noValue}
            ref={ref}
            {...props}
          />

          <span className="btn btn-outline-primary w-100">{_noLabel}</span>
      </label>
    </Row>
  );
});

export default YesNoToggle;
