import Wysiwyg from "@/components/Inputs/Wysiwyg";
import DisplayWysiwyg from "@/components/Elements/DisplayWysiwyg";
import Buttons from "../components/Buttons";

function CallToAction({ site, edit, onChange, ...props })
{
  /**
   * IMPORTANT!!!!
   * Make sure to update the counter part in the laravel blade template
   */

  return (
    <section className="block block-call-to-action py-3 py-xl-4">
      <div className="container py-3 py-xl-5 text-center">
        {edit && <Wysiwyg value={props.content} onChange={event => onChange({ ...props, content: event.target.value })} /> || <DisplayWysiwyg html={props.content || ''} />}

        <Buttons buttons={props.buttons} edit={edit} updateButtons={buttons => onChange({ ...props, buttons })} />
      </div>
    </section>
  );
}



export default CallToAction;
