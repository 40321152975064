import Help     from "../../views/pages/Help";
import HelpIcon from "../../components/icons/HelpIcon";

const helpRoutes = [

    {
        path         : "/help",
        component    : Help,
        exact        : true,
        gate         : 'view_help',
        title        : 'Need Help?',
        translate_key: 'need_help',
        // icon         : 'bi-question-circle',
        icon         : <HelpIcon/>,
        main         : true,
    },
]
export default helpRoutes;
