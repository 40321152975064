import {useHistory, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import React, {useEffect} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import moment from "moment-timezone";
import DisplayChecks from "../compliance/components/DisplayChecks";

function VehicleIssues() {
    const {t, i18n} = useTranslation();

    const location = useLocation();
    const history = useHistory();
    const {state: {vehicle} = {}} = location;

    useEffect(() => {
        if (!vehicle || !vehicle.id) {
            history.back();
        }
    }, []);

    const VehicleIssues = ({ data }) => {
        const renderAttachments = (attachments) => {
            return attachments.map((attachment, index) => (
                <div key={index}>
                    <img src={attachment.url} alt={attachment.hash} width="320" />
                </div>
            ));
        };

        const renderGroups = (groups) => {
            return groups.map((group, index) => (
                <div key={index} style={{ marginLeft: '30px' }}>
                    <h3>{t(group.key)}</h3>
                    {group.groups ? renderGroups(group.groups) : null}
                    {group.checks ? <DisplayChecks checks={group.checks} /> : null}
                </div>
            ));
        };

        return (
            <div>
                <h1>{t('compliance.details')} - {moment(data.occurance_at).format(t('formats.date'))}</h1>
                {renderGroups(data.groups)}
            </div>
        );
    };

    return (
        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{vehicle?.deleted_at && t('archived') || ''} {vehicle.registration_number} / {t('drawer_link_titles.vehicles')} - {t('app')}</title>
            </Helmet>

            <Card.Subtitle className="d-flex justify-content-start" style={{width: '100%'}}>
                <h3 className="text-primary">{vehicle?.deleted_at && t('archived') || ''} {vehicle.registration_number} {vehicle?.fleet && ' - ' + vehicle?.fleet.name} {vehicle?.fleet?.client && ' - ' + vehicle?.fleet?.client.name}</h3>
            </Card.Subtitle>

            <Row className="mt-2">
                <Col>
                    { vehicle.vehicle_issues && <VehicleIssues data={vehicle.vehicle_issues} /> }
                </Col>
            </Row>
        </Card>
    )
}

export default VehicleIssues;
