function PayMPark({classDefined}) {
    return (
        <div className={`custom-logo ${classDefined}`}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8947 39.7895C30.8823 39.7895 39.7895 30.8823 39.7895 19.8947C39.7895 8.90718 30.8823 0 19.8947 0C8.90718 0 0 8.90718 0 19.8947C0 30.8823 8.90718 39.7895 19.8947 39.7895ZM27.7551 30.199L26.1565 26.676C24.913 27.2286 23.9065 27.5049 23.1367 27.5049C22.7025 27.5049 21.8884 27.352 20.6943 27.0461C20.0232 26.8783 19.6186 26.7845 19.4805 26.7648C19.2042 26.7253 18.8933 26.7056 18.5479 26.7056C17.7288 26.7056 17.0479 26.8438 16.5051 27.1201C17.4229 26.0444 18.0298 25.176 18.3259 24.5148C18.6219 23.8536 18.7699 23.1332 18.7699 22.3536C18.7699 22.1365 18.7551 21.9194 18.7255 21.7023H22.663V18.4309H18.0446C17.8374 17.8684 17.6597 17.2467 17.5117 16.5658C17.3736 15.8849 17.3045 15.3372 17.3045 14.9227C17.3045 14.1234 17.566 13.4622 18.089 12.9391C18.612 12.4062 19.2683 12.1398 20.0578 12.1398C20.7979 12.1398 21.4147 12.3717 21.9081 12.8355C22.4015 13.2993 22.742 14.0592 22.9295 15.1151L26.941 14.523C26.6844 12.6875 25.9788 11.2812 24.8242 10.3043C23.6696 9.31743 22.1203 8.82401 20.1762 8.82401C18.9032 8.82401 17.7288 9.08059 16.6532 9.59375C15.5775 10.097 14.7436 10.8372 14.1515 11.8141C13.5693 12.7911 13.2782 13.8174 13.2782 14.8931C13.2782 16.0872 13.4805 17.2664 13.8851 18.4309H11.5611V21.7023H14.64C14.6795 21.9984 14.6992 22.2796 14.6992 22.5461C14.6992 23.3651 14.4525 24.2286 13.9591 25.1365C13.4755 26.0345 12.6762 26.8832 11.5611 27.6826L13.1449 31.2648C14.3982 30.4062 15.6269 29.977 16.8308 29.977C17.5413 29.977 18.6367 30.1891 20.117 30.6135C21.5972 31.028 22.7815 31.2352 23.6696 31.2352C24.3604 31.2352 25.0709 31.1365 25.8012 30.9391C26.5413 30.7516 27.1926 30.5049 27.7551 30.199Z" fill="white"/>
            </svg>
        </div>
    );
}

export default PayMPark;
