import {useTranslation}             from "react-i18next";
import {useAuth}                    from "@/services/Auth";
import LoadingPage                  from "@/components/LoadingPage";
import {Card, Row, Alert, Col}      from "react-bootstrap";
import React, {useEffect, useState} from "react";
import SiteMapCard                  from "./bookings/components/SiteMapCard";
import {Link}                       from "react-router-dom";
import { Helmet } from "react-helmet";

function FavouriteSites() {

    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const getRequest = auth.getRequest;
    const putRequest = auth.putRequest;


    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(true);

    const getSites = () => {
        setLoading(true);
        getRequest('/favourites')
            .then(response => {
                if (response.data.message === 'OK') {
                    setSites(response.data.sites);
                    setLoading(false);
                }

            })
            .catch(error => {
                setLoading(false);
            })
    }

    useEffect(() => {
        getSites();
    }, []);


    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('drawer_link_titles.favourite_sites')} - {t('app')}</title>
                </Helmet>

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h3 className="text-primary">{t('drawer_link_titles.favourite_sites')}</h3>
                </Card.Subtitle>
                <Row className="mt-3 d-flex list-view-container">
                    {
                        sites.map((site, index) => {
                            return (
                                <Col className="col-12 py-1 px-2 driver-dashboard" sm={6} md={4}>
                                    <SiteMapCard site={site} view="listView" key={index} width="100%" updateResponse={getSites}/>
                                </Col>
                            )
                        })
                    }
                </Row>
                {
                    sites.length === 0 &&

                    <Row>
                        <Col className="text-center">
                            <p className="text-danger">
                                {t('you_have_not_selected_any_site_as_favourite')}
                            </p>
                        </Col>
                    </Row>
                }
                {
                    sites.length === 0 &&
                    <Row className="my-3 d-flex justify-content-lg-center">
                        <Col lg={6} className="col-12">
                            <div className="d-grid gap-2">
                                <Link to={`/find-a-site`} className="btn btn-primary">{t('find_site_to_book')}</Link>
                            </div>
                        </Col>
                    </Row>
                }
            </Card>
        )
    );
}

export default FavouriteSites;
