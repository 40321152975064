function ClientsIcon({classDefined}) {
    return (
        <div className={`custom-logo ${classDefined}`}>
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 6H20V8H13V6ZM14 10H20V12H14V10ZM12 2H20V4H12V2ZM2 14H12V13C12 10.243 9.757 8 7 8H5C2.243 8 0 10.243 0 13V14H2ZM6 7C7.995 7 9.5 5.495 9.5 3.5C9.5 1.505 7.995 0 6 0C4.005 0 2.5 1.505 2.5 3.5C2.5 5.495 4.005 7 6 7Z" fill="white"/>
            </svg>
        </div>
    );
}

export default ClientsIcon;
