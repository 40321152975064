import {Alert, Button, Card, Col, Container, Row, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import { useLayout }                            from "@/layouts/Layout";
import {useAuth} from '@/services/Auth'
import {useTranslation} from "react-i18next";
import ResetPassword from "./ResetPassword";
import {ErrorMessage, Field, Formik, Form} from "formik";
import * as Yup from "yup";
import Logo from "@/components/Logo";
import {useParams} from "react-router-dom";
import LoadingPage from "@/components/LoadingPage";

function ForgotPasswordRequest(props) {


    const {t, i18n} = useTranslation();

    let auth = useAuth();
    const layout = useLayout();

    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const [securityCode, setSecurityCode] = useState('');
    const [resetPasswordForm, setResetPasswordForm] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);
    const [serverErrors, setServerErrors] = useState([]);


    let {sentMail} = useParams();
    let {sentCode} = useParams();

    const requestResetValidation = Yup.object().shape({
        email: Yup.string()
                  .typeError(t('form_validation.invalid_email_address'))
                  .required(t('form_validation.is_required',{
                    attribute: t('either_or', {
                      attribute1: t('email_address'),
                      attribute2: t('phone_number')
                    })
                  })),
    });


    const verifyCodeValidation = Yup.object().shape({
        code: Yup.number()
                .typeError(t('form_validation.code_is_required'))
                 .min(6, t('form_validation.code_invalid_min_length', {length: 6}))
                 .required(t('form_validation.code_is_required')),
    });


    const requestResetPassword = auth.requestResetPassword;
    const postRequest = auth.postRequest;

    const requestPasswordReset = (values) => {
        setLoading(true);

        requestResetPassword(values)
            .then(response => {
                setEmail(values.email);
                setEmailSent(true);
            })
            .catch(error => {
                setEmailSent(false);
                if (error.response.status === 422) {

                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }
                    setServerErrors(serverErrors)
                }
            })
            .finally(_ => setLoading(false));
    };

    const verifyCode = (values) => {
        setCode(values.code);
        setInvalidCode(false);
    }

    useEffect(() => {
        if (sentMail && sentCode) {
            setEmailSent(true);
            setEmail(sentMail);
            setCode(sentCode);
        }
    }, [sentMail, sentCode]);

    useEffect(() => {
        if (email && code && !loading) {
            setLoading(true);

            postRequest('/verify-code', {
                code,
                email,
            })
                .then(response => {
                    setResetPasswordForm(true);
                })
                .catch(error => {
                    if (error.response.status === 422 && error.response.data.errors.email)
                    {
                        setInvalidCode(error.response.data.errors.email[0] ?? true);
                    }
                    else
                    {
                        setInvalidCode(true);
                    }

                    setCode(true)
                })
                .finally(_ => {
                    setLoadingPage(false);
                    setLoading(false);
                })
        } else {
            setLoadingPage(false);
        }
    }, [email, code])

    useEffect(() => {
        layout.setVideoBackground(true);

        return _ => layout.setVideoBackground(false);
    }, []);

    return (
        loadingPage ? (
            <div className="clear-loading">
                <LoadingPage style={{background: "inherit"}}/>
            </div>
        ) : (

            <Container className="login-container">
                <Row style={{height: '100%'}}>
                    <Col className="col-12 d-none d-md-flex align-items-center justify-content-center" md={6}>
                        <Logo className="desktop-logo" size="250"/>
                    </Col>
                    <Col className="col-12 d-flex align-items-center justify-content-center my-2" md={6}>
                        {
                            resetPasswordForm ?
                                <ResetPassword email={email} code={code}/>
                                :
                                <div className="d-flex align-items-center justify-content-center my-2 px-2 p-md-4" style={{minHeight: '600px', height: '100%'}}>
                                    {email ? (

                                        <Card style={{maxWidth: '450px'}} className="login-card px-2 px-md-0 login-card-height login-card-md-height">
                                            <Card.Title className="d-flex justify-content-center align-items-center py-5 d-md-none d-block">
                                                <Logo as="div" className="mobile-logo" size="123"/>
                                            </Card.Title>

                                            {emailSent && (
                                                <Alert variant="success" className="mb-0">
                                                    <h6>{t('we_have_sent_a_security_code', {email: email})}</h6>
                                                </Alert>
                                            )}
                                            <Card.Body>

                                                <Formik
                                                    initialValues={{
                                                        code,
                                                    }}
                                                    validationSchema={verifyCodeValidation}
                                                    onSubmit={verifyCode}
                                                >
                                                    {({values, errors, touched}) => (
                                                        <Form>
                                                            <div className="form-group my-4">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <label htmlFor="code" className="my-2">{t('enter_your_security_code')}</label>
                                                                        <Field
                                                                            type="number"
                                                                            name="code"
                                                                            placeholder={t('enter_your_security_code')}
                                                                            className={`form-control ${errors.code && touched.code ? 'is-invalid' : ''}`}
                                                                        />
                                                                        <small>
                                                                            <ErrorMessage component="div" name="code" className="text-danger  py-1"/>
                                                                        </small>
                                                                        {
                                                                            invalidCode &&
                                                                            <small>
                                                                                <p className="text-danger py-1">
                                                                                    { t(invalidCode === true ?
                                                                                        'please_enter_a_valid_code' :
                                                                                        invalidCode
                                                                                    ) }</p>
                                                                            </small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group my-4">
                                                                <div className="d-grid gap-2">
                                                                    {
                                                                        !loading ?
                                                                            <Button type="submit" variant="primary">
                                                                                {t('reset_password')}
                                                                            </Button>
                                                                            :
                                                                            <Button variant="primary" disabled>
                                                                                <Spinner
                                                                                    as="span"
                                                                                    animation="border"
                                                                                    size="sm"
                                                                                    role="status"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                <span className="mx-2">{t('please_wait')}</span>
                                                                            </Button>
                                                                    }
                                                                </div>

                                                                <Button type="button" variant="btn-link"
                                                                    onClick={_ => { setEmailSent(false); setEmail(null); }}>
                                                                    {t('did_not_receive_security_code')}
                                                                </Button>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Card.Body>
                                        </Card>

                                    ) : (

                                        <Card style={{maxWidth: '450px'}} className="login-card px-md-4 px-2 px-md-0 login-card-height login-card-md-height">
                                            <Card.Title className="d-flex justify-content-center align-items-center py-5 d-md-none d-block">
                                                <Logo as="div" className="mobile-logo" size="123"/>
                                            </Card.Title>
                                            <Card.Body>
                                                <Card.Title className="text-white">{t('forgot_password')}</Card.Title>
                                                <Card.Subtitle className="my-4 text-white">{t('provide_email_address_to_sent_reset_request')}</Card.Subtitle>
                                                <Formik
                                                    initialValues={{
                                                        email,
                                                    }}
                                                    validationSchema={requestResetValidation}
                                                    onSubmit={requestPasswordReset}
                                                >
                                                    {({values, errors, touched}) => (
                                                        <Form>
                                                            <div className="form-group my-4">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <label htmlFor="email" className="my-2">{t('either_or', {attribute1: t('email_address'), attribute2: t('phone_number_intl')})}</label>
                                                                        <Field
                                                                            type="text"
                                                                            name="email"
                                                                            placeholder={t('either_or', {attribute1: t('enter_email_address'), attribute2: t('enter_phone_number')})}
                                                                            className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                                                        />
                                                                        <small>
                                                                            <ErrorMessage component="div" name="email" className="text-danger  py-1"/>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group my-4">
                                                                {
                                                                    (serverErrors.length !== 0) &&
                                                                    <div className="form-group mt-4">
                                                                        {
                                                                            serverErrors.map((error, index) => <p className="text-danger font-weight-bold" key={index}>{t(error)}</p>)
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="form-group my-4">
                                                                <div className="d-grid gap-2">
                                                                    {
                                                                        !loading ?
                                                                            <Button type="submit" variant="primary">
                                                                                {t('send_email')}
                                                                            </Button>
                                                                            :
                                                                            <Button variant="primary" disabled>
                                                                                <Spinner
                                                                                    as="span"
                                                                                    animation="border"
                                                                                    size="sm"
                                                                                    role="status"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                <span className="mx-2">{t('please_wait')}</span>
                                                                            </Button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Card.Body>
                                        </Card>
                                    )}
                                </div>
                        }
                    </Col>
                </Row>
            </Container>
        )
    );
}

export default ForgotPasswordRequest;
