import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState, useCallback, useMemo, useRef }   from "react";
import { Button, Dropdown, OverlayTrigger, Popover, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import LoadingPage from "@/components/LoadingPage";
import { useAuth, Style } from "@/services";
import { useLayout, ExtraNav } from "@/layouts/Layout";
import Blocks from "./blocks";
import MediaSelector from "./components/MediaSelector";
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { HexColorPicker  } from "react-colorful";
import { useForm } from "react-hook-form";

function SitePageEdit() {
  const { id } = useParams();
  const { t } = useTranslation();

  const auth = useAuth();
  const layout = useLayout();

  const route = useLocation();
  const history = useHistory();

  const blocksContainer = useRef(null);

  const blockIndex = useRef(0);

  const [site, setSite] = useState(false);
  const [colours, setColours] = useState(null);
  const [blocks, setBlocks] = useState(null);

  const [saving, setSaving] = useState(false);

  const [selectImage, setSelectImage] = useState(null);

  const insertBlock = (type, index) => {

    const newBlocks = [].concat(blocks);
    const insertIndex = blocks.findIndex(block => block.index == index);

    if(insertIndex >= 0)
    {
      newBlocks.splice(insertIndex, 0, { type });
    }
    else
    {
      newBlocks.push({ type });
    }


    setBlocks(newBlocks);
  };

  const moveBlock = ({oldIndex, newIndex}) => {

    const newBlocks = [].concat(blocks);

    const items = newBlocks.splice(oldIndex, 1);

    newBlocks.splice(newIndex, 0, items[0]);

    setBlocks(newBlocks);
  };

  const updateBlock = (index, values) => {

    const block = blocks.find(block => block.index == index);

    Object.assign(block, values);

    const newBlocks = [].concat(blocks);

    setBlocks(newBlocks);
  };

  const removeBlock = (index) => {

    const newBlocks = [].concat(blocks);

    newBlocks.splice(blocks.findIndex(block => block.index == index), 1);

    setBlocks(newBlocks);
  };

  const onSave = useCallback(() => {
    setSaving(true);

    auth.putRequest('/sites/' + id, { page_content: {
      blocks,
      colours,
    } })
      .then(response => {
        history.push('/pages/' + response.data.data.slug);
      })
      .finally(_ => setSaving(false));

  }, [blocks, colours]);

  useEffect(() => {
    if(!site)
    {
      auth.getRequest('/sites/' + id, { include: 'all' })
        .then(response => {
          setSite(response.data.data);

          if(typeof response?.data?.data?.page_content == 'undefined' || !response.data.can_update)
          {
            auth.updateErrorPage(401);
          }
        });
    }
  }, [site]);

  useEffect(() => {
    layout.setCollapsed(true);

    return _ => layout.setCollapsed(false);
  }, []);

  useEffect(() => {
    if(site)
    {

      blocks || setBlocks(site.page_content?.blocks || []);
      colours || setColours(site.page_content?.colours || {
        primary: '#003B82',
        secondary: '#6c757d',
      });
    }
  }, [site]);

  const primaryRGB = useMemo(_ => Style.hexToRGBString(colours?.primary), [colours]);
  const secondaryRGB = useMemo(_ => Style.hexToRGBString(colours?.secondary), [colours]);

  const primaryTextColour = useMemo(_ => Style.foregroundColour(colours?.primary), [colours]);
  const secondaryTextColour = useMemo(_ => Style.foregroundColour(colours?.secondary), [colours]);

  if(!site || !blocks)
  {
    return <LoadingPage />;
  }

  const toolbox = saving ? false : {
    selectImage: (options) => {
      return new Promise((resolve) => {
        setSelectImage({
          options,
          onSelect: resolve
        });
      });
    },
  };


  return (
    <>
      <Helmet>
        <title>{site?.name || ''} - {t('app')}</title>
      </Helmet>

      <ExtraNav key={JSON.stringify({ blocks, colours, saving })}>
        <NavActions {...{site, colours, setColours, saving, onSave}} />
      </ExtraNav>

      <div className="page-blocks position-relative" ref={blocksContainer} style={{
        "--primary-color": colours?.primary || '#000000',
        "--secondary-color": colours?.secondary || '#000000',
        "--primary-text-color": primaryTextColour,
        "--secondary-text-color": secondaryTextColour,
        "--bs-primary-rgb": primaryRGB,
        "--bs-secondary-rgb": secondaryRGB,
      }}>

      <SortableContainer useDragHandle onSortEnd={moveBlock} helperContainer={_ => blocksContainer.current} >
      {blocks.map((value, index) => {
        if(typeof value.index == 'undefined')
        {
          value.index = blockIndex.current ++;
        }


        return <SortableItem {...{ key: `block-${value.index}`, index, value, site, toolbox, updateBlock, insertBlock, removeBlock }} />;
      })}
      </SortableContainer>

      <div className="edit-block edit-block-add">
        <div className="edit-block-nav">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="primary" className="block-option">
              <span className="bi bi-plus-lg" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item  onClick={_ => insertBlock('Addons', blocks.length)}>
                {t('marketing.blocks.Addons')}
              </Dropdown.Item>

              <Dropdown.Item  onClick={_ => insertBlock('CallToAction', blocks.length)}>
                {t('marketing.blocks.CallToAction')}
              </Dropdown.Item>

              <Dropdown.Item  onClick={_ => insertBlock('Details', blocks.length)}>
                {t('marketing.blocks.Details')}
              </Dropdown.Item>

              <Dropdown.Item  onClick={_ => insertBlock('Gallery', blocks.length)}>
                {t('marketing.blocks.Gallery')}
              </Dropdown.Item>

              <Dropdown.Item  onClick={_ => insertBlock('MediaWithContent', blocks.length)}>
                {t('marketing.blocks.MediaWithContent')}
              </Dropdown.Item>

              <Dropdown.Item  onClick={_ => insertBlock('Pricing', blocks.length)}>
                {t('marketing.blocks.Pricing')}
              </Dropdown.Item>

              <Dropdown.Item  onClick={_ => insertBlock('Services', blocks.length)}>
                {t('marketing.blocks.Services')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      </div>

      <MediaSelector {...{ ...selectImage, onSelect: images => {
        selectImage.onSelect(images);
        setSelectImage(null);
      }, onHide: _ => setSelectImage(null),  show: !!selectImage, base_uri: '/sites/' + site.id }} />
    </>
  );
}


const SortableContainer = sortableContainer(({ children }) => (
  <div>
    {children}
  </div>
))

const SortableHandle = sortableHandle(({children, items, ...props}) => (
  <span className="btn btn-primary block-option">
    <span className="bi bi-chevron-expand" />
  </span>
))

const SortableItem = sortableElement(({ value, site, toolbox, updateBlock, insertBlock, removeBlock, ...props }) => {
  const { t } = useTranslation();

  const Block = Blocks[value.type];

  return (
    <div className="edit-block">
      {Block && <Block {...{...value, site, edit: toolbox, onChange: values => updateBlock(value.index, values)}} /> || ''}

      <div className="edit-block-nav">
        <Dropdown drop="start">
          <Dropdown.Toggle variant="primary" className="block-option">
            <span className="bi bi-plus-lg" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item  onClick={_ => insertBlock('Addons', value.index)}>
              {t('marketing.blocks.Addons')}
            </Dropdown.Item>

            <Dropdown.Item  onClick={_ => insertBlock('CallToAction', value.index)}>
              {t('marketing.blocks.CallToAction')}
            </Dropdown.Item>

            <Dropdown.Item  onClick={_ => insertBlock('Details', value.index)}>
              {t('marketing.blocks.Details')}
            </Dropdown.Item>

            <Dropdown.Item  onClick={_ => insertBlock('Gallery', value.index)}>
              {t('marketing.blocks.Gallery')}
            </Dropdown.Item>

            <Dropdown.Item  onClick={_ => insertBlock('MediaWithContent', value.index)}>
              {t('marketing.blocks.MediaWithContent')}
            </Dropdown.Item>

            <Dropdown.Item  onClick={_ => insertBlock('Pricing', value.index)}>
              {t('marketing.blocks.Pricing')}
            </Dropdown.Item>

            <Dropdown.Item  onClick={_ => insertBlock('Services', value.index)}>
              {t('marketing.blocks.Services')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Button className="block-option" onClick={_ => removeBlock(value.index)}>
          <span className="bi bi-dash-lg" />
        </Button>

        <SortableHandle />
      </div>
    </div>
  );
})

function NavActions({ site, colours, setColours, onSave, saving  })
{
  const { t } = useTranslation();

  const [selectedColour, setSelectedColour] = useState(null);

  const updateColour = ({ colour }) => {
    const newColours = {
      ...colours,
    };

    newColours[selectedColour] = colour;

    setColours(newColours);
    setSelectedColour(null);
  };

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
  } = useForm({});

  const colour = watch('colour');

  const primaryRGB = useMemo(_ => Style.hexToRGBString(colours?.primary), [colours]);
  const secondaryRGB = useMemo(_ => Style.hexToRGBString(colours?.secondary), [colours]);

  return (
    <div className="actions d-flex justify-content-between align-items-center elevated">
      <OverlayTrigger
          trigger="click"
          placement="bottom"
          onToggle={_ => setSelectedColour(null)}
          overlay={
            <Popover className="colour-pickers">
              {selectedColour && (

                <Popover.Body>
                  <HexColorPicker { ...{ color: colour, onChange: _ => setValue('colour', _) }} />

                  <Form noValidate onSubmit={handleSubmit(updateColour)}>
                    <Form.Group>
                      <Form.Control {...register('colour')} />

                      <Button type="submit">
                        {t('add')}
                      </Button>
                    </Form.Group>
                  </Form>
                </Popover.Body>
              ) || (

                <Popover.Body>
                  <Button variant="link" className="w-100 text-start d-flex align-items-center" onClick={_ => { reset({ colour: colours?.primary || '#000000' }); setSelectedColour('primary');  }}>
                    <span className="colour-sample me-2 bg-primary" style={primaryRGB && { "--bs-primary-rgb": primaryRGB } || null} />

                    <span>{t('marketing.colours.primary')}</span>
                  </Button>

                  <Button variant="link" className="w-100 text-start d-flex align-items-center" onClick={_ => { reset({ colour: colours?.secondary || '#000000' }); setSelectedColour('secondary');  }}>
                    <span className="colour-sample me-2 bg-secondary" style={secondaryRGB && { "--bs-secondary-rgb": secondaryRGB } || null} />

                    <span>{t('marketing.colours.secondary')}</span>
                  </Button>
                </Popover.Body>

              )}
            </Popover>
          }
        >
        <Button variant="outline-light" className="mx-2">

          <span className="d-none d-lg-inline me-2">
            {t('marketing.colours.change')}
          </span>

        <span className="bi bi-palette" />
        </Button>
      </OverlayTrigger>

      <Button variant="light" className="mx-2" onClick={onSave} disabled={saving}>

        <span className="d-none d-lg-inline me-2">
          {saving ? t('saving') : t('save')}
        </span>

        <span className="bi bi-save" />
      </Button>
    </div>
  );
}


export default SitePageEdit;
