import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

import en from './locales/en';
import fr from './locales/fr';
import ro from './locales/ro';
import cs from './locales/cs';
import pl from './locales/pl';
import lt from './locales/lt';
import hu from './locales/hu';
import sk from './locales/sk';


i18n
    // .use(I18NextHttpBackend) // passes i18n down to react-i18next
    .use(I18nextBrowserLanguageDetector) // passes i18n down to react-i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng  : "en",
        detection    : {
            order: ['queryString', 'cookie'],
            cache: ['cookie'],

        },
        resources: {
            en,
            fr,
            ro,
            cs,
            pl,
            lt,
            hu,
            sk,
        },
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

export default i18n;
