import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API
});

let handleApiErrorResponse = () => {
};

const entityRole = JSON.parse(localStorage.getItem('entity'));
if (entityRole?.type_id) {
    api.defaults.headers.common['system-id'] = entityRole.type_id;
    api.defaults.headers.common['system-type'] = entityRole.type;
}

api.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
api.defaults.headers.post['Content-Type'] = 'application/json';
api.defaults.headers.post['Accept'] = 'application/json';
api.defaults.headers.post['Referrer-Policy'] = 'strict-origin-when-cross-origin';

const postRequest = (endpoint, payload) => {
    return new Promise((resolve, reject) => {
        api.post(endpoint, payload)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                handleApiErrorResponse(error);
                reject(error);
            });
    })

}

const getRequest = (endpoint, params, options = {}) => {
    const source = axios.CancelToken.source();

    const request = new Promise((resolve, reject) => {
        api.get(endpoint, {
            params: params != null ? params : {},
            cancelToken: source.token,
            ...options
        })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    reject();
                } else {
                    handleApiErrorResponse(error);
                    reject(error);
                }
            });
    });

    request.abort = function () {
        source.cancel();
    };

    return request;
}

const putRequest = (endpoint, payload) => {
    return new Promise((resolve, reject) => {
        api.put(endpoint, payload)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                handleApiErrorResponse(error);
                reject(error);
            });
    })
}

const deleteRequest = (endpoint) => {
    return new Promise((resolve, reject) => {
        api.delete(endpoint)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                handleApiErrorResponse(error);
                reject(error);
            });
    })
}

const setHandler = (handler) => handleApiErrorResponse = handler;
const setToken = (token) => api.defaults.headers.common['Authorization'] = 'Bearer ' + token;


export default api;

export {
    postRequest,
    getRequest,
    putRequest,
    deleteRequest,

    setHandler,
    setToken,

};
