import SelectVehicle from "../../views/pages/instant_booking/SelectVehicle";
import {FindSiteIcon} from "../../components/icons";
import GenerateQr from "../../views/pages/instant_booking/GenerateQr";
import Create from "../../views/pages/instant_booking/Create";
import SelectSite from "../../views/pages/instant_booking/SelectSite";
import Confirmation from "../../views/pages/instant_booking/Confirmation";
import PayMPark from "../../components/icons/PayMPark";

const instantBookingRoutes = [
    {
        path         : "/instant-booking/select-vehicle",
        component    : SelectVehicle,
        exact        : true,
        gate         : 'view_bookings',
        title        : 'Pay Via M Park',
        translate_key: 'instant_booking',
        icon         : <FindSiteIcon classDefined="menu-logo"/>,
        main         : false,
    },
    {
        path         : "/instant-booking/generate-qr",
        component    : GenerateQr,
        exact        : true,
        gate         : 'generate_qr_code',
        title        : 'Pay Via M Park',
        translate_key: 'pay_via_m_park',
        icon         : <PayMPark/>,
        main         : true,
    },
    {
        path         : "/instant-booking/select-site",
        component    : SelectSite,
        exact        : true,
        gate         : 'create_instant_booking',
        title        : 'Instant Booking',
        translate_key: 'instant_booking',
        icon         : <FindSiteIcon classDefined="menu-logo"/>,
        main         : false,
    },
    {
        path         : "/instant-booking/confirmation",
        component    : Confirmation,
        exact        : true,
        gate         : 'create_instant_booking',
        title        : 'Instant Booking',
        translate_key: 'instant_booking',
        icon         : <FindSiteIcon classDefined="menu-logo"/>,
        main         : false,
    },
    {
        path         : "/instant-booking/create",
        component    : Create,
        exact        : true,
        gate         : 'create_instant_booking',
        title        : 'Instant Booking',
        translate_key: 'instant_booking',
        icon         : <FindSiteIcon classDefined="menu-logo"/>,
        main         : false,
    },
]
export default instantBookingRoutes
