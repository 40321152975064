import Suppliers       from "../../views/pages/suppliers/Suppliers";
import SupplierSingle  from "../../views/pages/suppliers/SupplierSingle";
import SupplierEdit    from "../../views/pages/suppliers/SupplierEdit";
import SupplierCreate  from "../../views/pages/suppliers/SupplierCreate";
import SitesIcon       from "../../components/icons/SitesIcon";
import SuppliersIcon   from "../../components/icons/SuppliersIcon";
import ImportSuppliers from "../../views/pages/suppliers/ImportSuppliers";
import ExportPayouts   from "../../views/pages/suppliers/ExportPayouts";

const suppliersRoutes = [


    {
        path         : "/suppliers",
        component    : Suppliers,
        exact        : true,
        gate         : 'view_suppliers',
        title        : 'Suppliers',
        translate_key: 'suppliers',
        // icon         : 'bi-person',
        icon         : <SuppliersIcon/>,
        main         : true,
    },

    {
        path     : "/suppliers/edit/:id",
        component: SupplierEdit,
        exact    : true,
        gate     : 'view_suppliers',
        main     : false,
    },

    {
        path     : "/suppliers/export/payouts",
        component: ExportPayouts,
        exact    : true,
        gate     : 'view_suppliers',
        main     : false,
    },

    {
        path     : "/suppliers/create",
        component: SupplierCreate,
        exact    : true,
        gate     : 'view_suppliers',
        main     : false,
    },

    {
        path     : "/suppliers/import",
        component: ImportSuppliers,
        exact    : true,
        gate     : 'view_suppliers',
        main     : false,
    },

    {
        path     : "/suppliers/:id",
        component: SupplierSingle,
        exact    : true,
        gate     : 'view_suppliers',
        main     : false,
    },
]

export default suppliersRoutes;
