import {useTranslation}                                               from "react-i18next";
import {useAuth}                                                      from "@/services/Auth";
import {useParams}                                                    from "react-router-dom";
import React, {useEffect, useState}                                   from "react";
import LoadingPage                                                    from "@/components/LoadingPage";
import {Button, Card, Col, Container, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import PromotionForm                                                  from "./components/PromotionForm";
import SuccessDialog                                                  from "../../../components/Modals/SuccessDialog";
import * as moment                                                    from "moment";
import { Helmet } from "react-helmet";

function PromotionEdit(props) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const getRequest = auth.getRequest;
    const putRequest = auth.putRequest;
    let {id} = useParams();


    const [promotion, setPromotion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updatingPromotion, setUpdatingPromotion] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [site, setSite] = useState('');


    const [sites, setSites] = useState([]);


    useEffect(() => {
        getRequest('/promotions/' + id)
            .then(response => {
                setPromotion(response.data.promotion);
                //client data
                setSite(response.data.promotion.site);

                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error)
            })

    }, []);

    const onSubmit = (values) => {
        setUpdatingPromotion(true);
        putRequest(`/promotions/${promotion.id}`, values)
            .then(response => {
                setUpdatingPromotion(false);
                if (response.data.message && response.data.message === "OK") {
                    setUpdateSuccess(true);
                }
            })
            .catch(error => {
                setUpdatingPromotion(false);
                console.log(error);
            })
    };


    const handleClose = () => {
        setUpdateSuccess(false)
        props.history.push(`/promotions`)
    }

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('edit')} / {t('drawer_link_titles.promotion')} - {t('app')}</title>
                </Helmet>

                <SuccessDialog
                    show={updateSuccess}
                    message="success_dialog.promotion_updated"
                    handleClose={handleClose}
                />

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h4 className="text-primary">{promotion.message}</h4>
                </Card.Subtitle>
                <PromotionForm
                    initialValues={{
                        message        : promotion.message,
                        site_id        : promotion.site_id,
                        category_id: promotion.category_id,
                        discount       : promotion.discount,
                        discount_type  : promotion.discount_type,
                        start_at       : moment(promotion.start_at_original).format('YYYY-MM-DDTHH:mm'),
                        expire_at      : moment(promotion.expire_at_original).format('YYYY-MM-DDTHH:mm') ,
                    }}
                    loading={updatingPromotion}
                    buttonText="save_new_promotion"
                    siteList={sites}
                    promotionSite={site}
                    handleSubmit={onSubmit}
                />
            </Card>
        )
    )
}

export default PromotionEdit;
