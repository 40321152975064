import ReactPaginate from "react-paginate";

function Paginate({changePage, totalRecords, page, perPage }) {

    let pageCount = Math.ceil(totalRecords / (perPage || 15));

    if(pageCount <= 1)
    {
        return  <></>;
    }

    return <ReactPaginate
        forcePage={page}
        breakLabel="..."
        nextLabel=">"
        onPageChange={changePage}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName={"pagination justify-content-start justify-content-md-end"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
    />
}

export default Paginate
