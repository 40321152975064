export default {
    roles(auth, type, t) {
        if (auth.user.primary_role === 1) {
            if (type === 'client' || type === 'clients') {
                return [
                    {id: 6, name: t('roles.client_admin')},
                    {id: 11, name: t('roles.client_operator')},
                    {id: 7, name: t('roles.fleet_manager')},
                    {id: 8, name: t('roles.driver')},
                ]
            } else if (type === 'supplier' || type === 'suppliers') {
                return [
                    {id: 3, name: t('roles.supplier_admin')},
                    {id: 10, name: t('roles.supplier_operator')},
                    {id: 4, name: t('roles.site_manager')},
                    {id: 5, name: t('roles.operator')},
                ]
            } else if (type === 'site' || type === 'sites') {
                return [
                    {id: 4, name: t('roles.site_manager')},
                    {id: 5, name: t('roles.operator')},
                ]
            } else if (type === 'fleet' || type === 'fleets') {
                return [
                    {id: 7, name: t('roles.fleet_manager')},
                    {id: 8, name: t('roles.driver')},
                ]
            }
            return [
                {id: 1, name: t('roles.platform_admin')},
                {id: 2, name: t('roles.platform_finance')},
                {id: 9, name: t('roles.platform_operator')},
                {id: 3, name: t('roles.supplier_admin')},
                {id: 10, name: t('roles.supplier_operator')},
                {id: 4, name: t('roles.site_manager')},
                {id: 5, name: t('roles.operator')},
                {id: 6, name: t('roles.client_admin')},
                {id: 11, name: t('roles.client_operator')},
                {id: 7, name: t('roles.fleet_manager')},
                {id: 8, name: t('roles.driver')},
            ]
        } else if (auth.user.is_supplier_admin) {
            if (type === 'site' || type === 'sites') {
                return [
                    {id: 4, name: t('roles.site_manager')},
                    {id: 5, name: t('roles.operator')},
                ]
            }
            return [
                {id: 3, name: t('roles.supplier_admin')},
                {id: 4, name: t('roles.site_manager')},
                {id: 5, name: t('roles.operator')},
            ]
        } else if (auth.user.is_site_manager) {
            return [
                {id: 4, name: t('roles.site_manager')},
                {id: 5, name: t('roles.operator')},
            ]
        } else if (auth.user.primary_role === 6) {
            return [
                {id: 6, name: t('roles.client_admin')},
                {id: 7, name: t('roles.fleet_manager')},
                {id: 8, name: t('roles.driver')},
            ]
        } else if (auth.user.is_fleet_manager) {
            return [
                {id: 8, name: t('roles.driver')},
            ]
        }
    }
}
