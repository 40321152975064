import {Link, useLocation} from "react-router-dom";
import {Col, Row}          from "react-bootstrap";
import {useTranslation} from "react-i18next";
import FooterFrame      from "../../components/Elements/FooterFrame";

function Footer() {
    const location = useLocation();
    const {t, i18n} = useTranslation();
    return (
        <footer className={`footer py-3 px-0 d-md-block print-break-inside-avoid ${location.pathname === '/find-a-site' ? 'd-none' : ''}`}>
            <Row className="footer-logo">
                <Col className="col-12 d-flex justify-content-center align-items-center">
                    <img src="/images/m-park-by-motis-footer-logo.svg" style={{width: 200}}/>
                </Col>
            </Row>
            <div className="footer-contents d-print-none">
                <Row className="text-white my-2 px-0 mx-0" style={{width: '100%'}}>
                    <Col className="col-12 col-sm-4 text-sm-start text-center">
                        <p className="text-white mb-0">©&nbsp;
                            <span>Motis</span>
                            &nbsp;{(new Date()).getFullYear()}</p>
                    </Col>
                    <Col className="col-12  col-sm-4 text-center">
                        <Link to="#" className="text-white text-nowrap">{t('terms_condition')}</Link>
                    </Col>
                    <Col className="col-12  col-sm-4 text-sm-end text-center">

                        <a href="//staxogroup.com?utm_source=website_footer" target="_blank" className="text-light">
                            {'SITE BY STAXO'}
                        </a>
                    </Col>
                </Row>
            </div>
        </footer>
    )
}

export default Footer;
