import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import React, {useState} from "react";
import {useAuth} from "@/services/Auth";
import {useTranslation} from "react-i18next";

function ToggleFavourite({site, updateResponse}) {
    let auth = useAuth();
    const {t, i18n} = useTranslation();
    const putRequest = auth.putRequest;

    const [favourite, setFavourite] = useState(site.favourite);

    const updateFavourite = () => {
        putRequest(`/favourites/${site.id}`, {
            add_favourite: !site.favourite
        })
            .then(response => {
                if (updateResponse !== undefined) {
                    updateResponse();
                }
                setFavourite(!favourite);
            })
            .catch(error => {

            })
    }
    return <Button variant={favourite ? 'outline-danger' : 'outline-primary'} className="mx-1" onClick={() => updateFavourite()}>
        {favourite ? t('remove_favourite') : t('add_favourite')}
    </Button>
}

export default ToggleFavourite;
