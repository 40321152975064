import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import * as moment                    from "moment";
import Select                         from "react-select";
import AsyncSelect                    from "react-select/async";
import React, { useEffect, useState, useRef }   from "react";
import {useTranslation}               from "react-i18next";
import {useAuth}                      from "@/services/Auth";
import { Cities, Countries }          from "../../../../services";
import {useForm}                      from "react-hook-form";

function Filters({options,searching, ...props}) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const formatRecord = (record) => {
        const  object = {...record};

        object.value = record.id;
        object.label = record.name;

        return object;
    };

    const formatSupplier= (record) => {
        const  object = {...record};

        object.value = record.id;
        object.label = record.company_name;

        return object;
    };

    const getSitesRequest = useRef(null);
    const getSuppliersRequest = useRef(null);

    const onSubmit = (values) => {
        let updatedValues = values;

        if (values.site && typeof values.site === 'object') {
            updatedValues.site = values.site.value
        }
        if (values.supplier && typeof values.supplier === 'object') {
            updatedValues.supplier = values.supplier.value
        }

        props.onSubmit(updatedValues)
    };

    const {
              handleSubmit,
              register,
              watch,
              setValue
          } = useForm({
        defaultValues: {
            ...props.values
        }
    });


    const [advanceFilters, setAdvanceFilters] = useState(false);

    useEffect(() => {
        register('city');
        register('supplier');
        register('site');
        register('product');
    }, []);

    const getSites = (search) => {
        getSitesRequest.current && getSitesRequest?.current?.abort && getSitesRequest.current.abort();

        return new Promise((resolve, reject) => {
            getSitesRequest.current = auth.getRequest('sites', { search, managing: true });

            getSitesRequest.current.then(response => {
                    resolve(response.data.sites.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    const getSuppliers = (search) => {
        getSuppliersRequest.current && getSuppliersRequest?.current?.abort && getSuppliersRequest.current.abort();

        return new Promise((resolve, reject) => {
            getSuppliersRequest.current = auth.getRequest('suppliers', { search });

            getSuppliersRequest.current.then(response => {
                    resolve(response.data.suppliers.map(formatSupplier));
                })
                .catch(error => reject(error))
        });
    };

    const supplier = watch('supplier');
    const site = watch('site');
    const product = watch('product');


    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="my-4">
                <Col sm={4} className="col-12">
                    <Form.Control
                        type="text"
                        {...register('search')}
                        placeholder={t('search_for_anything')}
                        />
                </Col>
                <Col sm={3} className="col-12 mt-md-0 mt-3">
                    <div className="d-grid gap-2">
                    {
                        !searching ?
                            <Button type="submit" variant="success" className="w-100">
                                {advanceFilters ? t('filters_labels.apply') : t('search')}
                            </Button>
                            :
                            <Button variant="success" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="mx-2">{t('please_wait')}</span>
                            </Button>
                    }
                    </div>
                </Col>
                <Col sm={4} className="col-12">
                    <Button variant="link" className="my-md-0 my-3" onClick={(e) => {
                        e.preventDefault();
                        setAdvanceFilters(!advanceFilters)
                    }}>{!advanceFilters ? t('filters_labels.show_advance_filters') : t('filters_labels.hide_advance_filters')}</Button>
                </Col>
            </Row>
            {advanceFilters && <>
                <Row className="my-md-4 my-2">
                    <Col sm={6}>
                        <Form.Label className="mb-2">
                            {t('filters_labels.active_date')}
                        </Form.Label>

                        <div className="d-flex align-items-center">
                            <Form.Control
                                placeholder={t('from')}
                                type="date"
                                {...register('active_from')}
                                />

                            <span className="mx-2">{t('to')}</span>

                            <Form.Control
                                placeholder={t('active_to')}
                                type="date"
                                {...register('to')}
                                />
                        </div>
                    </Col>

                    <Col sm={6}>
                        <Form.Label className="mb-2">
                            {t('filters_labels.expire_date')}
                        </Form.Label>

                        <div className="d-flex align-items-center">
                            <Form.Control
                                placeholder={t('from')}
                                type="date"
                                {...register('expire_from')}
                                />

                            <span className="mx-2">{t('to')}</span>

                            <Form.Control
                                placeholder={t('to')}
                                type="date"
                                {...register('expire_to')}
                                />
                        </div>
                    </Col>

                    {!!(auth.user.is_platform_admin || auth.user.is_platform_operator) &&
                        <Col md={3}>
                            <Form.Label className="mb-2">
                                {t('filters_labels.filter_by_suppliers')}
                            </Form.Label>

                            <AsyncSelect
                                className="react-select"
                                isClearable={true}
                                value={supplier}
                                onChange={(e) => setValue('supplier', e)}
                                placeholder={t('supplier')}
                                defaultOptions={true}
                                loadOptions={getSuppliers} />
                        </Col>
                    }

                    <Col md={3}>
                        <Form.Label className="mb-2">
                            {t('filters_labels.filter_by_site')}
                        </Form.Label>

                        <AsyncSelect
                            className="react-select"
                            isClearable={true}
                            value={site}
                            onChange={(e) => setValue('site', e)}
                            placeholder={t('site')}
                            defaultOptions={true}
                            loadOptions={getSites} />
                    </Col>

                    {site?.services?.map && (
                        <Col md={3}>
                            <Form.Label className="mb-2">
                                {t('filters_labels.filter_by_product')}
                            </Form.Label>

                            <Select
                                className="react-select"
                                isClearable={true}
                                value={product}
                                onChange={(e) => setValue('product', e)}
                                placeholder={t('product')}
                                options={site.services.map(formatRecord)} />
                        </Col>
                    )}

                </Row>
            </>}
        </Form>
    )
}

export default Filters;
