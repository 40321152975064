import Login                 from "../../views/auth/Login";
import SignUp                from "../../views/auth/SignUp";
import ForgotPasswordRequest from "../../views/auth/ForgotPasswordRequest";
import ResetPassword         from "../../views/auth/ResetPassword";
import AccountStopped        from "../../views/auth/AccountStopped";

const authRoutes = [
    {
        path     : "/login",
        component: Login,
        public   : true,
        exact    : true,
    },
    {
        path     : "/login/:sentMail",
        component: Login,
        public   : true,
        exact    : true,
    },
    // {
    //     path     : "/sign-up",
    //     component: SignUp,
    //     public   : true,
    //     exact    : true,
    // },
    {
        path     : "/forgot-password",
        component: ForgotPasswordRequest,
        public   : true,
        exact    : true,
    },
    {
        path     : "/set-your-password",
        component: ForgotPasswordRequest,
        public   : true,
        exact    : true,
    },
    {
        path     : "/set-your-password/:sentMail/:sentCode",
        component: ForgotPasswordRequest,
        public   : true,
        exact    : true,
    },
    {
        path     : "/reset-password/:token",
        component: ResetPassword,
        public   : true,
        exact    : true,
    },
    {
        path     : "/account-stopped",
        component: AccountStopped,
        public   : true,
        exact    : true,
    },
]
export default authRoutes;
