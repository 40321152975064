function ReportsIcon({classDefined}) {
    return (
        <div className={`custom-logo ${classDefined}`}>
            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.4 0.199951H16C15.6818 0.199951 15.3765 0.326379 15.1515 0.551423C14.9265 0.776467 14.8 1.08169 14.8 1.39995V21.7904H19.6V1.39995C19.6 1.08169 19.4736 0.776467 19.2486 0.551423C19.0235 0.326379 18.7183 0.199951 18.4 0.199951ZM11.2 7.39995H8.80002C8.48176 7.39995 8.17654 7.52638 7.9515 7.75142C7.72645 7.97647 7.60002 8.28169 7.60002 8.59995V21.7904H12.4V8.59995C12.4 8.28169 12.2736 7.97647 12.0486 7.75142C11.8235 7.52638 11.5183 7.39995 11.2 7.39995ZM4.00002 14.6H1.60002C1.28176 14.6 0.97654 14.7264 0.751496 14.9514C0.526453 15.1765 0.400024 15.4817 0.400024 15.8V21.7904H5.20002V15.8C5.20002 15.4817 5.0736 15.1765 4.84855 14.9514C4.62351 14.7264 4.31828 14.6 4.00002 14.6Z" fill="white"/>
            </svg>

        </div>
    );
}

export default ReportsIcon;
