import React, { useEffect, useState, useCallback }   from "react";
import Wysiwyg from "@/components/Inputs/Wysiwyg";
import DisplayWysiwyg from "@/components/Elements/DisplayWysiwyg";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Pricing({ site, edit, onChange, ...props })
{
  /**
   * IMPORTANT!!!!
   * Make sure to update the counter part in the laravel blade template
   */

  const { t } = useTranslation();

  return (
    <section className="block block-pricing py-3 py-xl-4">
      <div className="container py-3 py-xl-5">
        {edit && <Wysiwyg value={props.content} onChange={event => onChange({ ...props, content: event.target.value })} /> || <DisplayWysiwyg html={props.content || ''} />}

        <Table>
          <thead>
            <tr>
              <td colSpan="100%">
                {t('parking_prices_vat')}
              </td>
            </tr>
          </thead>

          <tbody>
            {
              ( site.parking_prices && site.parking_prices.length !== 0)  ? site.parking_prices.sort((a, b) => a.up_to_hours - b.up_to_hours)
                      .map((parkingPrices, index) => (
              <tr key={index}>
                  <td align="right">
                      {
                          parkingPrices.price_with_vat && (
                              <>
                                  ({parkingPrices.price_with_vat.toFixed(2) + ' ' + site.currency}
                                  <span
                                      className="text-black-50 ms-auto small"> {t('incl_vat')}</span>)
                              </>

                          )
                          ||
                          <span className='font-weight-bold'>{t('free')}</span>}
                  </td>
              <td>
                 {parkingPrices.price_with_vat && '/ ' + t(parkingPrices.per_period_type == 'Hour' ?  'n_hours' : 'n_days', { count: parkingPrices.period_value })}
              </td>
              <td>
                  {index + 1 < site.parking_prices.length && t('upto') + ' ' + t(parkingPrices.up_to_period == 'Hour' ?  'n_hours' : 'n_days', { count: parkingPrices.up_to_value })}

              </td>
          </tr>
          )) : (
            <tr>
              <td colSpan={5} className="text-center text-danger">
                {t('no_parking_prices_defined')}
              </td>
            </tr>
          )}
          </tbody>
        </Table>
      </div>
    </section>
  );
}

export default Pricing;
