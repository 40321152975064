import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import LoadingPage from "@/components/LoadingPage";
import {Badge, Button, Card, Col, Container, Form, Row, Table, Modal} from "react-bootstrap";
import {useAuth} from "@/services/Auth";
import {Link, useParams} from "react-router-dom";
import NumberFormat from 'react-number-format';
import ConfirmDialog from "../../../components/Modals/ConformDialog";
import { Helmet } from "react-helmet";
import * as moment from "moment-timezone";
import BookingRequestOptions from "../bookings/components/RequestOptions";

function OrderSingle() {
    const {t, i18n} = useTranslation();

    let auth = useAuth();
    let {id} = useParams();

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [canUpdate, setCanUpdate] = useState(false);
    const [transactions, setTransactions] = useState([]);

    const getOrder = () => {
        auth.getRequest('/orders/' + id)
            .then(response => {
                setOrder(response.data.order);
                setCanUpdate(response.data.can_update);
                //client data

                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error)
            })
    }

    const getTransactions = () => {
        auth.getRequest(`/orders/${id}/transactions`)
            .then(response => {
                setTransactions(response.data.transactions.filter(_ => Object.keys(_).length > 0));
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        getOrder();
        if( auth.user.is_platform_admin ||  auth.user.is_client_manager || auth.user.is_fleet_manager  ){
            getTransactions();
        }
    }, []);

    if(!order || loading)
    {
        return <LoadingPage/>;
    }

    var
    fleet = order?.vehicle?.fleet || order.fleet,
    net_total = 0,
    gross_total = 0,
    billing_total = 0,
    vat_total;

    order.order_items.forEach(orderItem => {
        net_total += parseFloat(orderItem.net_amount);
        gross_total += parseFloat(orderItem.total_amount);
        billing_total += parseFloat(orderItem.billing_total);
    });

    vat_total = gross_total - net_total;

    return (
            <div>
                <Helmet>
                    <title>#{order.order_number + ''} {order.booking?.booking_number && `(${order.booking.booking_number})` || ''} / {t('drawer_link_titles.orders')} - {t('app')}</title>
                </Helmet>

                <Card className="mx-2 my-2 p-2">
                    <Card.Subtitle className="d-flex justify-content-between flex-md-row flex-column" style={{width: '100%'}}>
                        <h4 className="text-primary">{t('order_id')} - {order.order_number} {order.booking?.booking_number && `(${order.booking.booking_number})` || ''}</h4>
                        {
                            (canUpdate && order.status === 'pending')
                            &&
                            <BookingRequestOptions {...{ booking: order.booking, updatedBooking: getOrder, setLoading }} />
                        }
                    </Card.Subtitle>

                    <Row className="mt-2">
                        {fleet && (
                            <Col md={3} sm={4} className="col-6">
                                <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                    <Form.Label column className="col-12">
                                        <small>{t('fleet_name')}</small>
                                    </Form.Label>
                                    <Col className="col-12">
                                        {fleet.id && (
                                            <Link to={`/fleets/${fleet.id}`} className="h6">
                                                {fleet.name}
                                            </Link>
                                        ) || (
                                            <h6>{fleet.name}</h6>
                                        )}
                                    </Col>
                                </Form.Group>
                            </Col>
                        )}
                        {order.booking?.user && (
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small>{t('driver')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>
                                        {order.booking?.user?.id && <Link to={`/users/${order.booking?.user?.id}`}>{order.booking?.user?.name}</Link> || order.booking?.user?.name}
                                    </h6>
                                </Col>
                            </Form.Group>
                        </Col>
                        ) || ''}

                        {order.site && (
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small> {t('site')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>
                                        {order.site?.id && <Link to={`/sites/${order.site?.id}`}>{order.site?.name}</Link> || order.site?.name}
                                    </h6>
                                </Col>
                            </Form.Group>
                        </Col>
                        ) || ''}

                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small> {t('date')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>{order.created_at && moment.utc(order.created_at).tz(timezone).format(t('formats.date'))}</h6>
                                </Col>
                            </Form.Group>
                        </Col>

                        {order.vehicle && (
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small> {t('vehicle_registration_number')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>{order.vehicle.registration_number}</h6>
                                </Col>
                            </Form.Group>
                        </Col>
                        ) || ''}
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small>
                                        {t('status')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>
                                        <Badge pill bg={order.status === 'pending' ? 'warning' : (order.status === 'disapproved' || order.status === 'rejected') ? 'danger' : 'success'} text={order.status === 'pending' ? 'dark' : 'light'}>
                                            {order?.booking?.status === 'completed' ? t(`order_status.${order?.booking?.status}`) : t(`order_status.${order.status}`)}
                                        </Badge>
                                    </h6>
                                </Col>
                            </Form.Group>
                        </Col>

                        {order.site && (
                        <>
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small>{t('latitude')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>{order.site.latitude}</h6>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small> {t('longitude')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>{order.site.longitude}</h6>
                                </Col>
                            </Form.Group>
                        </Col>
                        </>
                        ) || ''}
                        {order.booking ? (
                        <>
                            {typeof order.booking.has_trailer !== 'undefined' && (
                                <Col md={3} sm={4} className="col-6">
                                    <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                        <Form.Label column className="col-12">
                                            <small> {t('trailer')}</small>
                                        </Form.Label>
                                        <Col className="col-12">
                                            <h6>{order.booking.has_trailer && t('yes') || t('no')}</h6>
                                        </Col>
                                    </Form.Group>
                                </Col>
                            ) || ''}
                        {order.booking.from_datetime && <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextFrom">
                                <Form.Label column className="col-12">
                                    <small> {t('from')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>{moment.utc(order.booking.from_datetime).tz(timezone).format(t('formats.short_datetime'))}</h6>
                                </Col>
                            </Form.Group>
                        </Col>}
                        {order.booking.to_datetime && <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextTo">
                                <Form.Label column className="col-12">
                                    <small> {t('to')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>{moment.utc(order.booking.to_datetime).tz(timezone).format(t('formats.short_datetime'))}</h6>
                                </Col>
                            </Form.Group>
                        </Col>}
                            {order.booking.type_str && <Col md={3} sm={4} className="col-6">
                                <Form.Group as={Row} className="mb-2" controlId="formPlaintextTo">
                                    <Form.Label column className="col-12">
                                        <small> {t('booking_type')}</small>
                                    </Form.Label>
                                    <Col className="col-12">
                                        <h6>{t(`booking_types.${order.booking.type_str}`)}</h6>
                                    </Col>
                                </Form.Group>
                            </Col>}
                        </>
                        ) : order.is_subscription ?
                          <Col md={3} sm={4} className="col-6">
                              <Form.Group as={Row} className="mb-2" controlId="formPlaintextTo">
                                  <Form.Label column className="col-12">
                                      <small> {t('booking_type')}</small>
                                  </Form.Label>
                                  <Col className="col-12">
                                      <h6>{t(`booking_types.subscription`)}</h6>
                                  </Col>
                              </Form.Group>
                          </Col>: null
                        }


                    </Row>
                    <Row className="mt-2">
                        <Col className="col-12">
                            <p><small>{t('items')}</small></p>
                            <Table striped responsive bordered hover size="sm" className="">
                                <thead>
                                <tr>
                                    <th>
                                        <div style={{minWidth: "150px"}}>{t('description')}</div>
                                    </th>
                                    <th>{t('used')}</th>
                                    <th className="text-end">
                                        <div style={{minWidth: "100px"}}>{t('net_amount')}</div>
                                    </th>
                                    <th className="text-end">
                                        <div style={{minWidth: "100px"}}>{t('vat_rate')}</div>
                                    </th>
                                    <th className="text-end">
                                        <div style={{minWidth: "100px"}}>{t('vat')}</div>
                                    </th>
                                    <th className="text-end">
                                        <div style={{minWidth: "100px"}}>{t('total')}</div>
                                    </th>

                                    {order.currency != order.billing_currency && (
                                        <th className="text-end">
                                            <div style={{minWidth: "100px"}}>{order.billing_currency}</div>
                                        </th>
                                    ) || ''}
                                </tr>
                                </thead>
                                <tbody>
                                {order.order_items.map((orderItem, index) => (
                                            <tr key={index}>
                                                <td>{orderItem.name || t('parking')}
                                                    {' '}
                                                    {orderItem.quantity > 1 && ' x ' + orderItem.quantity || ''}
                                                </td>
                                                <td><Form.Check defaultChecked={orderItem.item_claimed_at != null} type="checkbox" disabled/></td>
                                                <td className="text-end">
                                                    <NumberFormat value={orderItem.net_amount} displayType={'text'} thousandSeparator={true} suffix={` ${order.currency || 'GBP'}`} decimalScale={2} fixedDecimalScale={true}/>
                                                </td>
                                                <td className="text-end">
                                                    <NumberFormat value={100 * ((orderItem.total_amount / orderItem.net_amount) - 1)} displayType={'text'} thousandSeparator={true} suffix={` %`} decimalScale={2} fixedDecimalScale={true}/>
                                                </td>
                                                <td className="text-end">
                                                    <NumberFormat value={orderItem.total_amount - orderItem.net_amount} displayType={'text'} thousandSeparator={true} suffix={` ${order.currency || 'GBP'}`} decimalScale={2} fixedDecimalScale={true}/>
                                                </td>
                                                <td className="text-end">
                                                    <NumberFormat value={orderItem.total_amount} displayType={'text'} thousandSeparator={true} suffix={` ${order.currency || 'GBP'}`} decimalScale={2} fixedDecimalScale={true}/>
                                                </td>

                                                {order.currency != order.billing_currency && (
                                                    <td className="text-end">
                                                        <NumberFormat value={orderItem.billing_total} displayType={'text'} thousandSeparator={true} suffix={` ${order.billing_currency || 'GBP'}`} decimalScale={2} fixedDecimalScale={true}/>
                                                    </td>
                                                ) || ''}

                                            </tr>
                                ))}
                                    <tr>
                                        <th>{t('total')}</th>
                                        <th>&nbsp;</th>
                                        <th className="text-end">
                                            <NumberFormat value={net_total} displayType={'text'} thousandSeparator={true} suffix={` ${order.currency || 'GBP'}`} decimalScale={2} fixedDecimalScale={true}/>
                                        </th>
                                        <th>&nbsp;</th>
                                        <th className="text-end">
                                            <NumberFormat value={vat_total} displayType={'text'} thousandSeparator={true} suffix={` ${order.currency || 'GBP'}`} decimalScale={2} fixedDecimalScale={true}/>
                                        </th>
                                        <th className="text-end">
                                            <NumberFormat value={gross_total} displayType={'text'} thousandSeparator={true} suffix={` ${order.currency || 'GBP'}`} decimalScale={2} fixedDecimalScale={true}/>
                                        </th>

                                        {order.currency != order.billing_currency && (
                                            <th className="text-end">
                                                <NumberFormat value={billing_total} displayType={'text'} thousandSeparator={true} suffix={` ${order.billing_currency || 'GBP'}`} decimalScale={2} fixedDecimalScale={true}/>
                                            </th>
                                        ) || ''}
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {
                        transactions.length !== 0 &&
                        <Row className="mt-2">
                            <Col className="col-12">
                                <p><small>{ t('transactions') }</small></p>
                                <Table striped responsive bordered hover size="sm" className="">
                                    <thead>
                                    <tr>
                                        <th>
                                            <div style={{minWidth: "150px"}}>{t('date')}</div>
                                        </th>
                                        <th>
                                            <div style={{minWidth: "150px"}}>{t('user')}</div>
                                        </th>
                                        <th className="">
                                            <div style={{minWidth: "100px"}}>{t('type')}</div>
                                        </th>
                                        <th className="text-end">
                                            <div style={{minWidth: "100px"}}>{t('amount')}</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {transactions.map((transaction, index) => (
                                        <tr key={index}>
                                            <td>{ moment(transaction.created_at).format(t('formats.short_datetime')) }</td>
                                            <td>{ transaction.user?.name }</td>
                                            <td>{ transaction.payment_method && `${t('card')} ${transaction.payment_method.number}` || transaction.supplier_id && t('payout') || t('credit') }</td>
                                            <td className="text-end"><NumberFormat value={transaction.amount} displayType={'text'} thousandSeparator={true} suffix={` ${transaction.currency || 'GBP'}`} decimalScale={2} fixedDecimalScale={true}/></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    }
                </Card>
            </div>
    )
}

export default OrderSingle;
