import {useTranslation}                               from "react-i18next";
import {useAuth}                                      from "@/services/Auth";
import {Link, useParams}                              from "react-router-dom";
import React, {useState, useEffect}                   from "react";
import {Button, Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import LoadingButton                                  from "@/components/LoadingButton";
import LoadingPage                                    from "@/components/LoadingPage";
import { Helmet }                                     from "react-helmet";
import * as csv                                       from "csv-stringify/lib/sync";
import FileSaver                                      from "file-saver";
import ImportUploadForm                               from "@/components/Forms/ImportUploadForm";

function ImportClients() {
    const {t, i18n: i18n} = useTranslation();
    let auth = useAuth();
    let {id} = useParams();
    const getRequest = auth.getRequest;
    const importEndpoint = '/clients/import';

    const [success, setSuccess] = useState(false);
    const [downloadingFile, setDownloadingFile] = useState(false);
    const [importSchema, setImportSchema] = useState(null);

    const downloadSampleFile = () => {
        let headers = importSchema.map(column => t(column));
        setDownloadingFile(true);
        getRequest('/clients/import-sample')
            .then(response => {
                const records = response.data.sample_records;

                records.unshift(headers);

                const data = "\uFEFF" + csv.stringify(records, {});
                const blob = new Blob([data], {type: 'text/plain;charset=utf-8', encoding: 'UTF-8'});

                FileSaver.saveAs(blob, 'clients.csv', {});
                setDownloadingFile(false);
            })
            .catch(error => {

                setDownloadingFile(false);
            })
    }

    useEffect(() => {
        if(importSchema === null)
        {
            let request = getRequest(importEndpoint);

            request
                .then(response => {
                    setImportSchema(Object.keys(response.data.schema));
                })
                .catch(error => {
                })

            return () => request?.abort && request.abort();

        }
    }, [importSchema]);

    return (
        <div>
            <Helmet>
                <title>{t('import')} / {t('drawer_link_titles.clients')} - {t('app')}</title>
            </Helmet>

            {
                success &&
                <div className="d-flex justify-content-center align-items-center" style={{minHeight: 400}}>
                    <Card border="light">
                        <Card.Body className="text-center">
                            <i className="bi bi-check-circle" style={{fontSize: "10rem"}}/>
                            <Card.Title>{t('clients_imported')}</Card.Title>
                            <Card.Text>
                                {t('all_clients_are_imported')}
                            </Card.Text>
                            <div className="d-grid gap-2">
                                <Link variant="primary" className="btn btn-primary" to={`/clients`}>{t('go_to_clients')}</Link>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                !success &&
                <Card className="mx-2 my-2 p-2">

                    <Card.Body className="px-2">
                        <Row>
                            <Col className="col-12 mb-4">
                                <h5>{t('choose_csv_file_to_upload')}</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12 mb-4 d-flex justify-content-between">
                                <h6>{t('csv_file_should_contain_the_following_columns')}</h6>
                                <LoadingButton loading={downloadingFile} variant="success" titleTranslationKey="download_sample_file" onSubmit={() => downloadSampleFile()}/>
                            </Col>
                            <Col className="col-12 mb-4">
                                {importSchema && (<Table striped bordered hover className="mt-1">
                                    <thead>
                                    <tr>
                                        <th>{t('column')}</th>
                                        <th>{t('example')}</th>
                                        <th>{t('notes')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        importSchema.includes('company_name') &&
                                        <tr>
                                            <td>{t('company_name')}</td>
                                            <td>Haulage Ltd</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('primary_contact') &&
                                        <tr>
                                            <td>{t('primary_contact')}</td>
                                            <td>John Doe</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('email_address') &&
                                        <tr>
                                            <td>{t('email_address')}</td>
                                            <td>john.doe@email.com</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('phone_number') &&
                                        <tr>
                                            <td>{t('phone_number')}</td>
                                            <td>john.doe@email.com</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('account_status') &&
                                        <tr>
                                            <td>{t('account_status')}</td>
                                            <td>{t('active')}</td>
                                            <td>
                                                {t('options')}
                                                <ul>
                                                    <li>{t('active')}</li>
                                                    <li>{t('stopped')}</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('account_type') &&
                                        <tr>
                                            <td>{t('account_type')}</td>
                                            <td>{t('card')}</td>
                                            <td>
                                                {t('options')}
                                                <ul>
                                                    <li>{t('card')}</li>
                                                    <li>{t('credit')}</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('account_number') &&
                                        <tr>
                                            <td>{t('account_number')}</td>
                                            <td>123456789</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('client_payment_terms') &&
                                        <tr>
                                            <td>{t('client_payment_terms')}</td>
                                            <td>DOI</td>
                                            <td>
                                                {t('options')}
                                                <ul>
                                                    <li>DOI</li>
                                                    <li>EOM</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('client_payment_days') &&
                                        <tr>
                                            <td>{t('payment_terms_days')}</td>
                                            <td>14</td>
                                            <td>
                                                {t('options')}
                                                <ul>
                                                    <li>7</li>
                                                    <li>14</li>
                                                    <li>30</li>
                                                    <li>45</li>
                                                    <li>60</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('currency') &&
                                        <tr>
                                            <td>{t('currency')}</td>
                                            <td>GBP</td>
                                            <td>
                                                {t('options')}
                                                <ul>
                                                    <li>GBP</li>
                                                    <li>EUR</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('country') &&
                                        <tr>
                                            <td>{t('country')}</td>
                                            <td>&nbsp;</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('1st_line_address') &&
                                        <tr>
                                            <td>{t('first_line_address')}</td>
                                            <td>&nbsp;</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('2nd_line_address') &&
                                        <tr>
                                            <td>{t('address_line_2')}</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('city') &&
                                        <tr>
                                            <td>{t('town_city')}</td>
                                            <td>&nbsp;</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('county') &&
                                        <tr>
                                            <td>{t('county')}</td>
                                            <td>&nbsp;</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('postcode') &&
                                        <tr>
                                            <td>{t('post_code')}</td>
                                            <td>&nbsp;</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    </tbody>
                                </Table>)
                                || <LoadingPage/>
                                }
                            </Col>

                        </Row>
                        <ImportUploadForm
                            importSchema={importSchema}
                            endpoint={importEndpoint}
                            onSuccess={()=>{setSuccess(true)}}
                        ></ImportUploadForm>
                    </Card.Body>
                </Card>
            }
        </div>
    )
}

export default ImportClients;
