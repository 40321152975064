import { getRequest } from "./Api";

let booking_types_request;
let booking_types;

export default {
  get: function () {
    return new Promise((resolve, reject) => {

      if(booking_types)
      {
        resolve(booking_types);
      }
      else
      {
        if(!booking_types_request)
        {
          booking_types_request = getRequest('/bookings/booking-types');
        }

        booking_types_request
          .then((response) => {
            booking_types = response.data.booking_types;

            resolve(booking_types);
          })
          .catch(error => reject(error));
      }
    });
  }

};
