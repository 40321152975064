import { getRequest } from "./Api";

let countries_request;
let countries;

let countries_active_request;
let countries_active;

export default {
    get: function () {
        return new Promise((resolve, reject) => {

      if(countries)
      {
        resolve(countries);
      }
      else
      {
        if(!countries_request)
        {
          countries_request = getRequest('/countries')
        }

        countries_request
          .then((response) => {
            countries = response.data.countries;

            resolve(countries);
          })
          .catch(error => reject(error));
      }
    });
        },
    active: function () {
      return new Promise((resolve, reject) => {

        if(countries_active)
        {
          resolve(countries_active);
        }
        else
        {
          if(!countries_active_request)
          {
            countries_active_request = getRequest('/countries?active=true')
          }

          countries_active_request
            .then((response) => {
              countries_active = response.data.countries;

              resolve(countries_active);
            })
            .catch(error => reject(error));
        }
      });
    }

};
