import ScanQRCode           from "../../views/pages/qr_code/ScanQRCode";
import ScanIcon             from "../../components/icons/ScanIcon";

const qrCodeRoutes = [
    {
        path         : "/scan-qr-code",
        component    : ScanQRCode,
        exact        : true,
        gate         : 'view_scan_qr_code',
        title        : 'Scan QR Code',
        translate_key: 'scan_qr_code',
        // icon         : 'bi-upc-scan',
        icon         : <ScanIcon/>,
        main         : true,
    },
]

export default qrCodeRoutes;
