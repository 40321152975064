import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";


function DisplayAttachment({ attachment }) {
    const { t, i18n } = useTranslation();
    const [img, setImg] = useState(null);

    const onImgClick = (event, attachment) => {
        setImg(event.target.src);
        setImg(attachment.url);
    };

    return (
        <>
            <div className="attachments">
                <button className="btn btn-primary btn-sm" onClick={(e) => onImgClick(e, attachment)}>{t('compliance.view_image')}</button>
            </div>

            <Modal show={!!img} onHide={_ => setImg(null)} fullscreen={true}>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body className="d-flex align-items-center justify-content-center">
                    <img src={img} style={{ maxWidth: '100%' }} />
                </Modal.Body>

            </Modal>
        </>
    );
}


export default DisplayAttachment;
