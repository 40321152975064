import Wysiwyg from "@/components/Inputs/Wysiwyg";
import { useHistory } from "react-router-dom";
import DisplayWysiwyg from "@/components/Elements/DisplayWysiwyg";
import Buttons from "../components/Buttons";
import { useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services";
import JourneyDetails from '../components/JourneyDetails';

function Details({ site, edit, onChange, ...props })
{
  /**
   * IMPORTANT!!!!
   * Make sure to update the counter part in the laravel blade template
   */

  const auth = useAuth();
  const history = useHistory();
  const { t } = useTranslation();

  const onClick = () => {
    if(!site.prebookable)
    {
      window.location = `https://google.com/maps/dir/?api=1&destination=${site.latitude},${site.longitude}`;
    }
    else
    if(site.id)
    {
      if(auth.user?.is_driver || auth.user?.is_client_admin || auth.user?.is_fleet_manager)
      {
        history.push('/bookings/create', {
          site,
          booking: {
            site_id: site.id,
          }
        })
      }
      else
      {
        history.push('/bookings/site-card/' + site.id);
      }
    }
    else
    {
      history.push('/login', {
        next: {
          pathname: '/pages/' + site.slug,
          state: {
            book: true,
          },
        }
      });
    }
  };

  return (
    <section className={`block block-details media-${props.media_left ? 'left' : 'right'}`}>
      <div className="container-fluid">
        <Row>
          <Col md={6} lg={7} className="media-container p-0">
            {edit && (
              <a href="javascript:void(0);" className="edit-image" onClick={_ => edit.selectImage && edit.selectImage(false).then((images) => {
                  onChange({ ...props, image: images[0] })
                })}>
                <img width="100%" src={props.image?.url || "data:image/svg+xml,%3Csvg width='134' height='94' viewBox='0 0 134 94' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='134' height='94' rx='8' fill='%2300458C'/%3E%3C/svg%3E%0A"} />
              </a>
            ) || (
              <img width="100%" src={props.image?.url || "data:image/svg+xml,%3Csvg width='134' height='94' viewBox='0 0 134 94' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='134' height='94' rx='8' fill='%2300458C'/%3E%3C/svg%3E%0A"} />
            )}
          </Col>

          <Col md={6} lg className={`content-container d-md-flex flex-column py-3 my-2 m-container-lg-${props.media_left ? 'right' : 'left'} `}>
            <div className="logo-container">
              {edit && (
                <a href="javascript:void(0);" className="edit-image" onClick={_ => edit.selectImage && edit.selectImage(false).then((images) => {
                    onChange({ ...props, logo: images[0] })
                  })}>
                  <img src={props.logo?.url || "data:image/svg+xml,%3Csvg width='134' height='134' viewBox='0 0 134 134' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='134' height='134' rx='8' fill='%2300458C'/%3E%3C/svg%3E%0A"} />
                </a>
              ) || props.logo?.url && (
                <img src={props.logo?.url} />
              ) || ''}
            </div>


            <h1 className="text-primary mt-auto">{site.name}</h1>

            <div className="details-info-box">
              <JourneyDetails coordinates={site} value={site.journey} className="text-muted my-2 py-3 " />

              {site.facilities.find(_ => _.name == "24/7") && (
                <div className="d-flex align-items-center mb-2">
                  <span className="bi bi-clock-history me-2" />
                  {t('open_24_hours')}
                </div>
              ) || ''}

              <a href={`https://google.com/maps/dir/?api=1&destination=${site.latitude},${site.longitude}`} target="_blank" className="d-flex">
                <span className="bi bi-geo-alt-fill me-2" />
                <span>{site.address} {site.second_line_address} {site.town} {site.city} {site.county} {site.postcode} {site?.country?.label}</span>
              </a>

              <Button className="w-100 btn-book-now" onClick={onClick}>
                {site.prebookable && t('book_now') || t('get_directions')}
              </Button>
            </div>
          </Col>
        </Row>

        {edit && (
          <div className="py-3 text-center">
            <Button className="block-option" onClick={_ => onChange({ ...props, media_left: !props.media_left })}>
              {t('marketing.switch_sides')}
            </Button>
          </div>
        ) || ''}
      </div>
    </section>
  );
}

export default Details;
