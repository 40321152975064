function GPSIcon({ variant, classDefined }) {
  return (
    <div className={`custom-logo ${classDefined || ''}`}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
        <path d="M7.00117 9.79998C8.54757 9.79998 9.80117 8.54638 9.80117 6.99999C9.80117 5.45359 8.54757 4.19999 7.00117 4.19999C5.45477 4.19999 4.20117 5.45359 4.20117 6.99999C4.20117 8.54638 5.45477 9.79998 7.00117 9.79998Z" fill="white" style={{ fill: variant ? 'var(--bs-' + variant + ')' : 'white'}} />
        <path d="M7.7 1.4483V0H6.3V1.4483C5.06821 1.60558 3.92348 2.16732 3.0454 3.0454C2.16732 3.92348 1.60558 5.06821 1.4483 6.3H0V7.7H1.4483C1.60535 8.93188 2.16702 10.0767 3.04514 10.9549C3.92326 11.833 5.06812 12.3947 6.3 12.5517V14H7.7V12.5517C8.93193 12.3948 10.0768 11.8331 10.955 10.955C11.8331 10.0768 12.3948 8.93193 12.5517 7.7H14V6.3H12.5517C12.3947 5.06812 11.833 3.92326 10.9549 3.04514C10.0767 2.16702 8.93188 1.60535 7.7 1.4483V1.4483ZM7 11.2C4.6837 11.2 2.8 9.3163 2.8 7C2.8 4.6837 4.6837 2.8 7 2.8C9.3163 2.8 11.2 4.6837 11.2 7C11.2 9.3163 9.3163 11.2 7 11.2Z" fill="white" style={{ fill: variant ? 'var(--bs-' + variant + ')' : 'white'}} />
      </svg>
    </div>
  );
}

export default GPSIcon;
