import { useEffect, useState, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { useJsApiLoader } from "@react-google-maps/api";
import DistanceMatrixServiceComponent from "../../bookings/components/DistanceMatrixServiceComponent";
import { bearing as getBearing, hashJourney, parseCoordinate }  from "@/services/Sites";
import { useAuth } from "@/services";
import * as JourneyStore from "@/services/JourneyStore";
import { useTranslation } from "react-i18next";
import { ErrorBoundary, LEVEL_ERROR } from '@rollbar/react';

function JourneyDetails({ coordinates, value, onChange, ...props}) {
  const { t } = useTranslation();

  const  mapAPIKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: mapAPIKey
    })


  const [currentLocation, setCurrentLocation] = useState(null);
  const [checked, setChecked] = useState(false);

  const bearing = useMemo(
    _ => currentLocation && coordinates && getBearing(currentLocation, coordinates),
    [currentLocation, coordinates]
  );

  const direction = useMemo(
    _ => bearing && Math.round( bearing / 45 ) % 8,
    [bearing]
  );

  const locationError = () => {
    setChecked(false);
    setCurrentLocation(false);
  }

  const showPosition = (position) => {
    setChecked(false);
    setCurrentLocation(parseCoordinate(position.coords));
  };

  const responseCallback = (updatedDestinations) => {
    JourneyStore.merge(updatedDestinations).then(_ => {
      JourneyStore.get(currentLocation, coordinates).then(onChange);
    });
  }

  useEffect(() => {
    const journey = !value && onChange && currentLocation && coordinates;

    if(journey)
    {
      JourneyStore.get(currentLocation, parseCoordinate(coordinates))
        .then(result => {
          if(result)
          {
            onChange(result)
          }
          else
          {
            setChecked(true);
          }
        })
    }
    else
    {
      setChecked(true);
    }
  }, [currentLocation, coordinates])

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, locationError);
    }
  }, []);

  if(coordinates)
  return (
    <div className={`journey-details ${props.className || ''}`}>
      {
        value && (
          <>
          {`${value.distance.text} - ${value.duration.text} - `}
          <div className="d-inline-block">
            <div style={{
                  display: 'inline-block',
                  transform: `rotate(${bearing - 90}deg)`}}>&gt;</div>
              {(() => {
                switch(direction)
                {

                  case 0: return t('north_short');
                  case 1: return t('north_west_short');
                  case 2: return t('west_short');
                  case 3: return t('south_west_short');
                  case 4: return t('south_short');
                  case 5: return t('south_east_short');
                  case 6: return t('east_short');
                  case 7: return t('north_east_short');
                }
              })()}
          </div>
        </>

      ) || onChange && checked && currentLocation && isLoaded && (
        <>
          <Spinner />

          <DistanceMatrixServiceComponent
           {...{
            destinations: [
              coordinates
            ],
            origins     : currentLocation,
            updateDistanceInformation: responseCallback
            }}
          />
        </>
      ) || ''}

    </div>
  );
}


const JourneyDetailsWrapper = (props) => (
  <ErrorBoundary level={LEVEL_ERROR} fallbackUI={({error, resetError}) => <></>}>
    <JourneyDetails {...props} />
  </ErrorBoundary>
);

export default JourneyDetailsWrapper;
