import React, {useEffect, useState} from "react";
import {Card, Pagination, Row, Col, Button, Form, Spinner} from "react-bootstrap";
import Table, { useColumns }                      from "@/components/Tables/Sites";
import {useTranslation} from "react-i18next";
import {Link, useHistory, useLocation, useParams } from "react-router-dom";
import {useAuth} from "@/services/Auth";
import { Helmet } from "react-helmet";
import * as csv from 'csv-stringify/lib/sync';
import FileSaver from 'file-saver';
import Filters from "@/components/Forms/Search";

function Sites() {
    const history = useHistory();
    const route = useLocation();

    let auth = useAuth();


    const { supplier } = useParams();

    let url = '/sites';

    if(supplier)
    {
        url = `/suppliers/${supplier}${url}`;
    }

    const {t} = useTranslation();
    const columns = useColumns(undefined, true);

    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);

    const [query, setQuery] = useState(route?.state?.query || { managing: true });

    const exportSites = () => {
        let headers = columns.map(_ => _.text);

        let exportRequest = { ...query };

        exportRequest.skip = 0;
        exportRequest.limit = 0;
        exportRequest.include = ['supplier','facilities', 'security_facilities'];

        setExporting(true);

        auth.getRequest(url, exportRequest)
            .then(response => {

                const records = response.data.sites.map((record) => {
                    return columns.map(_ => {
                        var value = record,
                        paths = _.dataField.split('.'),
                        name;

                        while((name = paths.shift()) && value)
                        {
                            value = value[name];
                        }

                       return _.exportFormatter ? _.exportFormatter(value, record) : value;
                    });
                });

                records.unshift(headers);

                const data = "\uFEFF" + csv.stringify(records, {});
                const blob = new Blob([data], {type: 'text/plain;charset=utf-8', encoding: 'UTF-8'});

                FileSaver.saveAs(blob, 'sites.csv', {});

                setExporting(false);
            })
            .catch(error => {
                console.error(error);

                setExporting(false);
            })
    }

    useEffect(() => {
        history.replace(
            route.pathname,
            {
                ...route.state,

                query,
            }
        )
    }, [query]);

    return (

        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{t('drawer_link_titles.sites')} - {t('app')}</title>
            </Helmet>

            <Card.Subtitle className="d-flex justify-content-between flex-md-row flex-column">
                <h4 className="text-primary">{t('drawer_link_titles.sites')}</h4>

                <div className="d-flex align-items-center">
                    {
                        (auth.roles.update_options) &&
                        <Link to={`/sites/options`} className="btn btn-warning mx-1">{t('marketing.options')}</Link>
                    }
                    {
                        (auth.user.is_platform_admin || auth.user.is_supplier_admin) &&
                        <Link to={`/sites/import`} className="btn btn-primary mx-1">{t('import_sites')}</Link>
                    }
                    {
                        !exporting ?
                            <Button variant="success" className="mx-md-1 my-1" onClick={() => exportSites()}>{t('export')} (.csv)</Button> :
                            <Button variant="success" className="mx-md-1 my-1" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="mx-2">{t('please_wait')}</span>
                            </Button>
                    }
                    {
                        auth.user.is_platform_admin &&
                        <Link to="/sites/create" className="btn btn-secondary mx-1">{t('new_site')}</Link>
                    }
                </div>

            </Card.Subtitle>

            <Filters searching={loading || exporting} values={query} onSubmit={(values) => setQuery({...values, managing: true})} />

            <Table url={url} query={query} showPagination={true} onData={_ => setLoading(!_)} />
        </Card>
    )
}

export default Sites;
