import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import * as moment                    from "moment";
import Select                         from "react-select";
import AsyncSelect                    from "react-select/async";
import React, { useEffect, useState }   from "react";
import {useTranslation}               from "react-i18next";
import {useAuth}                      from "@/services/Auth";
import { Cities, Countries }          from "../../../../services";
import {useForm}                      from "react-hook-form";

function Filters({options,searching, ...props}) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const formatRecord = (company) => {
        const  object = {...company};

        object.value = company.id;
        object.label = company.name;

        return object;
    };

    const [countries, setCountries] = useState(null);

    const onSubmit = (values) => {
        let updatedValues = values;

        console.log(updatedValues);

        props.onSubmit(updatedValues)
    };

    const {
              handleSubmit,
              register,
              watch,
              setValue
          } = useForm({
        defaultValues: {
            ...props.values
        }
    });


    const [advanceFilters, setAdvanceFilters] = useState(false);

    useEffect(() => {
        if (countries === null) {
            Countries.active()
                .then(countries => setCountries(countries.map(formatRecord)))
                .catch(_ => setCountries([]));
        }
    }, [countries]);

    useEffect(() => {
        register('country_id');
    }, []);

    const toDate = watch('to');

    const country_id = watch('country_id');

    const country = countries?.find && countries.find(_ => _.id == country_id);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="my-4">
                <Col sm={4} className="col-12">
                    <Form.Control
                        type="text"
                        {...register('search')}
                        placeholder={t('search_for_anything')}
                        />
                </Col>
                <Col sm={3} className="col-12 mt-md-0 mt-3">
                    <div className="d-grid gap-2">
                    {
                        !searching ?
                            <Button type="submit" variant="success" className="w-100">
                                {advanceFilters ? t('filters_labels.apply') : t('search')}
                            </Button>
                            :
                            <Button variant="success" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="mx-2">{t('please_wait')}</span>
                            </Button>
                    }
                    </div>
                </Col>
                <Col sm={4} className="col-12">
                    <Button variant="link" className="my-md-0 my-3" onClick={(e) => {
                        e.preventDefault();
                        setAdvanceFilters(!advanceFilters)
                    }}>{!advanceFilters ? t('filters_labels.show_advance_filters') : t('filters_labels.hide_advance_filters')}</Button>
                </Col>
            </Row>

            <Row className={`my-md-4 my-2 ${!advanceFilters && 'd-none'}`}>
                <Col sm={6}>
                    <Form.Label className="mb-2">
                        {t('filters_labels.filter_by_creation_date')}
                    </Form.Label>

                    <div className="d-flex align-items-center">
                        <Form.Control
                            placeholder={t('from')}
                            type="date"
                            {...register('from')}
                            max={toDate ? moment(toDate).format('YYYY-MM-DD') : null}
                            />

                        <span className="mx-2">{t('to')}</span>

                        <Form.Control
                            placeholder={t('to')}
                            type="date"
                            {...register('to')}
                            min={toDate ? moment(toDate).format('YYYY-MM-DD') : null}
                            />
                    </div>
                </Col>

                <Col md={3}>
                    <Form.Label className="mb-2">
                        {t('filters_labels.filter_by_region')}
                    </Form.Label>

                    <Select
                        className="react-select"
                        isClearable={true}
                        value={country}
                        onChange={_ => setValue('country_id', _.value)}
                        placeholder={t('region_country')}
                        options={countries}
                        />
                </Col>

                <Col md={3}>
                    <Form.Label className="mb-2">
                        {t('filters_labels.filter_by_company_name')}
                    </Form.Label>

                    <Form.Control
                        type="text"
                        placeholder={t('filters_labels.filter_by_company_name')}
                        {...register('company_name')}
                        />
                </Col>


                {auth.roles.view_compliance && (
                <Col md={3}>
                    <Form.Label className="mb-2">
                        {t('compliance.defects')}
                    </Form.Label>

                    <Form.Select
                        {...register('defects')}
                        >
                        <option value=""></option>
                        <option value="Fault">{t('compliance.severity.Fault')}</option>
                        <option value="Severe">{t('compliance.severity.Severe')}</option>

                    </Form.Select>
                </Col>
                ) || ''}

            </Row>
        </Form>
    )
}

export default Filters;
