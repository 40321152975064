import { Link } from "react-router-dom";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-bootstrap";
import { useAuth } from "@/services";
import { CheckIcon } from "@/components/icons";
import Markdown from "@/components/Markdown";

function Inactive()
{
  const {t} = useTranslation();
  const auth = useAuth();
  // TODO
 
  return (
    <div className="container">
      <Helmet>
        <title>{t('drawer_link_titles.compliance')} - {t('app')}</title>
      </Helmet>

      <Row>
        <Col xl={{ span: 6, order: 'last' }} className="pb-2">
          <img src="/images/compliance-cover.jpg" style={{ maxWidth: '100%' }} className="mb-3" />
        </Col>

        <Col xl={6}>
          <h1 className="text-primary mb-3 pb-1">{t('compliance.inactive.title')}</h1>

          <Markdown content={t('compliance.inactive.content')} />

          {auth.user.is_fleet_manager && (
            <Link to={'/settings'} className="btn btn-success">
              {t('compliance.inactive.button_text')}
            </Link>
          ) || (auth.user.is_client_admin || auth.user.is_client_operator)  && (
            <p className="text-success">
              {t('compliance.inactive.message_to_clients')}
            </p>
          ) || (
            <p className="text-success">
              {t('compliance.inactive.message_to_drivers')}
            </p>
          )}

        </Col>
      </Row>
    </div>
  );
}

export default Inactive;
