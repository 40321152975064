import React, {useEffect, useState, useRef} from "react";
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import LoadingPage from "@/components/LoadingPage";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";
import {useAuth} from "@/services/Auth";
import Filters from "./components/Filters";
import { Helmet } from "react-helmet";
import * as csv from 'csv-stringify/lib/sync';
import FileSaver from 'file-saver';
import Paginate from "@/components/Paginate";
import {useColumns} from "../../../components/Tables/Clients";


function Clients(props) {
    const history = useHistory();
    const route = useLocation();

    const {t} = useTranslation();
    let auth = useAuth();

    const request = useRef(null);

    const perPage = 15;

    const [exporting, setExporting] = useState(false);

    const [totalRecords, setTotal] = useState(false);
    const [clients, setClients] = useState(null);

    const [query, setQuery] = useState(route?.state?.query || {});

    const page = (query?.skip || 0) / perPage;

    const getClients = (query) => {
        request?.current?.abort && request.current.abort();

        setClients(false);

        const critera = {...query};

        critera.take = perPage;

        request.current = auth.getRequest('/clients', critera)

        request.current
           .then(response => {
               setClients(response.data.clients);
               setTotal(response.data.total);
           })
    };

    const columns       = useColumns();
    const columnsExport = useColumns(undefined, true);

    const exportClients = () => {
        let headers = columnsExport.map(_ => _.text);

        let exportRequest = { ...query };

        exportRequest.skip = 0;
        exportRequest.take = 0;

        setExporting(true);

        auth.getRequest('clients', exportRequest)
            .then(response => {

                const records = response.data.clients.map((record) => {
                    return columnsExport.map(_ => {
                        var value = record,
                        paths = _.dataField.split('.'),
                        name;

                        while((name = paths.shift()) && value)
                        {
                            value = value[name];
                        }

                       return _.exportFormatter ? _.exportFormatter(value, record) : value;
                    });
                });

                records.unshift(headers);

                const data = "\uFEFF" + csv.stringify(records, {});
                const blob = new Blob([data], {type: 'text/plain;charset=utf-8', encoding: 'UTF-8'});

                FileSaver.saveAs(blob, 'clients.csv', {});

                setExporting(false);
            })
            .catch(error => {
                console.error(error);

                setExporting(false);
            })
    }

    const changePage = ({ selected }) => {
        const critera = { ... query };

        critera.skip = selected * perPage;

        setQuery(critera);
    };

    useEffect(() => {
        getClients(query);

        history.replace(
            route.pathname,
            {
                ...route.state,

                query,
            }
        )

        return _ => request?.current?.abort && request.current.abort();
    }, [query]);

    const statePop = (event) => {
        if(window.location.pathname == route.pathname)
        {
            event?.state?.state?.query && setQuery(event.state.state.query);
            event?.state?.state?.clients && setQuery(event.state.state.clients);
        }
    };

    useEffect(() => {
        window.addEventListener('pushState', statePop);
        window.addEventListener('popstate', statePop);

        return () => {
            window.removeEventListener('pushState', statePop);
            window.removeEventListener('popstate', statePop);
        };
    }, []);


    return (
        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{t('drawer_link_titles.clients')} - {t('app')}</title>
            </Helmet>


            <Card.Subtitle className="d-flex justify-content-start justify-content-md-between flex-md-row flex-column">
                <h4 className="text-primary">{t('drawer_link_titles.clients')}</h4>
                <div className="d-flex  flex-md-row flex-column">
                    {
                        auth?.roles?.create_client &&
                        <Link to={`/clients/import`} className="btn btn-primary mx-md-1 my-1">{t('import_clients')}</Link>
                    }
                    {
                        auth.user.is_platform_admin &&
                        <Link to={`/clients/import/payments`} className="btn btn-primary mx-md-1 my-1">{t('import_payments')}</Link>
                    }
                    {
                        !exporting ?
                            <Button variant="success" className="mx-md-1 my-1" onClick={() => exportClients()}>{t('export')} (.csv)</Button> :
                            <Button variant="success" className="mx-md-1 my-1" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="mx-2">{t('please_wait')}</span>
                            </Button>
                    }
                    {
                        auth?.roles?.create_client &&
                        <Link to="/clients/create" className="btn btn-secondary mx-md-1 my-1">{t('new_client')}</Link>
                    }
                </div>
            </Card.Subtitle>

            <Filters
                searching={!clients || exporting}
                values={query}
                onSubmit={setQuery}
                />

            <BootstrapTable
                keyField="id"
                striped
                responsive
                hover
                columns={columns}
                data={clients || []}
                noDataIndication={clients && t('no_data') || t('loading')}
                />

            {totalRecords && clients && <Paginate {...{ changePage, totalRecords, page, perPage }} /> || ''}
        </Card>
    );
}

export default Clients;
