import { getRequest } from "./Api";

let daily_report_vehicle_templates_request;
let daily_report_vehicle_templates;

export default {
  get: function () {
    return new Promise((resolve, reject) => {

      if(daily_report_vehicle_templates)
      {
        resolve(daily_report_vehicle_templates);
      }
      else
      {
        if(!daily_report_vehicle_templates_request)
        {
          daily_report_vehicle_templates_request = getRequest('/reports/daily-vehicle-templates');
        }

        daily_report_vehicle_templates_request
          .then((response) => {
            daily_report_vehicle_templates = response.data;

            resolve(daily_report_vehicle_templates);
          })
          .catch(error => reject(error));
      }
    });
  }

};
