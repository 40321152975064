import { useHistory, useLocation, Link } from "react-router-dom";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import { useLayout } from "@/layouts/Layout";
import { useForm } from 'react-hook-form';
import { Helmet } from "react-helmet";
import { VehicleDailyReportTemplates }   from "@/services";
import { Form, Button, Row, Col } from "react-bootstrap";
import { CheckIcon, AlertIcon } from "@/components/icons";
import LoadingPage                    from "@/components/LoadingPage";
import Calendar from 'react-calendar';
import moment from  'moment-timezone';

function ComplianceDasboard() {
  const {t} = useTranslation();
  const layout = useLayout();
  const route = useLocation();
  const history = useHistory();

  const requestStore = useRef({});
  const { current: requests } = requestStore;

  const [vehicles, setVehicles] = useState(route.state && route.state.vehicles || false);
  const [reports, setReports] = useState(route.state && route.state.reports || null);

  const auth = useAuth();

  const [occurance_date, setOccuranceDate] = useState(route?.state?.occurance_date || new Date());

  const [vehicle_id, setVehicleId] = useState(route?.state?.vehicle_id || "");

  const vehicle = vehicle_id && vehicles && vehicles.find(v => v.id == vehicle_id);

  const [schemas, setSchemas] = useState(null);
  const schema = vehicle && schemas && schemas[ vehicle.vehicle_type ] || null;

  const updateSchemas = useCallback(value => {
    setSchemas(value);
  }, [schemas]);


  useEffect(() => {
    if(!schemas)
    {
      VehicleDailyReportTemplates.get().then(updateSchemas);
    }
  }, [schemas]);

  useEffect(() => {
    if(!vehicles)
    {
      requests.vehicles && requests?.vehicles?.abort && requests.vehicles.abort();

      requests.vehicles = auth.getRequest('vehicles', { take: 0 })

      requests.vehicles
          .then((response) => {
              setVehicles(response.data.vehicles);

              if(response.data.vehicles.length === 1)
              {
                onVehicleChange(response.data.vehicles[0].id);
              }
          })
          .catch(() => console.error(arguments))
    }
  }, [vehicles]);

  useEffect(() => {
    if(!reports)
    {
      requests.reports && requests?.reports?.abort && requests.reports.abort();

      requests.reports = auth.getRequest('reports', {
        vehicle_id,
        occurance_from: moment(occurance_date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        occurance_to: moment(occurance_date).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
      })

      requests.reports
          .then((response) => {
              setReports(response.data.data);
          })
          .catch(() => console.error(arguments))
    }
  }, [reports, vehicle_id]);

  useEffect(() => {
    history.replace(route.pathname, {
      ...route.state,

      vehicles,
      reports,
    });
  }, [vehicles, reports]);

  const onDateChange = occurance_date => {

    history.push(route.pathname, {
      ...(route.state || {}),

      occurance_date
    });
  };

  const onVehicleChange = vehicle_id => {
    history.push(route.pathname, {
      ...(route.state || {}),

      vehicle_id
    });
  };

  const statePop = (event) => {
    if(window.location.pathname == route.pathname)
    {
        setOccuranceDate(event.state && event.state.state?.occurance_date || new Date());
        setVehicleId(event.state && event.state.state?.vehicle_id || "");
        setReports(null);
        layout.scrollTop();
    }
  };

  useEffect(() => {
      window.addEventListener('pushState', statePop);
      window.addEventListener('popstate', statePop);

      return () => {
          window.removeEventListener('pushState', statePop);
          window.removeEventListener('popstate', statePop);
      };
  }, []);

  useEffect(() => {
      return () => {
          for(var key in requests)
          {
              requests[key] && requests[key].abort && requests[key].abort();
          }
      }
  }, []);

  if(!vehicles || reports === null)
  {
    return <LoadingPage />;
  }

  return (
    <div className="container">
      <Helmet>
        <title>{t('drawer_link_titles.compliance')} - {t('app')}</title>
      </Helmet>

      <h1 className="text-primary">
        {t('drawer_link_titles.compliance')}
      </h1>

      <Form.Group className="mb-2 pt-3 small">
        <div><strong>{auth.user.name}</strong></div>

        <Form.Label className="mb-2">
          {t('compliance.reporting_to')  + ' '}
          <strong>{auth.user?.company?.name || ''}</strong>
        </Form.Label>
      </Form.Group>

      <Row>
        <Col xl={4}>
          <Form.Group className="mb-3">
            <Form.Select
              value={vehicle_id}
              onChange={event => onVehicleChange(event.target.value)}>
              <option value="">--{t('choose')}--</option>
              {vehicles.map((vehicleData) => <option value={vehicleData.id} key={vehicleData.id}>{t(vehicleData.registration_number)}</option>)}
            </Form.Select>
          </Form.Group>

        {
            (vehicle && vehicle.vehicle_issues) && (
                <>
                    <div className={'text-danger my-3'}>
                        {t('vehicle_existing_issue')}.&nbsp;
                        <a href='#' onClick={(event) => {
                            event.preventDefault();
                            history.push(`/vehicles/${vehicle.id}/issues`, {vehicle});
                        }}>
                            {t('view_details')}
                        </a>
                    </div>
                </>
            )
        }

          {vehicle && (
            <Calendar
              prevLabel={<span className="bi bi-chevron-left" />}
              nextLabel={<span className="bi bi-chevron-right" />}
              value={occurance_date} onChange={onDateChange} maxDate={new Date()}
            />
          ) || ''}
        </Col>

        {vehicle && (
        <Col xl={8}>

          <Row>


          {schema && <Col lg={6} className="py-3">
            <h3 className="text-primary">{t('compliance.DailyReport.title')}</h3>
            <p className="small">{t('compliance.DailyReport.dashboard_summary')}</p>

            {reports?.filter && reports.filter(_ => _.type == 'DailyReport').length > 0 && reports.filter(_ => _.type == 'DailyReport').map((report, index) => {
              const severity = report.severity === 'Severe' && 'danger' || report.severity  === 'Fault' && 'warning' || 'info';

              return (
                <span key={report.guid}>

                <Link
                  className={`btn btn-${report.status === 'Incomplete' ? 'warning' : 'success'} mb-2 w-100`}
                  to={report.status === 'Incomplete' && moment.utc(report.occurance_at).startOf('day').isSame(moment.utc().startOf('day')) && { pathname: '/compliance/daily-report', state: { report, vehicle, occurance_date } } || '/compliance/' + report.guid}>
                  {report.status === 'Incomplete' ? t('compliance.DailyReport.incomplete') :  t('compliance.DailyReport.complete') }
                </Link>


                {report.status === 'Incomplete' ? null : (
                  <Button  className={`btn btn-${severity} mb-2 w-100`}>
                    {severity === 'danger' && t('compliance.DailyReport.severe_issues') || severity === 'warning' && t('compliance.DailyReport.minor_issues') || t('compliance.DailyReport.no_issues')}

                    {severity === 'info' ? '' : <AlertIcon />}
                  </Button>
                )}
                </span>
              );
            }) || moment(occurance_date).utc().startOf('day').isSame(moment().utc().startOf('day')) && (

              <Link className="btn btn-success mb-2 w-100" to={{ pathname: '/compliance/daily-report', state: { vehicle, occurance_date } }}>
                {t('begin')}
              </Link>
            )}
          </Col> || ''}

          <Col lg={6} className="py-3">
            <h3 className="text-primary">{t('compliance.LoadingReport.title')}</h3>
            <p className="small">{t('compliance.LoadingReport.dashboard_summary')}</p>

            {reports?.filter && reports.filter(_ => _.type == 'LoadingReport').map((report, index) => (
              <Link key={report.guid} className="btn btn-info mb-2 w-100" to={'/compliance/' + report.guid}>
                {(report?.detail?.company_name || '') + ' ' + moment.utc(report.occurance_at).local().format('HH:mm')}
              </Link>
            ))}

            <Link className="btn btn-success mb-2 w-100" to={{ pathname: '/compliance/loading-report', state: { vehicle, occurance_date } }}>
              {t('begin')}
            </Link>
          </Col>

          <Col lg={6} className="py-3">
            <h3 className="text-primary">{t('compliance.JourneyReport.title')}</h3>
            <p className="small">{t('compliance.JourneyReport.dashboard_summary')}</p>

            <div className="mx-n2 d-flex">
              <Link className="btn btn-success mb-2 mx-2 w-50" to={{ pathname: '/compliance/journey-report', state: { vehicle, occurance_date } }}>
                {t('compliance.JourneyReport.start')}
              </Link>

              <Link className="btn btn-primary mb-2 mx-2 w-50" to={{ pathname: '/compliance/journeys', state: { vehicle } }}>
                {t('compliance.JourneyReport.list')}
              </Link>
            </div>
          </Col>

          <Col lg={6} className="py-3">
            <h3 className="text-primary">{t('compliance.CrimeReport.title')}</h3>
            <p className="small">{t('compliance.CrimeReport.dashboard_summary')}</p>

            {reports?.filter && reports.filter(_ => _.type == 'CrimeReport').map((report, index) => (
              <Link key={report.guid} className="btn btn-info mb-2 w-100" to={'/compliance/' + report.guid}>
                {moment.utc(report.created_at).local().format('HH:mm')}
              </Link>
            ))}

            <Link className="btn btn-success mb-2 w-100" to={{ pathname: '/compliance/crime-report', state: { vehicle, occurance_date } }}>
              {t('begin')}
            </Link>
          </Col>

          <Col lg={6} className="py-3">
            <h3 className="text-primary">{t('compliance.BorderReport.title')}</h3>
            <p className="small">{t('compliance.BorderReport.dashboard_summary')}</p>

            {reports?.filter && reports.filter(_ => _.type == 'BorderReport').map((report, index) => (
              <Link key={report.guid} className="btn btn-info mb-2 w-100" to={'/compliance/' + report.guid}>
                {moment.utc(report.occurance_at).local().format('HH:mm')}
              </Link>
            ))}

            <Link className="btn btn-success mb-2 w-100" to={{ pathname: '/compliance/border-report', state: { vehicle, occurance_date } }}>
              {t('begin')}
            </Link>
          </Col>

          <Col lg={6} className="py-3">
            <h3 className="text-primary">{t('compliance.TrafficIncidentReport.title')}</h3>
            <p className="small">{t('compliance.TrafficIncidentReport.dashboard_summary')}</p>

            {reports?.filter && reports.filter(_ => _.type == 'TrafficIncidentReport').map((report, index) => (
              <Link key={report.guid} className="btn btn-info mb-2 w-100" to={'/compliance/' + report.guid}>
                {moment.utc(report.occurance_at).local().format('HH:mm')}
              </Link>
            ))}

            <Link className="btn btn-success mb-2 w-100" to={{ pathname: '/compliance/traffic-incident-report', state: { vehicle, occurance_date } }}>
              {t('begin')}
            </Link>
          </Col>

        </Row>
      </Col>
      ) || ''}
      </Row>
    </div>
  );
}

export default ComplianceDasboard;
