function HistoryIcon({classDefined}) {
    return (
        <div className={`custom-logo ${classDefined}`}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.12 11.526C0.862 16.306 5.022 19.996 10 19.996C15.5 19.996 20 15.496 20 9.998C20 4.5 15.5 5.00214e-08 10 5.00214e-08C8.44752 -0.000155233 6.91634 0.361228 5.52776 1.05552C4.13918 1.74981 2.93136 2.75792 2 4V5.00214e-08H0.00300002L0 7H7V5H3.8C5.2 3.2 7.502 2 10.002 2C14.4 2 18 5.6 18 9.998C18 14.396 14.4 17.998 10 17.998C6.123 17.998 2.87 15.203 2.152 11.526H0.12Z" fill="white"/>
            </svg>
        </div>
    );
}

export default HistoryIcon;
