import {Button, Card, Col, Container, Row, Spinner} from "react-bootstrap";
import { useLayout }                            from "@/layouts/Layout";
import {useAuth}                                    from "@/services/Auth";
import {useState}                                   from "react";
import {useTranslation}                             from "react-i18next";
import {ErrorMessage, Field, Formik, Form}          from "formik";
import * as Yup                                     from "yup";
import Logo                                         from "@/components/Logo";

function SignUp(props) {

    const {t, i18n} = useTranslation();

    let auth = useAuth();
    const [loading, setLoading] = useState(false);

    const signUp = auth.signUp;

    let passwordMinLength = 6;

    const validation = Yup.object().shape({
        name                       : Yup.string()
                                        .required(t('form_validation.name_is_required')),
        email                      : Yup.string()
                                        .email(t('form_validation.invalid_email_address'))
                                        .required(t('form_validation.email_address_is_required')),
        phone_number               : Yup.string()
                                        .required(t('form_validation.phone_number_is_required')),
        vehicle_registration_number: Yup.string()
                                        .required(t('form_validation.vehicle_registration_number_is_required')),
        password                   : Yup.string()
                                        .min(passwordMinLength, t('form_validation.password_invalid_min_length', {length: passwordMinLength}))
                                        .required(t('form_validation.password_is_required')),
        password_confirmation      : Yup.string().oneOf([Yup.ref('password'), null], t('form_validation.password_is_must_match'))
                                        .required(t('form_validation.password_confirm_required')),
    });

    const onSubmit = (payload) => {
        setLoading(true);
        signUp(payload)
            .then(response => {
                setLoading(false);
                props.history.push('/')
            })
            .catch(error => {
                setLoading(false);
            })
    };
    return (

        <Container className="login-container">
            <Row style={{height: '100%'}}>
                <Col className="col-12 d-none d-md-flex align-items-center justify-content-center" md={6}>
                    <Logo className="desktop-logo" size="250"/>
                </Col>
                <Col className="col-12 d-flex align-items-center justify-content-center my-2" md={6}>
                    <Card style={{width: '450px', minWidth: '300px'}} className="login-card px-2 px-md-0 login-card-height login-card-md-height">
                        <Card.Title className="d-flex justify-content-center align-items-center py-5 d-md-none d-block">
                            <Logo as="div" className="mobile-logo" size="123"/>
                        </Card.Title>
                        <Card.Body>
                            <Card.Title className="text-white">{t('create_account')}</Card.Title>

                            <Formik
                                initialValues={{
                                    name                       : '',
                                    email                      : '',
                                    password                   : '',
                                    password_confirmation      : '',
                                    phone_number               : '',
                                    vehicle_registration_number: '',
                                }}
                                validationSchema={validation}
                                onSubmit={onSubmit}
                            >
                                {({errors, touched}) => (
                                    <Form>
                                        <div className="form-group my-4">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="name">{t('name')}</label>
                                                    <Field
                                                        type="text"
                                                        name="name"
                                                        placeholder={t('enter_name')}
                                                        className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                                                    />
                                                    <small>
                                                        <ErrorMessage component="div" name="name" className="text-danger  py-1"/>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group my-4">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="email">{t('email_address')}</label>
                                                    <Field
                                                        type="email"
                                                        name="email"
                                                        placeholder={t('enter_email_address')}
                                                        className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                                    />
                                                    <small>
                                                        <ErrorMessage component="div" name="email" className="text-danger  py-1"/>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group my-4">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="email">{t('password')}</label>
                                                    <Field
                                                        type="password"
                                                        name="password"
                                                        placeholder={t('password')}
                                                        className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`}
                                                    />
                                                    <small>
                                                        <ErrorMessage component="div" name="password" className="text-danger  py-1"/>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group my-4">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="email">{t('repeat_password')}</label>
                                                    <Field
                                                        type="password"
                                                        name="password_confirmation"
                                                        placeholder={t('password')}
                                                        className={`form-control ${errors.password_confirmation && touched.password_confirmation ? 'is-invalid' : ''}`}
                                                    />
                                                    <small>
                                                        <ErrorMessage component="div" name="password_confirmation" className="text-danger  py-1"/>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group my-4">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="email">{t('phone_number')}</label>
                                                    <Field
                                                        type="text"
                                                        name="phone_number"
                                                        placeholder={t('enter_phone_number')}
                                                        className={`form-control ${errors.phone_number && touched.phone_number ? 'is-invalid' : ''}`}
                                                    />
                                                    <small>
                                                        <ErrorMessage component="div" name="phone_number" className="text-danger  py-1"/>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group my-4">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="email">{t('vehicle_registration_number')}</label>
                                                    <Field
                                                        type="text"
                                                        name="vehicle_registration_number"
                                                        placeholder={t('vehicle_registration_number')}
                                                        className={`form-control ${errors.vehicle_registration_number && touched.vehicle_registration_number ? 'is-invalid' : ''}`}
                                                    />
                                                    <small>
                                                        <ErrorMessage component="div" name="vehicle_registration_number" className="text-danger  py-1"/>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group my-4">
                                            <div className="d-grid gap-2">
                                                {
                                                    !loading ?
                                                    <Button type="submit" variant="primary">
                                                        {t('sign_up')}
                                                    </Button>
                                                             :
                                                    <Button variant="primary" disabled>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        <span className="mx-2"> {t('please_wait')}</span>
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default SignUp;
