import { useTranslation } from "react-i18next";
import { DisplayWysiwyg, DisplayLocation, DisplayAttachments } from "@/components/Elements";
import { Form, Button, Card, Table, Row, Col, Modal } from "react-bootstrap";

function DisplayChecks({ className, ...subject }) {
  const { t } = useTranslation();

  return (
    <div className={className || ''}>
      {subject?.checks?.length > 0 && subject.checks.map((check, index) => {
        return (
          <div className={`card print-break-inside-avoid ${check.severity == 'Severe' && 'text-danger' || check.severity == 'Fault' && 'text-warning' || ''}`} key={check.key}>
            <div className="card-body">
              <h5 className="card-title">{t(check.key)}</h5>
              <p className="card-text">{check.value && [1, 0, -1, '1', '0', '-1'].includes(check.value) && (check.value == "-1" ? t('na') : (check.value == "0" ? t('no') : t('yes'))) || t(check.value)}</p>
              <DisplayAttachments attachments={check.attachments?.filter && check.attachments.filter(_ => !_.embeded) || []} />
              <DisplayWysiwyg html={check.content} />
            </div>
            <hr />
          </div>
        )
      })}
    </div>
  );
}

export default DisplayChecks;
