import Help         from "../../views/pages/Help";
import HelpIcon     from "../../components/icons/HelpIcon";
import MyHistory    from "../../views/pages/history/MyHistory";
import SiteFeedback from "../../views/pages/history/SiteFeedback";
import HistoryIcon  from "../../components/icons/HistoryIcon";


const myHistoryRoutes = [

    {
        path         : "/history",
        component    : MyHistory,
        exact        : true,
        gate         : 'view_history',
        title        : 'My History',
        translate_key: 'my_history',
        // icon         : 'bi-question-circle',
        icon         : <HistoryIcon/>,
        main         : true,
    },

    {
        path     : "/history/site-feedback/:id",
        component: SiteFeedback,
        exact    : true,
        gate     : 'view_history',
        main     : false,
    },
]

export default myHistoryRoutes;
