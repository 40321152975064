function FavouriteIcon({classDefined}) {
    return (
        <div className={`custom-logo ${classDefined}`}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.5133 19.924C4.03081 20.188 3.48331 19.7253 3.58081 19.1347L4.6183 12.8282L0.214566 8.35372C-0.196683 7.93507 0.0170667 7.16976 0.568315 7.0871L6.6908 6.15913L9.42079 0.389987C9.66704 -0.129996 10.3333 -0.129996 10.5795 0.389987L13.3095 6.15913L19.432 7.0871C19.9833 7.16976 20.197 7.93507 19.7845 8.35372L15.382 12.8282L16.4195 19.1347C16.517 19.7253 15.9695 20.188 15.487 19.924L9.99829 16.9161L4.51205 19.924H4.5133Z" fill="white"/>
            </svg>
        </div>
    );
}

export default FavouriteIcon;
