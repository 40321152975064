import React, { useEffect, useState, useRef }   from "react";
import { Modal } from "react-bootstrap";


function DisplayWysiwyg({ html })
{
  const ref = useRef(null);

  const [img, setImg] = useState(null);

  const onImgClick = (event) => {
    setImg(event.target.src);
  };

  useEffect(() => {
    ref.current.innerHTML = html || '';

    const imgs = ref.current.querySelectorAll('img');

    for(var i = 0; i < imgs.length; i++)
    {
      const anchor = document.createElement('a');
      anchor.href = 'javascript:void(0);';
      anchor.onclick = onImgClick;

      imgs[i].after(anchor);

      anchor.appendChild(imgs[i]);
    }
  }, [ref.current]);

  return (
    <>
      <div ref={ref} className="wysiwyg-content" />

     <Modal show={!! img} onHide={_ => setImg(null)} fullscreen={true}>
      <Modal.Header closeButton>
      </Modal.Header>

      <Modal.Body className="d-flex align-items-center justify-content-center">
        <img src={img} style={{ maxWidth: '100%' }} />
      </Modal.Body>

    </Modal>
    </>
  );
}


export default DisplayWysiwyg;
