import {Button, Card}     from "react-bootstrap";
import {Link, Redirect}   from "react-router-dom";
import React, {useEffect} from "react";
import {useTranslation}   from "react-i18next";
import {useAuth}          from "@/services/Auth";

function AccountStopped(props) {
    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const updateErrorPage = auth.updateErrorPage;
    useEffect(() => {
        updateErrorPage(true);
        return () => {
            updateErrorPage(false);
        }
    }, []);


    const logOut = () => {
        auth.signOut().then(response => {
            window.location.reload();
        });
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{height: "100%"}}>
            <Card border="light">
                <Card.Body className="text-center">
                    <i className="bi bi-x-circle" style={{fontSize: "10rem"}}/>
                    <Card.Text>
                        {t('your_account_is_current_on_stop')}
                    </Card.Text>
                </Card.Body>
                <Card.Footer className="d-grid gap-2">
                    <Button className="btn-icon" variant="primary" onClick={() => logOut()}>
                        <div className="d-flex align-items-center justify-content-between mx-2">
                            <span className="me-2">{t('logout')}</span>
                            <i className="bi bi-arrow-left-circle"/>
                        </div>
                    </Button>
                </Card.Footer>
            </Card>
        </div>
    )
}

export default AccountStopped;
