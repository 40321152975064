import React, {useEffect, useReducer, useState} from "react";
import {useAuth}                                from "@/services/Auth";
import {useParams}                              from "react-router-dom";
import LoadingPage                              from "@/components/LoadingPage";
import {Card, Container}                        from "react-bootstrap";
import {useTranslation}                         from "react-i18next";
import UserForm                                 from "./UserForm";
import * as Yup                                 from "yup";
import SuccessDialog                from "../../../components/Modals/SuccessDialog";
import { Helmet } from "react-helmet";

function UserEdit(props) {

    const {t, i18n} = useTranslation();

    let auth = useAuth();

    let {id} = useParams();


    const [updated, setUpdated] = useState(false);

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [updatingUser, setUpdating] = useState(false);
    const [serverErrors, setServerErrors] = useState([]);



    const handleClose = () => {
        props.history.push(`/users/${user.id}`)
    };

    useEffect(() => {
        if(!user || user.id != id)
        {
            auth.getRequest('/users/' + id)
                .then(response => {
                    setUser(response.data.user)
                })
        }
    }, [user, id]);

    const onSubmit = (payload) => {
        setUpdating(true);
        auth.putRequest(`/users/${user.id}`, payload)
            .then(response => {
                setUpdating(false);
                if (response.data.message && response.data.message === "OK") {
                    setUpdated(true);
                }
            })
            .catch(error => {
                console.log(error);
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }
                    setServerErrors(serverErrors)
                }
                setUpdating(false);
            })
    };


    if(!user || loading)
    {
        return <LoadingPage/>;
    }

    return (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('edit')} / {t('drawer_link_titles.users')} - {t('app')}</title>
                </Helmet>

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h3>{user.name}</h3>
                </Card.Subtitle>
                <SuccessDialog
                    show={!!updated}
                    message="messages.user_updated"
                    handleClose={handleClose}
                />
                <UserForm
                    task="update"
                    buttonText="create_user"
                    {...{
                        onSubmit,
                        serverErrors,
                        user,
                        processing: updatingUser,
                    }}
                  />
            </Card>
    )

}

export default UserEdit;
